import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Pagination as _Pagination } from 'antd';
// import { useHistory, useParams, useLocation } from "react-router-dom";

export const Pagination = ({ total, current, pageSize, onChange }) => {
  const [pagination, setPagination] = useState({
    current,
    pageSize: pageSize || 25,
  })

  const onPaginationChange = (page, pageSize) => {
    // let new_pagination = { ...pagination, page, pageSize };
    setPagination({ current: page });
    onChange({ page, pageSize })
  }

  useEffect(() => {
    setPagination({ ...pagination, current });
    //   return () => { effect };
  }, [current])


  return (<>
    <_Pagination
      {...pagination}
      total={total}
      onChange={onPaginationChange}
      showSizeChanger={false}
      // total={props.total}
    />


  </>)
}

export default Pagination;