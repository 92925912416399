import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'

// addReducers({ admins: reducers });
addModule(
  {
    permission_required:'delivery-slots-access',
    path: `${ROOT}`, component: Home, icon: 'clock', title: "Delivery Slot", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
