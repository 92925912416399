/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import { ContentArea, E404, LayoutWrapper } from "Layout_v1";
import { Col, Row } from 'antd';

import { ROOT, MODULE } from './constants'
import IconsList from './containers/IconsList';
import AllSettings from './containers/AllSettings';
import SystemUpdates from './containers/SystemUpdates';
import Orders from './containers/orders';


// const 

/**
 * Home
 */
export const Home = props => {
    return (<LayoutWrapper topMenu={null}>
        <Row style={{flexWrap:"nowrap"}}>
            <Col flex="200px"><ContentArea>
                <p><Link to={`${ROOT}/icons`}>Icons</Link></p>
                <p><Link to={`${ROOT}/all-settings`}>All Settings</Link></p>
                <p><Link to={`${ROOT}/system_updates`}>System Updates</Link></p>
                <p><Link to={`${ROOT}/orders`}>Ordes management</Link></p>
            </ContentArea></Col>
            <Col flex="auto">
                <ContentArea>
                    <Switch>
                        <Route exact={true} path={`${ROOT}/all-settings`} component={AllSettings} />
                        <Route exact={true} path={`${ROOT}/icons`} component={IconsList} />
                        <Route exact={true} path={`${ROOT}/system_updates`} component={SystemUpdates} />
                        <Route exact={true} path={`${ROOT}/orders`} component={Orders} />
                        <Route key={999} component={E404} />
                    </Switch>
                </ContentArea>
            </Col>
        </Row>
    
    </LayoutWrapper>)

}

Home.propTypes = {
    // login: PropTypes.object.isRequired,
}

export default Home;