import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'antd';
import { Button } from 'Common/components'
import { FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'


export const Status_Declined = ({ onCancel, onSubmit, order }) => {
    const on_submit = async (values) => {
        const input = {
            _id: order._id,
            status: "declined",
        }

        return onSubmit(input);
    }

    return (<>
        <Alert message={<>Are you sure to change order status to <b>Declined</b>?</>}
            description={<>
                <p>Following actions will be performed</p>
                <ol>
                    <li>Order will be locked</li>
                    <li>Remove pickup data</li>
                    <li>Remove dispatch data</li>
                    <li>All baskets will be released</li>
                    <li>Update order status</li>
                </ol>
            </>}
            type="warning" showIcon />

        <FormComponent onSubmit={on_submit} id='OrderStatusUpdateForm' fields={{ status: "declined", pickup_data: order.items }} debug={true}
            form_render={formProps => {
                const { values, submitting, form, valid, dirty } = formProps;

                return (<>

                    <div style={{ height: '30px' }} />
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white-outline" disabled={submitting}>Cancel</Button></Col>
                        <Col><Button loading={submitting} htmlType="submit">Update Order Status</Button></Col>
                    </Row>

                </>)
            }}
        />

    </>)
}
Status_Declined.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
