import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BarcodeScanner, Barcode, Drawer, Button, Heading, Icon, Loader, Table, DevBlock, Avatar, IconButton } from 'Common/components'
import { Select } from 'Common/components/Form'
import { message, Row, Col, Alert, Popconfirm, Modal, InputNumber, Input } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import RenderInvoice from './RenderInvoice'
import CustomerInformation from './CustomerInformation'
import RenderHistory from './RenderHistory'
import { utcToDate, lightOrDark, jsonStringify } from 'Common/scripts/Functions'
import { ReceiptPrinter } from 'Common/components/ReceiptPrinter'
import moment from 'moment';

const LIST_BASKETS = loader('src/graphqls/baskets/baskets.graphql');
const RECORD = loader('src/graphqls/orders/order.graphql');
const CANCEL_ORDER = loader('src/graphqls/orders/cancel.graphql');
const UPDATE_ORDER = loader('src/graphqls/orders/update_order.graphql');
const CONFIRM_TILL = loader('src/graphqls/orders/confirmOrderTill.graphql');

const { confirm } = Modal;


const InfoColumn = props => {
    return (<Col flex={props.flex || "25%"} style={{ backgroundColor: "#FFF", margin: "0 2px", padding: "10px", textAlign: "center", ...props.style }}>
        <div style={{ fontWeight: "bold", fontSize: "22px" }}>{props.value}</div>
        <div style={{ fontWeight: "bold", fontSize: "14px" }}>{props.label}</div>
    </Col>)
}

class TillConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            busy: false,
            order: false,
            loadingStatus: false,
            loadingEditNode: props.loadingEditNode,
            selectedItem_id:null,
            scann_result: 'No result',
            // basket:null,
            selectedBaskets:null,
            printComp:null,
            zone:null,
        }
        this.onUpdateStatus = this.onUpdateStatus.bind(this);
        this.readyForDispatch = this.readyForDispatch.bind(this);
        this.handleScan = this.handleScan.bind(this)
        this.setItemQty = this.setItemQty.bind(this)

        this.componentRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.loadingEditNode === state.loadingEditNode) return state;

        let dispatch_data = {
            baskets: [], // [{ _id title barcode }],
            products: props.order.items.map(p => {
                let pickup = props.order.pickup_data.products.find(o => o.product_id == p.product_id);

                return {
                    product_id: p.product_id,
                    qty_picked: pickup.qty_picked,
                    requried_qty: p.cart_qty,
                    deliverable_qty: 0,
                }
            })
            // grandTotal
            // till_by{ _id name }
            // till_at
        }

        return {
            ...state,
            loadingEditNode: props.loadingEditNode,
            order: { ...props.order, dispatch_data },
        }


        // if (props.loadingEditNode !== state.loadingEditNode) {

        //     let pickup_products = props.order.pickup_data.products;

        //     return {
        //         ...state,
        //         loadingEditNode: props.loadingEditNode,
        //         order: {
        //             ...props.order,
        //             items: props.order.items.map(item => {
        //                 let found = pickup_products.find(o => o._id == item._id)
        //                 return {
        //                     ...item,
        //                     deliverable_qty: found.qty_picked,
        //                     decline_notes: found.decline_notes,
        //                     delivery_status: (found.qty_picked==0 && found.decline_notes) ? "canceled" : null,
        //                 }
        //             }),
        //             dispatch_data: {
        //                 products: props.order.items.map(item => {
        //                     let pickup = props.order.pickup_data.products.find(o => o._id == item._id);
        //                     return {
        //                         _id: item.product_id,
        //                         requried_qty: item.qty,
        //                         deliverable_qty: 0,
        //                         delivery_status: (pickup && (pickup.qty_picked == 0 || pickup.decline_notes)) ? "canceled" : "undelivered",
        //                     }
        //                 }),
        //             }
        //         }
        //     };

        // }
        // return { ...state };

    }

    onUpdateStatus = status => {
        // console.log(`onUpdateStatus(${status})`); return;
        this.setState({ loadingStatus: true });

        let filteredValues = {
            _id: this.props.match.params._id,
            status: status,
        };

        this.props.updateOrder(filteredValues).then((e) => {
            this.setState({ loadingStatus: false })
            if (e.data.updateOrder.error) {
                let err = e.data.updateOrder.error;
                message.error(err.message);
                return false;
            }
            message.success("Status updated.");
            // onClose(e.data.updateOrder);
        }).catch(error => {
            this.setState({ loadingStatus: false })
            console.log(error);
            message.error("Query Error");
        });
    }

    calculateOrder() {
        const { order } = this.state;
        let grandTotal = 0 + order.totalTax;
        let totalPrice = 0;
        let totalTax = 0;

        order.items.forEach(item => {
            if (item.delivery_status =='deliverable'){
                grandTotal += item.deliverable_qty * item.price
                totalPrice += item.deliverable_qty * item.price
            }
        });
        
        this.setState({ order: { ...order, grandTotal, totalPrice, totalTax }})
    }

    RenderReadyToDispatch() {
        const { busy, order } = this.state;
        if (!order) return null;
        const { dispatch_data, items, status, invoice } = order;
        if (status != "in-review") return null;
        

        let remainingItems = order.items.filter(o=>{
            return order?.dispatch_data?.products?.find(oo => (oo.product_id == o.product_id && oo.delivery_status == 'undelivered')) ? true : false;
        })?.length || 0;



        var subtotal = 0;
        var delivery_charges = invoice.service_charges.find(o => o.type == "delivery")?.amount || 0;
        items.forEach(item => {
            let item_dispatched = !dispatch_data ? null : dispatch_data.products.find(o => o.product_id == item.product_id);
            subtotal += item_dispatched.deliverable_qty * item.price;
        });

        // var grand_total = subtotal + delivery_charges;
        // var invoiceAmountMatch = (invoice.grand_total == grand_total);
        var invoiceAmountMatch = invoice.total == subtotal;

        if (!invoiceAmountMatch) return <Alert message="Order not confirmed as per payment" type='warning' />


        return (<Button style={{ width: "100%", height: "57px", marginBottom: "20px" }}
            shape="round" onClick={this.readyForDispatch} size="large" loading={busy} 
            disabled={remainingItems > 0 || !invoiceAmountMatch} color="green"
        >Ready For Dispatch</Button>)
    }

    readyForDispatch = async() => {
        console.log("readyForDispatch()");

        const { order } = this.state;
        
        // if (!this.state.selectedBaskets || this.state.selectedBaskets.length<1){
        //     Modal.error({ title: 'Missing dispatch basket!', content: 'Please select a dispatch basket', });
        //     return false;
        // }
        // const selectedBaskets = this?.props?.baskets?.filter(o => this.state.selectedBaskets.includes(o._id));


        const input = {
            _id: order._id,
            // baskets: !selectedBaskets ? null : selectedBaskets.map(o => (o._id)),
            products: order.dispatch_data.products.map(o => {
                return {
                    product_id: o.product_id,
                    // requried_qty: o.requried_qty,
                    deliverable_qty: o.deliverable_qty,
                }
            }),
            // products: order.items.map(o => ({
            //     _id: o.product_id,
            //     requried_qty: o.qty,
            //     deliverable_qty: o.deliverable_qty,
            //     delivery_status: o.delivery_status,
            // }))
        }

        // console.log("input: ", input)
        // return false;


        this.setState({ busy: true });
        const resutls = await this.props.confirmOrderTill(input).then(r => (r.data.confirmOrderTill)).catch(err => {
            console.log(__error("ERROR:"), err);
            return { error: { message:"Request Error" }}
        })
        this.setState({ busy: false });
        
        if (resutls.error){
            Modal.error({ title: 'Error!', content: resutls.error.message });
            return false;
        }

        message.success("Order confirmed for dispatch");
        this.props.history.replace({ pathname: "/orders/on_till" });
        
    }

    onItemClicked(selectedItem){
        this.setState({ selectedItem_id: selectedItem.product_id })
    }

    searchBarcode(barcode){
        // this.handleScan(barcode);
        let prod = this.state.order.items.find(O => O.barcode == barcode);
        this.setState({ selectedItem_id: prod ? prod.product_id : null })
    }

    handleScan(barcode) {
        console.log("********** Till Confirmation Scanned *******", barcode);
        if (!barcode) return console.log(__error("No barcode found"));

        // check if shortcut is scanned
        if (barcode.includes('EVENT-')){
            console.log("********** EVENT CALLED *******");
            const eventCode = barcode.replace('EVENT-', '');
            if (eventCode == '0001'){
                this.readyForDispatch();
            }
            else{
                Modal.error({ title: 'Error!', content: 'Invalid shortcut', });
            }
            return;
            // if (barcode =='EVENT-0001')
        }

        // check if product scanned
        let prod = this.state.order.items.find(O => O.barcode == barcode);
        if (prod){
            console.log("********** Product Called *******", prod);
            this.setState({ selectedItem_id: prod.product_id }, ()=>{
                this.setItemQty(prod, { increase:1 }).then(r=>{
                    console.log(`prod: ${prod.requried_qty} == ${r}`)
                    if (prod.requried_qty == r) this.confirmProduct();
                })
            })
            return prod.product_id
            // this.confirmProduct(prod);
            // return;
        }

        // check if basket scanned
        let basket = this.props.baskets.find(O => O.barcode == barcode);
        if (basket){
            console.log("********** Basket called *******");
            let _selectedBaskets = this.state.selectedBaskets ? this.state.selectedBaskets.slice() : [];

            // remove basket if already added
            if (this.state.selectedBaskets){
                console.log("********** Basket found *******");
                const found = this.props.baskets.filter(o => this.state.selectedBaskets.includes(o._id)).find(o => o.barcode == barcode);
                if (found){
                    _selectedBaskets = _selectedBaskets.filter(o => o != found._id);
                    this.setState({ selectedBaskets: _selectedBaskets });
                    return;
                }
            }

            // add new basket
            _selectedBaskets.push(basket._id);
            this.setState({ selectedBaskets: _selectedBaskets });
            return;
        }

    }

    handleScanError(err) {
        console.log("handleScanError: ", err)
        console.error(__error("Scann Error: "), err)
    }

    getPrintComponent = (printComp) => {
        this.setState({ printComp })
    }

    doPrint(){
        this.state.printComp.handlePrint()
    }

    async setItemQty(item, action){
        if (!action) return;
        const { order } = this.state;

        let products = order?.dispatch_data?.products?.slice() || [];
        let found = products.find(o => o.product_id == item.product_id);

        var newQty = 0;
        if (action.increase) newQty = Number(found.deliverable_qty + action.increase);
        else if (action.decrease) newQty = Number(found.deliverable_qty - action.decrease);
        else if (action.set) newQty = Number(action.set);

        if (!(newQty < 0 || newQty > found.requried_qty)) Object.assign(found, { deliverable_qty: newQty });

        if (newQty == found.requried_qty || found.requried_qty == found.deliverable_qty) Object.assign(found, { delivery_status: "deliverable" });
        else Object.assign(found, { delivery_status: "undelivered" });

        let dispatch_data = order.dispatch_data ? { ...order.dispatch_data } : {};
        Object.assign(dispatch_data, { products: products.map(o => (o.product_id == found.product_id) ? found : o) });

        return new Promise((resolve, reject) => {
            this.setState({ order: { ...order, dispatch_data } }, () => {
                resolve(newQty)
            })
        });
    }

    confirmProduct() {
        const { selectedItem_id, order } = this.state;

        let products = order?.dispatch_data?.products?.slice() || [];
        let found = products.find(o => o.product_id == selectedItem_id);

        let dispatch_data = order.dispatch_data ? { ...order.dispatch_data } : {};
        Object.assign(dispatch_data, { products: products.map(o => (o.product_id == found.product_id) ? { ...found, delivery_status: "deliverable" } : o) });

        this.setState({ order: { ...order, dispatch_data } })
    }

    // un_confirmProduct(p) {
    //     const { selectedItem_id, order } = this.state;

    //     let products = order?.dispatch_data?.products?.slice() || [];
    //     let found = products.find(o => o.product_id == selectedItem_id);

    //     let dispatch_data = order.dispatch_data ? { ...order.dispatch_data } : {};
    //     Object.assign(dispatch_data, { products: products.map(o => (o.product_id == found.product_id) ? { ...found, delivery_status: "undelivered" } : o) });

    //     this.setState({ order: { ...order, dispatch_data } })
    // }

    cancelItem(p) {
        const { selectedItem_id, order } = this.state;

        let products = order?.dispatch_data?.products?.slice() || [];
        let found = products.find(o => o.product_id == selectedItem_id);

        let dispatch_data = order.dispatch_data ? { ...order.dispatch_data } : {};
        Object.assign(dispatch_data, { products: products.map(o => (o.product_id == found.product_id) ? { ...found, deliverable_qty:0, delivery_status: "canceled" } : o) });

        this.setState({ order: { ...order, dispatch_data } })
    }



    render(){
        const { loading, busy, loadingEditNode, loadingStatus, selectedItem_id, order } = this.state;
       
        if (loadingEditNode) return <Loader loading={true} center />

        var baskets = []
        if (this.props.baskets){
            baskets = this.props.baskets.map(i => {
                return { ...i, 
                    // itemBgColor: i.color, 
                    _title: i.title,
                    title: <span><Icon icon="circle" color={i.color} style={{ marginRight: "10px" }} />{`${i.title}`}</span>
                };
            })
        }
        
        let time_range_utc = null;

        if (order) {
            time_range_utc = JSON.parse(order.delivery_slot.time_range_utc);
            time_range_utc = time_range_utc.map(itm => moment(itm, "DD-MM-YYYYTHH:mm"));
        }
        
        const selectedItem = selectedItem_id ? order.items.find(o => o.product_id == selectedItem_id) : false;

        var deliverable = order.items.filter(o => o.delivery_status == 'deliverable');
        var canceled = order.items.filter(o => o.delivery_status == 'canceled')

        let grandTotal = 0;
        order.items.forEach(itm => {
            if (itm.delivery_status == "deliverable") grandTotal += (itm.deliverable_qty * itm.price);
        });

        const thisPickup = order?.dispatch_data?.products?.find(o => o.product_id == selectedItem.product_id);

        
        if (!order?.pickup_data) return <Alert message="No pickup data found!" type='error' showIcon />
        return (<>
            <BarcodeScanner onScan={this.handleScan} />

            <Row style={{ flexWrap: "nowrap" }}>
                <Col flex="auto">
                    <Row style={{ margin: "10px 0px 0 8px" }}>
                        <InfoColumn value={order.items.length} style={order.items.length != deliverable.length ? {} : { backgroundColor:"green", color:"white" }} label="Total Items" flex="auto" />
                        <InfoColumn value={deliverable.length} label="In Bucket" flex="auto" />
                        <InfoColumn value={canceled.length} style={canceled.length < 1 ? {} : { backgroundColor: "red", color: "white" }} label="Unavailable" flex="auto" />
                        <InfoColumn value={grandTotal} label="Total Bill Amount" flex="auto" />
                        {/* <InfoColumn value={'-'} label="Previous Orders" />
                        <InfoColumn value={'-'} label="Complaints" flex="auto" /> */}
                    </Row>

                    <Row style={{ flexWrap: "nowrap" }}>
                        <Col flex="auto">
                            <RenderInvoice order={order}
                                tillConfirmation
                                onScanPressed={this.handleScan} 
                                // onRowClick={(val) => this.onItemClicked(val)} 
                                // onRowClick={(val) => this.setState({ selectedItem:val })} 
                            />
                        </Col>
                        <Col flex="25%">
                            <Input size="large" allowClear className='shadow'
                                style={{ borderRadius: "10px", margin: "10px 0", fontSize: "32px" }}
                                placeholder="Search Barcode"
                                onChange={(e) => this.searchBarcode(e.currentTarget.value)}
                                suffix={<Icon icon="search" color="#EEE" />}
                            />

                            {thisPickup && <>
                                <div className="grid-block" style={{ marginLeft: 0, marginRight: 0 }}>
                                    <div style={{ textAlign: "center", fontSize: "24px", flexWrap: "wrap", lineHeight:"24px" }}>{selectedItem.title}</div>
                                    <div style={{ textAlign: "center", margin:"10px 0" }}>
                                        <Avatar size={300} shape="square" src={selectedItem.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${selectedItem.picture_thumb}` : null} icon={<Icon icon="image" />} />
                                    </div>

                                    <div>
                                        <div style={{ textAlign: "center", fontSize: "24px", marginBottom: "20px" }}>
                                            <div style={{ justifyContent: "center", display: "flex", flexDirection: "row" }}>
                                                <Row align='middle'>
                                                    <Col><IconButton
                                                        onClick={() => this.setItemQty(selectedItem, { decrease: 1 })}
                                                        // disabled={selectedItem.deliverable_qty <= 1}
                                                        disabled={thisPickup?.deliverable_qty < 1}
                                                        icon="minus" size="large" /></Col>
                                                    <Col className='hidewebkit'><input
                                                        // requried_qty
                                                        // qty_picked
                                                        // deliverable_qty

                                                        className='customInputField' type="number"
                                                        style={{ width: "100px", borderBottomLeftRadius: "50px", borderTopLeftRadius:"50px" }} 
                                                        // disabled={selectedItem.deliverable_qty <= 1 || selectedItem.deliverable_qty >= selectedItem.qty}
                                                        onChange={(e) => {
                                                            if (e.currentTarget.value > thisPickup.requried_qty) return this.setItemQty(selectedItem, { set: thisPickup.requried_qty });
                                                            if (e.currentTarget.value < 0) return false;
                                                            this.setItemQty(selectedItem, { set: e.currentTarget.value })
                                                            return true;
                                                        }}
                                                        value={thisPickup?.deliverable_qty || 0}
                                                        max={thisPickup.requried_qty} min={0}
                                                    /></Col>
                                                    <Col style={{ borderBottomRightRadius: "50px", borderTopRightRadius: "50px", padding: "2px 10px", backgroundColor: "#EEE" }}>
                                                        <div style={{ fontSize: "32px", color: "#999" }}>{thisPickup.requried_qty}</div>
                                                    </Col>
                                                    <Col><IconButton
                                                        onClick={() => this.setItemQty(selectedItem, { increase: 1 })}
                                                        // disabled={selectedItem.deliverable_qty >= selectedItem.qty}
                                                        disabled={thisPickup.deliverable_qty == thisPickup.requried_qty}
                                                        icon="plus" size="large" /></Col>
                                                </Row>
                                            </div>

                                        </div>

                                        <Row align="middle" style={{ flexWrap: "nowrap" }}>
                                            <Col flex="auto" align="center" style={{ padding: "0 20px" }}>
                                                <Button style={{ height: "50px" }} color="green" size="large" shape="round" 
                                                    disabled={thisPickup.delivery_status == "deliverable" || thisPickup?.deliverable_qty < 1}
                                                    onClick={() => this.confirmProduct()} block>Confirm</Button>
                                            </Col>
                                            <Col style={{ width: "120px" }}>
                                                <Button style={{ height: "50px" }} shape="round" size="large" onClick={() => this.cancelItem()} type="danger" block
                                                    disabled={thisPickup.delivery_status == "canceled"}
                                                >Unavailable</Button>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div style={{ textAlign: "center", fontSize: "24px", marginTop: "20px", color: selectedItem.delivery_status == 'canceled' ? 'red' : 'green' }}>
                                        {selectedItem.delivery_status == 'canceled' && <>Unavailable</>}
                                        {selectedItem.delivery_status == 'deliverable' && <>Confirmed</>}
                                    </div>

                                </div>
                            </>}

                        </Col>

                    </Row>

                </Col>

                <Col flex="370px">
                    <div className="grid-block">
                        {this.RenderReadyToDispatch()}
                        {/* <div style={{ fontSize: "18px", color: "#498aff", paddingRight: "10px", paddingTop: "3px", }}>Dispatch Basket</div>
                        <Select
                            width="100%"
                            name="basket"
                            type="multiple"
                            addNull={false}
                            disabled={this.props.loading_baskets}
                            onChange={(selectedBaskets) => {
                                this.setState({ selectedBaskets })
                                // console.log("onChange: ", selectedBaskets)
                                // let basket = this.props.baskets.find(o => o._id == e);
                                // this.setState({ basket });
                            }}
                            // defaultValue={""}
                            value={this.state.selectedBaskets || undefined}
                            data={baskets}
                            mode='tags'
                        /> */}
                    </div>

                    <div><ReceiptPrinter
                        order={order}
                        getPrintComponent={this.getPrintComponent}
                        zone={this.state.zone}
                        // baskets={this.state.selectedBaskets} 
                        baskets={this.state.selectedBaskets ? this.props.baskets.filter(o => this.state.selectedBaskets.includes(o._id)) : undefined}
                    /></div>

                    <div className="grid-block">
                        Pickup By: <span style={{ fontSize: "18px" }}>{order?.pickup_data?.pickup_by?.name || order?.pickup_data?.pickup_by?._id}</span>
                    </div>

                    <CustomerInformation order={order} onZoneMatch={(zone) => this.setState({ zone })} />

                    <div className="grid-block">
                        <Heading>Delivery Schedule</Heading>
                        <div style={{ padding: "0 10px", marginTop: "-10px", fontWeight: "bold" }} align="center">
                            {utcToDate(order.delivery_slot.date).format("ddd, MMM Do YYYY")}
                            <br />{time_range_utc[0].format("hh:mm A")} to {time_range_utc[1].format("hh:mm A")}
                        </div>
                        {order.note && order.note.length > 1 &&
                            <Alert message="Client Notes" description={order.note} type="info" showIcon />
                        }
                    </div>

                    <RenderHistory order={order} />


                </Col>
            </Row>

            <DevBlock obj={order} />

        </>)

    }
}
TillConfirmation.propTypes = {
    // onClose: PropTypes.func.isRequired,
    // showform: PropTypes.bool.isRequired,
    // order: PropTypes.object,
}

const WithApollo = compose(
    graphql(CANCEL_ORDER, {
        props: ({ mutate }) => ({
            cancelOrder: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    graphql(UPDATE_ORDER, {
        props: ({ mutate }) => ({
            updateOrder: (args) => mutate({
                variables: { input: args }
            }),
        })
    }),

    graphql(CONFIRM_TILL, {
        props: ({ mutate }) => ({
            confirmOrderTill: (args) => mutate({
                variables: { input: args }
            }),
        })
    }),

    graphql(LIST_BASKETS, {
        options: props => {
            return { variables: { filter: JSON.stringify({ category: 'dispatch', order_id:null  })  } };
        },
        props: ({ ownProps, data }) => {
            const { loading, baskets, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading_baskets:loading, baskets }
        },
    }),


)(TillConfirmation);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ match }) => {
            return { variables: { id: match.params._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, order, error } = data;
            if (error) console.log(__error("error"), error);
            return { loadingEditNode:loading, order, queryErrors: error, }
        },
    }),
)(WithApollo);


const Wrapper = props => (props.match && props.match.params._id && props.match.params._id.length > 0) ? <EditWrapper {...props} /> : <p>Missing order ID</p>

export default Wrapper;
