/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'Common/components';
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";

import WalletsList from './containers/wallets.jsx'

import { ROOT } from './constatns';

export const menuArray = props => ([
    { path: `${ROOT}`, exact: true, title: 'Wallets', component: WalletsList },
])

/**
 * WalletHome
 */
export const WalletHome = props => {
    let routs = menuArray();

    return (
        <LayoutWrapper topMenu={null}>
            <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>
    )

}

// WalletHome.propTypes = {
//   // login: PropTypes.object.isRequired,
// }

export default WalletHome;