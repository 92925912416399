// import React from 'react';
import ls from 'local-storage';
import { __error, __warning, __yellow, __success, __green } from './consoleHelper';
// const user = ls('user');
import LocalStorage from './LocalStorage';
// import { store, persistor } from 'Store';

export function verifyRole(_roleString, _user){
  let roleString = _roleString.toLowerCase();

  const user = _user || LocalStorage.getJson('user');
  if(!user){
    console.log(`${__error('')} verifyRole( ${roleString} ) ${__yellow('FAIL')}`);
    return false;
  }
  if ((user && user.type == 'super-admin') || user.rights=="*"){
    console.log(`${__success('')} verifyRole( ${roleString} ) ${__green('PASS')}`);
    return true;
  }

  if(user && user.rights && user.rights.indexOf(roleString) > -1){
    console.log(`${__success('')} verifyRole( ${roleString} ) ${__green('PASS')}`);
    return true;
  }

  // console.log(__warning(`You are not allowed to access (${roleString}) => ${user.type}@${user.name}`));
  console.log(`${__error('')} verifyRole( ${roleString} ) ${__yellow('FAIL')}`);
  return false;
}

export const verifyModule = (_moduleName) => {
  let moduleName = _moduleName.toLowerCase();
  console.log(__warning(`verifyModule(${moduleName}) >> Incomplete function`));
  console.trace(">>>");
  return false;

  let verified = verifyRole(moduleName);
  // if(!verified){
  //   console.log(`${__warning(' >> ')} verifyModule( ${moduleName} ) ${__yellow('FAIL')}`);
  //   // console.trace(">>>>>");
  // }
  // else console.log(`${__success(' >> ')} verifyModule( ${moduleName} ${__green('PASS')}`);

  // console.log(`verifyModule(${moduleName}): `, verified?'YES':'NO');
  return verified;

  // const user = LocalStorage.getJson('user');
  // if(!user) return false;
  // // console.log("user.rights: ", user.rights);
  // if(user && user.type=='super-admin') return true;
  //
  // if(user && user.rights && user.rights.indexOf(moduleName) > -1) return true;
  //
  // console.log(__warning(`You are not allowed to access (${moduleName}) => ${user.type}@${user.name}`));
  // // .then(user=>{
  // //   console.log("verifyModule: ", user.type);
  // // });
  // // // return <div>Restricted Area</div>
  // return false;
}

export const checkLoggedIn = args => {
  const user = LocalStorage.getJson('user');
  return (user && user._id && user._id>0 && user.TokenData);
}
// export const checkLoggedIn = login => {
//   // console.log("checkLoggedIn: ", login);
//   return (login && login._id && login._id>0 && login.TokenData);
// }


export const checkRights = (rights, required, matchAll=false) => {
  // console.log("checkRights: ", { required, rights });

  if (!rights) {
    if (process.env.NODE_ENV == "development") console.log(__error("checkRights()"), "No rights provided")
    return false;
  }
  if (rights=="*") return true;
  let _required = required.split(",");

  var allowed = false;
  if (matchAll){
    allowed = true;
    _required.forEach(element => {
      if (!rights.includes(element)) allowed = false;
    });
  }
  else{ // match any
    _required.forEach(element => {
      if (rights.includes(element)) allowed = true;
    });
  }

  if(process.env.NODE_ENV=="development"){
    if (!allowed) console.log(__error("checkRights: FAILED: "), { required, matchAll })
  }

  return allowed;
  
  // return rights.includes(required);
  // return rights.indexOf(required)>-1;
}

export const isSuperAdmin = (_user) => {
  const user = _user || LocalStorage.getJson('user');
  if (!user) {
    console.log(`${__error('')} isSuperAdmin() - user not found - ${__yellow('FAIL')}`);
    return false;
  }
  if (user && user.type == 'super-admin') {
    console.log(`${__success('')} isSuperAdmin() ${__green('PASS')}`);
    return true;
  }
  
  return false;
}