import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Row, Col, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';

import { Drawer, Button, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import { publishStatus } from 'configs/constants';
import { string_to_slug } from 'Common/scripts/Functions';

const RECORD_ADD = loader('src/graphqls/pages/add.graphql');
const RECORD_EDIT = loader('src/graphqls/pages/edit.graphql');
const RECORD = loader('src/graphqls/pages/pageById.graphql');


const defaultFields = { status: "hidden", edit_slug:true }

const PageForm = props => {
    const [loading, setLoading] = React.useState(false);
    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

    const onSubmit = values => {
        const { onClose, editPage, addPage } = props;

        let filteredValues = {
            title: values.title,
            slug: values.slug,
            body: values.body,
            status: values.status,
        };

        setLoading(true);


        if (values._id) {
            editPage({ ...filteredValues, _id: values._id }).then((e) => {
                setLoading(false)
                if (e.data.editPage.error) {
                    let err = e.data.editPage.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editPage);
            }).catch(error => {
                setLoading(false)
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addPage(filteredValues).then((e) => {
                setLoading(false);
                if (e.data.addPage.error) {
                    let err = e.data.addPage.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.addPage);
            }).catch(error => {
                setLoading(false);
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    let fields = props.pageById ? { ...props.pageById } : { ...defaultFields }
        

    return (
        <Drawer height={"100%"} destroyOnClose maskClosable={false} keyboard={false} placement="top"
            bodyStyle={{ padding: "0px" }} onClose={props.onClose} visible={props.showform}
            footer={<>
                <span></span>
                <Button loading={loading} type="primary" onClick={() => {
                    document.getElementById('PageForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`Page Editor`}
        >

            {props.loadingEditNode && <Loader loading={true} />}

            {!props.loadingEditNode &&
                <FormComponent onSubmit={onSubmit} id='PageForm' loading={loading} fields={fields}
                    mutators={{
                        setPageBody: (newValueArray, state, tools) => {
                            tools.changeValue(state, 'body', () => newValueArray[0])
                        },
                    }}
                    form_render={formProps => {
                        const { values } = formProps;

                        return (<Row style={{flexDirection:"row", flexWrap:"nowrap"}}>
                            <Col flex="auto">
                                <FormField type="html"
                                    data={(values && values.body) ? values.body : null}
                                    editorStyle={{ minHeight: "calc(100vh - 210px)" }}
                                    toolbar={{ options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history', ] }}
                                    onUpdate={(val) => formProps.form.mutators.setPageBody(val)} name="body" label="Page Body" />
                            </Col>

                            <Col flex="300px">
                                <FormField type="select" name="status" label="Status" className={values.status == 'published' ? "active" : "inactive"} data={publishStatus} validate={rules.required} />
                                <FormField type="text" name="title" label="Title" placeholder="Page title" validate={composeValidators(rules.required, rules.minChar(4))} />
                                <Row>
                                    <Col flex="auto"><FormField disabled={!values.edit_slug} onChange={filterSlug} type="text" name="slug" placeholder="Slug" label="Key (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} /></Col>
                                    <Col style={{paddingTop:'35px', paddingRight:'10px'}}><FormField type="switch" name="edit_slug" compact /></Col>
                                </Row>
                                <h2 style={{margin:"20px 0 5px 0"}}>SEO Details</h2>
                                <FormField type="text" name="seo_title" label="SEO Title" placeholder="SEO title" />
                                <FormField type="text" name="seo_desc" label="SEO Description" placeholder="SEO Description" />
                            </Col>

                        </Row>)
                    }}
                />
            }

        </Drawer>
    )
}


const WithApollo = compose(
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addPage: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editPage: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(PageForm);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, pageById, error } = data;
            if (error) console.log(__error("error"), error);

            return { loadingEditNode: loading, pageById, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;
