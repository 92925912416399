import React from 'react'
import PropTypes from 'prop-types';
import { Button, DevBlock, Heading, Loader, Table } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { dateToUtc, sortArrayByVal } from 'Common/scripts/Functions';
import { Col, message, Row } from 'antd';
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, Label } from 'Common/components/Form'
import moment from 'moment'

const LIST_DATA = loader('src/graphqls/orders/order_cnt_by_timeslot.graphql');

const SlotsDetails = (props) => {
    const { client } = props;

    const [busy, setBusy] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        // fetchData();
    //   return () => { };
    }, [props])

    const fetchData = async (variables) => {
        setBusy(true);
        setError(false);

        const results = await client.query({ query: LIST_DATA, variables }).then(e => {
            let results = e.data.order_cnt_by_timeslot;
            return results;

            // if (results.error) {
            //     console.log(__error("ERROR: "), e);
            //     message.error(__error("ERROR "), results.error.message);
            //     return setError(results.error.message);
            // }

            // setData(results)

        }).catch(err => {
            console.log(__error("Order count by slot Call ERROR: "), err);
            return { error: { message:"Request ERROR"}}
            // message.error("Request ERROR");
            // return setError("Request ERROR")
        })

        if (results.error){
            message.error(__error("ERROR "), results.error.message);
            return setError(results.error.message);
        }

        var dataSet = {}
        results.forEach(row => {
            let yr = moment(row.from_date).format('DD_MM_YYYY');
            let rowArray = dataSet[yr] ? dataSet[yr].slice() : [];
                rowArray.push(row)

            Object.assign(dataSet, {
                [yr]: rowArray
            })

        });

        setData(dataSet);
        setBusy(false);

    }

    const onSubmit = values => {
        // console.log("onSubmit(): ", values)
        const variables = {
            start_date: dateToUtc(values.start_date, { tz: props.default_timezone }),
            end_date: dateToUtc(values.end_date, { tz: props.default_timezone }),
        }

        fetchData(variables)
    }

    const columns = [
        { title: 'Time Slot', dataIndex: 'from_date', render:(__, rec)=>{
            return <>{moment(rec.from_date).format('DD-MM-YYYY')} - {moment(rec.from_date).format('HH:mm')} ~ {moment(rec.to_date).format('HH:mm')}</>
        } },
        // { title: 'From', dataIndex: 'from_date', render:(__, rec)=>{
        //     return moment(rec.from_date).format('DD-MM-YYYY HH:mm')
        // } },
        // { title: 'To', dataIndex: 'to_date', render:(__, rec)=>{
        //     return moment(rec.to_date).format('DD-MM-YYYY HH:mm')
        // } },
        { title: 'Count', dataIndex: 'count', align:"center" },
    ];

    const renderResults = () => {
        if (!data) return null;

        let keys = Object.keys(data)
        // keys = sortArrayByVal(keys, 'from_date', 'asc');
        keys = keys.sort()

        return keys.map((row, i)=>{
            return <div key={i}>
                <div style={{fontSize:"24px", textAlign:"center"}}>{row}</div>
                <Table 
                    columns={columns}
                    // dataSource={data[row]}
                    dataSource={sortArrayByVal(data[row], 'from_date', 'asc')}
                    rowKey={(v) => v.from_date}
                    pagination={false}
                />
            </div>
        })

    }


    return (<>
        <ContentArea >
            <h4>Slots Details</h4>

            {busy && <Loader loading={busy} />}

            <div>
                <FormComponent onSubmit={onSubmit} id='slotSearchForm' loading={busy} fields={{}}
                    // decorators={[setStartTime, setEndTime]}
                    // mutators={{ addBlockDate, removeBlockDate }}

                    form_render={formProps => {
                        const { values } = formProps;

                        return <>
                            <Row>
                                <Col><FormField type="date" name="start_date" placeholder="Start Date" validate={rules.required} compact /></Col>
                                <Col><FormField type="date" name="end_date" placeholder="End Date" validate={rules.required} compact /></Col>
                                <Col><Button type="primary" htmlType='submit' loading={busy}>Show</Button></Col>
                            </Row>
                        </>
                    }}
                />
            </div>

            {renderResults()}

            {/* <DevBlock obj={data} /> */}

        </ContentArea>
    </>)
}
const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        default_timezone: grocer_storeadmin.default_timezone,
    };
}
const WithRedux = connect(mapStateToProps)(SlotsDetails);

export default withApollo(WithRedux);
