import React from 'react'
import PropTypes from 'prop-types';

import BrandsList from '../components/brands_list';
import BrandForm from '../components/brand_form';
import { ContentArea } from 'Layout_v1';


export const Brands = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <BrandsList onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddClick={() => toggleDrawer({})}
        />

        {visible && <BrandForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </>)
}
export default Brands;