import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'


addModule(
  { // manage-my-store
    permission_required:'personal-profile-manage',
    path: `${ROOT}`, component: Home, title: "Personal Profile", exact: 0, hideNav: 1,
    // subMenu: SubMenu()
  },
)
