import React from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Divider, message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { publishStatus, yesNoStatus } from 'configs/constants';
import { FormField, FormComponent, FormFieldGroup, DateField } from 'Common/components/Form'
import { DevBlock, Icon, Button, Loader, IconButton, BrandSelection } from 'Common/components'
import { formToFilter } from 'Common/scripts/Functions'
import { connect } from "react-redux";

const LIST_DATA = loader('src/graphqls/product_type/prodTypes.graphql');
const LIST_CATS = loader('src/graphqls/product_cat/productCats.graphql');


const ProductFilter = props => {
    // const [advanced, setAdvanced] = React.useState(false);
    const exclude = props.exclude || [];

    const onSubmit = values => {
        let filter = formToFilter(values, { tz: props.default_timezone })
        if (props.onChange) props.onChange({ filter })
    }

    if(props.loading) return <Loader loading={true} />


    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='OrderFilterForm' hideDevBlock={true} fields={props.defaultValue} style={{ padding: 0 }}>
            <Row className="pagination-filter-row">
                <Col flex="auto" className="filters-column">
                    <FormFieldGroup compact style={{ padding: 0, margin: 0 }}>
                        {exclude.indexOf('search.keywords') < 0 && 
                            <FormField type="text" prefix={<Icon icon="user" color="#CCC" />} name="search.keywords" placeholder="keyword to search..." label="Keyword search" width="200px" compact allowClear size="small" />
                        }
                        {exclude.indexOf('cat_id') < 0 && 
                            <FormField type="select" data={!props.loadingCats && props.productCats} name="cat_id" placeholder="Category" label="Category" width="200px" compact allowClear size="small" />
                        }
                        {exclude.indexOf('type_id') < 0 &&
                            <FormField type="select" data={props.prodTypes} name="type_id" placeholder="Product Type" label="Product Type" width="200px" compact allowClear size="small" />
                        }
                        {exclude.indexOf('status') < 0 &&
                            <FormField type="select" data={publishStatus} name="status" placeholder="Status" label="Status" width="100px" compact allowClear size="small" />
                        }
                        {/* {exclude.indexOf('brand') < 0 &&
                            <BrandSelection name="brand*_id" filter={{ }} label="Brands" placeholder="Search Brands..." width="150px" compact allowClear size="small" />
                        } */}
                        {exclude.indexOf('featured') < 0 &&
                            <FormField type="select" data={[
                                {title:"Yes", _id:'yes'},
                                { title: "No", _id: 'no'},
                            ]} name="featured" label="Featured" width="70px" compact allowClear size="small" />
                        }
                    </FormFieldGroup>
                </Col>
                <Col className="go-column">
                    <Button className="send_button" loading={props.loading} htmlType="submit"><Icon icon="search" /></Button>
                </Col>
            </Row>
        </FormComponent>
    </div>)

}
ProductFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    // onSearch: PropTypes.func.isRequired,
    exclude: PropTypes.array,
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, prodTypes, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);
            
            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.prodTypes
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, prodTypes, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),


    graphql(LIST_CATS, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productCats, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.productCats
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loadingCats:loading, productCats, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(ProductFilter);

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.default_timezone };
}
const WithRedux = connect(mapStateToProps)(WithApollo);
export default WithRedux;

