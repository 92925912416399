import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { connect } from "react-redux";

const USERS_ROLES = loader('src/graphqls/user_role/userRoles.graphql');


const UserTypeSelectionComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        return () => console.log;
    }, [props.preload])

    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
    
        var filter = {};
        if (_.isString(value)){
            setState({ kw: value, loading: true })
            filter = { ...props.filter, search:{keywords: value} };
        }
        else if(value){
            setState({ loading: true })
            filter = { ...props.preload, ...props.filter };
        }

        // add store ID if available
        if (props.store) Object.assign(filter, { store_id:props.store._id })


        let resutls = await props.client.query({ query: USERS_ROLES, variables: { filter: JSON.stringify(filter) } })
            .then(r => (r.data.userRoles))
            .catch(err => {
                console.log(__error("API Call ERROR: users : "), err);
                return { error: { message:"Request ERROR" }}
            })

        setState({ loading: false });

        if (!resutls || resutls.error){
            message.error(resutls?.error?.message || "Invalid response!");
        }else{
            setList(resutls.map(o => ({ _id: o.acc_type, title: o.title, raw: o })))
        }

    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField validate={props.validate}
            type={props.mode || 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder={props.placeholder || "Search Users"}
            addNull={false}
            compact={props.compact}
            size={props.size}
            style={{...props.style, width:"100%"}}
            allowClear={props.allowClear}
            // keyMap="value=>text"
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
UserTypeSelectionComp.propTypes = {
    name: PropTypes.string.isRequired,
    // mode: PropTypes.string,
    // label: PropTypes.string,
    // defaultValues: PropTypes.array,
    // onSelect: PropTypes.func,
    // onDeselect: PropTypes.func,
    // filter: PropTypes.object,
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    console.log("grocer_storeadmin: ", grocer_storeadmin)
    return {
        // user: grocer_storeadmin.user,
        // store: grocer_storeadmin.store,
        // rights: grocer_storeadmin.rights
    };
}
const WithRedux = connect(mapStateToProps)(UserTypeSelectionComp);


export const UserTypeSelection = withApollo(WithRedux);
export default UserTypeSelection;
