import React, { Component } from 'react'
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { Table, Loader, IconButton } from 'Common/components';
import { getTimeDifference, utcToDate } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs'
import { Link } from 'react-router-dom';

const LIST_DATA = loader('src/graphqls/orders/ordersQuery.graphql');

class OrderHistory extends Component {
    state = {
        pagination: { current: 1, pageSize: 10, },
        // filter: defaultFilter,
        busy: false,
    };

    componentWillReceiveProps(nextProps) {
        // consider loading complete
        console.log("nextProps.ordersQuery: ", nextProps.ordersQuery);

        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    total: nextProps.ordersQuery.totalCount
                }
            })
        }

    }

    columns = [
        { title: 'Order ID', dataIndex: 'serial', render: (text, row) => (<Link to={`/orders/id/${row._id}`}>{text}</Link>) },
        { title: 'Date', dataIndex: 'created_at', align: "center", render: (text, row) => (utcToDate(text).format("Do MMM YYYY ~ hh:mm A")), width: '170px' },
        { title: 'Total', dataIndex: 'grandTotal', align: "center", width: '100px' },
        { title: 'Status', dataIndex: 'status', align: "center", width: '100px' },
        // {
        //     title: ' ', dataIndex: 'actions', align: "center", width: '100px', render: (text, row) => {
        //         return (<><Button><Link to={`${ROOT}/order/${row.serial}`}>View</Link></Button></>)
        //     }
        // },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            ...this.state,
            busy: true,
            pagination: {
                ...this.state.pagination,
                pageSize: pagination.pageSize,
                current: pagination.current
            }
        })
        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.current - 1),
            JSON.stringify({ "customer._id": this.props.user._id }),
        ).then(r => {
            this.setState({ busy: false })
            return r;
        })
    };


  render() {
      const { loading, ordersQuery, queryErrors } = this.props;
      const { pagination, busy } = this.state;


    return (<>
            <h4>Orders History ({ordersQuery && ordersQuery.totalCount || 0})</h4>

            <Table loading={loading || busy}
                columns={this.columns}
                dataSource={ordersQuery ? ordersQuery.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
            />
    </>)
  }
}

const WithApollo = compose(
    graphql(LIST_DATA, {
        options: props => {
            return {
                variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ "customer._id": props.user._id }) },
                // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };
            // return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ "customer._id": props.user._id || 0 }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, ordersQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.ordersQuery.totalCount
                    const newEdges = fetchMoreResult.ordersQuery.edges
                    const pageInfo = fetchMoreResult.ordersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        ordersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'OrderQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, ordersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),
)(OrderHistory);


export default WithApollo;