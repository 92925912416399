import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT, MODULE } from './constants'

// addReducers({ admins: reducers });
addModule(
  {
    permission_required:'staff-manage',
    path: `${ROOT}`, component: Home, icon: 'user-astronaut', title: "Staff", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
