import React, { useState } from 'react'
import PropTypes from 'prop-types';
import StoresList from '../components/stores_list';
import StoreAddForm from '../components/StoreAddForm';
import OwnerForm from '../components/owner_form';
import StoreLocations from '../components/StoreLocations'
import { ContentArea } from 'Layout_v1';


export const Stores = props => {
    const [{ visible, fields }, setState] = useState({ visible: false, fields: null });
    const [editUser, set_editUser] = useState(false)
    const [showStoreLocations, set_showStoreLocations] = useState(false)

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    const onEditOwner = args => {
        if (editUser) return set_editUser(false);
        set_editUser(args);
    }

    const on_showStoreLocations = async(id) => {
        set_showStoreLocations(id)
    }

    return (<>
        <ContentArea>
            {/* <PageHeader title="Stores" sub="somethign for sub" right={<><div className="buttons"><Button onClick={toggleDrawer}>Add New Store</Button></div></>} /> */}
            <StoresList 
                toggleDrawer={toggleDrawer}
                onEditRecord={(item) => toggleDrawer({ fields: item })} 
                onEditOwner={(item) => onEditOwner(item)} 
                on_showStoreLocations={on_showStoreLocations}
            />
        </ContentArea>


        {visible && <StoreAddForm onClose={toggleDrawer} showform={visible} fields={fields} />}
        <OwnerForm onClose={()=>onEditOwner()} showform={editUser !== false} fields={editUser} />

        <StoreLocations onClose={() => set_showStoreLocations(false)} showform={showStoreLocations !== false} store_id={showStoreLocations} />

    </>)
}
export default Stores;