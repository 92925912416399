import React from 'react'
import { Button } from 'Common/components'

import AttributesList from '../components/attributes_list'
import AttributesForm from '../components/attributes_form'


export const ProdAttributes = props => {
    const [{ visible, fields }, setState] = React.useState({ visible:false, fields:null});

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <AttributesForm onClose={toggleDrawer} showform={visible} fields={fields} />

        <AttributesList onEditRecord={(item) => toggleDrawer({fields:item})} 
            onAddClick={() => toggleDrawer({})}
        />
    </>)
}
export default ProdAttributes;