import React from 'react'
import PropTypes from 'prop-types';
import { Button, Heading } from 'Common/components'
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';

import OffersList from '../components/offers_list';
import OffersForm from '../components/offers_form';
import { ContentArea } from 'Layout_v1';


const Offers = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<ContentArea>
        <h4>
            Offers
            {checkRights(props.rights, 'offers-manage') && 
                <div className="buttons"><Button onClick={toggleDrawer} size="small">Add New Offers</Button></div>
            }
        </h4>

        <OffersList onEditRecord={(item) => toggleDrawer({ fields: item })} />

        {visible && <OffersForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </ContentArea>)
}
const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(Offers);
