import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Row, Col } from 'antd';
import { Button, IconButton, Table, ListHeader, DeleteButton } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { connect } from "react-redux";
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/offices/offices.graphql');
const RECORD_DELETE = loader('src/graphqls/offices/deleteOffices.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/offices/subscription.graphql');

class ListComp extends Component {
    subscription = null;
    state = { busy:false };

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "officesUpdated",
                _subscriptionType: "simple-array",
                _queryName: "offices",
                _typename: "Offices",
                debug: false
            });
        }

    }

    handleDelete(id) {
        this.props.deleteOffices(id).then(r => {
            if (r && r.data && r.data.deleteOffices && r.data.deleteOffices.error) {
                message.error(r.data.deleteOffices.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        { title: 'Office', dataIndex: 'title', render: (text, record) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{record.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} />
                        <DeleteButton onClick={() => this.handleDelete(record?._id)} />
                    </Col>
                </Row>)
            }
        },
        { title: 'Full Address', dataIndex: 'full_address' },
        { title: 'Type', dataIndex: 'type' },
        { title: 'Status', dataIndex: 'status' },
    ];

    render() {
        const { loading, offices, queryErrors } = this.props;
        const { busy } = this.state;

        return (<ContentArea>
            <ListHeader title="Offices" sub={`Total ${offices && offices.length || '0'} records found`} 
                right={<>
                    <Button onClick={this.props.onAddClick}>Add New Office</Button>
                </>}
            />

            <Table loading={loading || busy}
                columns={this.columns}
                dataSource={offices ? offices : null}
                pagination={false}
            />
        </ContentArea>)
    }
}
ListComp.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}

const WithApollo = compose(
    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: JSON.stringify(props.filter || {}) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, offices, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter) => {
                let vars = { filter };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, offices, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteOffices: (id) => mutate({
                variables: { id }
            }),
        })
    }),


)(ListComp);


const mapStateToProps = ({ grocer_storeadmin }) => {
    return { user: grocer_storeadmin.user };
}
export default connect(mapStateToProps)(WithApollo);
