import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql, useSubscription, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import _ from 'lodash'
import compose from 'lodash.flowright';
import { Input, Space, Popconfirm, Alert, message, Row, Col, Tag } from 'antd';
import { BarcodeScanner, Barcode, Icon, Button, IconButton, DeleteButton, Table, Avatar, PageHeader, ListHeader, DevBlock } from 'Common/components';
import { lightOrDark, utcToDate } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
// import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import OrderFilter from './OrderFilter'
import LocalStorage from 'Common/scripts/LocalStorage';
import { defaultDateFormat, orderStatus } from 'configs/constants';
import { withRouter } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons';
import { checkRights, verifyRole } from 'Common/scripts/Security';
import { addToPaginationArray } from 'Common/scripts/query_helpers';
import moment from 'moment';
// import { ContentArea } from 'Layout_v1';


const LIST_DATA = loader('src/graphqls/orders/ordersQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/orders/subscription.graphql');
const BULK_DELETE = loader('src/graphqls/orders/deleteTheseOrders.graphql');
const DELETE = loader('src/graphqls/orders/deleteOrder.graphql');
const RELEASE_ORDER = loader('src/graphqls/orders/releaseOrder.graphql');
// const PICKER_ALLOW = loader('src/graphqls/orders/allowOrderPickup.graphql');
const defaultFilter = { status: "new" };

const defaultPagination = {
    defaultCurrent: 1, //this.props.match.params.pageNum || 1,
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
}
const defaultOthers = JSON.stringify({ sort: { "delivery_slot.from_date": 1 } });

// const _AllowPickupButton = ({ data, allowOrderPickup }) => {
//     const [allow, setAllow] = React.useState(data.pickup_allow)
//     const [busy, setBusy] = React.useState(false)

//     const allowPickup = args => {
//         setBusy(true)
//         allowOrderPickup({ pickup_allow:'yes', id: data._id }).then(r=>{
//             setBusy(false)
            
//             if (r.data.allowOrderPickup.error){
//                 message.error(r.data.allowOrderPickup.error.message);
//                 return;
//             }

//             setAllow('yes');
//             message.success("Picker is allowed");
//         }).catch(err=>{
//             setBusy(false)
//             console.log(__error("ERROR: "), err);
//             message.error("Unexpected Error!")
//         })
//     }

//     if (allow == 'no') {
//         return <Button loading={busy} onClick={allowPickup}>Allow</Button>
//     }
//     return <div><Icon icon="check" color="green" size="2x" /></div>
// }
// const AllowPickupButton = compose(
//     graphql(PICKER_ALLOW, {
//         props: ({ mutate }) => ({
//             allowOrderPickup: (args) => mutate({
//                 variables: { ...args }
//             }),
//         })
//     }),
// )(_AllowPickupButton)



function Subscriber({ QUERY, callback, filter }) {
    let variables = {};
    if (filter) Object.assign(variables, { ...filter });

    const { data, loading, error } = useSubscription( QUERY_SUBSCRIPTION,
        {
            // subscription: QUERY,
            variables: { filter: JSON.stringify(variables) },
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                console.log({ subscriptionData })
                if (callback) callback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    if (loading) {
        console.log("Receiving subscription on ....", variables);
        return null;
    }

    if (data) {
        console.log('Subscription received: ', data);
        // const { mutation, node } = data.formsDatasUpdated;
        // if (callback) callback({ mutation, node })
    }

    return null;
}



class ListComp extends Component {
    state = { 
        pagination: { ...defaultPagination, pageSize: (this.props.defaultPageSize || defaultPagination.pageSize) }, 
        filter: defaultFilter,
        busy:false,
        selectedRowKeys: [],
        user:{},
        ordersQuery: null,
    };

    constructor(props){
        super(props);
        this.handleScan = this.handleScan.bind(this);
        // this.handleScanError = this.handleScanError.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.releaseThisOrder = this.releaseThisOrder.bind(this);

        this.onSubscriptionReceived = this.onSubscriptionReceived.bind(this)

        this.searchInput = null;
    }

    componentDidMount() {
        LocalStorage.getJsonAsync('user').then(r => {
            this.setState({ user: r })
        });
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading && nextProps.ordersQuery) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.ordersQuery?.totalCount || 0
                },
                ordersQuery: nextProps.ordersQuery
            })
        }

        // if (!this.subscription) {
        //     this.subscription = new SubscriptionHandler({
        //         onSubscriptionReceive: (v) => console.log("onSubscriptionReceive: ", v),
        //         _subscribeToMore: subscribeToMore,
        //         _document: QUERY_SUBSCRIPTION,
        //         _variables: {
        //             filter: this.props.filter ? JSON.stringify(this.props.filter) : JSON.stringify(this.state.filter),
        //             others: JSON.stringify({})
        //         },
        //         _subscriptionName: "ordersUpdated",
        //         _subscriptionType: "array",
        //         _queryName: "ordersQuery",
        //         _typename: "Order",
        //         debug: true
        //     });
        // }

    }

    renderAddress = (text, { shipping_address }) => {
        if (!shipping_address) return null;

        return <div>
            <span style={{ fontSize: "18px", paddingRight: "10px" }}><Icon icon="map-marker-alt" color={shipping_address.verified == "yes" ? "#60A52C" : "#FF311A"} /></span>
            <b>{shipping_address.label}: </b>{shipping_address.full_address}, {shipping_address.city}
        </div>
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => this.searchInput = node}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>Search</Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>Reset</Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex, });
                        }}
                    >Filter</Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            let thisRec = record[dataIndex];
            if (!thisRec) return '';

            if (_.isArray(thisRec) || _.isObject(thisRec)) thisRec = JSON.stringify(thisRec);
            
            return thisRec.toString().toLowerCase().includes(value.toLowerCase())

            // return thisRec ? thisRec.toString().toLowerCase().includes(value.toLowerCase()) : '';
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => this.searchInput.select(), 100);
        },
        // render: text => (text),
    });
  
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    releaseThisOrder = (order_id) => {
        this.setState({ busy:true });

        this.props.releaseOrder(order_id).then(r=>{
            this.setState({ busy: false });
        });
    }

    renderZone = (___, rec) => {
        return <div>{rec.connectedZone?.title}</div>
    }

    async handleDelete(_id){
        this.setState({ busy: true })
        const results = await this.props.deleteOrder(_id).then(r => (r.data.deleteOrder)).catch(err=>{
            console.log(__error("Query Error: "), err);
            return { error:{message:"Query Error!"}}
        })
        this.setState({ busy:false })

        if (results.error) message.error(results.error.message);
    }

    handleTableChange = async (pagination, filters, sorter) => {
        console.log("handleTableChange: ", pagination)

        this.setState({ busy: true })
        // let filter = filters ? JSON.stringify(filters) : this.props.queryString;
        let filter = filters ? { ...filters, ...this.props.filter } : { ...this.state.filter, ...this.props.filter }

        // let pagination = { 
        //     ...this.state.pagination,
        //     pageSize: _pagination.pageSize,
        //     current: _pagination.page,
        // }
        // let filter = { ...this.state.filter, ...this.props.filter }

        // let vars = {
        //     first: pagination.pageSize, 
        //     after: (pagination.pageSize * (pagination.current - 1)), 
        //     filter: JSON.stringify(filter), 
        //     // others
        // }

        this.props.loadMoreRows(
            pagination.pageSize || defaultPageSize,
            (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            JSON.stringify(filter), 
        ).then(r => {

            let resutls = r?.data?.ordersQuery;

            if (resutls.error) {
                message.error(resutls.error);
                return this.setState({ busy: false })
            }

            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: resutls?.totalCount },
                busy: false,
                filter,
                ordersQuery: resutls,
            })
        })


        // let resutls = await this.props.loadMoreRows(vars.first, vars.after, vars.filter).then(r => (r.data.ordersQuery));
        // if (resutls.error){
        //     message.error(resutls.error);
        //     return this.setState({ busy: false })
        // }

        // return this.setState({ 
        //     filter, 
        //     busy: false, 
        //     pagination,
        //     ordersQuery: resutls
        // })

    };

    doSearch(filter) {
        this.handleTableChange({ page:1 }, filter)


        // this.setState({
        //     pagination: { ...defaultPagination, pageSize: (this.props.defaultPageSize || defaultPagination.pageSize) },
        //     searching:true,
        //     filter
        // }, ()=>{
        //     this.props.loadMoreRows(
        //         (this.props.defaultPageSize || defaultPageSize), 0,
        //         JSON.stringify({ ...filter, ...this.props.filter })
        //     ).then(r => {
        //         if (r.data && r.data.ordersQuery)
        //             this.setState({ 
        //                 pagination: { 
        //                     ...this.state.pagination,
        //                     total: r.data.ordersQuery.totalCount 
        //                 }, 
        //                 searching:false,
        //                 ordersQuery: r.data.ordersQuery
        //             })
        //         else
        //             this.setState({ searching: false })
        //         return r;
        //     })
        // })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex, });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    deleteSelected = args => {
        const { selectedRowKeys } = this.state;
        this.setState({ busy:true });

        this.props.deleteTheseOrders(selectedRowKeys).then(r=>{
            if (r.data.deleteTheseOrders.error) message.error("Unable to Delete!");
            else message.success("Orders deleted");

            this.setState({ busy: false });
        });
    }

    handleScan(barcode) {
        console.log("********** Till List Scanned *******", barcode);
        if (!this.props.ordersQuery.edges) return;

        // match basket
        const found = this.props.ordersQuery.edges.find(o=>{
            return o.picker_baskets.find(oo => oo.barcode == barcode);
        })

        if (!found){
            message.error(`Invalid barcode scanned for picker basket (${barcode})!`);
            return;
        }
        
        this.props.history.push(`${this.props.path}/${found._id}`);
    }

    onSubscriptionReceived({ data, error, loading, variables }) {
        console.log("onSubscriptionReceived()");

        const { mutation, node } = data.ordersUpdated;

        if (mutation =="CREATED"){
            addToPaginationArray(this.props, { queryName: 'ordersQuery', node: node, query: LIST_DATA, 
                variables: { filter: JSON.stringify({ ...this.state.filter, ...this.props.filter })}
            });
        }
        if (mutation =="DELETED"){}
        if (mutation =="UPDATED"){}


        // const { mutation, node } = data.ordersUpdated;
        // let _data = this.state.ordersQuery.edges ? this.state.ordersQuery.edges.slice() : [];

        // if (mutation == "DELETED"){
        //     console.log("BEFORE: ", this.state.ordersQuery.edges)
        //     _data = _data.filter(o => o._id != node._id);
        //     console.log("AFTER: ", _data)
        // }
        // else if (mutation == "UPDATED") _data = _data.map(o => (o._id == node._id) ? { ...node } : o)
        // else if (mutation == "CREATED") _data.push(node);
        // else {
        //     console.log(__error("Invalid Subscription received: ", data));
        //     return;
        // }

        // this.setState({ ordersQuery: { ...this.state.ordersQuery, edges: _data } })
    }

    columns = args => {
        const _columns = [
            { width: 200, title: 'Serial', dataIndex: 'serial', render: (__, record) => {

                return (<><Row className='show-menu-on-hover' gutter={[10]}>
                    <Col>
                        {this.props.path && <Link className="a" to={`${this.props.path}/${record._id}`}>{record.serial}</Link>}
                        {(!this.props.path && this.props.onEditRecord) && <div className="a" onClick={() => this.props.onEditRecord(record)}>{record.serial}</div>}
                        <div>Total Items: {record.items.length}</div>
                        {this.props.showBarcode && <Barcode value={`${record.serial}`} width={1} height={50} displayValue={true} />}

                        {record.store.title}
                    </Col>
                    <Col className='hidden-menu'>
                        {(!this.props.noActions) && <>
                            {/* <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} /> */}
                            {checkRights(this.props.rights, 'orders-delete', true) && <DeleteButton onClick={() => this.handleDelete(record?._id)} />}
                        </>}
                    </Col>
                </Row>
                    {record?.invoice?.discount?.voucher?.voucher_type?.key == 'gift' && <Tag color="#87d068">{record?.invoice?.discount?.voucher?.title}</Tag>}
                </>)
            }},
            { width: 200, title: 'Baskets', dataIndex: 'picker_baskets', render: (text, { dispatch_data, pickup_data }) => {
                    let dispatch_baskets = dispatch_data ? dispatch_data.baskets : null;
                    let picker_baskets = pickup_data ? pickup_data.baskets : null;

                    return <div>
                        {!dispatch_baskets && picker_baskets && <>
                            <div><b>Picker</b></div>
                            {picker_baskets.map((basket, i)=>{
                                return <div key={`picker_b_${i}`} className="basketLab">
                                    <div className="label" style={{ backgroundColor: basket.color || "#FFFFFF", color: basket.color ? (lightOrDark(basket.color) == 'light' ? "#000000" : "#FFFFFF") : '#000000' }}>{basket.title}</div>
                                </div>
                            })}
                        </>}

                        {dispatch_baskets && <>
                            <div><b>Dispatch</b></div>
                            {dispatch_baskets.map((basket, i)=>{
                                return <div key={`picker_b_${i}`} className="basketLab">
                                    <div className="label" style={{ backgroundColor: basket.color || "#FFFFFF", color: basket.color ? (lightOrDark(basket.color) == 'light' ? "#000000" : "#FFFFFF") : '#000000' }}>{basket.title}</div>
                                </div>
                            })}
                        </>}
                    </div>
            } },
            { width: 300, title: 'Address', dataIndex: 'address', render: this.renderAddress },
            // { width: 130, title: 'Pickup Allow', dataIndex: 'pickup_allow', render: (___, rec) => <AllowPickupButton data={rec} />, align: "center" },
            { width: 140, title: 'Order Date', dataIndex: 'created_at', render: (txt, rec) => (<>
                {utcToDate(txt).fromNow()}
                <br />
                {utcToDate(rec.created_at).format("DD MMM HH:mm")}
            </>) },
            { width: 160, title: 'Delivery Slot', dataIndex: 'delivery_slot', render: (__, rec) => {
                    if (!rec.delivery_slot.from_date) return <div>Invalid Date</div>
                    
                let d1 = utcToDate(rec.delivery_slot.date);
                let time_range_utc = JSON.parse(rec.delivery_slot.time_range_utc).map(o => (moment(o, "DD-MM-YYYYTHH:mm")));

                    let fromDate = d1.clone().set({ 'hour': time_range_utc[0].format("HH"), 'minute': time_range_utc[0].format("mm"), second:0 })
                    let toDate = d1.clone().set({ 'hour': time_range_utc[1].format("HH"), 'minute': time_range_utc[1].format("mm"), second:1 })

                    return (<>
                        <div><b>{d1.fromNow()}</b></div>
                        <div>{d1.format("Do MMM YYYY")}</div>
                        <div><b>{`${fromDate.format("HH:mm")} ~ ${toDate.format("HH:mm")}`}</b></div>
                    </>)

                }
            },
            { width: 300, title: 'Zone', dataIndex: 'zone', render: (__, rec) => (<span>{rec?.connectedZone?.title}</span>) },
            { width: 150, title: 'Order Status', dataIndex: 'status', align: "center", render: (text, record) => {
                    return <>{orderStatus({ user:this.props.user, store:this.props.store}).find(o => o._id == text)?.title}</>
                }
            },
            { width: 200, title: 'Payment method', dataIndex: 'paymentMethod', align: 'center', render: (__, rec) => (rec.paymentMethod.title) },
            { width: 100, title: 'Payment status', dataIndex: 'payment_status', align: 'center' },
            { width: 90, title: 'Total', dataIndex: 'grandTotal', align: 'center', render: (__, rec) => {
                return rec.invoice.grand_total;
            } },
        ];

        // checkRights(this.props.rights, 'orders-delete', true)
        // if (checkRights(this.props.rights, 'orders-delete', true) && !this.props.noActions) {
        //     _columns.push({
        //         width: 50,
        //         title: '',
        //         dataIndex: '',
        //         render: (__, record) => {
        //             return (<Row>
        //                 {/* <Col><IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" /></Col> */}
        //                 <Col><DeleteButton onClick={() => this.handleDelete(record._id)} /></Col>
        //             </Row>)
        //         },
        //         className: 'actions-column',
        //         align: 'right',
        //         hide: true
        //     })

        // }

        // products-access,product-manage,product-delete,prod-full-access,p-profile-manage,p-profile-update,staff-manage,staff-delete,orders-access,orders-update-status,access-till,access-carts,access-order-dispatched,access-order-readytodispatch,access-order-details,manage-product-outofstock,customers-access,customers-details-preview,settings-baskets,manage-my-store,personal-profile-manage,manage-featured-products,settings-access,manage-pickup-baskets,access-picking,admin-web-access,orders-delete
        return _columns;
    }



    render() {
        const { loading, queryErrors } = this.props;
        const { pagination, ordersQuery, busy, selectedRowKeys, user } = this.state;

        const columns = this.columns()

        let tableW = 0;
        columns.forEach(col => {
            tableW += col.width || 0;
        });

        return (<>
            <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={this.onSubscriptionReceived} variables={{}} filter={this.state.filter} />

            {!this.props.hideHeader &&
                <ListHeader title={<>{this.props.title || 'Orders'} <span>{this.props.titlePostfix}</span></>} sub={`Total ${ordersQuery && ordersQuery.totalCount || '0'} orders found`} />
            }

            {!this.props.hideFilter && <>
                <OrderFilter
                    onSearch={(filter) => this.doSearch({ ...filter, ...this.props.filter })}
                    defaultValue={this.props.filter || defaultFilter} />
            </>}

            {(ordersQuery && ordersQuery.error) && <Alert message={ordersQuery.error.message} type="error" showIcon />}

            <Table loading={loading || busy}
                columns={columns}
                dataSource={ordersQuery ? ordersQuery.edges : null}
                pagination={this.props.noPaginations ? false : pagination}
                onChange={this.handleTableChange}
                // rowSelection={rowSelection}
                // scroll={{ scrollToFirstRowOnChange: true, x: tableW + 50, y: -370 }}
                // scroll={{ x: tableW + 50, y: -370 }}
                // scroll={{ x: tableW + 50, y: 600 }}
                scroll={!this?.props?.screen?.height ? false : { x: tableW + 50, y: this?.props?.screen?.height - 200 }}
            />

            <BarcodeScanner onScan={this.handleScan} />

        </>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { 
                variables: { 
                    first: props.defaultPageSize || defaultPageSize, 
                    after: 0, 
                    filter: props.filter ? JSON.stringify(props.filter) : JSON.stringify(defaultFilter),
                    // others: JSON.stringify({ sort: { "delivery_slot.date": 1 } }),
                    // others: JSON.stringify({ sort: { "delivery_slot.pk_from": 1, "delivery_slot.date": 1 } }),
                    others: defaultOthers, //JSON.stringify({ sort: { "delivery_slot.from_date": 1 } }),
                },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, ordersQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (first, after, filter, others) => {

                let vars = { first, after, filter, others: (others || defaultOthers) };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.ordersQuery.totalCount
                    const newEdges = fetchMoreResult.ordersQuery.edges
                    const pageInfo = fetchMoreResult.ordersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        ordersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ 
                    variables: vars, 
                    fetchPolicy: "no-cache",
                    updateQuery: updateQuery,
                });
            }

            return { loading, ordersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(BULK_DELETE, {
        props: ({ mutate }) => ({
            deleteTheseOrders: (ids) => mutate({
                variables: { ids }
            }),
        })
    }),

    graphql(DELETE, {
        props: ({ mutate }) => ({
            deleteOrder: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    graphql(RELEASE_ORDER, {
        props: ({ mutate }) => ({
            releaseOrder: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    // graphql(UPDATE_STATUS, {
    //     props: ({ mutate }) => ({
    //         updateOrderStatus: (id, status, status_notes) => mutate({
    //             variables: { id, status, status_notes }
    //         }),
    //     })
    // }),

)(withApollo(ListComp));


const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        store: grocer_storeadmin.store,
        rights: grocer_storeadmin.rights,
    };
}
export default connect(mapStateToProps)(withRouter(WithApollo));
// export default withRouter(WithApollo);