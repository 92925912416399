import React, { useState } from 'react'
import OrdersList from '../components/orders_list'
import OrderDetails from '../components/order_details';
import { ContentArea } from 'Layout_v1';
import { useWindowDimensions } from 'Common/scripts/hooks';

export const OrdersDispatched = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const { height, width } = useWindowDimensions();

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        {visible && <OrderDetails onClose={toggleDrawer} showform={visible} order={fields} previewOnly={true} />}
        
        <ContentArea>
            <OrdersList 
                onEditRecord={(item) => toggleDrawer({ fields: item })} 
                noActions
                screen={{ height, width }}
                title="Dispatched"
                // path={`${props.match.path}/order`}
                // filter={JSON.stringify({ status: "dispatched" })}
                filter={{ status: "dispatched" }}
                hideFilter={true} 
            />
        </ContentArea>
    </>)
}

export default OrdersDispatched;