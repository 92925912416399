import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Popconfirm } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { defaultPageSize } from 'configs';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, IconButton, Table } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import ProductFilter from 'Modules/Products/components/product_filter'
import { connect } from "react-redux";

const LIST_DATA = loader('src/graphqls/product/query_all.graphql');
const defaultFilter = { status: 'published' }


const ProductSelector = props => {
    const getDefaultfilter = () => {
        let ___filter = { ...defaultFilter };
        if (props.exclude && props.exclude.length > 0) {
            ___filter = Object.assign({
                ...___filter,
                _id: { $nin: props.exclude.map(item => (item._id)) }
            })
        }
        return ___filter;
    }

    const [busy, setBusy] = React.useState(false);
    const [filter, setFilter] = React.useState(getDefaultfilter());
    const [selectedrows, setSelectedrows] = React.useState(null);
    const [pagination, setPagination] = React.useState({
        defaultCurrent: 1,
        pageSize: defaultPageSize,
        total: 0
    });

    React.useEffect(
        () => {
            // console.log("useEffect()", props.productsQuery);
            if (props.productsQuery) {
                setPagination({
                    ...pagination,
                    // pageSize: _pagination.pageSize,
                    // current: _pagination.current,
                    total: props.productsQuery.totalCount
                })
            }

            // const subscription = props.source.subscribe();
            // return () => {
            //     subscription.unsubscribe();
            // };
        },
        [props.productsQuery],
    );

    const { fields, loading, productsQuery } = props;

    const columns = [
        {
            title: 'Name', dataIndex: 'title', render: (text, record) => {
                return (<Row gutter={16} style={{flexWrap:"nowrap"}}>
                    <Col><Avatar size={40} shape="square" src={record.picture_thumb || null} icon={<Icon icon="image" />} /></Col>
                    <Col>{record.title}</Col>
                </Row>)
            }
        },
        { title: 'Price', dataIndex: 'price', width: '80px', align: 'center', render: (text, record) => (<span>{props.settings.currency} {text}</span>) },
        { title: 'Qty', dataIndex: 'available_qty', width: '60px', align: 'center' },
    ];

    const handleTableChange = (_pagination, filters, sorter) => {
        setPagination({
            ...pagination,
            pageSize: _pagination.pageSize,
            current: _pagination.current
        });
        setBusy(true);

        props.loadMoreRows(
            _pagination.pageSize,
            _pagination.pageSize * (_pagination.current - 1),
            JSON.stringify(filter)
        ).then(r => {
            // setPagination({
            //     ...pagination,
            //     pageSize: _pagination.pageSize,
            //     current: _pagination.current,
            //     total: r.data.productsQuery.totalCount
            // })
            setBusy(false);
        })
    };

    const doSeaarch = _filter => {
        // let newFilter = { ...defaultFilter, ..._filter };
        let newFilter = { ...getDefaultfilter(), ..._filter };
        setFilter(newFilter)
        
        setBusy(true);
        props.loadMoreRows(
            pagination.pageSize || defaultPageSize, 0,
            JSON.stringify(newFilter)
        )
        .then(r => {
            setBusy(false);
            if (r.data && r.data.productsQuery)
                setPagination({
                    ...pagination,
                    current: 1,
                    total: r.data.productsQuery.totalCount
                })
            return r;
        })
    }

    return (
        <Drawer width={600} placement="right"
            destroyOnClose={false}
            maskClosable={true}
            bodyStyle={{ padding: "0px" }}
            onClose={props.onClose}
            closable={false}
            visible={props.isVisible}
            footer={<>
                <span><Button onClick={props.onClose} type="default">Cancel</Button></span>
                <Button loading={loading || busy} type="primary"
                    disabled={!selectedrows || selectedrows.length<1}
                    onClick={() => {
                        props.onSelect(selectedrows);
                        setSelectedrows(null);
                    }
                    }>Add Selection</Button>
            </>}
            // title={`Product selection`}
        >
            <div style={{ padding: "10px" }}>
                <ProductFilter onSearch={(filter) => doSeaarch(filter)} defaultValue={{}} exclude={['status', 'available_qty.lte']} />

                <Table loading={loading || busy}
                    // updateHistory={true}
                    rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys, selectedRows) => {
                            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            setSelectedrows(selectedRows);
                        },
                        getCheckboxProps: (rec) => { 
                            return rec;
                        }
                    }}
                    columns={columns}
                    dataSource={productsQuery ? productsQuery.edges : null}
                    pagination={pagination}
                    onChange={handleTableChange}
                    title={() => <span>{productsQuery && productsQuery.totalCount || 0} Records Found</span>}
                // footer={() => 'Footer'}
                // expandable={{
                //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                //     // rowExpandable: record => record.name !== 'Not Expandable',
                // }}
                />
            </div>

        </Drawer>
    )
}
ProductSelector.propTypes = {
    onClose: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    exclude: PropTypes.array,
}

const WithApollo = compose(
    graphql(LIST_DATA, {
        options: props => {
            let filter = { ...defaultFilter };
            if (props.exclude && props.exclude.length > 0) {
                filter = Object.assign({
                    ...filter,
                    _id: { $nin: props.exclude.map(item=>(item._id)) }
                })
            }
            
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify(filter) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productsQuery, error, fetchMore, subscribeToMore } = data;

            if (error) {
                console.log(__error("error"), error);
                // message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
                // error.networkError.name
                // error.networkError.response.status
                // error.networkError.response.statusText
                // console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
            }

            const loadMoreRows = (_first, _after, filter, others) => {

                let vars = { first: _first, after: _after, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {

                    const totalCount = fetchMoreResult.productsQuery.totalCount
                    const newEdges = fetchMoreResult.productsQuery.edges
                    const pageInfo = fetchMoreResult.productsQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        productsQuery: {
                            totalCount,
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery }).then(r => {

                    if (r.error || r.errors) {
                        message.error(`Query Error!`);
                        console.log(__error("QUERY ERROR"), r);
                    }
                    return r;
                });
            }

            return { loading, productsQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),
)(ProductSelector);

const mapStateToProps = ({ grocer_storeadmin }) => {
    // props.settings.currency
    return {
        settings: grocer_storeadmin.settings, user: grocer_storeadmin.user, store: grocer_storeadmin.store,
    };
}
export default connect(mapStateToProps)(WithApollo);
