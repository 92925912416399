import React, { Component } from 'react'
import PropTypes, { array } from 'prop-types';
import moment from 'moment'
import moment_tz from 'moment-timezone';
import { Drawer, Button, Heading, Icon, Loader, IconButton, DevBlock } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, Label } from 'Common/components/Form'
import createDecorator from 'final-form-calculate'
import { connect } from "react-redux";
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { generalStatus, daysList } from 'configs/constants';
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import { utcToDate, toUTCDate, dateToUtc } from 'Common/scripts/Functions';

const RECORD = loader('src/graphqls/delivery_slot/delivery_slot.graphql');
const RECORD_EDIT = loader('src/graphqls/delivery_slot/edit.graphql');
const RECORD_ADD = loader('src/graphqls/delivery_slot/add.graphql');

const setStartTime = createDecorator({
    field: /start_time_*/,
    updates: (value, name, allValues) => {
        let hr = String(allValues.start_time_hr).length < 2 ? `0${allValues.start_time_hr}` : allValues.start_time_hr;
        let min = String(allValues.start_time_min).length < 2 ? `0${allValues.start_time_min}` : allValues.start_time_min;
        return { start_time: String(`${hr}${min}`)}
    }
})
const setEndTime = createDecorator({
    field: /end_time_*/,
    updates: (value, name, allValues) => {
        let hr = String(allValues.end_time_hr).length < 2 ? `0${allValues.end_time_hr}` : allValues.end_time_hr;
        let min = String(allValues.end_time_min).length < 2 ? `0${allValues.end_time_min}` : allValues.end_time_min;
        return { end_time: String(`${hr}${min}`)}
    }
})

const addBlockDate = (val, state, tools) => {
    console.log("addBlockDate()")
    const { blocked_dates_momoent, blocked_dates } = state.lastFormState.values;

    // console.log("blocked_dates_momoent: ", blocked_dates_momoent);
    // console.log("blocked_dates: ", blocked_dates)
    let target_date = moment(blocked_dates_momoent).format("DD-MM-YYYY");
    let _blocked_dates = blocked_dates && blocked_dates.length > 0 ? blocked_dates.split(",") : [];

    if(_blocked_dates.indexOf(target_date) > -1) return;
    // message.error("Selected date is already in the list!")
        _blocked_dates.push(target_date)
    
        // console.log(_blocked_dates);

    tools.changeValue(state, 'blocked_dates', () => _blocked_dates.toString())

    // let _blocked_dates_momoent = blocked_dates_momoent ? blocked_dates_momoent.slice() : [];
    // let _blocked_dates = blocked_dates ? blocked_dates.slice() : [];

    return;






    // if (!blocked_dates_momoent) return;
    // let target_date = moment(blocked_dates_momoent).format("DD-MM-YYYY");
    // // let target_date = moment(blocked_dates_momoent).format("YYYY-MM-DD");

    // // conver existing block date string to array
    // let blocked_dates_array = [];
    // if (blocked_dates && blocked_dates.length > 6) {
    //     if (blocked_dates.indexOf(",") > -1) blocked_dates_array = blocked_dates.split(",")
    //     else blocked_dates_array.push(blocked_dates);
    // }
    // // check fir duplicate
    // let index = findIndexByVal(blocked_dates_array, target_date)
    // if (index > -1) {
    //     message.error("Selected date is already in the list!")
    //     return;
    // }

    // // add new date to list
    // blocked_dates_array.push(target_date);
    // tools.changeValue(state, 'blocked_dates', () => blocked_dates_array.toString())

}

const removeBlockDate = (_dateToRemove, state, tools) => {
    console.log("removeBlockDate() ", _dateToRemove);
    const dateToRemove = _dateToRemove[0];

    const { blocked_dates } = state.lastFormState.values;

    let _blocked_dates = (blocked_dates && blocked_dates.length > 5) ? blocked_dates.split(",") : undefined;

    if (!_blocked_dates || _blocked_dates.indexOf(dateToRemove)<0) return;

    _blocked_dates = _blocked_dates.filter(o => o != dateToRemove);

    tools.changeValue(state, 'blocked_dates', () => _blocked_dates ? _blocked_dates.toString() : undefined)

    return;

    // const { blocked_dates } = state.lastFormState.values;
    // if (!blocked_dates || blocked_dates.length < 6) return;

    // // conver existing block date string to array
    // let blocked_dates_array = [];
    // if (blocked_dates && blocked_dates.length > 6) {
    //     if (blocked_dates.indexOf(",") > -1) blocked_dates_array = blocked_dates.split(",")
    //     else blocked_dates_array.push(blocked_dates);
    // }

    // blocked_dates_array = removeArrayElementByVal(blocked_dates_array, _dateToRemove);
    // let new_val = (blocked_dates_array.length > 0) ? blocked_dates_array.toString() : null;
    // tools.changeValue(state, 'blocked_dates', () => new_val)
}


export class FormComp extends Component {
    fields = { status: "disabled" };

    constructor(props) {
        super(props);
        this.state = { loading: false, __fields: false, loadingEditNode: props.loadingEditNode }
        this.onSubmit = this.onSubmit.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.loadingEditNode !== state.loadingEditNode) {
            return {
                ...state,
                loadingEditNode: props.loadingEditNode,
            };
        }
        return { ...state };
    }

    onSubmit = async (_values) => {
        console.log(__yellow("onSubmit()"), _values)

        let d0 = _values.time_range_utc[0]
        let d1 = _values.time_range_utc[1]

        const { deliverySlot, onClose, editDeliverySlot, addDeliverySlot } = this.props;
        const { __fields } = this.state;

        let fields = __fields ? { ...__fields } : deliverySlot ? { ...deliverySlot } : {}
        const _id = fields ? fields._id : false;

        let filteredValues = {
            // time_range_utc: JSON.stringify(_values.time_range_utc),
            // time_range_utc: JSON.stringify([d0.format('YYYY-MM-DD[T]HH:mm:ss'), d1.format('YYYY-MM-DD[T]HH:mm:ss')]),
            // time_range_utc: JSON.stringify([d0.format(), d1.format()]),
            // time_range_utc: JSON.stringify([dateToUtc(d0, { tz: this.props.settings.default_timezone }), dateToUtc(d1, { tz: this.props.settings.default_timezone })]),
            time_range_utc: JSON.stringify([d0.format("01-01-2222THH:mm"), d1.format("01-01-2222THH:mm")]),
            day: _values.day,
            blocked_dates: _values.blocked_dates,
            max_order_limit: Number(_values.max_order_limit)
        }

        if (filteredValues.start_time >= filteredValues.end_time) {
            console.log(`${filteredValues.start_time} >> ${filteredValues.end_time}`);
            message.error(`Start value must be greater than End value (${filteredValues.start_time} > ${filteredValues.end_time})`)
            return;
        }

        this.setState({ loading: true });

        let resutls;

        if (_id) {
            resutls = await editDeliverySlot({ ...filteredValues, _id: _id }).then((e) => (e.data.editDeliverySlot))
            .catch(error => {
                console.log(__error("Error: "), error);
                return { error: { message:"Query Error" } }
            });
        } else {
            resutls = await addDeliverySlot(filteredValues).then((e) => (e.data.addDeliverySlot))
            .catch(error => {
                console.log(__error("Error: "), error);
                return { error: { message: "Query Error" } }
            });
        }

        this.setState({ loading: false })
        if (resutls.error){
            message.error(resutls.error.message);
            return false;
        }

        this.props.onUpdate(resutls)
        // onClose(resutls);
    }

    disabledDate = (current, day) => {
        // Can not select days before today and today
        // return current && current < moment().endOf('day');
        return moment(current).format("ddd").toLowerCase() != day
    }



    render() {
        const { onClose, showform, deliverySlot } = this.props;
        const { __fields, loading, loadingEditNode } = this.state;

        if (loadingEditNode) return null;

        this.fields = __fields ? { ...__fields } : deliverySlot ? { ...deliverySlot } : this.fields;

        // convert UTC timestap array to mongo objects array 
        if (this.fields.time_range_utc){
            let time_range_utc = JSON.parse(this.fields.time_range_utc);
            time_range_utc = [moment(time_range_utc[0], "DD-MM-YYYYTHH:mm"), moment(time_range_utc[1], "DD-MM-YYYYTHH:mm")];
            this.fields = Object.assign(this.fields, { time_range_utc })
        }
        if (!this.props.showform) return null;

        return (
            <Drawer width={"400px"} destroyOnClose maskClosable={false} placement="right"
                loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5" }}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('deliverySlotsForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${this.fields && this.fields._id ? 'Edit' : 'Add'} Delivery Slot`}
            >
                <FormComponent onSubmit={this.onSubmit} id='deliverySlotsForm' loading={loading} fields={{ ...this.fields }}
                    style={{ padding:"0px" }}
                    decorators={[setStartTime, setEndTime]}
                    mutators={{ addBlockDate, removeBlockDate }}

                    form_render={formProps => {
                        const { values, submitting } = formProps;

                        return <>
                            <div className="grid-block" style={{margin:0}}>
                                <FormField type="select" name="day" label="Day" inputProps={{ disabled:values._id>0 }} data={daysList} validate={rules.required} />
                                <FormField type="number" name="max_order_limit" label="Order Limit" />
                                <FormField type="timerange" name="time_range_utc" label="Time Range" validate={rules.required} />
                                {/* {values.time_range_utc && <>
                                    <p>{values.time_range_utc[1].format()}</p>
                                    <p>{values.time_range_utc[0].utcOffset(5, true).format()}</p>
                                    <p>{values.time_range_utc[1].utcOffset(5, true).format()}</p>
                                </>} */}
                            </div>
                            <div style={{height:"10px"}} />
                            <div className="grid-block" style={{ margin: 0 }}>
                                <Heading>Blocked Dates</Heading>
                                <Row align="middle">
                                    <Col><FormField type="date" name="blocked_dates_momoent" inputProps={{ disabled:!values.day, disabledDate: (current) => this.disabledDate(current, values.day)}} _label="Blocked Dates" /></Col>
                                    <Col><IconButton icon="plus" onClick={() => formProps.form.mutators.addBlockDate()} /></Col>
                                </Row>

                                <div className="data-row-table grid">
                                {values.blocked_dates && values.blocked_dates.split(",").map((item, i) => {
                                    console.log("item: ", item)
                                    return (<div key={i} className="date-row">
                                        {item}
                                        <IconButton icon="minus" onClick={() => formProps.form.mutators.removeBlockDate(item)} />
                                        {/* <IconButton icon="minus" onClick={() => formProps.form.mutators.removeBlockDate(moment(item).format("YYYY-MM-DD"))} /> */}
                                    </div>)
                                })}
                                </div>

                            </div>
                        </>
                    }}
                />

            </Drawer>
        )
    }
}


FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editDeliverySlot: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addDeliverySlot: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, deliverySlot, error } = data;

            if (error) {
                message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
                console.log(__error("error"), error);
                // error.networkError.name
                // error.networkError.response.status
                // error.networkError.response.statusText
                console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
            }

            return { loadingEditNode: loading, deliverySlot, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />


const mapStateToProps = ({ grocer_storeadmin }) => {
    // console.log("settings: ", settings.default_timezone)
    return ({
        settings: grocer_storeadmin.settings,
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
// })
export default connect(mapStateToProps, null)(Wrapper);
