import React from 'react'
import { Row, Col, Divider } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { orderStatus } from 'configs/constants';
import { FormField, FormComponent, FormFieldGroup, DateField } from 'Common/components/Form'
import { Icon, Button } from 'Common/components'
import { dateToUtc, formToFilter } from 'Common/scripts/Functions'
import { connect } from "react-redux";


const OrderFilter = props => {

    const onSubmit = values => {
        const _values = { ...values };
        let filter = formToFilter(_values, { tz: props.default_timezone })

        if (_values.created_at_range) Object.assign(filter, { 
            created_at_range: [
                dateToUtc(_values.created_at_range[0], { tz: props.default_timezone }), 
                dateToUtc(_values.created_at_range[1], { tz: props.default_timezone })
        ] });

        // convert serial search to serial + _id
        if (filter.serial){
            delete filter.serial;
            if (!isNaN(values.serial)){
                Object.assign(filter, { $or: [{ _id: values.serial }, { serial: values.serial }] })
            }else{
                Object.assign(filter, { serial: values.serial })
            }
        }
        if (values.items?._id){
            delete filter._id;
            // Object.assign(filter, { $in: [{ "items._id": values.items._id }] })
            Object.assign(filter, { "items._id": values.items._id })
        }

        props.onSearch(filter);
    }

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='OrderFilterForm' hideDevBlock={true} fields={props.defaultValue} style={{padding:0}}>
            <Row gutter={[0, 0]}>
                <Col flex="auto" className="filters-column">
                    <FormFieldGroup compact style={{padding:0, margin:0}}>
                        <FormField type="text" prefix={<Icon icon="user" color="#CCC" />} name="customer.keywords" placeholder="name, email, phone" label="Customer" width="170px" compact allowClear size="small" />
                        <FormField type="text" prefix={<Icon icon="barcode" color="#CCC" />} name="serial" placeholder="Serial / Order ID" label="Serial" width="170px" compact allowClear size="small" />
                        <FormField type="select" data={orderStatus({ user:props.user, store:props.store })} name="status" placeholder="Status" label="Status" width="170px" compact inputProps={{ allowClear:true, size:"small"}} />
                        <FormField type="number" name="grandTotal.lte" placeholder="Total value" label="Max value" width="70px" min={0} compact allowClear size="small" />
                        <FormField type="daterange" name="created_at_range" label="Order date" compact allowClear size="small" />
                        <FormField type="number" label="With Product ID" name="items._id" placeholder="With Product ID" width="120px" compact allowClear size="small" />
                    </FormFieldGroup>
                </Col>
                <Col className="go-column">
                    <Button className="send_button" loading={props.loading} htmlType="submit"><Icon icon="search" /></Button>
                </Col>
            </Row>
        </FormComponent>
    </div>)

}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { 
        default_timezone: grocer_storeadmin.default_timezone,
        store: grocer_storeadmin.store,
        user: grocer_storeadmin.user
    };
}
const WithRedux = connect(mapStateToProps)(OrderFilter);
export default WithRedux;
