import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'

// addReducers({ admins: reducers });
addModule(
  {
    permission_required:'discount-vouchers-access',
    path: `${ROOT}`, component: Home, icon: 'tags', title: "Discount Vouchers", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
