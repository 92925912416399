import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, GMap, ListHeader, DeleteButton } from 'Common/components';
// import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import GeoZonesFilter from './GeoZonesFilter'
import { ContentArea } from 'Layout_v1';
// import { lightOrDark } from 'Common/scripts/Functions'


const LIST_DATA = loader('src/graphqls/geo_zone/geoZones.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/geo_zone/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/geo_zone/delete.graphql');


export class ListComp extends Component {
    subscription = null;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "geoZonesUpdated",
                _subscriptionType: "simple-array",
                _queryName: "geoZones",
                _typename: "GeoZone",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteGeoZone(id).then(r => {
            if (r && r.data && r.data.deleteGeoZone && r.data.deleteGeoZone.error) {
                message.error(r.data.deleteGeoZone.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        { title: 'ID', dataIndex: '_id', width:100 },
        {
            title: 'Geo Zone', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                        <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                    </Col>
                </Row>)
            }
        },

        { title: 'Category', dataIndex: 'category', width: 200, 
            filters: [
                { text: 'Service Area', value: 'service-area' },
                { text: 'Delivery Zones', value: 'delivery-zones' },
            ],
            onFilter: (value, record) => record.category.indexOf(value) === 0,
            sorter: (a, b) => a.category.length - b.category.length,
            // defaultSortOrder: 'descend',
        },
        { title: 'city', dataIndex: 'city', width: 200, 
            sorter: (a, b) => a.city.length - b.city.length,
            defaultSortOrder: 'descend',
        },
        { title: 'Status', dataIndex: 'status', width: 100, align:'center' },
    ];

    render() {
        const { loading, geoZones, queryErrors } = this.props;

        return (<ContentArea>
            {/* <div style={{ width: '100%', height: '400px' }}>
                <GMap
                    markers={[{ position: { lat:17.38, lng:78.38 } }]}
                    shapes={[{paths:[
                        { lat:17.4, lng:78.35 },
                        { lat:17.35, lng:78.35 },
                        { lat:17.35, lng:78.4 },
                        { lat:17.4, lng:78.4 },
                    ]}]}
                    zoom={13}
                    center={{ lat: 17.38, lng: 78.38 }}
                    // showDeliveryZones
                />
            </div> */}

            <ListHeader title="Geo Zones" sub={`Total ${geoZones && geoZones.length || '0'} records found`} 
                right={<>
                    <Button onClick={this.props.onAddClick}>Add New GeoZone</Button>
                </>}
            />

            <GeoZonesFilter onSearch={(val)=>{
                this.props.loadMoreRows(JSON.stringify(val))
            }} />
            <Table loading={loading}
                columns={this.columns}
                dataSource={geoZones ? geoZones : null}
                pagination={false}
            />
        </ContentArea>)
    }
}

ListComp.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { };
            // return { variables: { filter: JSON.stringify({ category:"service-area"}) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, geoZones, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter) => {
                let vars = { filter };
                let updateQuery = (previousResult, { fetchMoreResult }) => { return fetchMoreResult; }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, geoZones, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteGeoZone: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

export default WithApollo;