import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright'; 
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { Loader, Button, Heading, Icon, DevBlock } from 'Common/components'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import moment from 'moment';
import { dateToUtc, utcToDate } from 'Common/scripts/Functions';
import { connect } from "react-redux";


const GET_STORE_STATS = loader('src/graphqls/store_stats/getStoreStats.graphql');

class UniqueCustomers extends Component {
    state = { 
        getStoreStats:{}, 
        loading:false,
        filter:{
            from_date: moment().startOf("month"),
            to_date: moment().endOf("month")
        },
        error: null
    };
    constructor(props){
        super(props);
        this.onUniquecustomerFilter = this.onUniquecustomerFilter.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount(){
        this.fetchData()
    }

    onUniquecustomerFilter(values){
        const { default_timezone } = this.props;

        this.setState({ from_date: values.from_date, to_date: values.to_date }, ()=>{
            this.fetchData(dateToUtc(values.from_date, { tz: default_timezone }), dateToUtc(values.to_date, { tz: default_timezone }))
        })
    }

    fetchData(from_date, to_date){
        const { filter } = this.state;
        const { default_timezone } = this.props;

        // return console.log("fetchData()", { from_date, to_date })

        this.setState({ loading: true, error:null });

        this.props.client.query({
                query: GET_STORE_STATS,
                variables: { 
                    from_date: utcToDate(from_date || filter.from_date), // dateToUtc(from_date || filter.from_date, { tz: default_timezone }),
                    to_date: utcToDate(to_date || filter.to_date), // dateToUtc(to_date || filter.to_date, { tz: default_timezone })
                },
                fetchPolicy: "no-cache",
            }).then(r=>{
                this.setState({ 
                    loading: false, 
                    getStoreStats: r.data.getStoreStats?.error ? null : r.data.getStoreStats,
                    error: r.data.getStoreStats?.error?.message || null ,
                });
        }).catch(err=>{
            this.setState({ loading: false, error:"Invalid query response" });
        })
    }


  render() {
    const { loading, getStoreStats, filter, error } = this.state;

    return (<>
        <Loader loading={loading}><>
            {error && <Alert message={error} type='error' showIcon={true} />}

            <Heading>Number of Unique customers</Heading>
            <Row align="middle">
                <Col>
                    <FormComponent onSubmit={this.onUniquecustomerFilter} id='UniqueCustomersfilter' loading={loading} debug={false}
                        fields={filter}
                        form_render={formProps => {
                            const { values } = formProps;

                            return (<>
                                <Row>
                                    <Col><FormField type="date" name="from_date" label="Start Date" validate={rules.required} compact /></Col>
                                    <Col><FormField type="date" name="to_date" label="End Date" validate={rules.required} compact /></Col>
                                    <Col style={{ paddingTop: "20px" }}><Button loading={loading} htmlType="submit">Search</Button></Col>
                                </Row>

                            </>)
                        }}
                    />
                </Col>
                <Col><div style={{ fontSize: '22px', paddingTop: "15px", paddingLeft: "10px" }}><b>: </b>{getStoreStats?.num_uniqueCustomers || "0"}</div></Col>
            </Row>

        </></Loader>
    </>)
  }
}
const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.settings.default_timezone };
}
const WithRedux = connect(mapStateToProps)(UniqueCustomers);

export default withApollo(WithRedux);
