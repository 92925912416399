import React from 'react'
import TypeForm from '../components/type_form';
import TypeList from '../components/type_list';
import { ContentArea } from 'Layout_v1';


export const TypeManagement = props => {
    const [{ visible, fields }, setState] = React.useState({ visible:false, fields:{} });
    
    const toggleDrawer = args => {
        setState({ visible: !visible, fields:args.fields || null });
    };

    return (<>
        <ContentArea>
            <TypeList 
                onAddRecord={() => toggleDrawer({})} 
                onEditRecord={(record=null) => toggleDrawer({ fields: record })} 

                />
        </ContentArea>

        <TypeForm onClose={toggleDrawer} showform={visible} fields={fields} />

    </>)
}

export default TypeManagement;