import React, { Component, useRef, useEffect } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Button, PrintComponent, Barcode } from 'Common/components';
import { Row, Col } from 'antd';
import { connect } from "react-redux";
// import { utcToDate } from 'Common/scripts/Functions'
import moment from 'moment';

const styles = {
    zoneCircleWrapper: {
        textAlign: "center", display: 'flex', justifyContent: 'center', marginBottom:"3px",
    },
    zoneCircle: {
        display: 'flex', flexDirection: "column",
        backgroundColor: '#000', borderRadius: 50, minWidth: 70, height: 70, color: '#FFF',
        alignItems: 'center', justifyContent: 'center', padding: '20px',
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
}


const ReceiptBody = props => {
    const { customer, delivery_slot, shipping_address, connectedZone, serial, grandTotal, basket, invoice } = props.order;
    // const time_range_utc = JSON.parse(delivery_slot.time_range_utc).map(itm => utcToDate(itm))
    const time_range_utc = JSON.parse(delivery_slot.time_range_utc).map(itm => moment(itm, "DD-MM-YYYYTHH:mm"))

    return (
        <div style={{ borderBottom: '5px solid #EEE', backgroundColor: "#FFF", width: '88mm', paddingBottom: "30px" }}>
            <div style={{ padding: '15px' }}>
                <div>Delivery Address:</div>
                <div style={{ fontWeight: 'bold' }}>{shipping_address.full_address}, {shipping_address.city}</div>
            </div>

            <div style={styles.zoneCircleWrapper}><div style={styles.zoneCircle}>
                <div style={{ fontSize: 20, lineHeight:"20px" }}>
                    {connectedZone ? connectedZone.title : "N/A"}
                </div>
            </div></div>

            <div style={{ backgroundColor: '#000', color: '#FFF', padding: '10px', margin: '0px 0' }}>
                <Row align='middle'>
                    <Col flex='70px'>Customer:</Col>
                    <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>{customer.name}</Col>
                </Row>
                {/* <Row align='middle'>
                    <Col flex='70px'>Phone:</Col>
                    <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>{customer.phone}</Col>
                </Row> */}
                <Row align='middle'>
                    <Col flex='70px' style={{ lineHeight: 1 }}>Delivery Slot:</Col>
                    <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>{time_range_utc[0].format("hh:mm A")} to {time_range_utc[1].format("hh:mm A")}</Col>
                </Row>
                {/* <Row align='middle'>
                    <Col flex='70px' style={{ lineHeight: 1 }}>Invoice Amount:</Col>
                    <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>{props.settings.currency} {invoice.grand_total}</Col>
                </Row> */}
            </div>

            <div style={{ ...styles.center }}>
                <Barcode height={50} value={serial} />
            </div>
            <div style={{ ...styles.center, fontSize: 18 }}>{serial}</div>
            <Row align='middle' style={{ padding: 20 }}>
                <Col flex='auto' style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.settings.currency} {invoice.grand_total}</Col>
                <Col style={{ fontSize: '16px', textAlign:"right" }}>
                    {props.receiptNum} of {props.totalReceipts}<br />
                    <font style={{fontSize:'14px'}}>{props.basket?.title}</font>
                </Col>
            </Row>
        </div>
    );

}


const ComponentToPrint = React.forwardRef((props, ref) => {
    let baskets = props.baskets || ['1'];

    return (<div style={{ display: "block", height:'110mm', overflow:"auto" }}>
        <div ref={ref} style={{ width: '88mm' }}>{baskets.map((_b, i) => <ReceiptBody {...props} key={i} receiptNum={i + 1} totalReceipts={baskets.length} basket={_b} />)}</div>
    </div>)

});

const ReceiptPrinterComp = (props) => {
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        // console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "AwesomeFileName",
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);


    useEffect(() => {
        props.getPrintComponent({
            handlePrint
        })
    }, [props.getPrintComponent])


    return (<>
        <div style={{ justifyContent:"center", textAlign:"center" }}>
            <span style={{ display: "inline-block", textAlign: "left" }}><ComponentToPrint {...props} ref={componentRef} /></span>
        </div>

        <div style={{ justifyContent:"center", display:"flex" }}>
            <div style={{ width: '88mm' }}><Button block loading={loading} size="large" onClick={handlePrint}>Print Receipt</Button></div>
        </div>
    </>);
};


const mapStateToProps = ({ grocer_storeadmin }) => {
    // console.log("grocer_storeadmin: ", grocer_storeadmin);
    // console.log("props.settings.currency: ", grocer_storeadmin.settings.currency)
    // props.settings.currency
    return {
        settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        store: grocer_storeadmin.store,
        // token: grocer_storeadmin.token,
        // rights: grocer_storeadmin.rights
    };
}
export const ReceiptPrinter = connect(mapStateToProps)(ReceiptPrinterComp);
export default ReceiptPrinter;
