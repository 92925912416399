import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader, Avatar, IconButton, DevBlock } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { pageOptionsArray } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import BannerSelection from './BannerSelection'
import ProductSelector from './ProductSelector'

const RECORD = loader('src/graphqls/page_settings/page_setting.graphql');
const RECORD_EDIT = loader('src/graphqls/page_settings/edit.graphql');


const setProduct = (newValueArray, state, tools) => {

    let _target = newValueArray[0];
    let key = _target.key;

    // setup prev values/
    let products = state.formState.values.products || [];
    let prev = products?.find(o=>o.key==key) || {};
    let ids = prev.ids || [];// = state.formState.values.products && state.formState.values.products[key];
    let title = prev.title;
    let _products = prev.products || [];

    // console.log("prev: ", prev);

    // update values
    if (_target.title) title = _target.title;
    // product actions
    if (_target.add_prod) {
        ids.push(_target.add_prod._id);
        _products.push(_target.add_prod);
    }
    if (_target.remove_prod) {
        ids = ids.filter(o => o != _target.remove_prod._id);
        _products = _products.filter(o => o._id != _target.remove_prod._id)
    }

    let product = { ids, title, key, products: _products }

    // update master array 'products'
    let index = products.findIndex(o => o.key == key);
    if (index > -1) products[index] = product;
    else products.push(product)

    // update form field
    tools.changeValue(state, 'products', () => products)
}




export class FormComp extends Component {
    fields = { status: "disabled" };

    constructor(props) {
        super(props);
        this.state = { loading: false, __fields: false, loadingEditNode: props.loadingEditNode }
        this.onSubmit = this.onSubmit.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.loadingEditNode !== state.loadingEditNode) {
            return {
                ...state,
                loadingEditNode: props.loadingEditNode,
            };
        }
        return { ...state };
    }

    onSubmit = values => {
        // console.log("onSubmit()", _values); return;

        // const pageOptions = pageOptionsArray[this.fields.key];
        const { pageSetting, onClose, editPageSettings } = this.props;
        const { __fields } = this.state;
        let fields = __fields ? { ...__fields } : pageSetting ? { ...pageSetting } : {}

        const _id = fields ? fields._id : false;
        const filteredValues = { _id: _id };

        if (values.products) { 
            Object.assign(filteredValues, { 
                products: values.products.map(item => ({ ids: item.ids, key: item.key, title: item.title }))
            })
        }
        if (values.banners) { 
            Object.assign(filteredValues, {
                banners: values.banners.map(item => ({ _id: item._id, key: item.key }))
            })
        }

        this.setState({ loading: true })


        if (_id) {
            editPageSettings(filteredValues).then((e) => {
                this.setState({ loading: false })
                if (e.data.editPageSettings.error) {
                    let err = e.data.editPageSettings.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editPageSettings);
            }).catch(error => {
                this.setState({ loading: false })
                console.log(error);
                message.error("Query Error");
            });
        }
        else {
            message.error("No ID to update")
        }

    }


    render() {
        const { onClose, showform, pageSetting } = this.props;
        const { __fields, loading, loadingEditNode } = this.state;

        this.fields = __fields ? { ...__fields } : pageSetting ? { ...pageSetting } : this.fields;
        if (!this.props.showform) return null;
        var initialValues = { ...pageSetting };
        const pageOptions = pageOptionsArray[this.fields.key];


        return (
            <Drawer width={"600px"} destroyOnClose maskClosable={false} placement="right"
                loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5" }}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('PageSettingForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`Edit Page Setting`}
            >
                <FormComponent onSubmit={this.onSubmit} id='PageSettingForm' loading={loading} fields={{ ...initialValues }} debug={true}
                    mutators={{
                        setProduct: setProduct,

                        setBanners: (newValueArray, state, tools) => {
                            // console.log("setBanners()", newValueArray)
                            let _target = newValueArray[0];
                            if (!_target) return;

                            let { _id, key } = _target;


                            // setup prev values/
                            let banners = state.formState.values.banners || [];
                            // remove previous records/
                            banners = banners.filter(o => o.key != key);


                            // remove request received
                            if (!_id){
                                return tools.changeValue(state, 'banners', () => banners)
                            }


                            banners.push({ _id, key })
                            // update form field
                            return tools.changeValue(state, 'banners', () => banners)
                        },
                        
                    }}
                    form_render={formProps => {
                        const { values } = formProps;

                        return <>
                            <div className="grid-block" style={{margin:"0 0 10px 0"}}>
                                <FormField disabled type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                            </div>

                            {(pageOptions && pageOptions?.banners?.length > 0) && <>
                                <div className="grid-block" style={{ margin: "0 0 10px 0" }}>
                                    <Heading>Banners</Heading>
                                    {pageOptions?.banners?.map((banner_key, i)=>{
                                        let defaultValue;
                                        let banner = values?.banners?.find(o => o.key == banner_key) || null;
                                        if (banner) {
                                            let bannerDetails = banner.banner;
                                            defaultValue = bannerDetails ? { _id: bannerDetails._id, title: bannerDetails.title } : null;
                                        }

                                        return (<div key={i} style={{ marginBottom: "30px" }}>
                                            <BannerSelection label={banner_key} name={`__banners.${banner_key}._id`}
                                                onChange={(val) => {
                                                    console.log("onChange()", val)
                                                    formProps.form.mutators.setBanners({ _id: val, key: banner_key })
                                                }}
                                                defaultValue={defaultValue}
                                            />
                                        </div>)
                                    })}
                                </div>
                            </>}

                            {pageOptions && pageOptions.products && pageOptions?.products?.length > 0 && <>
                                <div className="grid-block" style={{ margin: "0 0 10px 0" }}>
                                    <Heading>Products</Heading>

                                    {pageOptions?.products?.map((prod_key, i)=>{
                                        let prods = values?.products?.find(o => o.key == prod_key) || {};
                                        {/* let { ids, key, products, title } = prods || {}; */}

                                        return (<div key={i} style={{ marginBottom: "30px" }}>
                                            <ProductSelector values={values} label={prod_key} name={`__products.${prod_key}.ids`} blockIds={prods.ids}
                                                onAddClick={(prod) => formProps.form.mutators.setProduct({ add_prod: prod, key: prod_key })}
                                            >
                                                <FormField type="text" name={`__products.${prod_key}.title`} label="Title"
                                                    defaultValue={prods.title}
                                                    onChange={(e, callback) => {
                                                        formProps.form.mutators.setProduct({ title: e.target.value, key: prod_key })
                                                        callback(e);
                                                    }}
                                                // onChange={(val) => formProps.form.mutators.setProduct({ title: val, key: item })}
                                                />
                                            </ProductSelector>

                                            <div style={{ margin: "0 20px" }}>
                                                {prods.products && prods.products.map((prod, ii)=>{
                                                    return (<Row key={ii} className="date-row" gutter={[15, 0]} style={{ flexWrap: "nowrap" }}>
                                                        <Col flex="30px"><Avatar size={40} shape="square" src={`${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}`} /></Col>
                                                        <Col flex="auto"><div style={{ flexWrap: "wrap", whiteSpace: "normal" }}>{prod.title}</div></Col>
                                                        <Col flex="20px"><IconButton icon="minus" onClick={() => formProps.form.mutators.setProduct({ remove_prod: prod, key: prod_key })} /></Col>
                                                    </Row>)
                                                })}
                                            </div>

                                        </div>)
                                    })}

                                </div>
                            </>}

                        </>
                    }}
                />

            </Drawer>
        )
    }
}


FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editPageSettings: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, pageSetting, error } = data;

            if (error) console.log(__error("error"), error);

            return { loadingEditNode: loading, pageSetting, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;