import React from 'react'
// import PropTypes from 'prop-types';
import { __error } from 'Common/scripts/consoleHelper'
import { FormField } from 'Common/components/Form'
import { connect } from "react-redux";
import { staffTypes } from 'configs/constants';
import { verifyRole, checkRights, isSuperAdmin } from 'Common/scripts/Security';
import { Alert } from 'antd';

const SelectStaffDD = ({ user, rights, name, label, width, compact, allowClear, size, validate }) => {

    // let staff_manage = verifyRole("staff-manage", { user, rights })
    let staff_manage = checkRights(rights, "staff-manage")
    if (!staff_manage) return <Alert message="Not allowed to manage staff" type='error' showIcon />

    let add_picker = checkRights(rights, "add-picker");
    let add_driver = checkRights(rights, "add-driver");
    let till_dispatcher = checkRights(rights, "till-dispatcher");
    let store_supervisor = checkRights(rights, "store-supervisor");

    console.log("user: ", user.type == "super-admin")

    const _staffTypes = staffTypes.filter(o => {
        if (user.type == "super-admin") return (o._id == "driver");
        if (o._id == "driver" && add_driver) return true;
        if (o._id == "picker" && add_picker) return true;
        if (o._id == "till-dispatcher" && till_dispatcher) return true;
        if (o._id == "store-supervisor" && store_supervisor) return true;

        // if(user.store_id){
        //     return (o._id == "driver" || o._id == "till-dispatcher" || o._id == "store-supervisor");
        // }

        return false;
    })

    return <FormField type="select" 
        name={name || "type"} label={label || "Type"} 
        data={_staffTypes} width={width || "200px"} compact={compact} allowClear={allowClear} size={size || "medium"}
        validate={validate}
        />
}
const mapStateToProps = ({ grocer_storeadmin }) => {
    return { 
        user: grocer_storeadmin.user,
        rights: grocer_storeadmin.rights,
    };
}
export const SelectStaff = connect(mapStateToProps)(SelectStaffDD);
