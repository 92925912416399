/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox, message } from 'antd';
import { Form, Field } from 'react-final-form'
import LocalStorage from 'Common/scripts/LocalStorage';
import { Loader, Button, DevBlock, Icon } from 'Common/components'
import { rules, composeValidators, FormField } from 'Common/components/Form'
import axios from 'axios';
import { connect } from "react-redux";
import { loginUser, logoutUser } from 'Store/grocer_storeadmin.actions';
import { appVer } from 'configs';
import { __error } from 'Common/scripts/consoleHelper';
import './styles.scss';




/**
 * Login
 */
class Login extends Component { // eslint-disable-line react/prefer-stateless-function
  // Verification = verifyModule('Login');
  constructor(props) {
    super(props);
    this.state = { busy: false, warning: false, error: false };
    this.onSubmit = this.onSubmit.bind(this);
    this.handelResponse = this.handelResponse.bind(this);

    if (this.props.token || this.props?.user?._id){
      this.props.logoutUser();
    }
  }

  handelResponse = async(res) => {
    console.log("handelResponse() ", JSON.stringify(res, 0, 2));

    // {TokenData:response.data.TokenData, LoggedUser:response.data.LoggedUser}
    // const { description, status, email, name, password, type, _id } = res.LoggedUser;
    if (!res) { this.setState({ error: "Empty response!", busy: false }); return; }
    if (res.error) { this.setState({ error: res.error?.message, busy: false }); return; }
    if (res.warning) { this.setState({ warning: res.warning, busy: false }); return; }
    if (!res.data) { this.setState({ error: "No response Data available", busy: false }); return; }

    const { LoggedUser, TokenData, status, error } = res.data;
    if (error) { 
      console.log(__error('Error: '), res.data)
      this.setState({ error: error.message, busy: false });
      return; 
    }

    if (status=='error'){
      console.log(__error('Status Error: '), res.data)
      this.setState({ error: "Login fail", busy: false });
      return;
    }

    if (TokenData && LoggedUser) {
      if (LoggedUser.user.type !== 'super-admin' && (!LoggedUser.rights || LoggedUser.rights.length < 5)) {
        this.setState({ error: "You have no rights!", busy: false });
        return false;
      }
      
      if (LoggedUser.user.type == 'customer') {
        this.setState({ error: "Only user with admin rights can log in", busy: false });
        return;
      }

      this.props.loginUser({ 
        user: LoggedUser.user, 
        rights: LoggedUser.rights, 
        store: LoggedUser.store, 
        token: TokenData 
      })
      message.success("Login successful");
      await LocalStorage.login(LoggedUser.user, TokenData);
      // LocalStorage.set('TokenData', TokenData);
      // await LocalStorage.setJsonAsync('user', LoggedUser.user);
      // window.location.href = '/';
      return;
    }

    this.setState({ response:res, busy: false });
  }

  async onSubmit(values) {
    // console.log("onSubmit: ", values);
    this.setState({ busy: true, error: false, warning:false });

    const results = await axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL + '/user/login',
      data: { 
        store_code: values.store_code,
        username: values.username,
        pwd: values.pwd,
        app: "admin-web",
        ver: appVer,
      }
    })
      .then(r => (r))
      .catch((error) => {
        console.log(__error("ERROR: "), error);
        return { error: {message:'Netowrk Error'} };
      });

    this.handelResponse(results)
  }

  render() {
    // if(checkLoggedIn()) return <h3>Not Logged In</h3>;
    const { busy, warning, error } = this.state;

    return (
      <div className="login_form_page">

        <div className="lgf_wrapper">

          <Loader spinning={busy}>
            <div className="lgf_container">

              <img src={`/assets/grocerzm-logo-190.png`} width="200px" />

              <div className="warning_container">
                {error && <Alert message="Error" type="error" description={String(error)} closable />}
                {warning && <Alert message="Warning" type="warning" description={String(warning)} closable banner={true} />}
              </div>

              <Form onSubmit={this.onSubmit} initialValues={{}} validate={(__values)=>(false)}>
                {({ handleSubmit, submitting, reset, values, invalid }) => (<>
                  <form className="form_component" onSubmit={event => {
                    if (invalid) message.error("Invalid submission! Please verify your input.");
                    handleSubmit(event);
                  }}>

                    <FormField name="store_code" type="text" placeholder="Store Code" validate={composeValidators(rules.required, rules.minChar(3))} />
                    <FormField name="username" type="text" placeholder="User name" validate={composeValidators(rules.required, rules.minChar(3))} />
                    <FormField name="pwd" type="password" placeholder="Password" validate={composeValidators(rules.required, rules.minChar(3))} />

                    <Button type="primary" htmlType='submit' loading={busy}>Login</Button>

                    {/* <FormField name="remember" label="Remember Me" type="checkbox" /> */}


                  </form>

                  {/* <DevBlock obj={values} /> */}
                  
                </>)}
              </Form>

            </div>
          </Loader>

        </div>

      </div>
    )


  }
}
Login.propTypes = {
  // login: PropTypes.object.isRequired,
}


const mapDispatchToProps = (dispatch, ownProps) => ({
  loginUser: (payload) => dispatch(loginUser(payload)),
  logoutUser: (payload) => dispatch(logoutUser(payload)),
})
const mapStateToProps = ({ grocer_storeadmin }) => {
  
  return {
    token: grocer_storeadmin.token,
    user: grocer_storeadmin.user,
  };
}

export const WithRedux = connect(mapStateToProps, mapDispatchToProps)(Login);

export default WithRedux;