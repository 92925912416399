import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'


const GET_BANNERS = loader('src/graphqls/banners/banners.graphql');


export const BannerSelection = props => {
    const [list, setList] = React.useState(props.defaultValue ? [props.defaultValue] : []);
    const [state, setState] = React.useState({});
    const [showList, setShowList] = React.useState(false);

    const toggleDrawer = args => setShowList(!showList);

    var lastFetchId = 0; 
    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;
    
        let filter = JSON.stringify({ search:{keywords: value} });
        
        setState({ kw: value, loading: true })
        const results = await props.client.query({ query: GET_BANNERS, variables: { filter } }).then(r => {
            return r.data.banners
        }).catch(err => {
            console.log(__error("API Call ERROR: BANNERS : "), err);
            return { error: { message:"Request ERROR"}}
        })
        setState({ loading: false })

        if (results.error){
            return message.error(results.error.message);
        }

        setList(results);
    }
    const fetchData = debounce(_fetchData, 800);

    const handelChange = (vals, callback) => {
        if (props.onChange) props.onChange(vals)
        callback(vals)
    }

    return (
        <>
            <FormField validate={props.validate} allowClear
                type="select" name={props.name}
                label={props.label}
                data={list}
                placeholder="Search Banners"
                // addNull={true}
                // keyMap="value=>text"
                onChange={handelChange}
                defaultValue={props.defaultValue && props.defaultValue._id}
                loading={state.loading}
                inputProps={{
                    loading:state.loading,
                    // mode: props.mode || 'tags',
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: state.loading ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchData,
                }}
            />
        </>
    )
}

export default withApollo(BannerSelection);