import React from 'react'
import PropTypes from 'prop-types';
import { Button, Heading, ListHeader } from 'Common/components'
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';

import BannersList from '../components/banners_list';
import BannersForm from '../components/banners_form';
import { ContentArea } from 'Layout_v1';


const Banners = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<ContentArea>
        <h4>
            Banners
            {checkRights(props.rights, 'banners-manage') &&
                <div className="buttons"><Button onClick={toggleDrawer} size="small">Add New Banners</Button></div>
            }
        </h4>
        {/* <ListHeader title="Banners" sub={`Total ${this.props?.brandQuery?.totalCount || '0'} records found`}
            right={<>
                {checkRights(this.props.rights, 'brand-manage') &&
                    <Button onClick={this.props.onAddClick} size="small">Add New Brand</Button>
                }
            </>}
        /> */}


        <BannersList onEditRecord={(item) => toggleDrawer({ fields: item })} />

        {visible && <BannersForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </ContentArea>)
}
const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(Banners);
