import React, { useState } from 'react'
import { FormField, FormComponent, FormFieldGroup, DateField } from 'Common/components/Form'
import { DevBlock, Icon, Button, Loader, IconButton, SelectStore } from 'Common/components'
import { Row, Col, message } from 'antd';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'

const UPDATE = loader('src/graphqls/product/updateProducts.graphql');


const HandelOrphenProductsComp = ({ list, updateProducts }) => {
    const [busy, setBusy] = useState(false);

    const onSubmit = async (values) => {
        console.log("onSubmit()", values)
        let ids = list.map(o => o._id);

        setBusy(true);

        const results = await updateProducts(ids, JSON.stringify(values)).then(r=>{
            if (r?.error?.message) console.log(__error(r.error.message))
            return r;
        }).catch(error=>{
            message.error("Query Error")
        });
        console.log("results: ", results)
        
        setBusy(false);
    }

    if(busy) return <Loader loading={true} />
    
    
    return (<>
        <FormComponent onSubmit={onSubmit} id='HandelOrphenProductsForm'>
            <b>Update Current page</b>
            <Row className="pagination-filter-row">
                <Col flex="auto" className="filters-column">
                    <SelectStore name="store_id" compact />
                </Col>
                <Col>
                    <Button loading={busy} htmlType="submit">Update</Button>
                </Col>
            </Row>
        </FormComponent>

    </>)

}

const WithApollo = compose(
    graphql(UPDATE, {
        props: ({ mutate }) => ({
            updateProducts: (ids, input) => mutate({
                variables: { ids, input }
            }),
        })
    }),
)(HandelOrphenProductsComp);

export default WithApollo;
