import React from 'react'
import { Button } from 'Common/components'

import TypesList from '../components/type_list'
import TypesForm from '../components/type_form'


export const ProdTypes = props => {
    const [{ visible, fields }, setState] = React.useState({ visible:false, fields:null});

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <TypesForm onClose={toggleDrawer} showform={visible} fields={fields} />

        <TypesList onEditRecord={(item) => toggleDrawer({fields:item})} 
            onAddClick={() => toggleDrawer({})}
        />
    </>)
}
export default ProdTypes;