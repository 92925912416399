import React from 'react'
import { ListHeader } from 'Common/components';
import { Radio } from 'antd';

const SalesHeader = ({ title, totalCount, onTypeChange, value }) => {
    return (<>
      <ListHeader
        title={<>{title}</>}
        sub={<>Total {totalCount || 0} records</>}
        right={<>
          <Radio.Group value={value} buttonStyle="solid" onChange={({ target }) => onTypeChange(target.value)}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="pending">Pending</Radio.Button>
            <Radio.Button value="confirmed">Confirmed</Radio.Button>
            {/* <Radio.Button value="paid">Paid</Radio.Button> */}
          </Radio.Group>
        </>}
      />
    </>)
}

export default SalesHeader;
