import React, { useEffect, useState, useCallback, useRef } from 'react'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { message, Row, Col, Tooltip, Card, Alert, Space, Table } from 'antd';
import { connect } from "react-redux";
import { Button, Heading, Icon, Loader, DevBlock, PageHeader, IconButton, DeleteButton } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import arrayMutators from 'final-form-arrays'
import { __error, __yellow } from 'Common/scripts/consoleHelper'
// import LocalStorage from 'Common/scripts/LocalStorage';
import moment from 'moment';
import { ContentArea } from 'Layout_v1';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { updateSettings } from 'Store/grocer_storeadmin.actions';
import { checkRights } from 'Common/scripts/Security';
import FieldFormEditor from '../components/FieldFormEditor';
import _ from 'lodash'
import { dateToUtc, sleep } from 'Common/scripts/Functions';

const RECORD = loader('src/graphqls/settings/settings.graphql');
const EDIT_MULTIPLE = loader('src/graphqls/settings/editMultipleSettings.graphql');
const DEL_SETTINGS = loader('src/graphqls/settings/deleteSettings.graphql');
const UPDATE_SORT = loader('src/graphqls/settings/updateSettingsSort.graphql');


const languageArray = [
    { _id:"en", title:"English" }
]
// const settingsToload = ['default_timezone', 'currency']


const SortableTable = ({ fields, onUpdate, client }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [busy, setBusy] = useState(false);
    const [data, setData] = useState(fields.slice());

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef(null);
        const type = 'DraggableBodyRow';

        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) return {};
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: (item) => {
                moveRow(item.index, index);
            },
        });

        const [, drag] = useDrag({
            type,
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drop(drag(ref));

        return (
            <tr ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{ cursor: 'move', ...style }}
                {...restProps}
            />
        );
    };

    const columns = [
        {
            title: 'Drag to sort',
            dataIndex: 'title',
            key: 'title',
        },
        {
            type: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
    ];

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];
            if (!isDirty) setIsDirty(true)
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [data],
    );

    const saveSort = async() => {
        console.log("saveSort()")
        setBusy(true);

        const input = data.map((o, i) => ({ _id: o._id, sort_order:i }))
        
        let resutls = await client.mutate({ mutation: UPDATE_SORT, variables: { input } }).then(({ data }) => (data.updateSettingsSort))
        if (resutls.error) {
            alert(resutls.error.message);
            return false;
        }
        setBusy(false);

        onUpdate()
    }


    return (<>
        <DndProvider backend={HTML5Backend}>
            <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                components={components}
                onRow={(_, index) => {
                    const attr = { index, moveRow };
                    return attr;
                }}
            />
        </DndProvider>
                
        <div align="center" style={{ padding:"20px" }}>
            <Button loading={busy} onClick={saveSort} disabled={!isDirty}>Save The Order</Button>
        </div>

    </>) 

}



const SystemConfigurations = ({ editMultipleSettings, on_updateSettings, rights, client }) => {
    const [settingsArray, set_settings] = useState(null)
    const [showFieldForm, set_showFieldForm] = useState(false)
    const [busy, setBusy] = useState(false)
    const [enableSort, set_enableSort] = useState(false)

    useEffect(() => {
        if (settingsArray) return;
        fetchData();

      return () => {
          set_settings(null)
      };
    }, [])

    const fetchData = async() => {
        set_enableSort(false)
        setBusy(true)
        let resutls = await client.query({ query: RECORD, variables:{ filter:JSON.stringify({ cat:"system" })} }).then(({ data }) => data.settings);
        set_settings(resutls)
        setBusy(false)
    }
   
    const onSubmit = async (values) => {
        console.log(__yellow("onSubmit()"));

        const input = values.settings.map(item => {
            let _return = {
                _id: item._id,
                // tooltip: item.tooltip,
                // title: item.title,
                value: String(item.value || ""),
                // code: item.code,
                // type: item.type,
                // cat: item.cat,
            }
            if (_return.type == "switch") Object.assign(_return, { value: (_return.value===true) ? "yes" : "no" })
            if (_return.type == "select") Object.assign(_return, { value: JSON.stringify(_return.options) })
            if (_return.type == "date" || _return.type == "date_time") Object.assign(_return, { value: dateToUtc(_return.value) })

            return _return;
        })

        let resutls = await client.mutate({ mutation: EDIT_MULTIPLE, variables: { input } }).then(({ data }) => (data.editMultipleSettings))
            .catch(err => {
                console.log(__error("Query Error"), err);
                return { error: { message: "Unable to save changes!" } }
            })

        if (resutls.error) {
            alert(resutls.error.message);
            return false;
        }

        message.success("Settings Updated!")
        fetchData();
        return true;
    }

    const renderField = (code) => {
        let fieldDate = settingsArray.find(o=>o.code==code)
        let index = settingsArray.findIndex(o=>o.code==code)
        
        let tooltip = !fieldDate ? undefined : <>{fieldDate.tooltip} <br />code: {fieldDate.code} <br />type: {fieldDate.type}</>;
        let label = !fieldDate ? undefined : <>{fieldDate.title}</>; // <Tooltip placement="top" title={tooltip}>{fieldDate.title}</Tooltip>;
        // let label = !fieldDate ? undefined : <>{fieldDate.title} <span className='tooltip'><Tooltip placement="top" title={tooltip}><div><Icon icon="info-circle" style={{ fontSize: "14px" }} color="rgb(71,144,247)" /></div></Tooltip></span></>; // <Tooltip placement="top" title={tooltip}>{fieldDate.title}</Tooltip>;

        if (!fieldDate) return <Alert message={`Invalid field (${code})`} type='error' showIcon />

        if (code == "current_language") return <FormField type="select" data={languageArray} name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (code == "default_timezone") {
            let arr = moment.tz.names();
            return <FormField type="select" showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
                data={arr.map(o=>({ _id:o, title:o }))}
                name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear
            />
        }

        if (fieldDate.type == "text") return <FormField type="text" name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (fieldDate.type == "textarea") return <FormField type="textarea" name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (fieldDate.type == "number") return <FormField type="number" name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (fieldDate.type == "email") return <FormField type="email" name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (fieldDate.type == "switch") return <FormField type="switch" name={`settings[${index}].value`} label={label} tooltip={tooltip} />
        if (fieldDate.type == "date_time") return <FormField type="date" showTime name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (fieldDate.type == "date") return <FormField type="date" name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />
        if (fieldDate.type == "select") return <FormField type="select" data={[]} name={`settings[${index}].value`} label={label} tooltip={tooltip} allowClear />


        return <Alert message={`Invalid field (code: ${code}) ~ (type: ${fieldDate && fieldDate.type})`} type="error" showIcon />

    }

    const onFieldsUpdate = () => {
        set_showFieldForm(false);
        fetchData();
    }

    const onDeletePress = async(id) => {
        let resutls = await client.mutate({ mutation: DEL_SETTINGS, variables: { id:Number(id) } }).then(({ data }) => (data.deleteSettings))
        if (resutls.error) {
            alert(resutls.error.message);
            return false;
        }
        fetchData();
    }

    if (busy) return <Loader loading={true} center />
    if (!settingsArray) return <Alert message="Empty settingsArray" type='error' showIcon />


    return (<>
        <ContentArea transparent>
            <PageHeader title="Settings" right={<>
                {checkRights(rights, 'settings-mutate') && <><Button onClick={() => set_showFieldForm(true)}>Add New Field</Button></>}
            </>} />

            <FormComponent onSubmit={onSubmit} id='ConfigForm' debug={true}
                fields={{ settings: settingsArray }}
                mutators={{ ...arrayMutators }}
                form_render={({ values, submitting }) => {

                    let groupped = _.groupBy(values.settings, "sub_cat");
                    let keys = Object.keys(groupped);

                    return <>
                        <Row gutter={[5, 5]}>
                            {keys.map((group, i) => {
                                if (!checkRights(rights, `settings-${String(group).toLowerCase()}`)) return <span key={i} />;

                                const group_fields = groupped[group];
                                group_fields.sort(function (a, b) { return a.sort_order - b.sort_order });

                                return (<Col span={12} key={i}>
                                    <Card>
                                        <Row>
                                            <Col flex="auto"><Heading compact color="black">{group == "null" ? "Others" : group}</Heading></Col>
                                            <Col><Button disabled={enableSort && enableSort !== group} size="small" type={enableSort ? "primary" : "dashed"} shape="round" 
                                                onClick={() => set_enableSort(!enableSort ? group : false)}
                                                // onClick={() => alert(!enableSort ? group : "false")}
                                                >{!enableSort ? "Enable Sort" : "Disable Sort"}</Button></Col>
                                        </Row>

                                        {enableSort==group ? 
                                            <SortableTable fields={group_fields} client={client} onUpdate={() => fetchData()} /> : 
                                            <Row gutter={[5, 0]}>
                                                {group_fields.map((field, ii) => {
                                                    {/* Set collumn width by group type */}
                                                    return (<Col span={["Orders", "General", "Applications", "Products"].includes(group) ? 12 : 24} key={ii}>
                                                        <Row align="bottom">
                                                            <Col flex="auto">{renderField(field.code)}</Col>
                                                            <Col>
                                                                {checkRights(rights, 'settings-mutate') && <>
                                                                    <IconButton onClick={() => set_showFieldForm(field.code)} style={{ marginBottom: "15px" }} icon="pen" />
                                                                    <DeleteButton onClick={() => onDeletePress(field._id)} />
                                                                </>}
                                                            </Col>
                                                        </Row>
                                                    </Col>)
                                                })}
                                            </Row>
                                        }


                                    </Card>
                                </Col>)
                            })}
                        </Row>

                        <div style={{ padding: "50px" }}>
                            <Button block type="primary" htmlType='submit' loading={submitting}>Save</Button>
                        </div>
                    </>
                }}
            />
        </ContentArea>

        <FieldFormEditor show={showFieldForm !== false} edit_field={showFieldForm === true ? null : showFieldForm}
            cat="system"
            onSuccess={onFieldsUpdate}
            onCancel={() => set_showFieldForm(false)}
        />
    </>)

}

const mapDispatchToProps = (dispatch, ownProps) => ({
    on_updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const mapStateToProps = ({ grocer_storeadmin:{rights} }) => {
    return { rights };
}
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(SystemConfigurations);
const WithApollo = withApollo(WithRedux);

export default WithApollo;
