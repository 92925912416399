/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import StoreDashboard from './containers/StoreDashboard'
import SuperAdminDashboard from './containers/SuperAdminDashboard'
import SystemAdminDashboard from './containers/SystemAdminDashboard'


/**
 * Dashboard
 */
const DashboardComp = ({ store, user }) => {
  // console.log("user: ", user)

  if (user.type == 'store-owner') return <StoreDashboard />
  if (user.type == 'super-admin') return <SuperAdminDashboard />
  if (user.type == 'system-admin') return <SystemAdminDashboard />

  return (<>
    Opps! undefined user dashabord ({user.type})
  </>)

}



DashboardComp.propTypes = {
  // login: PropTypes.object.isRequired,
}

const mapStateToProps = ({ grocer_storeadmin }) => {
  let { user, store } = grocer_storeadmin;
  return { user, store }
}
export const Dashboard = connect(mapStateToProps)(withRouter(DashboardComp));
export default Dashboard;
