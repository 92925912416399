import React from 'react'
import PropTypes from 'prop-types';
import { DatePicker as _DatePicker } from 'antd';
import { Field } from 'react-final-form'
import { Label } from './FormField'
import moment from 'moment';
import _ from 'lodash'
// import debounce from 'lodash/debounce';


export const DateField = _props => {
    let props = { ..._props };

    delete props.label;

    if(props.type=='daterange'){
        return <Field {..._props}>
            {({ input, meta }) => {
                let value = undefined;
                if (_.isArray(input.value)) value = [
                    moment.isMoment(input.value[0]) ? moment(input.value[0]) : undefined,
                    moment.isMoment(input.value[1]) ? moment(input.value[1]) : undefined,
                ]

                return (
                    <div className={`form-field ${!props.compact && "field-margins"} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div><_DatePicker.RangePicker
                            {...props}
                            {...input}
                            value={value}
                            // {...props.inputProps}
                            // onChange={onChange}
                        /></div>
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    }

    return (
        <Field {..._props}>
            {({ input, meta }) => {
                let value = moment.isMoment(input.value) ? moment(input.value) : undefined;

                return (
                    <div className={`form-field ${!props.compact && "field-margins"} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div><_DatePicker
                            {...props}
                            {...input}
                            value={value}
                            // {...props.inputProps}
                            // onChange={onChange}
                        /></div>

                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )

}
DateField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
}

export default DateField;




export const DatePicker = props => {
    let _props = { ...props }
    
    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })
    
    delete _props.label
    delete _props.style
    
    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        <_DatePicker
            style={{...style}}
            {..._props}
            // onChange={onChange}
        />
    </div>)
}




// import { DatePicker as _DatePicker } from 'antd';
// const { RangePicker } = _DatePicker;
// export const DateRangePicker = props => {
//     const onChange = (value, dateString) => {
//         console.log('Selected Time: ', value);
//         console.log('Formatted Selected Time: ', dateString);
//     }

//     const onOk = (value) => {
//         console.log('onOk: ', value);
//     }

//     <RangePicker
//         showTime={{ format: 'HH:mm' }}
//         format="YYYY-MM-DD HH:mm"
//         onChange={onChange}
//         onOk={onOk}
//     />
// }