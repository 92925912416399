/* eslint-disable eqeqeq */
import React, { Component, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { ContentArea, E404, LayoutWrapper } from "Layout_v1";
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { updateSettings } from 'Store/grocer_storeadmin.actions';
import { Row, Col, Alert } from 'antd';
// import UserForm from './components/user_form'
import UserProfile from './components/UserProfile'
import StoreProfile from './components/StoreProfile'
import { DevBlock, IconButton, Loader, PageHeader } from 'Common/components';
import { updateQueryData } from 'Common/scripts/query_helpers';

const RECORD = loader('src/graphqls/user/profile.graphql');

const ProfileHome = (props) => {
  const { profile, loading } = props;

  const updateStoreData = storeData => {
    updateQueryData(props.client, { store:storeData }, RECORD, {})
  }

  const updateProfileData = storeData => {
    updateQueryData(props.client, { ...profile, ...storeData }, RECORD, {})
  }

  if (loading) return <Loader loading={true}><ContentArea /></Loader>
  if (profile && profile.error) return <Alert message="Error!" description={profile.error.message} showIcon />

  // return <DevBlock obj={profile} />

  return (<LayoutWrapper topMenu={null} loading={loading}>
    <Row style={{ flexWrap: "nowrap" }}>
      <Col flex="400px">
        <ContentArea>
          <UserProfile profile={profile} updateProfileData={updateProfileData} />
        </ContentArea>
      </Col>
      <Col flex="auto">
        <ContentArea>
          {/* <h3>Store Profile</h3> */}
          <StoreProfile store={profile.store} updateStoreData={updateStoreData} />
        </ContentArea>
      </Col>
    </Row>

    {/* <DevBlock obj={profile} /> */}
  </LayoutWrapper>)
}
// ProfileHome.propTypes = {
//   // login: PropTypes.object.isRequired,
// }

const WithApollo = compose(
  graphql(RECORD, {
    options: ({ }) => {
      return { variables: {} };
    },
    props: ({ ownProps, data }) => {
      const { loading, profile, error } = data;
      if (error) console.log(__error("error"), error);

      return { loading, profile, queryErrors: error, }
    },
  }),
)(ProfileHome);

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const mapStateToProps = ({ grocer_storeadmin }) => {
  return { user: grocer_storeadmin.user };
}
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(WithApollo);

export default withApollo(WithRedux);