import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const updateUserSettings = payload => ({ type: UPDATE_USER_SETTINGS, payload });

const default_state = { selected_filters:[] };

export const reducer = ( state=default_state, {type, payload} ) => {
  switch (type) {
    case UPDATE_USER_SETTINGS:
      return Object.assign({}, {...state}, {...payload});
      break;
    // case FLUSH_NAV:
    //   return default_state;
    //   break;
    default:
      return state;
  }
}

export default { settings_customers: reducer };

// const authPersistConfig = {
//   key: 'redux_nav',
//   storage: storage,
//   blacklist: ['somethingTemporary'],
//   // whitelist: [],
// }
// export default persistReducer(authPersistConfig, reducer);
