import React, { Component } from 'react'
import PropTypes, { array } from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
// import moment from 'moment';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Modal, Table as _Table } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import WalletFilter, { prepareFilterForMongo } from './WalletFilter';
import LocalStorage from 'Common/scripts/LocalStorage';
import { utcToDate } from 'Common/scripts/Functions';
import { connect } from "react-redux";


const LIST_DATA = loader('src/graphqls/driver_wallet/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/driver_wallet/subscription.graphql');
const BULK_DELETE = loader('src/graphqls/driver_wallet/bulkDeleteWallets.graphql');
const CLEAR_BULK_DRIVER_WALLET = loader('src/graphqls/driver_wallet/clearBulkDriverWallet.graphql');


function confirm_print(props) {
    Modal.confirm({
        title: 'Would you like to print receipts?',
        icon: <Icon icon="print" />,
        // content: 'Would you like to print receipts?',
        okText: 'Yes',
        cancelText: 'No',
        // onOk: ()=>console.log("onOK"),
        // onCancel: () => console.log("onCancel")
        onOk: props.onOk,
        onCancel: props.onCancel
    });
}

const defaultFilter = {
    status:['in-wallet']
}


class ListComp extends Component {
    state = { 
        pagination: { defaultCurrent: 1, current: 1, pageSize: defaultPageSize, total: 0, },
        selectedRowKeys: [],
        user:{},
        busy:false,
        filter: prepareFilterForMongo(defaultFilter, { tz: this.props.default_timezone }),
    };
    subscription = false;

    constructor(props){
        super(props);
        this.makeBulkPayment = this.makeBulkPayment.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.driverWalletQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify(prepareFilterForMongo(defaultFilter, { tz: this.props.default_timezone })),
                    others: JSON.stringify({})
                },
                _subscriptionName: "driverWalletsUpdated",
                _subscriptionType: "array",
                _queryName: "driverWalletQuery",
                _typename: "DriverWallet",
                debug: true
            });
        }

    }

    componentDidMount(){
        LocalStorage.getJsonAsync('user').then(r => {
            this.setState({ 
                // thisUser: `${r.fname || r.lname || r.email} (${r._id})`,
                user: r
            })
        })
    }

    renderActions = (text, record) => {
        return (
            <span className="action_buttons">
                <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                    <IconButton icon="trash-alt" />
                </Popconfirm> */}
            </span>
        )
    }

    renderTitleColumn = (text, record) => <div>{record.order_id}</div>

    renderDriverColumn = (text, record) => {
        if (record?.driver?._id) return (<div>{record?.driver?.fname} {record?.driver?.lname}</div>)
        return <div>
            <b style={{ color: 'red' }}>Account Not Found</b>
            <div>{record?.user?.name || record?.user?.email}</div>
        </div>
    }

    renderAmountColumn = (text, record) => <div>{record.amount} / {record.amount_paid}</div>

    columns = [
        // { title: 'ID', dataIndex: '_id' },
        { title: 'Serial', dataIndex: 'order_serial', width:'220px' },
        { title: 'Driver', dataIndex: 'name', render:this.renderDriverColumn },
        { title: 'Zone', dataIndex: 'zone', render:(___,_rec)=>_rec?.zone?.title || null },
        { title: <>Amount <div style={{ fontSize: '10px' }}>(due/paid)</div></>, dataIndex: 'amount', render: this.renderAmountColumn, width: '150px' },
        // { title: 'Note', dataIndex: 'note' },
        { title: <>Date <div style={{ fontSize: '10px' }}>(DD-MM-YYYY HH:mm)</div></>, dataIndex: 'created_at', render: (txt, rec) => utcToDate(txt).format('DD-MM-YYYY HH:mm'), width:'150px' },
        { title: 'Status', dataIndex: 'status', width: "80px", align: "center" },
        {
            title: 'Actions',
            dataIndex: '',
            render: this.renderActions,
            className: 'actions-column',
            align: 'right',
            width: '80px'
        },
    ];

    handleTableChange = async (pagination, filters, sorter) => {
        this.getPageData(this.state.filter, pagination.pageSize * (pagination.current - 1), pagination.pageSize, pagination);
    };

    doSearch(filter) {
        console.log("doSearch > filter: ", filter);

        // if (filter?.user?._id) Object.assign(filter, { user: { _id: Number(filter.user._id) }})
        // this.getPageData(filter || this.state.filter, 0, this.state.pagination.pageSize || defaultPageSize );
        this.getPageData(filter || this.state.filter, 0, this.state.pagination.pageSize );
    }

    getPageData(filter, skip, limit, pagination){
        // return console.log("getPageData() ", filter);

        this.setState({ busy: true, filter }, () => {
            this.props.loadMoreRows( limit, skip, JSON.stringify(filter) ).then(r => {
                const results = r.data.driverWalletQuery;

                let _state = { driverWalletQuery: results, busy: false, pagination: { ...this.state.pagination, current: 1, total: results.totalCount } }
                if (pagination) Object.assign(_state, { pagination })

                this.setState({ ..._state })

                return r;
            })

        })

    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    deleteSelected = args => {
        const { selectedRowKeys } = this.state;
        this.setState({ busy: true });

        this.props.bulkDeleteWallets(selectedRowKeys).then(r => {
            if (r.data.bulkDeleteWallets.error) {
                message.error("Unable to Delete!");
            } else {
                message.success("Success");
            }

            this.setState({ busy: false });
        });
    }

    onBulkPaymentsReceived = args => {
        confirm_print({
            onOk: ()=>this.makeBulkPayment('print-receipt'),
            onCancel: () => this.makeBulkPayment()
        });
    }

    async makeBulkPayment(action){
        const { selectedRowKeys, user } = this.state;
        const _thisUser = `${user.fname || user.lname || user.email} (${user._id})`;

        this.setState({busy:true});

        const result = await this.props.clearBulkDriverWallet({ ids: selectedRowKeys, collector: _thisUser }).then(r=>{
                const response = r?.data?.clearBulkDriverWallet;
                return response;

            }).catch(err=>{
                console.log(__error("ERROR: "), err)
                return { error: { message:"Query Error!"}}
            });


        if (!result || result.error){
            message.error(!result ? "Unable to complete request" : result.error.message);
            this.setState({ busy: false });
            return;
        }

        message.success("Records updated");
        if (action == 'print-receipt') {
            alert("This feature is coming soon!");
        }
        this.handleTableChange(this.state.pagination);

    }

    calculateSelection(){
        const driverWalletQuery = this.state.driverWalletQuery || this.props.driverWalletQuery;

        let edges = driverWalletQuery?.edges?.filter(o => this.state.selectedRowKeys.indexOf(o._id) > -1) || [];

        let _amount = 0;
        let _amount_paid = 0;

        for (let a = 0; a < edges.length; a++) {
            _amount += edges[a].amount;
            _amount_paid += edges[a].amount_paid;
        }

        return <div style={{fontSize:'20px', padding:'0 0 0 10px'}}>{`${_amount}/${_amount_paid}`}</div>

    }


    render() {
        const { loading, queryErrors } = this.props;
        const { pagination, selectedRowKeys, busy, user } = this.state;

        const driverWalletQuery = this.state.driverWalletQuery || this.props.driverWalletQuery;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const summary = (driverWalletQuery && driverWalletQuery.summary) ? JSON.parse(driverWalletQuery.summary) : []
        // console.log({ summary })


        return (<>
            <h4>Wallets ({driverWalletQuery && driverWalletQuery.totalCount || '0'})</h4>

            <WalletFilter onSearch={(filter) => this.doSearch(filter)} defaultValue={defaultFilter} />

            <Table loading={loading || busy}
                columns={this.columns}
                dataSource={driverWalletQuery ? driverWalletQuery.edges : []}
                pagination={pagination}
                onChange={this.handleTableChange}
                rowSelection={rowSelection}
                rowClassName={(record => {
                    return record.status == 'paid-off' ? 'disabled-table-row' : "";
                })}
                // title={() => <span>Header</span>}
                // footer={() => 'Footer'}
                // expandable={{
                //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                //     // rowExpandable: record => record.name !== 'Not Expandable',
                // }}
                summary={pageData => {
                    return (
                        <>
                            {(summary && summary.totals) && 
                                <_Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#EEE" }}>
                                    <_Table.Summary.Cell colSpan={3}>Total</_Table.Summary.Cell>
                                    <_Table.Summary.Cell colSpan={1} align="center">{`${summary.totals.amount}/${summary.totals.amount_paid}`}</_Table.Summary.Cell>
                                    <_Table.Summary.Cell colSpan={3} align="center"></_Table.Summary.Cell>
                                </_Table.Summary.Row>
                            }

                            {(summary && summary.drivers) && summary.drivers.map((o, i)=>(
                                <_Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#FFF" }} key={i}>
                                    <_Table.Summary.Cell colSpan={2}>Driver's Total ({o.counts})</_Table.Summary.Cell>
                                    <_Table.Summary.Cell colSpan={1}>{o.user.name}</_Table.Summary.Cell>
                                    <_Table.Summary.Cell colSpan={1} align="center">{o.total}</_Table.Summary.Cell>
                                </_Table.Summary.Row>
                            ))}
                        </>
                    );
                }} 
            />

            {hasSelected && <>
                <Row>
                    <Col style={{ borderRight: "1px solid #CCC", padding: "8px", marginRight: "10px", }}>{`Selected ${selectedRowKeys.length} items`}</Col>
                    {user.type =='super-admin' && <>
                        <Col>
                            <Popconfirm title={`Are you sure to delete ${selectedRowKeys.length} records?`} onConfirm={() => this.deleteSelected()} onCancel={console.log} okText="Yes" cancelText="No">
                                <Button loading={busy} onClick={console.log} type='danger'><Icon icon="trash-alt" /> Delete</Button>
                            </Popconfirm>
                        </Col>
                        <Col>
                            <Popconfirm title={`Clear payments for selected orders?`} onConfirm={() => this.onBulkPaymentsReceived()} onCancel={console.log} okText="Yes" cancelText="No">
                                <Button loading={busy} onClick={console.log} type='primary' style={{ marginLeft: '20px' }}><Icon icon="check" /> Clear Payments</Button>
                            </Popconfirm>
                        </Col>

                        <Col>{this.calculateSelection()}</Col>
                    </>}
                    {/* <Col><Button loading={false} onClick={console.log} type='danger'><Icon icon="trash-alt" /> Delete All</Button></Col> */}
                </Row>
            </>}

        </>)
    }
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: (props, ownProps) => {
            return { 
                variables: { first: defaultPageSize, after: 0, 
                    filter: JSON.stringify(prepareFilterForMongo(defaultFilter, { tz: ownProps && ownProps.default_timezone } )) 
                },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, driverWalletQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.driverWalletQuery.totalCount
                    const newEdges = fetchMoreResult.driverWalletQuery.edges
                    const pageInfo = fetchMoreResult.driverWalletQuery.pageInfo
                    const summary = fetchMoreResult.driverWalletQuery.summary
                    return {
                        // ...fetchMoreResult
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        driverWalletQuery: {
                            totalCount: totalCount || 0,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges || [],
                            pageInfo,
                            summary,
                            __typename: 'DriverWallet'
                        }
                    }
                }

                return fetchMore({ variables: vars, fetchPolicy: "no-cache", updateQuery: updateQuery });
            }

            return { loading, driverWalletQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(CLEAR_BULK_DRIVER_WALLET, {
        props: ({ mutate }) => ({
            clearBulkDriverWallet: ({ ids, collector }) => mutate({
                variables: { ids, collector }
            }),
        })
    }),

    graphql(BULK_DELETE, {
        props: ({ mutate }) => ({
            bulkDeleteWallets: (ids) => mutate({
                variables: { ids }
            }),
        })
    }),

)(ListComp);

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.default_timezone };
}
const WithRedux = connect(mapStateToProps)(WithApollo);

export default WithRedux;