import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, GMap, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormComponent, SubmitButton } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { generalStatus } from 'configs';
import icon_location_blue from 'assets/icon_location_blue.png';

const RECORD_EDIT = loader('src/graphqls/offices/editOffices.graphql');
const RECORD_ADD = loader('src/graphqls/offices/addOffices.graphql');


class FormComp extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false, fields: props.fields ? props.fields : { status: "enabled"},
            center: this.props?.fields?.geo_point?.coordinates,
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onCenterChange = this.onCenterChange.bind(this);
    }

    onSubmit = async values => {
        const { onClose, editOffices, addOffices } = this.props;
        const { fields } = this.state;

        const _id = fields ? fields._id : false;

        this.setState({ loading: true });

        let input = {
            store_id: this.props.store_id,
            full_address: values.full_address,
            city: values.city,
            title: values.title,
            geo_point: {
                type: "Point",
                coordinates: this.state.center,
            },
            status: values.status,
            // type: values.type,
        };

        var results;
        if (_id) {
            Object.assign(input, { _id })
            
            results = await editOffices(input).then((r) => (r.data.editOffices))
            .catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message:"Query Error" }}
            });
        } else {
            if (this.props.store_id) Object.assign(input, { store_id: Number(this.props.store_id), type: "store_pickup" })
            else Object.assign(input, { type: "dispatch" })

            results = await addOffices(input).then((r) => (r.data.addOffices))
            .catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message: "Query Error" } }
            });
        }

        this.setState({ loading: false })
        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onClose(results);
    }

    onCenterChange(center) {
        // console.log("onCenterChange()", center);
        this.setState({ center })
    }

    render() {
        const { onClose, showform } = this.props;
        const { fields, center } = this.state;


        return (<>
            <FormComponent fields={fields} onSubmit={this.onSubmit} id='OfficeForm' debug={true}
                mutators={{
                    onAddressChanged: (newValue, state, utils) => {
                        utils.changeValue(state, 'full_address', () => newValue[0]);
                    },
                }}

                style={{ minHeight: "100%", flex: 1, display: "flex", flexDirection:"column" }}
                form_render={({ values, submitting, form }) => {
                    return (<>
                        <div style={{ width: '100%', minHeight:"500px", display:"flex", flex: 1, position: "relative" }}>
                            <GMap zoom={11}
                                center={center ? { lat: center[0], lng: center[1] } : undefined}
                                showDeliveryZones showSearch
                                onCenterChange={this.onCenterChange}
                                onAddressChanged={form.mutators.onAddressChanged}
                            >
                                <div style={{ backgroundColor: 'red' }}>-</div>
                            </GMap>
                            <div style={{ position: "absolute", top: "50%", zIndex: 100, width: "100%", borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }} />
                            <div style={{ position: "absolute", top: 0, left: "50%", zIndex: 100, height: "100%", borderRight: "1px solid rgba(255, 255, 255, 0.5)" }} />
                            <div style={{ position: "absolute", top: "50%", marginTop: "-46px", left: "50%", marginLeft: "-19px", zIndex: 100, }}><img src={icon_location_blue} alt="You" width="38px" height="50px" /></div>
                        </div>

                        <Row>
                            <Col flex="300px"><FormField type="text" name="title" label="Title" validate={rules.required} /></Col>
                            <Col flex="200px"><FormField type="select" data={generalStatus} name="status" label="Status" validate={rules.required} /></Col>
                            {/* <Col flex="200px"><FormField type="select" data={[
                                { title: "Dispatch", _id: "dispatch" }
                            ]} disabled={fields._id ? true : false} name="type" label="Type" validate={rules.required} /></Col> */}
                            <Col flex="auto"><FormField type="text" name="full_address" label="Full Address" validate={rules.required} /></Col>
                            <Col flex="200px"><FormField type="text" name="city" label="City" validate={rules.required} /></Col>
                        </Row>

                        <Row gutter={[20, 50]} align="center">
                            <Col><Button onClick={onClose} disabled={submitting} color="white" type="default">Cancel</Button></Col>
                            <Col><SubmitButton loading={submitting}>Save</SubmitButton></Col>
                        </Row>
                    </>)
                }}
            />
        </>)

    }
}
FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const OfficeForm = props => {
    const { showform, onClose } = props;
    
    return (<>
        <Drawer closable={true} destroyOnClose={true}
            height="100%" width="100%" placement="top" title={`Office`}
            visible={showform}
            onClose={onClose}
        >
            <FormComp {...props} />
        </Drawer>
    </>)
}


const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editOffices: (input) => mutate({
                variables: { input }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addOffices: (input) => mutate({
                variables: { input }
            }),
        })
    }),
)(OfficeForm);


export default WithApollo;