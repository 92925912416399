import React from 'react'
// import { Button } from 'Common/components'

// import ProductsList from '../components/product_list'
// import ProductForm from '../components/product_form'
import Catalogue from './Catalogue';

const defaultFilter = {
    $expr: {
        $or: [
            { $lte: ["$available_qty", "$min_stock_level"] },
            { $eq: ["$available_qty", 0] },
        ]
    },
    status:"published"
}; // { status: 'published', available_qty: { $lt: min_limit } }

export const OutOfStock = React.memo(props => {
    // // React.useEffect(() => console.log('Catalogue did update'));
    // const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });

    // const toggleDrawer = args => {
    //     setState({ visible: !visible, fields: args.fields || null });
    // };

    return (<>
        <Catalogue filter={defaultFilter} title="Out of stock Products" {...props} searchFilterConfig={{ exclude: ["status"] }} />

        {/* <ProductForm onClose={toggleDrawer} showform={visible} fields={fields} />
        <ProductsList {...props} filter={{ $expr: { $lt: ["$available_qty", "$min_stock_level"] } }} onEditRecord={(item) => toggleDrawer({ fields: item })} toggleDrawer={toggleDrawer} /> */}
    </>)
}, ()=>true)

export default OutOfStock;
