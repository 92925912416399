import React, { Component } from 'react'
import { ListHeader, PageHeader, Button } from 'Common/components';
import { ContentArea, E404, LayoutWrapper } from "Layout_v1";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";

class SystemAdminDashboard extends Component {
  render() {
    return (<>
      <LayoutWrapper>
        <ContentArea>
          <PageHeader title="Dashboard" style={{ margin: "10px" }} right={<Button href="/order_dashboard">Order Dashboard</Button>} />


        </ContentArea>

      </LayoutWrapper>
    </>)
  }
}


const mapStateToProps = ({ grocer_storeadmin }) => {
  return {
    // settings: grocer_storeadmin.settings,
    user: grocer_storeadmin.user,
    // store: grocer_storeadmin.store,
  };
}
export default connect(mapStateToProps)(withRouter(SystemAdminDashboard));
