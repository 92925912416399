import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import Store from 'Store';
import ApolloWrapper from './apollo/aClient';
// import moment_tz from 'moment-timezone';
import moment from 'moment'
import { Provider } from 'react-redux';
import { defaultTimeZone } from 'configs';
// import './index.css';

moment.tz.setDefault(defaultTimeZone);


ReactDOM.render(
  <PersistGate persistor={Store.persistor} loading={<div>Initilizing data...</div>} onBeforeLift={() => { /* do soemting */ }}>
    <Provider store={Store.store}>
      <ApolloWrapper>
        <App />
      </ApolloWrapper>
    </Provider>
  </PersistGate>
  ,
  document.getElementById('root')
);

// RectDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
