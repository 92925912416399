import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Divider } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { genders } from 'configs';
import { sleep } from 'Common/scripts/Functions';

const RECORD = loader('src/graphqls/user/user.graphql');
const RECORD_EDIT = loader('src/graphqls/user/edit.graphql');
const defaultFilter = { status: 'published' }

const OwnerForm = props => {
    let fields = { ...defaultFilter, ...props.fields };
    const [busy, setBusy] = React.useState(false);

    const { loading } = props;

    const updateUser = async (values) => {
        const { onClose, editUser } = props;
        const _id = props.fields ? props.fields._id : false;

        let filteredValues = {
            fname: String(values.fname).trim(),
            email: String(values.email).trim(),
            gender: values.gender,
            phone: String(values.phone).trim(),
            _id: props.fields._id,
            // phone_confirmed: values.phone_confirmed ? 1 : 0,
            // password: values.store.password,
        };

        if (values.password && (values.password != values.confirm_pwd)) {
            message.error("Password missmatch");
            return;
        } else if (values.password && (values.password == values.confirm_pwd)) {
            filteredValues = Object.assign(filteredValues, { password: values.password });
        }

        setBusy(true);
       
        var resutls = await editUser(filteredValues).then((e) => {
            return e.data.editUser;
        }).catch(error => {
            console.log(__error("Query Error"), error);
            return { error: { message: "Query Error" } }
        });

        setBusy(false);

        if (resutls.error) message.error(resutls.error.message);
        else onClose(resutls);

    }

    return (
        <FormComponent onSubmit={updateUser} id='UserForm' loading={loading || busy} fields={fields} debug={true}
            form_render={formProps => {
                const { values, submitting } = formProps;

                return (<>
                    <Divider>Owner Account Info</Divider>

                    <Row>
                        <Col flex="auto"><FormField type="text" name="fname" label="Name" placeholder="Name" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                        <Col flex="150px"><FormField type="select" name="gender" label="Gender" data={genders} validate={rules.required} /></Col>
                    </Row>

                    <Row>
                        <Col flex="50%"><FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} /></Col>
                        <Col flex="auto"><FormField type="text" name="phone" label="Phone" placeholder="Mobile number" validate={composeValidators(rules.required, rules.minChar(4))} /></Col>
                    </Row>

                    <Row>
                        <Col flex="auto">
                            <FormField type="password" name="password" label="Password" />
                        </Col>
                        <Col flex="49%">
                            <FormField type="password" name="confirm_pwd" label="Confirm Password" />
                        </Col>
                    </Row>

                </>)
            }}
        />
    )
}

OwnerForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool ]).isRequired,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(OwnerForm);

const OwnerFormWrapper = props => {
    // if (props.fields) return <OwnerForm {...props} />
    const [loading, setLoading] = useState(false);
    const [busy, setBusy] = useState(false);
    const [fields, setFields] = useState(null);

    const getRecord = async () => {
        setLoading(true);

        await props.client.query({ query: RECORD, variables: { id:props.fields._id } }).then(e => {
            const results = e.data.user;

            if (results.error) {
                console.log(__error("ERROR "), e);
                message.error(results.error.message);
                return;
            }

            setFields(results)

        }).catch(err => {
            console.log(__error("API Call ERROR: baskets : "), err);
            message.error("Request ERROR");
        })

        setLoading(false);
    }

    useEffect(() => {
        if (!props.showform) return;
        getRecord();
    }, [props.showform])

    return (<>
        <Drawer width={600} destroyOnClose={false} maskClosable={false} placement="right" loading={loading}
            bodyStyle={{ padding: "10px" }}
            onClose={props.onClose}
            visible={props.showform}
            footer={<>
                <span></span>
                <Button loading={busy} type="primary" onClick={() => {
                    document.getElementById('UserForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`${fields && fields._id ? 'Edit' : 'Add'} Store`}
        ><>
                {(!busy && fields && fields._id) && <>
                    <WithApollo {...props} fields={fields} />
                </>}
            </></Drawer>
    </>)
}

export default withApollo(OwnerFormWrapper);