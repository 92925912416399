/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import OrdersOnTill from './containers/OrdersOnTill'
import ReadyToDispatch from './containers/ReadyToDispatch'
import TillConfirmation from './components/till_confirmation'
import OrdersDispatched from './containers/OrdersDispatched'
import Orders from './containers/Orders'
import OrderPreview from './components/OrderPreview';
import OrdersPicking from './containers/OrdersPicking';
import { ROOT, MODULE } from './constants'


export const menuArray = props => ([
  { 
    path: `${ROOT}`, exact: true, title: 'Orders', component: Orders 
  },
  { 
    permission_required:"access-order-details",
    path: `${ROOT}/id/:_id`, exact: true, title: 'Orders Details', component: OrderPreview, hide: true 
  },
  // { path: `${ROOT}`, route: `${ROOT}/(filter)?/:keywords?`, exact: true, title: 'Orders', component: Orders },
  
  { permission_required: "access-picking",
    path: `${ROOT}/picking`, exact: true, title: 'Picking', component: OrdersPicking 
  },
  { permission_required: "access-picking", hide: true,
    path: `${ROOT}/picking/order/:_id`, exact: true, title: 'Picking Processs', component: OrderPreview 
  },
  
  { 
    permission_required: "access-till",
    path: `${ROOT}/on_till`, exact: true, title: 'Orders On Till', component: OrdersOnTill 
  },
  { 
    permission_required:"access-till",
    path: `${ROOT}/on_till/order/:_id`, exact: true, title: 'Till Confirmation', component: TillConfirmation, hide:true 
  },
  
  { 
    permission_required:"access-order-readytodispatch",
    path: `${ROOT}/ready_to_dispatch`, exact: true, title: 'Ready To Dispatch', component: ReadyToDispatch, hide:false 
  },
  // { path: `${ROOT}/ready_to_dispatch/order/:_id`, exact: true, title: 'Order Ready To Dispatch', component: OrderReadyToDispatch, hide:true },
  { 
    permission_required:"access-order-dispatched",
    path: `${ROOT}/dispatched`, exact: true, title: 'Dispatched', component: OrdersDispatched 
  },
  // { 
  //   permission_required:"access-carts",
  //   path: `${ROOT}/carts`, exact: true, title: 'Carts', component: Carts 
  // },
])




/**
 * Home
 */
export const Home = props => {
  // console.log("--- props: ", props);
  
  let routs = menuArray();

  let isPageTransparent = false;
  if (String(props.location.pathname).indexOf('/orders/on_till/order/') > -1) isPageTransparent = true;
  if (String(props.location.pathname).indexOf('/orders/ready_to_dispatch/order/') > -1) isPageTransparent = true;

  return (
    <LayoutWrapper topMenu={routs} skipContentArea={isPageTransparent}>
      <Switch>
        {routs.map((item, i) => {
          if (!item.component) return null;
          return <Route exact={item.exact} path={item.route || item.path} component={item.component} key={i} />
        })}
        <Route key={999} component={E404} />
      </Switch>
    </LayoutWrapper>
  )

}

Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

export default Home;