import React from 'react';
import { addModule, addReducers } from '../connector';
import { ROOT } from './constants'
import Home from './Home';


addModule(
  {
    permission_required : "store-stats-access",
    path: `${ROOT}`, component: Home, title: "Store Stats", icon:"chart-line", exact: 0, hideNav: 0,
  },
)
