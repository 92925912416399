import React from 'react'
import OfficesList from './components/offices_list';
import OfficeForm from './components/office_form';

export const Offices = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = (args={}) => {
        setState({ visible: !visible, fields: args?.fields || null });
    };

    return (<>
        {visible && <OfficeForm onClose={toggleDrawer} showform={visible} fields={fields} store_id={props.store_id} />}
        <OfficesList 
            filter={props.filter}
            onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddClick={() => toggleDrawer()}
        />
    </>)
}
export default Offices;