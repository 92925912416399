import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent } from 'Common/components/Form'
import { Input, message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { generalStatus, taxTypes } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import AttributesSelector from './attributes_selector'
import { string_to_slug } from 'Common/scripts/Functions';

const RECORD = loader('src/graphqls/product_type/prodType.graphql');
const RECORD_EDIT = loader('src/graphqls/product_type/edit.graphql');
const RECORD_ADD = loader('src/graphqls/product_type/add.graphql');

const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

const updateAttributes = (attributes, state, tools) => {
    tools.changeValue(state, 'attributes', () => attributes[0])
}


// export const FormComp = props => {
export class FormComp extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, __fields: false }
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading && !this.props.loading)
            this.setState({loading:false})
    }

    onSubmit = _values => {
        if (!_values.attributes || _values.attributes.length < 1 || !_values.attributes[0]._id) {
            message.error("You forgot to assign attributes!")
            return;
        }

        const { prodType, onClose, editProductType, addProductType } = this.props;
        const { __fields } = this.state;
        let fields = __fields ? { ...__fields } : prodType ? { ...prodType } : {}
        let values = { ..._values }
        
        if (!values.tax || !values.tax.unit || values.tax.unit == 'null' || !values.tax.value || values.tax.value<1) values.tax = null;
        else values.tax = { value: values.tax.value, unit: values.tax.unit }

        const _id = fields ? fields._id : false;

        this.setState({loading:true});

        let attributes;
        if (values.attributes) {
            attributes = values.attributes.map(item => ({
                    _id: item._id,
                    title: item.title,
                    slug: item.slug,
                    code: item.code,
                    required: item.required,
                    show_in_store: item.show_in_store,
                    values: item.values,
                    displayAs: item.displayAs,
                    seo_title: item.seo_title,
                    seo_desc: item.seo_desc
                })
            )
        }

        let filteredValues = {
            title: values.title,
            slug: values.slug,
            // status: values.status,
            tax: values.tax,
            seo_title: values.seo_title,
            seo_desc: values.seo_desc,
            attributes: attributes, //omitAllTypeName(values.attributes), // ? JSON.stringify(values.attributes) : values.attributes,
        };

        if (_id) {
            editProductType({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({loading:false})
                if (e.data.editProductType.error) {
                    let err = e.data.editProductType.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editProductType);
            }).catch(error => {
                this.setState({loading:false})
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addProductType(filteredValues).then((e) => {
                this.setState({loading:false});
                if (e.data.addProductType.error) {
                    let err = e.data.addProductType.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                console.log("e.data.addProductType: ", e.data.addProductType);
                
                // _fields = Object.assign(_fields, { ...e.data.addProductType })
                // _setFields({ ...e.data.addProductType });
                onClose(e.data.addProductType);
            }).catch(error => {
                this.setState({loading:false});
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    render() {
        const { onClose, showform, prodType, loadingEditNode } = this.props;
        const { __fields, loading } = this.state;
        let fields = __fields ? { ...__fields } : prodType ? { ...prodType } : {}

        return (
            <Drawer width={500} destroyOnClose maskClosable={false} placement="right" transparent
                onClose={onClose}
                visible={showform}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('TypeForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${fields && fields._id ? 'Edit' : 'Add'} Product Type`}
            >
                {!loadingEditNode && 
                    <FormComponent onSubmit={this.onSubmit} id='TypeForm' loading={loading} fields={fields} debug={true}
                        mutators={{ updateAttributes }}
                        form_render={formProps => {
                            const { values } = formProps;

                            return <>
                                <div className="grid-block" style={{margin:"0 0 10px 0"}}>
                                    <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                                    <FormField type="text" onChange={filterSlug} name="slug" label="Slug (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} />
                                </div>
        
                                <div className="grid-block" style={{ margin: "0 0 10px 0" }}>
                                    <Heading>Tax Settings</Heading>
                                    <FormFieldGroup compact>
                                        <FormField type="select" width={"70px"} name="tax.unit" data={taxTypes} compact addNull />
                                        <FormField type="number" disabled={values.tax && !values.tax.unit} min={0} max={100} name="tax.value" compact />
                                    </FormFieldGroup>
                                </div>

                                <AttributesSelector 
                                    values={values}
                                    onChange={(attributes) => formProps.form.mutators.updateAttributes(attributes)} 
                                    attributes={fields && fields._id ? fields.attributes : undefined} />
        
                                <div className="grid-block" style={{ margin: "0 0 10px 0" }}>
                                    <Heading>SEO Details</Heading>
                                    <FormField type="text" name="seo_title" label="SEO Title" />
                                    <FormField type="text" name="seo_desc" label="SEO Description" />
                                </div>

                            </>
                        }}
                    />
                }
    
            </Drawer>
        )

    }

}

FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    prodType: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editProductType: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addProductType: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, prodType, error } = data;

            if (error) console.log(__error("error"), error);

            return { loadingEditNode:loading, prodType, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />

export default Wrapper;