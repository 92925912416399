// import _ from 'lodash';
import {jsonStringify, parseJson, sleep} from './Functions';
const prefex = 'bad_';

const isSupported = () => {
  let supported = typeof(Storage) !== "undefined";
  if(!supported) alert('Local Storage Not available');
  return supported;
}


/*******************
* Local Storage
*********************/
export const _LocalStorage = {

  setJsonAsync: async function (key, json_value) {
      await null;
      let val = jsonStringify(json_value); //_.isString(value) ? val : JSON.stringify(value);
    return localStorage.setItem(`${prefex}${key}`, val);
  },
  getJsonAsync: async function (key) {
    await null;
    let value = localStorage.getItem(`${prefex}${key}`);
          value = parseJson(value);
      // console.log(`value: ${key} => `, value);
      return value;
  },

  setJson: function (key, json_value) {
    let val = jsonStringify(json_value); //_.isString(value) ? val : JSON.stringify(value);
    return localStorage.setItem(`${prefex}${key}`, val);
  },
  getJson: function (key) {
    let value = localStorage.getItem(`${prefex}${key}`);
          value = parseJson(value);
      // console.log(`value: ${key} => `, value);
      return value;
  },

  setAsync: async function (key, value) {
    await null;
    return localStorage.setItem(`${prefex}${key}`, value);
  },
  getAsync: async function (key) {
    await null;
    return localStorage.getItem(`${prefex}${key}`);
  },

  set: function (key, value) {
    return localStorage.setItem(`${prefex}${key}`, value);
  },
  get: function (key) {
    return localStorage.getItem(`${prefex}${key}`);
  },

  logout: function () {
    this.set('user', false);
    this.set('token', false);
    // sleep(100);
    // window.location.href = "/";
  },
  login: async function (user, token) {
    await this.setJsonAsync('user', user);
    await this.setJsonAsync('token', token);
    sleep(100);
    window.location.href = "/";
  },

  getAll: localStorage,
}
export const LocalStorage = isSupported() ? _LocalStorage : false;
export default LocalStorage;




/*******************
* Session Storage
*********************/
const _SessionStorage = {
  set: async function (key, value) {
    await null;
    return sessionStorage.setItem(`${prefex}${key}`, value);
  },
  get: async function (key) {
    await null;
    return sessionStorage.getItem(`${prefex}${key}`);
  },
  getAll: sessionStorage,
}
export const SessionStorage = isSupported() ? _SessionStorage : false;
