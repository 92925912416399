import React from 'react'
// import { Button } from 'Common/components'
import TreeList from '../components/Category/tree_list';
import CategoriesForm from '../components/Category/categories_form';
import './styles.scss';


export const Categories = props => {
    const [{ visible, fields }, setState] = React.useState({ visible:false, fields:null});
    const [visibleList, setList] = React.useState(true);

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: !visible ? args.fields : null });
    };

    const onUpdateCallback = ({action, data}) => {
        // setList(false);
        // sleep(200).then(r => { setList(true) })
    }

    return (<>
        <CategoriesForm onClose={toggleDrawer} showform={visible} fields={fields} callback={onUpdateCallback} />

        {visibleList && <TreeList 
            onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddClick={() => toggleDrawer({})}
        />}
            
    </>)
}
export default Categories;