import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader, BasketCategorySelector } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
// import { basketCategories } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'

// const RECORD = loader('src/graphqls/baskets/basket.graphql');
const RECORD_EDIT = loader('src/graphqls/baskets/edit.graphql');
const RECORD_ADD = loader('src/graphqls/baskets/add.graphql');


export class BasketForm extends Component {
    // fields = { status: "disabled" };

    constructor(props) {
        super(props);
        this.state = { loading: false, fields: props.fields ? props.fields : { status: "disabled"} }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = _values => {
        const { basket, onClose, editBasket, addBasket } = this.props;
        const { fields } = this.state;
        // let fields = fields ? { ...fields } : basket ? { ...basket } : {}
        let values = { ..._values }

        const _id = fields ? fields._id : false;

        this.setState({ loading: true });

        let filteredValues = {
            title: values.title,
            color: values.color,
            category: values.category,
        };

        if (_id) {
            editBasket({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({ loading: false })
                if (e.data.editBasket.error) {
                    let err = e.data.editBasket.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                this.props.onSuccess(e.data.editBasket)
                // onClose(e.data.editBasket);
            }).catch(error => {
                this.setState({ loading: false })
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addBasket(filteredValues).then((e) => {
                this.setState({ loading: false });
                if (e.data.addBasket.error) {
                    let err = e.data.addBasket.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                console.log("e.data.addBasket: ", e.data.addBasket);

                // onClose(e.data.addBasket);
                this.props.onSuccess(e.data.addBasket)
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                message.error("Query Error");
            });
        }

    }


    render() {
        const { onClose, basket, showform } = this.props;
        const { fields, loading } = this.state;


        return (
            <Drawer width={"300px"} destroyOnClose maskClosable={false} placement="right"
                // loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5", padding:0 }}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('BasketForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${fields && fields._id ? 'Edit' : 'Add'} Basket`}
            >
                <FormComponent onSubmit={this.onSubmit} id='BasketForm' loading={loading} fields={{ color:"#FFFFFF", ...fields }} enterSubmit={true}
                    form_render={({ values }) => {
                        return <>
                            <div className="grid-block">
                                <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required)} />
                                <FormField type="text" name="color" label="Color (#FFFFFF)" validate={composeValidators(rules.required, rules.minChar(7))} />
                                {/* <FormField type="select" data={basketCategories} name="category" label="Category" validate={composeValidators(rules.required)} /> */}
                                <BasketCategorySelector name="category" size='medium' validate={composeValidators(rules.required)} />
                            </div>
                        </>
                    }}
                />

            </Drawer>
        )
    }
}
BasketForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editBasket: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addBasket: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(BasketForm);

export default WithApollo;