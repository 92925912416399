import React, { useState, useEffect } from 'react'
import { ContentArea, E404, LayoutWrapper } from "Layout_v1";
import OutOfStock from "Modules/Products/containers/OutOfStock";
import { ListHeader, PageHeader, OrdersMiniList, Button, DevBlock, Loader } from 'Common/components';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { Alert, Col, Row, message, Card, Modal } from 'antd';
import { withApollo } from 'react-apollo';
import { __error, __yellow } from 'Common/scripts/consoleHelper';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { utcToDate } from 'Common/scripts/Functions';
import { updateSettings } from 'Store/grocer_storeadmin.actions';
import { OrderDetails } from 'Modules/Orders/components/order_details';
import moment from 'moment';


// const LIST_DATA = gql`query ordersQuery($first: Int!, $after: ID, $filter: String, $others: String) {
//     ordersQuery(first: $first, after: $after, filter: $filter, others: $others) {
//         totalCount
//         edges{
//             _id
//             serial
//             delivery_slot{
//               _id
//               day
//               start_time
//               end_time
//               date
//               time_range_utc
//               from_date
//               to_date
//             }
//             invoice { total grand_total }
//             connectedZone { _id title city }
//             customer { _id name }
//             store{
//               # _id
//               title
//               # slug
//               # owner { _id name }
//               # barcode
//             }

//             status
//         }
//         pageInfo {
//             endCursor
//             hasNextPage
//         }
//         error{ message }
//     }
// }
// `
const LIST_DATA = loader('src/graphqls/orders/ordersQuery.graphql');

const isSystemAdmin = (user) => (user.type == "super-admin" || user.type == "system-admin");

const RenderOrder = ({ order, showStoreDetails }) => {
  let time_range_utc = JSON.parse(order.delivery_slot.time_range_utc);
  time_range_utc = time_range_utc.map(itm => moment(itm, "DD-MM-YYYYTHH:mm"));

  return (<div>
    {showStoreDetails && <h3 style={{ margin:0 }}>{order.store.title}</h3>}

    <Row>
      <Col flex="auto"><div style={{ fontWeight: "bold", fontSize: "18px" }}>Serial: {order.serial}</div></Col>
      <Col><div><b>Total: {order.invoice.grand_total}</b></div></Col>
    </Row>

    <div>Customer: {order.customer.name}</div>

    <div>
      Delivery Slot: {` `}
      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
        {utcToDate(order.delivery_slot.date).format("DD-MM-YYYY")}
        {` - `}
        {time_range_utc[0].format("hh:mm A")} ~ {time_range_utc[1].format("hh:mm A")}</span>
    </div>

  </div>)
}

const OrderDashboard = ({ user, store, client, navCollapsed, on_updateSettings }) => {
  const [mounted, setMounted] = useState(false);
  const [error, setError] = useState(null);
  const [newOrders, set_newOrders] = useState(null);
  const [loading_new_orders, set_loading_new_orders] = useState(false);
  const [readyOrders, set_readyOrders] = useState(null);
  const [loading_ready_orders, set_loading_ready_orders] = useState(false);
  const [interval, set_interval] = useState(false);
  const [showDetails, set_showDetails] = useState(false);

  const fetchOrders = async(filter) => {
    console.log(__yellow("fetchOrders()"))

    var variables = {
      first: 50,
      after: 0,
      filter: JSON.stringify(filter || {}),
      others: JSON.stringify({ sort: { "delivery_slot.date": 1 } })
    }

    const results = await client.query({ query: LIST_DATA, variables })
    .then(e => (e.data.ordersQuery))
    .catch(err => {
      console.log(__error("API Call ERROR: baskets : "), err);
      return { error:{message:"Unable to fetch orders, check your network!"}}
    })

    console.log("results: ", results)

    if (results.error) {
      console.log(__error("ERROR "), results);
      setError(results.error.message);
      return;
    }

    return results;
  }

  const fetchAllData = async() => {
    set_loading_new_orders(true)
    fetchOrders({ status: { $in: ["new", "picking"] } }).then(r => {
      set_loading_new_orders(false)
      if (r.error) return;
      set_newOrders(r);
    })

    set_loading_ready_orders(true)
    fetchOrders({ status: { $in: ["in-review", "ready-to-dispatch"] } }).then(r => {
      set_loading_ready_orders(false)
      if (r.error) return;
      set_readyOrders(r);
    })
  }


  useEffect(() => {
    if (!isSystemAdmin(user) && (!store || !store._id)) return;

    fetchAllData();
    set_interval(setInterval(fetchAllData, 5*60*1000)) // 5 min

    return () => {
      return clearInterval(interval)
    };

  }, [])

  useEffect(() => {
    if (navCollapsed || mounted) return;
    setMounted(true)
    on_updateSettings({ navCollapsed:true })
  }, [navCollapsed])


  if (!isSystemAdmin(user) && (!store || !store._id)) return (<LayoutWrapper><Alert message="Store not found!" type='error' showIcon /></LayoutWrapper>)
  if (error) return <Alert message={error} type='error' showIcon />

  return (<>
      <LayoutWrapper>
      <PageHeader title="Order Dashboard" style={{ margin: "10px" }} />

        <Row gutter={[20, 20]}>
          <Col span={16}>
            <ContentArea>
              <ListHeader title="New Orders" sub={((newOrders && newOrders.totalCount) || 0) + " Orders found"} />

              {loading_new_orders && <Loader center loading={true} />}

              {(newOrders && newOrders.edges && newOrders.edges.length > 0) && <>
                <Card title={false}>
                  {(newOrders && newOrders.edges) && newOrders?.edges?.map((order, i) => {
                    return (<Card.Grid onClick={() => set_showDetails(order)} style={{ width: '50%' }} key={i}>
                      <RenderOrder showStoreDetails={isSystemAdmin(user)} order={order} />
                    </Card.Grid>)
                  })}
                </Card>
              </>}

              {(!newOrders || !newOrders.edges) && <p>No new orders found!</p>}

            </ContentArea>

          </Col>
          <Col span={8}>
            <ContentArea>
              <ListHeader title="Orders Ready for delivery" sub={((readyOrders && readyOrders.totalCount) || 0) + " Orders found"} />

              {loading_ready_orders && <Loader center loading={true} />}

              {(readyOrders && readyOrders.edges && readyOrders.edges.length > 0) && <>
                <Card title={false}>
                  {(readyOrders && readyOrders.edges) && readyOrders?.edges?.map((order, i) => {
                    return (<Card.Grid onClick={() => set_showDetails(order)} style={{ width: '100%' }} key={i}>
                      <RenderOrder order={order} />
                    </Card.Grid>)
                  })}
                </Card>
              </>}

              {/* {(readyOrders && readyOrders.edges) && <DevBlock obj={readyOrders.edges} />} */}
              {(!readyOrders || !readyOrders.edges) && <p>No orders here!</p>}
            </ContentArea>

          </Col>
        </Row>

      </LayoutWrapper>


    <Modal title="Order Details" width={"90%"} centered visible={showDetails !== false} footer={false} onCancel={() => set_showDetails(false)}>
      {showDetails && <>
        <OrderDetails previewOnly order={showDetails} />
      </>}
    </Modal>

  </>)
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    let { user, store } = grocer_storeadmin;
    return { 
      user, 
      store,
      navCollapsed: grocer_storeadmin.settings.navCollapsed
    }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  on_updateSettings: (payload) => dispatch(updateSettings(payload)),
})

const WithApollo = withApollo(OrderDashboard)
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithApollo));
