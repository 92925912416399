import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, IconButton, Loader, SelectStaff } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import { generalStatus, genders, staffTypes } from 'configs/constants';


const RECORD = loader('src/graphqls/user/user.graphql');
const RECORD_EDIT = loader('src/graphqls/user/edit.graphql');
const RECORD_ADD = loader('src/graphqls/user/addStaff.graphql');
const RECORD_DELETE = loader('src/graphqls/user/delete.graphql');
const DELETE_USER_AVATAR = loader('src/graphqls/user/deleteUserAvatar.graphql');


class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false, 
            error: null, 
            __fields: { 
                // type: staffAccountTypes[0]._id,
                status: generalStatus[0]._id, 
                gender: genders[0]._id,
            }
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    // updateFields = (__fileds) => {
    //     this.setState({__fields:{
    //         ...__fileds,
    //         activation_date: __fileds.activation_date ? moment(__fileds.activation_date) : __fileds.activation_date,
    //         deactivation_date: __fileds.deactivation_date ? moment(__fileds.deactivation_date) : __fileds.deactivation_date
    //     }})
    //     document.getElementById('UserForm').dispatchEvent(new Event('reset', { cancelable: true }))
    // } 

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading && !this.props.loading) this.setState({ loading: false, __fields: this.props.user })
    }

    onSubmit = async values => {
        const { onClose, editUser, addStaff, __fields, user } = this.props;

        let filteredValues = {
            type: values.type,
            fname: String(values.fname).trim(),
            lname: String(values.lname).trim(),
            email: String(values.email).trim(),
            status: values.status,
            gender: values.gender,
            note: values.note,
            phone: String(values.phone).trim(),
            phone_confirmed: values.phone_confirmed ? 1 : 0,
        };

        if (values.password && (values.password != values.confirm_pwd)) { 
            message.error("Password missmatch")
            return false;
        }
        else if (values.password && (values.password == values.confirm_pwd)){
            filteredValues = Object.assign(filteredValues, { password: values.password });
        }

        
        var results;
        
        this.setState({ loading: true })
        if (user && user._id) {
            results = await editUser({ ...filteredValues, _id: user._id }).then((e) => (e.data.editUser)).catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message:"Query Error" }}
            });
        } else {
            results = await addStaff(filteredValues).then((e) => (e.data.addStaff)).catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message: "Query Error" } }
            });
        }
        this.setState({ loading: false });

        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        if (user && user._id) message.success("Account updated");
        else message.success("Account added");
        onClose(results);
        return true;

    }

    onFileDeleteClicked = async (id) => {
        this.setState({ loading: true })

        const results = await this.props.deleteUserAvatar(id).then((e) => e.data.deleteUserAvatar).catch(error => {
            return { error: { message: "Query Error" } };
        });
        if (results.error){
            console.log(__error("ERROR: "), results.error);
            message.error(results.error.message);
        }
        this.setState({ loading: false })

        return results;

    }


    render() {
        const { onClose, showform } = this.props;
        const { __fields, loading } = this.state;

        var fields = { ...__fields }
        delete fields.password;
        fields.phone_confirmed = (fields.phone_confirmed > 0) ? true : false;

        return (
            <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
                bodyStyle={{ padding: "10px" }}
                onClose={onClose}
                visible={showform}
                footer={<>
                    <span></span>
                    <Button loading={loading || this.props.loading} type="primary" onClick={() => {
                        document.getElementById('UserForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${__fields && __fields._id ? 'Edit' : 'Add'} Staff`}
            ><>
                <FormComponent onSubmit={this.onSubmit} id='UserForm' loading={loading || this.props.loading} fields={{ ...fields }} enterSubmit
                    // mutators={{
                    //     addItems: (newValueArray, state, tools) => { tools.changeValue(state, 'items', () => newValueArray[0]); },
                    //     removeItems: (newValueArray, state, tools) => { tools.changeValue(state, 'items', () => newValueArray[0]); },
                    // }}
                    form_render={formProps => {
                        const { values } = formProps;

                        return (<>
                            <Row>
                                <Col flex="50%" />
                                <Col flex="auto"><FormField type="select" name="status" label="Status" className={values.status == 'enabled' ? "active" : "inactive"} data={generalStatus} validate={rules.required} /></Col>
                            </Row>

                            <Row>
                                {/* <Col flex="50%"><FormField type="select" name="type" label="Type" data={staffTypes} validate={rules.required} /></Col> */}
                                <Col flex="50%"><SelectStaff name="type" label="Type" width="200px" validate={rules.required} /></Col>
                                <Col flex="auto"><FormField type="select" name="gender" label="Gender" data={genders} validate={rules.required} /></Col>
                            </Row>

                            {/* <FormField type="text" name="name" label="Full Name" validate={composeValidators(rules.required, rules.minChar(4))} /> */}
                            <Row>
                                <Col flex="50%"><FormField type="text" name="fname" label="First Name" validate={rules.required} /></Col>
                                <Col flex="auto"><FormField type="text" name="lname" label="Last Name" /></Col>
                            </Row>

                            <Row>
                                <Col flex="50%"><FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} /></Col>
                                <Col flex="auto"><FormField type="text" name="phone" label="Phone" placeholder="Mobile number" validate={composeValidators(rules.required, rules.minChar(4))} /></Col>
                            </Row>

                            <Row>
                                <Col flex="auto">
                                    <FormField type="password" name="password" label="Password" />
                                </Col>
                                <Col flex="49%">
                                    <FormField type="password" name="confirm_pwd" label="Confirm Password" />
                                </Col>
                            </Row>

                            {/* {values._id && <div style={{ margin: "20px", display: "flex", justifyContent: "center" }}>
                                <UploadField
                                    type="picture" listType="picture-card" limit={1}
                                    onDeleteClicked={() => this.onFileDeleteClicked(values._id)}
                                    // label="Product default picture"
                                    buttonLabel="User Avatar"
                                    name="avatar"
                                    fileList={values.avatar ? [{ srcUrl: `${process.env.REACT_APP_DATA_URL}/${values.avatar}`, thumbUrl: `${process.env.REACT_APP_DATA_URL}/${values.avatar_thumb}` }] : null}
                                    data={{ fieldsJson: JSON.stringify({ _id: values._id }), action: "user_avatar" }}
                                />
                            </div>} */}

                            <FormField type="textarea" name="note" label="Note" placeholder="Notes" />

                        </>)
                    }}
                />
            </></Drawer>
        )
    }
}

UserForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addStaff: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(DELETE_USER_AVATAR, {
        props: ({ mutate }) => ({
            deleteUserAvatar: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),

)(UserForm);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, user, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, user, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;
// export default WithApollo;