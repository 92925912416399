import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';

import { Form, Field } from 'react-final-form'
import { Row, Col, message, Modal, Alert } from 'antd';
import { Drawer, Heading, Button, Icon, UserSelection, BasketSelection, DevBlock } from 'Common/components'
import { Select, FormField, FormComponent, FormFieldGroup, composeValidators, rules } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'


export const Status_New = ({ onCancel, onSubmit, order }) => {
    const [busy, setBusy] = useState(false);

    const on_submit = async (values) => {
        return onSubmit(values)
    }

    return (<>
        <Alert 
            message={<>Are you sure to change order status to <b>New</b>?</>} 
            description={<>
                <p>Following actions will be performed</p>
                <ol>
                    <li>Remove any Pickup data</li>
                    <li>Remove any Dispatch data</li>
                    <li>Update order status</li>
                </ol>
            </>} 
            type="warning" showIcon />

        <FormComponent onSubmit={on_submit} id='OrderStatusUpdateForm' fields={{ status:"new" }}
            form_render={formProps => {
                const { values, submitting } = formProps;

                return (<>


                    <div style={{ height: '30px' }} />
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white-outline" disabled={submitting}>Cancel</Button></Col>
                        <Col><Button loading={submitting} htmlType="submit">Update Order Status</Button></Col>
                    </Row>

                </>)
            }}
        />

    </>)
}
Status_New.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}