import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'

// addReducers({ admins: reducers });
addModule(
  {
    permission_required:'products-access',
    path: `${ROOT}`, menuPath: `${ROOT}/catalogue`, component: Home, icon: 'cubes', title: "Products", exact: 0, hideNav: 0,
    // subMenu: ProductsMenu()
  },
)
