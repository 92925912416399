import React, { Component, useRef } from 'react'
import PropTypes from 'prop-types';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Button } from 'Common/components';



const pageStyle = `
  @page {
    size: 80mm;
    margin: 5mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

export class ComponentToPrint extends React.PureComponent {
    render() {
        if (this.props.contents) return this.props.contents;
        
        return (<div>Nothing to print here</div>);
    }
}


export const PrintComponent = React.forwardRef((props, ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle,
    });

    if (props.handlePrint) props.handlePrint(handlePrint);

    return <ComponentToPrint  {...props} ref={componentRef} />
});
PrintComponent.propTypes = {
    handlePrint: PropTypes.func.isRequired,
    contents: PropTypes.any.isRequired,
}

