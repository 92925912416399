import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Heading, Icon, DevBlock } from 'Common/components'
import { rules, composeValidators, FormField, UploadField, FormComponent, SubmitButton } from 'Common/components/Form'
import { string_to_slug } from 'Common/scripts/Functions';
import { generalStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper';

const RECORD_EDIT = loader('src/graphqls/product_cat/edit.graphql');
const RECORD_ADD = loader('src/graphqls/product_cat/add.graphql');
const DELETE_IMG = loader('src/graphqls/product_cat/delete_img.graphql');

const defaultFields = ({
    status:"enabled"
})


export const CategoriesForm = props => {
    const [loading, setLoading] = React.useState(false);
    const [__fields, setFields] = React.useState(false);
    let fields = __fields ? { ...__fields } : props.fields ? { ...props.fields } : {}
    if (!props.showform && __fields) setFields(false);

    const onSubmit = async (values) => {
        const { onClose, editProductCat, addProductCat, callback } = props;
        const _id = fields ? fields._id : false;
        
        let filteredValues = {
            title: values.title,
            slug: values.slug,
            status: values.status,
            seo_title: values.seo_title,
            seo_desc: values.seo_desc,
        };

        let results;
        
        setLoading(true);
        if (_id) {
            results = await editProductCat({ ...filteredValues, _id: _id }).then((r) => {
                let response = r.data.editProductCat;
                if (!response.error && callback) callback('updated', response)

                return response;
            }).catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message:"Query Error"}}
            });
        } else {
            results = await addProductCat(filteredValues).then((r) => {
                let response = r.data.addProductCat;
                if (!response.error && callback) callback('added', response)

                return response;
            }).catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message: "Query Error" } }
            });
        }

        setLoading(false);

        if (results.error) return message.error(results.error.message);
        onClose(results);

    }

    const onFileDeleteClicked = async (fileInfo) => {
        let srcField = fileInfo.name
        let src = fileInfo.srcUrl;
        let thumb = fileInfo.thumbUrl;
        let thumbField = fileInfo.name + "_thumb";

        // props.deleteProductCatImg(fields._id, `${srcField},${thumbField}`).then((e) => {
        return props.deleteProductCatImg(fields._id, `${srcField},${thumbField}`).then((e) => {
            setLoading(false)
            if (e.data.deleteProductCatImg.error) {
                // let err = e.data.deleteProductCatImg.error;
                // message.error(err.message);
                // return false;
            }
            return e.data.deleteProductCatImg;
            // message.success("Fl");
            // onClose(e.data.editProductCat);
        }).catch(error => {
            setLoading(false)
            console.log(error);
            // message.error("Query Error");
            return { error: { message: "Query Error" } };
        });
        // return { error: { message: "onFileDeleteClicked" } };
    }

    const onFileUploadClicked = async (fileInfo) => {
        console.log("fileInfo: ", fileInfo);
        return { error: { message: "onFileUploadClicked" } };
    }

    let menu_bg_img_recordPath = "product_cat.menu_bg_img";
    let menu_bg_img = (fields && fields._id && fields.menu_bg_img)
        ? [{
            _id: fields._id,
            // recordPath: menu_bg_img_recordPath,
            srcUrl: fields.menu_bg_img,
            thumbUrl: fields.menu_bg_img_thumb
        }]
        : null;

    let title_img_recordPath = "product_cat.title_img";
    let title_img = (fields && fields._id && fields.title_img)
        ? [{
            _id: fields._id,
            // recordPath: title_img_recordPath,
            srcUrl: fields.title_img,
            thumbUrl: fields.title_img_thumb
        }]
        : null;

    let icon_img_recordPath = "product_cat.icon_img";
    let icon_img = (fields && fields._id && fields.icon_img)
        ? [{
            _id: fields._id,
            // recordPath: title_img_recordPath,
            srcUrl: fields.icon_img,
            thumbUrl: fields.icon_img_thumb
        }]
        : null;

   
    return (
        <Drawer width={400} destroyOnClose maskClosable={false} placement="right"
            onClose={props.onClose}
            visible={props.showform}
            bodyStyle={{ padding:"10px"}}
            footer={<>
                <span></span>
                <SubmitButton loading={false} formid="ProdCatForm" childre={null} />
            </>}
            title={`${fields && fields._id ? 'Edit' : 'Add'} Product Category`}
        >
            <FormComponent onSubmit={onSubmit} id='ProdCatForm' loading={loading} fields={{ ...defaultFields, ...fields }} debug={false}>
                <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                <FormField onChange={(e, onChange) => onChange(string_to_slug(e.target.value))} type="text" name="slug" label="Slug (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} />

                <FormField type="select" name="status" label="Status" data={generalStatus} validate={rules.required} />

                <Heading>SEO Details</Heading>
                <FormField type="text" name="seo_title" label="SEO Title" />
                <FormField type="text" name="seo_desc" label="SEO Description" />

                <Heading>Images</Heading>
                {(!fields || !fields._id) && <div style={{ padding: '0 20px' }}>Save record to upload images</div>}
                {fields && fields._id &&
                    <div style={{ align: "center", textAlign: "center" }}>
                        <UploadField type="picture" listType="list"
                            onDeleteClicked={onFileDeleteClicked} onUploadClicked={onFileUploadClicked}
                            label="Menu Background Image" buttonLabel=" "
                            name="menu_bg_img" fileList={menu_bg_img}
                            data={{ fieldsJson: JSON.stringify({ _id: fields._id }), action:"product_cat" }}
                        />
                        <hr />
                        <UploadField type="picture" listType="list"
                            onDeleteClicked={onFileDeleteClicked} onUploadClicked={onFileUploadClicked}
                            label="Category Title Image" buttonLabel=" "
                            name="title_img" fileList={title_img}
                            data={{ fieldsJson: JSON.stringify({ _id: fields._id }), action: "product_cat" }}
                        />
                        <hr />
                        <UploadField type="picture" listType="list"
                            onDeleteClicked={onFileDeleteClicked} onUploadClicked={onFileUploadClicked}
                            label="Category Icon (75 x 75)" buttonLabel=" "
                            name="icon_img" fileList={icon_img}
                            data={{ fieldsJson: JSON.stringify({ _id: fields._id }), action: "product_cat" }}
                        />
                    </div>
                }

                {/* <Button type="primary" htmlType="submit">Submit</Button> */}
            </FormComponent>
        </Drawer>
    )
}

CategoriesForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    callback: PropTypes.func,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editProductCat: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addProductCat: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(DELETE_IMG, {
        props: ({ mutate }) => ({
            deleteProductCatImg: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(CategoriesForm);

export default WithApollo;