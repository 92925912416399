/*********************
* Rights constructor rules
{
_id: => Number
title: => String
rules: => Array [
    {
      title: => String
      key: => String => MainModuleName-ChildModuleName-2ndChildModuleName-nthChildModuleName
        * Each child module name should be preceeded by a DASH [-]
        eg: accounts-create-sales
            accounts-update-sales
            accounts-delete-sales
    }
  ]
},

**************************/

export const UserRightsByCat = [
    {
        _id: 100, title: 'Accounts Management',
        rules: [
            // { title: 'Edit Personal Account', key: 'accounts-personal-edit' },
            { title: 'Add Admin Account', key: 'accounts-admin-create' },
            { title: 'Update Admin Account', key: 'accounts-admin-update' },
            { title: 'Delete Admin Account', key: 'accounts-admin-delete' },

            // {title:'Manage Account Roles', key:'accounts-roles-update'},
        ]
    },

    {
        _id: 200, title: 'Account Role Management',
        rules: [
            { title: 'Access Role Manage Module', key: 'account-role-access' },
        ]
    },

    {
        _id: 300, title: 'Users',
        rules: [
            { title: 'Manage Users', key: 'users-manage' },
            { title: 'Create', key: 'users-create' },
            { title: 'Update', key: 'users-update' },
            { title: 'Delete', key: 'users-delete' },
        ]
    },
    {
        _id: 301, title: 'Staff',
        rules: [
            { title: 'Add/Edit Staff Account', key: 'staff-manage' },
            { title: 'Add picker account', key: 'add-picker' },
            { title: 'Add driver account', key: 'add-driver' },
            { title: 'Manage Till Dispatcher', key: 'till-dispatcher' },
            { title: 'Manage Store Supervisor', key: 'store-supervisor' },
            { title: 'Delete', key: 'staff-delete' },
        ]
    },
    {
        _id: 302, title: 'Personal Profile',
        rules: [
            { title: 'Access Personal Profile', key: 'personal-profile-manage' },
            { title: 'Update Profile', key: 'p-profile-update' },
        ]
    },

    {
        _id: 400, title: 'Orders',
        rules: [
            { title: 'Access Orders Module', key: 'orders-access' },
            { title: 'Update Address', key: 'orders-update-address' },
            { title: 'Update Status', key: 'orders-update-status' },
            { title: 'Update History', key: 'orders-update-history' },
            { title: 'Delete', key: 'orders-delete' },
            { title: 'Access Picking', key: 'access-picking' },
            { title: 'Access Till', key: 'access-till' },
            { title: 'Access Cart List', key: 'access-carts' },
            { title: 'Access Dispatched Orders', key: 'access-order-dispatched' },
            { title: 'Access Ready to dispatch Orders', key: 'access-order-readytodispatch' },
            { title: 'View Order Details', key: 'access-order-details' },
            
            { title: 'Manage All Orders', key: 'manag-all-orders' },
        ]
    },

    {
        _id: 500, title: 'Products',
        rules: [
            { title: 'Access Products Module', key: 'products-access' },
            { title: 'Add / Edit', key: 'product-manage' },
            { title: 'Delete Product', key: 'product-delete' },
            { title: 'Manage Product Categories', key: 'manage-product-categories' },
            { title: 'Manage Product Types', key: 'manage-product-types' },
            { title: 'Manage Product Attributes', key: 'manage-product-attributes' },
            { title: 'Manage Out Of Stock', key: 'manage-product-outofstock' },
            { title: 'Full Access (access to disabled fields)', key: 'prod-full-access' },
            { title: 'Manage Featured products by store', key: 'manage-featured-products' },
            
            { title: 'Show column: Category', key: 'prodlicol-cat' },
            { title: 'Show column: Store', key: 'prodlicol-store' },
        ]
    },

    {
        _id: 600, title: 'Brands',
        rules: [
            { title: 'Access Brands Module', key: 'brands-access' },
            { title: 'Add/Edit Brands', key: 'brand-manage' },
            { title: 'Delete', key: 'brand-delete' },
        ]
    },

    {
        _id: 700, title: 'Vedors',
        rules: [
            { title: 'Access Vedors Module', key: 'vedors-access' },
            { title: 'Add/Remove Vedors', key: 'vedors-manage' },
            { title: 'Delete', key: 'vedors-delete' },
        ]
    },

    {
        _id: 800, title: 'Wallet',
        rules: [
            { title: 'Access Wallet Module', key: 'wallet-access' },
        ]
    },

    {
        _id: 900, title: 'Delivery Slots',
        rules: [
            { title: 'Access Delivery Slots Module', key: 'delivery-slots-access' },
            { title: 'Add/Edit Delivery Slots', key: 'dslots-manage' },
            { title: 'Delete', key: 'dslots-delete' },
        ]
    },

    {
        _id: 1000, title: 'Discount Vouchers',
        rules: [
            { title: 'Access Discount Vouchers Module', key: 'discount-vouchers-access' },
            { title: 'Add/Edit Discount Vouchers', key: 'd-vouchers-manage' },
            { title: 'Add/Edit Bulk Vouchers', key: 'd-vouchers-bulk' },
            { title: 'Delete', key: 'd-vouchers-delete' },
            { title: 'Manage Voucher Categories', key: 'd-vouchers-cat' },
            { title: 'Manage Voucher Types', key: 'd-vouchers-type' },
            { title: 'Export vouchers', key: 'd-vouchers-export' },
        ]
    },

    {
        _id: 1100, title: 'Banners',
        rules: [
            { title: 'Access Banners Module', key: 'banners-access' },
            { title: 'Add/edit Banners', key: 'banners-manage' },
            { title: 'Delete', key: 'banners-delete' },
        ]
    },

    {
        _id: 1200, title: 'Offers',
        rules: [
            { title: 'Access Offers Module', key: 'offers-access' },
            { title: 'Add/Edit Offers', key: 'offers-manage' },
            { title: 'Delete', key: 'offers-delete' },
        ]
    },

    {
        _id: 1300, title: 'Pages',
        rules: [
            { title: 'Access Pages Module', key: 'pages-access' },
            { title: 'Add/Remove Pages', key: 'pages-manage' },
            { title: 'Delete', key: 'pages-delete' },
        ]
    },

    {
        _id: 1400, title: 'Customers',
        rules: [
            { title: 'Access Customers Module', key: 'customers-access' },
            { title: 'Edit', key: 'customers-edit' },
            { title: 'Delete', key: 'customers-delete' },
            { title: 'Show Customer Details', key: 'customers-details-preview' },
            { title: 'Show Customer Address', key: 'show-customers-adderss' },
        ]
    },

    {
        _id: 1500, title: 'Settings',
        rules: [
            { title: 'Access Settings Module', key: 'settings-access' },
            { title: 'Manage GEO Zones', key: 'settings-geo-zones' },
            { title: 'Manage Tags', key: 'settings-tags' },
            { title: 'Manage Agreements', key: 'settings-aggrements' },
            { title: 'Manage Page Settings', key: 'settings-pagesettings' },
            { title: 'Manage Locations', key: 'settings-locations' },

            { title: 'Manage System Configurations', key: 'settings-systemconfigs' },
            { title: 'Manage configs: Applications', key: 'settings-applications' },
            { title: 'Manage configs: Orders', key: 'settings-orders' },
            { title: 'Manage configs: Loyalty Program', key: 'settings-loyalty-program' },
            { title: 'Manage configs: Emails', key: 'settings-emails' },
            { title: 'Manage configs: General', key: 'settings-general' },
            { title: 'Manage configs: Products', key: 'settings-products' },
            
            { title: 'Store Settings', key: 'store-settings' },

            { title: 'Add/Edit Key', key: 'settings-mutate' },
        ]
    },

    {
        _id: 1600, title: 'Vehicles',
        rules: [
            { title: 'Access Vehicles Module', key: 'vehicles-access' },
            { title: 'Add/Delete Vehicle', key: 'manage-vehicle' },
        ]
    },

    {
        _id: 1700, title: 'Store Stats',
        rules: [
            { title: 'Access Store Stats Module', key: 'store-stats-access' },
        ]
    },

    {
        _id: 1800, title: 'Stores',
        rules: [
            // { title: 'Access Store', key: 'store-access' },
            { title: 'Manage All Stores', key: 'manage-all-store' },
            { title: 'Manage My Store', key: 'manage-my-store' },
            { title: 'Delete Store', key: 'delete-store' },
            { title: 'Reset Store', key: 'reset-store' },
        ]
    },

    {
        _id: 1900, title: 'Application access',
        rules: [
            { title: 'Login to Admin', key: 'admin-web-access' },
            { title: 'Login to client Web', key: 'client-web-access' },
            { title: 'Login to client App', key: 'client-app-access' },
            { title: 'Login to picker App', key: 'picker-app-access' },
            { title: 'Login to driver App', key: 'driver-app-access' },
            { title: 'Access RestFull Api', key: 'rest-app-access' },
        ]
    },

    {
        _id: 2000, title: 'Baskets',
        rules: [
            { title: 'Manage Pickup Baskets', key: 'manage-pickup-baskets' },
            { title: 'View Pickup Baskets', key: 'view-pickup-baskets' },
            
            { title: 'Manage Delivery Baskets', key: 'manage-delivery-baskets' },
            { title: 'View Delivery Baskets', key: 'view-delivery-baskets' },
        ]
    },

    {
        _id: 2100, title: 'Accounts',
        rules: [
            { title: 'Access Accounts', key: 'acc.access' },
        ]
    },

    {
        _id: 2200, title: 'Webhooks',
        rules: [
            { title: 'Access Webhooks', key: 'acc.webhooks' },
        ]
    },

    {
        _id: 2300, title: 'Payment Methods',
        rules: [
            { title: 'Access Payment Methods', key: 'payment-methods' },
        ]
    },

];
