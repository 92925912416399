import React from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';
import { Timeline } from 'antd';
import { DevBlock, Heading, Icon } from 'Common/components'
import { getTimeDifference, utcToDate, sortArrayByVal } from 'Common/scripts/Functions'
import { orderStatus } from 'configs/constants';
import { connect } from 'react-redux'

import StatusForm from './StatusForm'
import { checkRights } from 'Common/scripts/Security';

function getConditionalStyle(note) {
    let style = {  };
    if (note.indexOf("Status Updated") > -1) style = Object.assign(style, { fontWeight:"bold"});
    return style
}
function getConditionalIcon(note) {
    let returnVal = null;

    orderStatus().forEach(itm => {
        if (itm.title && itm.icon && String(note).toLowerCase().indexOf(`status updated to ${itm.title.toLowerCase()}`) > -1) {
            if (returnVal) return;

            const _p = {}
            if (itm.icon) Object.assign(_p, { icon: itm.icon });
            if (itm.color) Object.assign(_p, { color: itm.color });
            returnVal = <Icon {..._p} />
        }     
    });

    return returnVal;

}

export const RenderHistoryComp = props => {
    const { order, rights } = props;

    const status_notes = sortArrayByVal(order.status_notes, 'created_at', 'desc');

    return (<>
        <div className="grid-block">
            <Heading>Order History</Heading>
            <div style={{ padding: "0 10px" }}>
                <Timeline>
                    {checkRights(rights, "orders-update-history", false) && 
                        <Timeline.Item dot={<Icon icon="plus" />} color="blue"><StatusForm order={order} /></Timeline.Item>
                    }
                    {status_notes.reverse().map((item, i) => {
                        return <Timeline.Item key={i} style={getConditionalStyle(item.note)} dot={getConditionalIcon(item.note)}>
                            <span style={{ color: "#999" }}>{utcToDate(item.created_at).format("DD-MM-YYYY HH:mm")}</span>
                            {/* <span style={{color:"#999"}}>{moment(item.created_at).format("DD-MM-YYYY HH:mm")}</span> */}
                            <div><Icon icon="comment" style={{ color: "#ccc" }} /> {item.note}</div>
                        </Timeline.Item>
                    })}
                    <Timeline.Item dot={<Icon icon="shopping-cart" />} color="blue">
                        {/* <span style={{ color: "#999" }}>{moment(props.order.created_at).format("DD-MM-YYYY HH:mm")}</span> */}
                        <span style={{ color: "#999" }}>{utcToDate(order.created_at).format("DD-MM-YYYY HH:mm")}</span>
                        {/* <div>Order placed {getTimeDifference({ before: props.order.created_at })}</div> */}
                        <div>Order placed {utcToDate(order.created_at).fromNow()}</div>
                    </Timeline.Item>
                </Timeline>
            </div>
        </div>

    </>)
}
RenderHistoryComp.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
}

export const RenderHistory = connect(({ grocer_storeadmin: { rights } }) => {
    return { rights };
})(RenderHistoryComp);

export default RenderHistory;
