import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { message, Row, Col, Button } from 'antd'
import { Heading, Loader, Icon } from 'Common/components'
import { rules, FormField } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'

const GET_TYPE = loader('src/graphqls/product_type/prodType.graphql');

const ProductAttributesSelectorComp = props => {
    const [loading, setLoading] = React.useState(false);
    const [prodType, setProdType] = React.useState(null);
    const [attrOnDD, setAttrOnDD] = React.useState();
    const [attributesList, setAttributesList] = React.useState(props?.formValues?.attributes || []);


    React.useEffect(() => {
        if (!prodType || props.type_id!=prodType._id) {
            // setState({ ...state, type_id: props.type_id, loading: true })
            getProdType(props.type_id);
            // sleep(2000)
        }
    }, [props.type_id])

    const getProdType = async (type_id) => {
        if (!type_id) return;

        setLoading(true);

        const pTypes = await props.client.query({ query: GET_TYPE, variables: { id: type_id } }).then(e => {
            if (e.error || e.errors) {
                console.log("ERROR: ", e);
                message.error(__error("ERROR "), (e.error && e.error.message) || (e.errors && e.errors[0].message));
                // setState({ ...state, loading: false, prodType: null });
                setLoading(false);
                setProdType(null)
                return false;
            }

            return e.data.prodType;
            // setProdType(e.data.prodType)
            // setLoading(false);

        }).catch(err => {
            console.log(__error("API Call ERROR: USER_ROLE : "), err);
            message.error("Request ERROR");
            // setState({ ...state, loading: false, prodType: null });
            setLoading(false);
            return false;
        })
        if (!pTypes) return;

        setProdType(pTypes);

        const _attr = props.defaultValues?.filter(o => {
            let found = pTypes.attributes.find(oo=>oo._id==o._id);
            if (!found) return false;
            // console.log("o._id: ", o._id)
            return { ...found, value:o.value, id:o._id }
        })
        setAttributesList(_attr);
        setLoading(false);

    }

    const onAttributeSelection = (__1, __2) => {
        const item_data = __2["item-data"];
        setAttrOnDD(item_data);
    }

    const addAttribute = args => {
        if (attributesList?.find(o => o._id == props.formValues.attr_selector)) return;

        let _attributesList = attributesList?.slice() || [];
        _attributesList.push(attrOnDD);

        setAttributesList(_attributesList)
        props.updateAttributes(_attributesList)

    }

    const removeAttribute = __id => {
        const _attributesList = attributesList.filter(o => (o._id != __id));
        setAttributesList(_attributesList)
        props.updateAttributes(_attributesList)
    }

    if (!props.type_id) return null;
    if (loading) return (<div className="grid-block"><Heading>Attributes <Loader loading={true} /></Heading></div>);

    return (<><div className="grid-block">
        <Row>
            <Col flex="auto" align="middle"><Heading>Attributes</Heading></Col>
            <Col align="middle" flex="100px">
                <FormField compact
                    style={{ textAlign:"left" }}
                    data={prodType?.attributes?.map(o=>({ ...o, _id:o._id, id:o._id })) || []}
                    type="select"
                    placeholder="Add Attribute"
                    name="attr_selector"
                    onSelect={onAttributeSelection}
                />
            </Col>
            <Col align="middle"><Button disabled={!props.formValues.attr_selector} onClick={() => addAttribute()} icon={<Icon icon="plus" />} /></Col>
        </Row>

        <div className="data-row-table"><Row>{attributesList && attributesList.map((item, i)=>{
            return <Col key={i} span={6}>
                <div style={{backgroundColor:"#EEE", borderRadius:"5px", margin:"5px", padding:"5px 10px"}}>
                <Row key={i} style={{flexWrap:"nowrap"}} align="bottom">
                    <Col flex="auto">
                        <FormField compact
                            addonAfter={item.code}
                            name={`attribute_values.val_${item._id}`}
                            validate={item.required ? rules.required : undefined}
                            label={item.title}
                            defaultValue={item.value}
                            width={"100%"} type="text" />
                    </Col>
                    <Col><Button type="danger" onClick={() => removeAttribute(item._id)} icon={<Icon icon="times" />} /></Col>
                </Row>
                </div>
            </Col>
        })}</Row></div>

    </div></>);
}
ProductAttributesSelectorComp.propTypes = {
    updateAttributes: PropTypes.func.isRequired, // mutator
    // onChange: PropTypes.func.isRequired,
    // showform: PropTypes.bool.isRequired,
    attributes: PropTypes.array,
    type_id: PropTypes.string,
    // prev_type_id: PropTypes.string,
    defaultValues: PropTypes.array,
    // onRemove: PropTypes.func,
}

export const ProductAttributesSelector = withApollo(ProductAttributesSelectorComp);
export default ProductAttributesSelector;
