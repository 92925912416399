import React from 'react';
import { Button as _Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { useHistory, useParams, useLocation } from "react-router-dom";

// https://ant.design/components/button/

/***
 * disabled={boolean}
 * ghost={boolean} // transparent background
 * href={string} // redirect url of link button
 * target={string} // same as target attribute of a, works when href is specified
 * htmlType={string}
 * icon={ReactNode}
 * loading={boolean}
 * shape={circle | round}
 * size={large | middle | small}
 * type={primary | ghost | dashed | link}
 * block={boolean}
 * danger={boolean}
 */

// export const Button = props => <_Button {...props} type={props.type || "primary"} />;
/*****
 * React.forwardRef used to fix refs error
 * ERROR: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()
 */
// export const Button = React.forwardRef((props, ref) => ( <_Button ref={ref} {...props} type={props.type || "primary"} /> ) );
export const Button = React.forwardRef((props, ref) => {
    const _props = { ...props, style: { ...props.style } };
    // add left margin if icon is provided with children
    if (props.icon && props.children) {
        Object.assign(_props, { children: <span style={{ marginLeft: '5px' }}>{props.children}</span> })
    }
    if (props.color) {
        delete _props.color;
        Object.assign(_props, { className: `btn_${props.color}` })
        // Object.assign(_props, {style: { ...props.style, background: props.color }})
    }

    return (<_Button ref={ref} {..._props} type={props.type || "primary"} />)
});
export default Button;

export const ToggleButton = props => {
    const propTypes = {
        labelA: PropTypes.any.isRequired,
        labelB: PropTypes.any,
        toggle: PropTypes.bool,
        // icon: PropTypes.oneOfType([
        //             PropTypes.string,
        //             PropTypes.object
        //         ]),
    }

    let _props = { ...props };
    _props = Object.assign(_props)
    delete _props.toggle;
    delete _props.labelA;
    delete _props.labelB;
    // <Button type="primary" shape="circle" icon={<SearchOutlined />} />
    return <Button {..._props} >{!props.toggle ? props.labelA : (props.labelB || props.labelA)}</Button>

}


// export const IconButton = props => {
export const IconButton = React.forwardRef((props, ref) => {
    let _props = { ...props };
    delete _props.icon;
    delete _props.onClick;
    delete _props.type;
    delete _props.icon_type;
    delete _props.placement;

    if (props.type=="delete"){
        return (<Popconfirm title="Sure to delete?" onConfirm={props.onClick} placement={props.placement || "topRight"}>
            <Button ref={ref} shape='circle' style={{ margin: '3px' }} size="small" 
                type={props.icon_type || "danger"} 
                {..._props}>
                {!props.loading && <Icon style={{ fontSize: "0.8em" }} icon="trash-alt" />}
                </Button>
        </Popconfirm>)
    }

    return <Button onClick={props.onClick} ref={ref} shape='circle' style={{ margin: '3px' }} size="small" 
        type={props.type || "default"} 
        {..._props}>
        {!props.loading && <Icon style={{ fontSize: "0.8em" }} icon={props.icon} />}
        </Button>
});


export const BackButton = ({ onClick, style }) => {
    let history = useHistory();
    const onBackClick = () => {
        if (onClick) onClick();
        else history.goBack()
    }
    return <Button type="link" onClick={onBackClick}><Icon icon="chevron-left" style={{ marginRight: "10px", ...style }} /> Back</Button>
}


export const DeleteButton = ({ onClick, message, children }) => {
    return (<Popconfirm onConfirm={onClick} title={message || "Are you sure to delete this record？"}>
        {children || <span><IconButton type="danger" icon="trash-alt" /></span>}
    </Popconfirm>)
}