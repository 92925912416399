import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT, MODULE } from './constants'

// addReducers({ admins: reducers });
addModule(
    {
        permission_required: 'acc.access',
        path: `${ROOT}`, component: Home, icon: 'cash-register', title: "Accounts", exact: 0, hideNav: 0,
        // subMenu: SubMenu()
    },
)
