/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'Common/components';
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";

import Vouchers from './containers/Vouchers.jsx'
import VoucherCategories from './containers/VoucherCategories'
import VoucherTypes from './containers/VoucherTypes'

import { ROOT } from './constants';

export const menuArray = props => ([
  { path: `${ROOT}`, exact: true, title: 'Vouchers', component: Vouchers, hide: false },
  { path: `${ROOT}/categories`, exact: true, title: 'Categories', component: VoucherCategories, hide: false, permission_required:'d-vouchers-cat' },
  { path: `${ROOT}/types`, exact: true, title: 'Types', component: VoucherTypes, hide: false, permission_required: 'd-vouchers-type' },
])


/**
 * Home
 */
export const Home = props => {
  let routs = menuArray();

  return (
    <LayoutWrapper topMenu={routs} skipContentArea>
      <Switch>
        {routs.map((item, i) => {
          if (!item.component) return null;
          return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
        })}
        <Route key={999} component={E404} />
      </Switch>
    </LayoutWrapper>
  )

}

Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

export default Home;