import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, IconButton } from 'Common/components'
import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { generalStatus, genders } from 'configs/constants';

const RECORD = loader('src/graphqls/user/user.graphql');
const RECORD_EDIT = loader('src/graphqls/user/edit.graphql');
const RECORD_ADD = loader('src/graphqls/user/add.graphql');
const RECORD_DELETE = loader('src/graphqls/user/delete.graphql');


// export const CustomerForm = props => {
export class CustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, __fields: { status:"disabled"} }
        this.onSubmit = this.onSubmit.bind(this);
    }

    updateFields = (__fileds) => {
        this.setState({__fields:{
            ...__fileds,
            activation_date: __fileds.activation_date ? moment(__fileds.activation_date) : __fileds.activation_date,
            deactivation_date: __fileds.deactivation_date ? moment(__fileds.deactivation_date) : __fileds.deactivation_date
        }})
        document.getElementById('CustomerForm').dispatchEvent(new Event('reset', { cancelable: true }))
    } 

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading && !this.props.loading) this.setState({ loading: false, __fields: this.props.user })
    }

    onSubmit = values => {
        const { onClose, editUser, addUser, __fields } = this.props;
        const { _id } = this.props.user;

        let filteredValues = {
            // name: values.name,
            fname: String(values.fname).trim(),
            lname: values.lname ? String(values.lname).trim() : "",
            email: String(values.email).trim(),
            status: values.status,
            gender: values.gender,
            note: values.note,
            phone: String(values.phone),
            phone_confirmed: values.phone_confirmed ? 1 : 0,
            email_confirmed: values.email_confirmed ? 1 : 0,
        };

        if (values.password && (values.password != values.confirm_pwd)) { 
            message.error("Password missmatch")
            return;
        } else if (values.password && (values.password == values.confirm_pwd)){
            filteredValues = Object.assign(filteredValues, { password: values.password });
        }


        this.setState({ loading: true })

        const resutls = editUser({ ...filteredValues, _id }).then((e) => (e.data.editUser))
        .catch(error => {
            console.log(__error("Query Error"), error)
            return { error: { message:"Query Error"}}
        });

        if (resutls.error){
            this.setState({ loading: false })
            message.error(resutls.error.message);
            return false;
        }

        message.success("Success");
        onClose(resutls);
    }

    render() {
        const { onClose, showform } = this.props;
        const { __fields, loading } = this.state;

        var fields = { ...__fields }
        delete fields.password;
        fields.phone_confirmed = (fields.phone_confirmed > 0) ? true : false;
        fields.email_confirmed = (fields.email_confirmed > 0) ? true : false;
        
        
        return (
            <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
                bodyStyle={{ padding: "10px" }}
                onClose={onClose}
                visible={showform}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('CustomerForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${__fields && __fields._id ? 'Edit' : 'Add'} Customer`}
            >
                <FormComponent onSubmit={this.onSubmit} id='CustomerForm' loading={loading} fields={{ ...fields}} debug={true}
                    // mutators={{
                    //     addItems: (newValueArray, state, tools) => {
                    //         tools.changeValue(state, 'items', () => newValueArray[0])
                    //     },
                    //     removeItems: (newValueArray, state, tools) => {
                    //         tools.changeValue(state, 'items', () => newValueArray[0])
                    //     },
                    // }}
                    form_render={formProps => {
                        const { values } = formProps;
    
                        return (<>
                            <FormField type="select" name="status" label="Status" className={values.status == 'enabled' ? "active" : "inactive"} data={generalStatus} validate={rules.required} />

                            {/* <FormField type="text" name="name" label="Full Name" validate={composeValidators(rules.required, rules.minChar(4))} /> */}
                            <Row>
                                <Col><FormField type="text" name="fname" label="First Name" validate={rules.required} /></Col>
                                <Col><FormField type="text" name="lname" label="Last Name" /></Col>
                            </Row>
                            
                            <FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} />

                            <Row>
                                {/* <Col>
                                    <FormFieldGroup compact>
                                        <FormField type="number" name="dob_mo" width="100px" placeholder="Month" label="DOB" compact min={1} max={10000} />
                                        <FormField type="number" name="dob_day" width="60px" placeholder="Day" label=" " compact min={1} max={31} />
                                        <FormField type="number" name="dob_year" width="80px" placeholder="Year" label=" " compact min={1900} max={2020} />
                                    </FormFieldGroup>
                                </Col> */}
                                <Col>
                                    <FormField type="select" width="100px" label={<>&nbsp;</>} name="gender" data={genders} validate={rules.required} />
                                </Col>
                            </Row>

                            <Row>
                                <Col flex="auto">
                                    <FormField type="password" name="password" label="Password" />
                                </Col>
                                <Col flex="49%">
                                    <FormField type="password" name="confirm_pwd" label="Confirm Password" />
                                </Col>
                            </Row>

                            <FormField type="text" name="phone" label="Phone" placeholder="Mobile number" validate={composeValidators(rules.required, rules.minChar(4))} />
    
                            <FormField type="textarea" name="note" label="Note" placeholder="Notes" />

                            <Row gutter={[20, 20]}>
                                <Col><FormField type="checkbox" name="phone_confirmed" checked={values.phone_confirmed}>Confirm Phone</FormField></Col>
                                <Col><FormField type="checkbox" name="email_confirmed" checked={values.email_confirmed}>Confirm Email</FormField></Col>
                            </Row>

                        </>)
                    }}
                />
    
            </Drawer>
        )
    }
}

CustomerForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(CustomerForm);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, user, error } = data;

            if (error) console.log(__error("error"), error);

            return { loading, user, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;
// export default WithApollo;