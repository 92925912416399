import React from 'react'
import PropTypes from 'prop-types';
// import { Button, Heading } from 'Common/components'
// import { connect } from "react-redux";
// import { updateUserSettings } from '../local_store/reducer';

import UserList from '../components/user_list';
import UserForm from '../components/user_form';


const Users = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <UserList {...props} onEditRecord={(item) => toggleDrawer({ fields: item })} />
        {visible && <UserForm {...props} onClose={toggleDrawer} showform={visible} fields={fields} />}
    </>)
}


// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updateUserSettings: (payload) => dispatch(updateUserSettings(payload)),
// })
// // const mapStateToProps = ({ settings_customers }) => {
// //     return {
// //         settings_customers,
// //         // user: grocer_storeadmin.user,
// //         // token: grocer_storeadmin.token,
// //         // rights: grocer_storeadmin.rights
// //     };
// //     // return ({ user: (state.user && state.user.user) || {} });
// // }

// const WithRedux = connect(null, mapDispatchToProps)(Users);

export default Users;