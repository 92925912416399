import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routes, navItems } from 'Modules';
import { Wrapper, E404 } from "Layout_v1";
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';
import { __blue, __success, __yellow } from 'Common/scripts/consoleHelper';
import { loader } from 'graphql.macro';
import { useLazyQuery } from 'react-apollo';
import { updateUser } from 'Store/grocer_storeadmin.actions';


const GET_USER = loader('src/graphqls/user/getLoggedInUserV2.graphql');


const NavigationRoutes = connect(({ grocer_storeadmin }) => (grocer_storeadmin || {}))(({ user, rights })=>{
  return (<Switch>
    {navItems.map((route,i)=>{
      if (route.permission_required){
        let haveAccess = checkRights(rights, route.permission_required)
        if (!haveAccess) return null;
      }

      if (!route.path) return null;

      return <Route exact={route.exact ? true : false} path={route.path} component={route.component} key={`root_${i}`} />
    })}
    <Route component={E404} />
  </Switch>)
});


const UserSyncComp = ({ user, rights, on_updateUser }) => {
  // console.log({ user, rights })

  const [userLoaded, set_userLoaded] = useState(false)
  const [getLoggedInUserV2, { called, loading, data }] = useLazyQuery(
    GET_USER,
    { variables: { id: "english" } }
  );

  useEffect(() => {
    if (called || data || loading) return;

    console.log(__yellow("=================="))
    console.log(__blue("Application created"))
    console.log(__yellow("=================="))

    getLoggedInUserV2();

  }, [])

  if (!userLoaded && data && called && !loading) {
    // console.log(__success("User loaded"), data.getLoggedInUserV2)
    on_updateUser({ user: { ...data.getLoggedInUserV2, rights: undefined }, rights: data?.getLoggedInUserV2?.rights })
    set_userLoaded(true);
  }

  return null;
  // return (!called || loading || !userLoaded) ? <p>Loading..</p> : <NavigationRoutes />;
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  on_updateUser: (payload) => dispatch(updateUser(payload)),
})
const mapStateToProps = ({ grocer_storeadmin }) => {
  return { 
    user: grocer_storeadmin.user,
    rights: grocer_storeadmin.rights
  };
}
const UserSync = connect(mapStateToProps, mapDispatchToProps)(UserSyncComp);




export const App = (props) => {
  return (<BrowserRouter>
    <Switch>
      {/* <Route path={'/template'} exact component={Template} key={1} /> */}
      <Wrapper>
        <UserSync />
        <NavigationRoutes />
      </Wrapper>
    </Switch>
  </BrowserRouter>)
}
export default App;



// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <p>
//           Login page
//         </p>
//       </header>
//     </div>
//   );
// }

// export default App;
