import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import CustomerDetails from './CustomerDetails';
import reducers from './local_store/reducer'
import { ROOT } from './constants'

// export const SubMenu = props => ([
//   { path: `${ROOT}/countries`, exact: true, title: 'Countries', component: Countries },
//   { path: `${ROOT}/agreement`, exact: true, title: 'Agreement', component: Agreement },
//   { path: `${ROOT}/tags`, exact: true, title: 'Tags', component: Tags },
// ])

addReducers(reducers);
addModule(
  {
    permission_required: "customers-access",
    path: `${ROOT}`, component: Home, icon: 'user', title: "Customers", exact: 1, hideNav: 0,
    // subMenu: SubMenu()
  },
)
addModule(
  {
    permission_required: "customers-details-preview",
    path: `${ROOT}/details/:_id`, component: CustomerDetails, icon: 'user', title: "Customers Details", exact: 0, hideNav: 1,
    // subMenu: SubMenu()
  },
)
// { path: `${ROOT}/on_till/order/:_id`, exact: true, title: 'Till Confirmation', component: TillConfirmation, hide: true },
