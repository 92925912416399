import { Col, Divider, Row } from 'antd'
import { Avatar, Icon, IconButton } from 'Common/components'
import { ContentArea } from 'Layout_v1';
import React, { Component } from 'react'

const env = process.env;

const StoreInfo = ({ store }) => {
    return (<>
        <ContentArea>
            <Row gutter={[12]}>
                <Col>
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <Avatar size={100} shape="square" src={store.logo_thumb ? `${env.REACT_APP_DATA_URL}/store/${store.logo_thumb}` : null} icon={<Icon icon="image" />} />
                        <span style={{ position: "absolute", top: "0px", right: "0px" }}><IconButton onClick={console.log} icon="pen" /></span>
                    </div>
                </Col>
                <Col flex="auto">
                    <div style={{ fontSize: "18px" }}>
                        <a href={`${env.REACT_APP_HOST_URL}/store/${store.slug}`} target="_blank">{store.title}</a>
                        <IconButton onClick={console.log} icon="pen" />
                    </div>
                    <div>
                        <span style={{ border: "1px solid #EEE", padding: "2px 10px", borderRadius: "25px", backgroundColor: store.status == 'hidden' ? "red" : 'green', color: "white" }}>{store.status}</span>
                    </div>
                </Col>
            </Row>
            <div style={{ margin: "20px 0" }}>{store.description}</div>

            <Divider>Owner</Divider>
            <Row gutter={[5]}>
                <Col>Name: </Col>
                <Col>{store.owner.name}</Col>
            </Row>
        </ContentArea>
    </>)
}

export default StoreInfo;