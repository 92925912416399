import React, { Component, useState, useEffect } from 'react'
import { graphql, Subscription, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Modal } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';
import moment from 'moment';

const RESET_ORDERS = loader('src/graphqls/system_updates/reset_all_orders.graphql');



const Orders = ({ client }) => {
    const [busy_orders_reset, set_busy_orders_reset] = useState(false);
    const [error, setError] = useState(null);

    const resetOrders = async() => {
        set_busy_orders_reset(true);

        const resutls = await client.mutate({ mutation: RESET_ORDERS, variables: { }, fetchPolicy: "no-cache" })
            .then(r => (r.data.reset_all_orders)).catch(err => {
                console.log(__error("Query ERROR"), err);
                return { error: { message: "Query ERROR" } }
            })
        set_busy_orders_reset(false);
        
        if (!resutls || resutls.error){
            setError(resutls && resutls.error.message || "Invalid response")
        }

        message.success("resetOrders complete");
        
    }

    return (<>
        {error && <Alert message={error} type='error' showIcon />}

        <Row gutter={[20, 20]}>
            <Col><Button loading={busy_orders_reset} onClick={resetOrders}>Delete All orders</Button></Col>
        </Row>
    </>)

}

export default withApollo(Orders);