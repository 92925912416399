import React from 'react'
// import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { Icon } from 'Common/components';
import { Link } from 'react-router-dom';
import { navItems } from 'Modules';
import { connect } from "react-redux";
import { updateSettings } from 'Store/grocer_storeadmin.actions';
import { checkRights } from 'Common/scripts/Security';

const { Sider } = Layout;
const { SubMenu } = Menu;

const RenderFullMenu = ({ menu, rights, collapsed }) => {
    // var patt = new RegExp(`.*${window.location.pathname}*.`, 'i'); // patt.constructor; // >> /.*pro,*./i
    if (!menu) return <div>Empty Menu</div>
    let activeMenuItem = "";

    menu.forEach((element, i) => {
        if (element.path == window.location.pathname){
            // console.log(`${element.path} == ${window.location.pathname}`)
            activeMenuItem = `${element.path}`;
        }
        // if (window.location.pathname.indexOf(element.path) > -1) activeMenuItem += `,${i}`;
        

        // if (!element.exact && element.exact < 1) {
        //     // let patt = new RegExp(`.*${element.menuPath || element.path}*.`, 'i');
        //     let patt = new RegExp(`^${element.menuPath || element.path}`, 'i');
        //     let result = String(window.location.pathname).search(patt) > -1; // String(window.location.pathname).search(patt);
        //     if (result) activeMenuItem += `,${i}`;
        // }
    });


    return (
        // <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${activeMenuItem}`]}>
        <Menu theme="dark" mode="inline" selectedKeys={activeMenuItem && activeMenuItem.length>0 && activeMenuItem.split(",")}>
            {menu.filter(route =>{
                return (route.permission_required) ? checkRights(rights, route.permission_required) : true;

            }).map((item, i) => {
                if (item.hideNav > 0) return null;

                if (!item.subMenu) {
                    let itemProps = { title: item.title }
                    let path = item.menuPath || item.path;
                    if (item.icon) itemProps = Object.assign(itemProps, {
                        icon: path ? <Link to={path}><Icon anticon icon={item.icon} /></Link> : <Icon anticon icon={item.icon} />
                        })

                    return <Menu.Item {...itemProps} key={item.path}>
                        {(path && !collapsed) && <Link to={path}>{item.title}</Link>}
                        <span>{!item.path && item.title}</span>
                    </Menu.Item>
                }

                else {
                    let menuProps = { title: item.title }
                    if (item.icon) menuProps = Object.assign(menuProps, { icon: <Icon anticon icon={item.icon} /> })

                    return (
                        <SubMenu {...menuProps} key={i}>
                            {item.subMenu.filter(r=>{
                                if (r.permission_required) return checkRights(rights, r.permission_required)
                                return true;
                            }).map((item, ii) => {
                                let itemProps = { title: item.title }
                                if (item.icon) itemProps = Object.assign(itemProps, { icon: <Icon anticon icon={item.icon} /> })

                                return <Menu.Item {...itemProps} key={ii}>
                                    {(item.menuPath || item.path) && <Link to={item.menuPath || item.path}>{item.title}</Link>}
                                    <span>{!item.path && item.title}</span>
                                </Menu.Item>
                            })}
                        </SubMenu>
                    )
                }

            })}

        </Menu>
    )
}

const _SideMenu = props => {
    // const [collapsed, setNavCollaps] = React.useState(false);
    // const [collapsedWidth, setCollapsedWidth] = React.useState(undefined);

    const renderMenu = menu => {
        return <RenderFullMenu 
            collapsed={props.grocer_storeadmin.settings.navCollapsed}
            menu={menu || navItems} rights={props.grocer_storeadmin.rights} />
    }

    return (
        <Sider 
            trigger={undefined} //_trigger={<p onClick={() => props.updateSettings({ navCollapsed: !props.grocer_storeadmin.settings.navCollapsed })}>trigger button</p>} 
            collapsible
            // breakpoint="lg"
            // onBreakpoint={broken => {
            //     console.log("onBreakpoint()", broken);
            //     if (!broken) return;
            //     // props.setCollapsedWidth(broken ? 0 : undefined)
            //     // props.setNavCollaps(broken);
            // }}
            collapsedWidth={props.collapsedWidth}
            defaultCollapsed={props.grocer_storeadmin.settings.navCollapsed}
            onCollapse={(current_state) => {
                props.updateSettings({ navCollapsed: !props.grocer_storeadmin.settings.navCollapsed })
            }} // required for footer collaps button/
            collapsed={props.grocer_storeadmin.settings.navCollapsed}
            className="site-sidebar"
        >
            
            {/* <div className="sidebar-logo"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/grocerzm-logo-190.png`} /></div> */}
            <div className="sidebar-logo"><img src={`/assets/grocerzm-logo-190.png`} /></div>
            {renderMenu(props.menu)}

        </Sider>
    )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const mapStateToProps = ({ grocer_storeadmin }) => {
    return {grocer_storeadmin};
    // return ({ user: (state.user && state.user.user) || {} });
}

export const SideMenu = connect(mapStateToProps, mapDispatchToProps)(_SideMenu);
export default SideMenu;
