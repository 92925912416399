import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent } from 'Common/components/Form'
import { Input, message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { attributesDisplayTypes } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import { string_to_slug } from 'Common/scripts/Functions';

const RECORD = loader('src/graphqls/product_attributes/productAttribute.graphql');
const RECORD_EDIT = loader('src/graphqls/product_attributes/edit.graphql');
const RECORD_ADD = loader('src/graphqls/product_attributes/add.graphql');

export class FormComp extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, __fields: false }
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading && !this.props.loading)
            this.setState({loading:false})
    }

    filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

    onSubmit = values => {
        const { productAttribute, onClose, editProductAttributes, addProductAttributes } = this.props;
        const { __fields } = this.state;
        let fields = __fields ? { ...__fields } : productAttribute ? { ...productAttribute } : {}
        
        const _id = fields ? fields._id : false;

        this.setState({loading:true});

        let filteredValues = {
            title: values.title,
            slug: values.slug,
            code: values.code,
            required: values.required ? 1 : 0,
            show_in_store: values.show_in_store ? 1 : 0,
            // values: values.values ? JSON.stringify(values.values) : null,
            // displayAs: values.displayAs,
            seo_title: values.seo_title,
            seo_desc: values.seo_desc,
        };

        if (_id) {
            editProductAttributes({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({loading:false})
                if (e.data.editProductAttributes.error) {
                    let err = e.data.editProductAttributes.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editProductAttributes);
            }).catch(error => {
                this.setState({loading:false})
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addProductAttributes(filteredValues).then((e) => {
                this.setState({loading:false});
                if (e.data.addProductAttributes.error) {
                    let err = e.data.addProductAttributes.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.addProductAttributes);
            }).catch(error => {
                this.setState({loading:false});
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    render() {
        const { onClose, showform, productAttribute, loadingEditNode} = this.props;
        const { __fields, loading } = this.state;

        let fields = __fields ? { ...__fields } : productAttribute ? { ...productAttribute } : {}

        return (
            <Drawer width={400} destroyOnClose maskClosable={false} placement="right"
                onClose={onClose}
                visible={showform && !loadingEditNode}
                bodyStyle={{padding:"10px"}}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('ProductAttributesForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${fields && fields._id ? 'Edit' : 'Add'} Product Type`}
            >
                <FormComponent onSubmit={this.onSubmit} id='ProductAttributesForm' loading={loading} fields={fields}
                    form_render={({values}) => {
                        return <>
                            <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(2))} />
                            <FormField onChange={this.filterSlug} type="text" name="slug" label="Slug (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(2))} />
                            <FormField type="text" name="code" label="Attribute Code" validate={composeValidators(rules.required, rules.nospace)} />
                            
                            <FormField type="switch" name="required" label="This is a required field" />
                            <FormField type="switch" name="show_in_store" label="Visible in store front" />
    
                            <Heading>SEO Details</Heading>
                            <FormField type="text" name="seo_title" label="SEO Title" />
                            <FormField type="text" name="seo_desc" label="SEO Description" />
                        </>
                    }}
                />
    
            </Drawer>
        )

    }

}

FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    productAttribute: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editProductAttributes: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addProductAttributes: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productAttribute, error } = data;

            if (error) console.log(__error("error"), error);

            return { loadingEditNode:loading, productAttribute, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />

export default Wrapper;