import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Popconfirm, Modal } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, IconButton } from 'Common/components'
import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import { generalStatus, discountTypes, publishStatus, discountRequirements } from 'configs/constants';

const RECORD_EDIT = loader('src/graphqls/banners/edit.graphql');
const RECORD_ADD = loader('src/graphqls/banners/add.graphql');
const RECORD_DELETE = loader('src/graphqls/banners/delete.graphql');
const REMOVE_IMG = loader('src/graphqls/banners/delete_image.graphql');



export const CustomGalleryRender = props => {
    const [previewImg, setPreviewImg] = React.useState(false);

    if (!props.items) return null;
    // console.log("CustomGalleryRender: ", props);

    return (<div>
        <div>{props.items.length} / 10</div>

        {props.items.map((item, i) => {
            return <Row key={i} gutter={[0, 0]} style={{margin:"10px 0", border:"1px solid #EEE"}}>
                <Col flex="100px"><Avatar onClick={() => setPreviewImg(`${process.env.REACT_APP_DATA_URL}/${item.img}`)} style={{ cursor: "pointer" }} size={100} shape="square" src={item.thumb ? `${process.env.REACT_APP_DATA_URL}/${item.thumb}` : null} /></Col>
                {(!item.status || item.status == 'done') && !item.response &&
                    <Col flex="auto">
                        <FormField type="text" name={`items.${i}.url`} placeholder="Banner url" __validate={composeValidators(rules.required, rules.is_url)} />
                        <Popconfirm title="Sure to delete?" onConfirm={() => props.onDeleteClick(item)}>
                            <IconButton icon="trash-alt" />
                        </Popconfirm>
                    </Col>
                }
            </Row>
        })}

        <Modal visible={previewImg!=false} title={"Preview"} footer={null}
            onCancel={() => setPreviewImg(false)}>
            {previewImg && <img alt={previewImg} style={{ width: '100%' }} src={`${previewImg}`} />}
        </Modal>


    </div>)

    // return <DevBlock obj={props.items} />
}


export const CategoriesForm = props => {
    const [loading, setLoading] = React.useState(false);

    let _fields = {...props.fields};
    if (_fields._id) {
        _fields.activation_date = _fields.activation_date ? moment(_fields.activation_date) : _fields.activation_date;
        _fields.deactivation_date = _fields.deactivation_date ? moment(_fields.deactivation_date) : _fields.deactivation_date;
        _fields.deleteable = _fields.deleteable=='yes';
    } else {
        _fields.status = "hidden";
        _fields.deleteable = "yes";
    }

    const [fields, setFields] = React.useState(_fields);
    // const defaultFields = { requirements: { type: "none" }, limit_one_per_customer: false, }

    const updateFields = (__fileds) => {
        // _fields.activation_date = _fields.activation_date ? moment(_fields.activation_date) : _fields.activation_date;
        // _fields.deactivation_date = _fields.deactivation_date ? moment(_fields.deactivation_date) : _fields.deactivation_date;
        setFields({
            ...__fileds,
            activation_date: __fileds.activation_date ? moment(__fileds.activation_date) : __fileds.activation_date,
            deactivation_date: __fileds.deactivation_date ? moment(__fileds.deactivation_date) : __fileds.deactivation_date
        })
        document.getElementById('BannersForm').dispatchEvent(new Event('reset', { cancelable: true }))
    } 

    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));


    const onSubmit = values => {
        const { onClose, editBanner, addBanner } = props;
        const _id = fields ? fields._id : false;
        const _setFields = setFields;

        setLoading(true);

        let filteredValues = {
            title: values.title,
            key: values.key,
            height: values.height,
            width: values.width,
            status: values.status,
            deleteable: values.deleteable ? 'yes' : 'no',
            notes: values.notes,
        };

        if (_id && values.items) {
            filteredValues = Object.assign(filteredValues, {
                            items: values.items ? values.items.map(row => ({ id: row.id, img: row.img, thumb: row.thumb, url: row.url })) : null,
                        },
            )
        }

       
        if (_id) {
            editBanner({ ...filteredValues, _id: _id }).then((e) => {
                setLoading(false)
                if (e.data.editBanner.error) {
                    let err = e.data.editBanner.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editBanner);
            }).catch(error => {
                setLoading(false)
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addBanner(filteredValues).then((e) => {
                setLoading(false);
                if (e.data.addBanner.error) {
                    let err = e.data.addBanner.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                updateFields({ ...e.data.addBanner });
            }).catch(error => {
                setLoading(false);
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    const onFileDeleteClicked = async (fileInfo) => {
        let img = fileInfo.img || fileInfo.srcUrl;
        let thumb = fileInfo.thumb || fileInfo.thumbUrl;
        let id = fileInfo.id || fileInfo.uid;
        let _id = fields._id;
        let callback = fileInfo.callback;

        setLoading(true);

        return props.removeBannerImg({ _id: fields._id, id, img, thumb }).then((e) => {
            setLoading(false)
            if (e.data.removeBannerImg.error) {
                message.error(e.data.removeBannerImg.error.message);
                return false;
            }

            if (!e.data.removeBannerImg || e.data.removeBannerImg.error) {
                if (!e.data.removeBannerImg) { message.error("No response"); }
                if (!e.data.removeBannerImg.error) { message.error(e.data.removeBannerImg.error.message); }
                return false;
            }

            if (callback) callback(e.data.removeBannerImg.items);

            return e.data.removeBannerImg;
        }).catch(error => {
            setLoading(false)
            console.log(error);
            return { error: { message: "Query Error" } };
        });

    }


    return (
        <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
            bodyStyle={{ padding: "0px" }}
            onClose={props.onClose}
            visible={props.showform}
            footer={<>
                <span></span>
                <Button loading={loading} type="primary" onClick={() => {
                    document.getElementById('BannersForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`${fields && fields._id ? fields.title : 'Add'} Banner`}
            // title={`${fields && fields._id ? 'Edit' : 'Add'} Banner`}
        >
            <FormComponent onSubmit={onSubmit} id='BannersForm' loading={loading} fields={fields}
                mutators={{
                    addItems: (newValueArray, state, tools) => {
                        tools.changeValue(state, 'items', () => newValueArray[0])
                    },
                    removeItems: (newValueArray, state, tools) => {
                        tools.changeValue(state, 'items', () => newValueArray[0])
                    },
                }}
                form_render={formProps => {
                    const { values } = formProps;

                    return (<>
                        <FormField type="select" name="status" label="Status" className={values.status == 'published' ? "active" : "inactive"} data={publishStatus} validate={rules.required} />
                        <div style={{fontWeight:"bold", padding:"0 10px"}}>{fields.notes}</div>

                        <div style={{ padding: '20px', backgroundColor: !values.edit_configs ? "#EEE" : "#FFF"}}>
                            <h1>Configurations <FormField type="switch" name="edit_configs" compact /></h1>
                            
                            <FormField disabled={!values.edit_configs} type="text" name="title" label="Title" placeholder="Banner title" validate={composeValidators(rules.required, rules.minChar(4))} />
                            <FormField disabled={!values.edit_configs} onChange={filterSlug} type="text" name="key" placeholder="Banner placeholder key" label="Key (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} />
                            <Row>
                                <Col flex="50%"><FormField disabled={!values.edit_configs} type="number" name="width" label="Width" placeholder="Width" /></Col>
                                <Col flex="auto"><FormField disabled={!values.edit_configs} type="number" name="height" label="Height" placeholder="Height" /></Col>
                            </Row>
                            <FormField type="switch" name="deleteable" label="Deleteable" disabled={!values.edit_configs} />
                            {values.edit_configs && 
                                <FormField type="textarea" name="notes" label="Notes" placeholder="Notes" rows={2} />
                            }
                        </div>

                        {fields._id && <div className="grid-block">
                            <Heading>Banner Pictures</Heading>
                            <div style={{ align: "center", textAlign: "center" }}>
                                <UploadField listType="picture-grid" limit={10}
                                    onUploadComplete={(args) => {
                                        formProps.form.mutators.addItems(args.banner.items);
                                    }}
                                    buttonLabel="Add image"
                                    name="img"
                                    data={{ fieldsJson: JSON.stringify({ _id: fields._id }), action: "banner.img" }}
                                    disableGalleryDisplay={true}
                                />

                                <CustomGalleryRender items={values.items} onDeleteClick={(args) => onFileDeleteClicked({ ...args, callback: formProps.form.mutators.removeItems })} />

                            </div>
                        </div>}

                    </>)
                }}
            />

        </Drawer>
    )
}

CategoriesForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editBanner: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addBanner: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteBanner: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(REMOVE_IMG, {
        props: ({ mutate }) => ({
            removeBannerImg: (args) => mutate({
                variables: { input:{...args} }
            }),
        })
    }),
)(CategoriesForm);

export default WithApollo;