import React from 'react'
// import PropTypes from 'prop-types';

import HooksList from '../components/hooks_list';
import HooksForm from '../components/hooks_form';


export const WebhooksList = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <HooksList 
            onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddRecord={toggleDrawer}
        />

        {visible && <HooksForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </>)
}
export default WebhooksList;