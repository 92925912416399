import React, { Component, useRef } from 'react'
// import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Barcode, Button, PrintComponent } from 'Common/components';
import { Row, Col } from 'antd';
import { connect } from "react-redux";

const styles = {
    zoneCircleWrapper:{
        textAlign: "center", display: 'flex', justifyContent: 'center'
    },
    zoneCircle:{
        display: 'flex', flexDirection: "column",
        backgroundColor: '#000', borderRadius: 50, minWidth: 70, height: 70, color: '#FFF',
        alignItems:'center', justifyContent:'center', padding:'20px',
    },
    center:{
        display:'flex',
        justifyContent:'center',
    },
}

class PrintTest extends Component {
    // componentRef = React.createRef();
    doPrint;

    handlePrint = handler => {
        console.log("handlePrint()", handler);
        this.doPrint = handler;
    }

    render() {
        return (
            <div>
                {/* <Wrapper handlePrint={this.handlePrint} /> */}
                <PrintComponent handlePrint={this.handlePrint} contents={<div>
                    <div style={{border:'1px solid #EEE', width:'88mm'}}>
                        <div>Delivery Address:</div>
                        <div style={{fontWeight:'bold'}}>House 79, Pasha Street, Gulshane Tavi, Lahore</div>

                        <div style={styles.zoneCircleWrapper}><div style={styles.zoneCircle}>
                            <div style={{fontSize:20}}>3</div>
                            <div style={{ }}>Zone</div>
                        </div></div>

                        <div style={{backgroundColor:'#000', color:'#FFF', padding:'10px', margin:'10px 0'}}>
                            <Row align='middle'>
                                <Col flex='70px'>Customer:</Col>
                                <Col style={{fontSize:'20px', fontWeight:'bold'}}>Faheem Hashmi</Col>
                            </Row>
                            <Row align='middle'>
                                <Col flex='70px' style={{lineHeight:1}}>Delivery Slot:</Col>
                                <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>13:00 - 15:00</Col>
                            </Row>
                        </div>

                        <div style={{ ...styles.center }}>
                            <Barcode value={`0123-456-789`} height={50} />
                        </div>
                        <div style={{ ...styles.center }}>MON-2308-1-5382</div>
                        <Row align='middle'>
                            <Col flex='auto' style={{ fontWeight: 'bold' }}>{this.props.settings.currency} 3570</Col>
                            <Col style={{fontSize:'16px'}}>1 of 2</Col>
                        </Row>
                    </div>

                </div>} />

                <Button onClick={()=>{
                    this.doPrint()
                    // console.log("this.componentRef: ", this.componentRef.current)
                }}>Test Print</Button>
            </div>
        )
    }
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    // props.settings.currency
    return {
        settings: grocer_storeadmin.settings,
        store: grocer_storeadmin.store,
        rights: grocer_storeadmin.rights
    };
}
export default connect(mapStateToProps)(PrintTest);

