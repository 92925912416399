import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'

addModule({
  permission_required:'users-manage',
    path: `${ROOT}`, component: Home, icon: 'user', title: "Users", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
