import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import GoogleMapReact from 'google-map-react';
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'


const CustomMarker = ({ onClick, text }) => {
    return <div 
        alt={text}
        onClick={onClick}
        style={{ 
        backgroundColor:"#0000FF", 
        position:"absolute",
        top: "50%", left: "50%",
        transform: "translate(-50%, -50%)",
        width: "18px",
        height: "18px",
        border: "2px solid #fff",
        borderRadius: "100%",
        userSelect: "none",
        cursor: onClick ? 'pointer' : 'default',
        }}
    ></div>
}

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
    }

    componentDidMount({ map, mapsApi } = this.props) {
        // this.mapsApi.places.SearchBox
        this.searchBox = new mapsApi.places.SearchBox(this.searchInput);
        this.searchBox.addListener('places_changed', this.onPlacesChanged);
        this.searchBox.bindTo('bounds', map);
    }

    componentWillUnmount({ mapsApi } = this.props) {
        mapsApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlacesChanged = ({ map, addplace } = this.props) => {
        console.log("onPlacesChanged()")

        const selected = this.searchBox.getPlaces();
        
        const { 0: place } = selected;
        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        addplace(selected);
        this.searchInput.blur();
    };

    clearSearchBox() {
        this.searchInput.value = '';
    }

    render() {
        return (<div style={{ top:0, left:0, position:"absolute", zIndex:999, alignItems:"center", justifyContent:"center", width:"400px", padding:"20px" }}>
            <input
                style={{ width:"100%", border:"1px solid #EEE", padding:"5px", borderRadius:"5px" }}
                ref={(ref) => {
                    this.searchInput = ref;
                }}
                type="text"
                onFocus={this.clearSearchBox}
                placeholder="Search a location"
            />
        </div>);
    }
}


class GMapComp extends Component {
    static defaultProps = {
        center: {
            lat: -15.38773584583862,
            lng: 28.323153652297975,
        },
        zoom: 13
    };
    state = { 
        polygon: false, 
        deliveryZones: null, 
        loading_deliveryZones:false,
        polygons: null,
        mapApiLoaded: false,
        places: [],
    };
    map;
    mapsApi;
    mapRef;

    
    constructor(props){
        super(props);
        this.onGoogleApiLoaded = this.onGoogleApiLoaded.bind(this);
        this.isMarkerIntoZone = this.isMarkerIntoZone.bind(this);
        this.addPlace = this.addPlace.bind(this);
    }

    onGoogleApiLoaded = ({ map, maps, ref }) => {
        this.map = map;
        this.mapsApi = maps;
        this.mapRef = ref;
        this.setState({ mapApiLoaded: true })
        // console.log("this.mapsApi: ", this.mapsApi.places.SearchBox)

        this.createShapes(); // create shapes if splied in props
        this.createMarkers();
        this.createPolyline()

        // if (this.props.showDeliveryZones) this.showDeliveryZones()
    }

    createShapes(_shapes=null) {
        if (!_shapes && (!this.props.shapes || this.props.shapes.length<1)) return;

        const shapesArray = _shapes || this.props.shapes;

        const polygons = shapesArray.map(shape=>{
            // console.log("shape: ", shape)
            const theShape = new this.mapsApi.Polygon({
                paths: shape.paths,

                fillColor: shape.fillColor || "green", // #FF0000
                fillOpacity: shape.fillOpacity || 0.35,
                
                strokeColor: shape.strokeColor || 'black', // "#61dafb", // FF0000
                strokeOpacity: shape.strokeOpacity || 0.3,
                strokeWeight: shape.strokeWeight || 2,
                
                draggable: shape.draggable || false,
                editable: shape.editable || false,
                clickable: shape.clickable || true,

                zIndex: shape.zIndex || 1,
                map: this.map,
            });

            // if (this.props.onZoneMatch) this.props.onZoneMatch(this.isMarkerIntoZone(marker, { ...shape, poly: theShape }))
            return { ...shape, poly:theShape };
        })

        // console.log("this.props.markers: ", this.props.markers)

        this.setState({ polygons }, ()=>{
            if (this.props.onZoneMatch){
                var isInBounds = false;
    
                const markersArray = this.props.markers;
                if (markersArray){
                    markersArray.forEach(marker => {
                        isInBounds = this.isMarkerIntoZone(marker);
                        if (isInBounds) this.props.onZoneMatch({ ...isInBounds, marker});
                    });
                }
            }
        });

        
    }

    isMarkerIntoZone(marker, poly=false){
        // console.log("marker: ", marker.geo_point)
        if (!marker || !marker.geo_point) return;
        if (!this.mapsApi.geometry){
            alert('Geometry not found')
            return;
        }
        
        const { polygons } = this.state;
        const coords = marker.geo_point.coordinates;
        const point = new this.mapsApi.LatLng(coords[0], coords[1]);

        // const { lat, latitudeDelta, lng, longitudeDelta } = marker.geo_point.coordinates;
        // const point = new this.mapsApi.LatLng(lat, lng);

        if (poly) return this.mapsApi.geometry.poly.containsLocation( point, poly )

        var isInBounds = false;
        if (polygons && polygons.length>0){
            polygons.forEach(item=>{
                let _isInBounds = this.mapsApi.geometry.poly.containsLocation(
                    point,
                    item.poly
                )
                if (_isInBounds) isInBounds = item;
            })
        }

        return isInBounds;

    }

    createMarkers(){
        if ((!this.props.markers || this.props.markers.length < 1)) return;

        const markersArray = this.props.markers;
        const isMarkerIntoZone = this.isMarkerIntoZone;

        markersArray.forEach(marker => {
            // console.log("marker: ", marker);

            const thisMarker = new this.mapsApi.Marker({
                position: marker.position,
                map: this.map,
                title: marker.title || undefined,
                zIndex: marker.zIndex || 11,
                info: new this.mapsApi.InfoWindow({
                    // content: `<div>
                    //     <p><b>${marker.label}: </b> ${marker.full_address}, ${marker.city}</p>
                    //     <b>This location is inside zone ('null')</b>
                    // </div>`
                }),
            });
            thisMarker.setClickable(true);

            if (!this.props.skipInfo){
                this.mapsApi.event.addListener(thisMarker, 'click', function () {
                    let isInBounds = isMarkerIntoZone(marker);
    
                    thisMarker.info.setContent(`<div>
                        <p><b>${marker.label}: </b> ${marker.full_address || ""}, ${marker.city || ""}</p>
                        <b>This location is inside zone (${isInBounds ? isInBounds.title : 'null'})</b>
                    </div>`)
                    // if (isInBounds){ }
                    thisMarker.info.open(this.map, thisMarker);
                });
            }


        });
    }

    createPolyline(){
        if (!this.props.encodedPolyline) return null;

        if (!this?.maps?.geometry?.encoding?.decodePath){
            console.log(__error("decodePath not found"))
            return null;
        }

        let decoded = this.mapsApi.geometry.encoding.decodePath(this.props.encodedPolyline)

        new this.mapsApi.Polyline({ 
            path:decoded,
            map: this.map,
            geodesic: true,
            strokeColor: "#0000FF",
            strokeOpacity: 0.7,
            strokeWeight: 2,
        })

    }

    // async showDeliveryZones(){
    //     this.setState({ deliveryZones:null })
    //     const filter = JSON.stringify({ category: 'delivery-zones', status:'published' });

    //     const resutls = await this.props.client.query({ query: GEO_ZONES, variables: { filter } }).then(e => {
    //         if (e.errors) return { error:{ message:"Query Error!", details:e } }
    //         return e.data.geoZones;
    //     }).catch(err => {
    //         return { error:{ message:"Geo Zone Error", details:err } }
    //     })

    //     this.setState({ loading: false })
    //     if (resutls.error){
    //         console.log(__error("Query Error! "), resutls);
    //         message.error(resutls.error.message);
    //         return;
    //     }

    //     if (this.props.onZoneLoad) this.props.onZoneLoad(resutls);
    //     const deliveryZones = resutls.map(o => ({
    //         paths: o.polygon.coordinates[0].map(o => ({ lat: o[0], lng: o[1] })),
    //         // paths: o.polygon.coordinates,
    //         title: o.title,
    //     }))
    //     this.createShapes(deliveryZones);
    //     this.setState({ loading_deliveryZones: false, deliveryZones: resutls });
    // }

    _onBoundsChange = (center, zoom /* , bounds, marginBounds */) => {
        // console.log("center: ", center)
        if (this.props.onCenterChange) this.props.onCenterChange(center);
        // this.props.onZoomChange(zoom);
    }

    addPlace = (place) => {
        // console.log("place[0]: ", place[0])
        this.setState({ places: place });
        if (this.props.onAddressChanged) this.props.onAddressChanged(place[0].formatted_address)

        place.forEach(_place => {
            return new this.mapsApi.Marker({
                position: _place.geometry.location,
                map: this.map,
                title: _place.name,
                optimized: true,
                // icon: <CustomMarker />,
                //zIndex: marker.zIndex || 11,
                // info: new this.mapsApi.InfoWindow({
                //     // content: `<div>
                //     //     <p><b>${marker.label}: </b> ${marker.full_address}, ${marker.city}</p>
                //     //     <b>This location is inside zone ('null')</b>
                //     // </div>`
                // }),
            });
        });

    };

    render() {
        const { mapApiLoaded, places } = this.state;

        return (<div style={{ width: '100%', position: "relative", display: "flex", flex: 1, border: "1px solid #EEE" }}>
            {(mapApiLoaded && this.props.showSearch) && <SearchBox map={this.map} mapsApi={this.mapsApi} addplace={this.addPlace} />}

            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY, //GOOGLE_API_KEY,
                    libraries: ['drawing', 'geometry', 'places'], // 'DrawingManager'],
                }}
                options={{
                    // editable: true,
                    // draggable: true,
                    // drawingControl:true,
                    // panControl: false,
                    // mapTypeControl: false,
                    // drawingMode: google.maps.drawing.OverlayType.MARKER,
                    // drawingControlOptions: {
                    //     position: google.maps.ControlPosition.TOP_CENTER,
                    //     drawingModes: [
                    //         google.maps.drawing.OverlayType.MARKER,
                    //         google.maps.drawing.OverlayType.CIRCLE,
                    //         google.maps.drawing.OverlayType.POLYGON,
                    //         google.maps.drawing.OverlayType.POLYLINE,
                    //         google.maps.drawing.OverlayType.RECTANGLE,
                    //     ],
                    // },
                }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
                onGoogleApiLoaded={this.onGoogleApiLoaded}
                onBoundsChange={this._onBoundsChange}
            >
                {/* {places.map((place) => (
                    <CustomMarker
                        key={place.id}
                        text={place.name}
                        lat={place.geometry.location.lat}
                        lng={place.geometry.location.lng}
                    />
                ))} */}
            </GoogleMapReact>
        </div>)
    }
}
GMapComp.propTypes = {
    encodedPolyline: PropTypes.string,
    markers: PropTypes.array,
    shapes: PropTypes.array,
    showDeliveryZones: PropTypes.bool,
    onZoneLoad: PropTypes.func,
    onZoneMatch: PropTypes.func,
    showSearch: PropTypes.bool,
}

export const GMap = withApollo(GMapComp)