import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { __error } from 'Common/scripts/consoleHelper'
import { Alert, Col, Row, Space } from 'antd';
import { StoreAvatar, IconButton, Icon, PageHeader, StoreStatusTag, DevBlock } from 'Common/components';
import StoreForm from './store_form'

const env = process.env;

const StoreProfile = ({ store, updateStoreData }) => {
    const [mode, setMode] = useState(false);

    const toggleEditMode = (_mode = null) => {
        if (mode == _mode) return setMode(null);
        setMode(_mode);
    }

    if (!store) return <Alert type="error" message="Store not found!" showIcon />

    return (<>
        <PageHeader title={<>Store Settings
            {mode ?
                <IconButton onClick={() => toggleEditMode()} icon="times" /> :
                <IconButton onClick={() => toggleEditMode('edit-store-profile')} icon="pen" />
            }
        </>} />

        {!mode && 
            <Row gutter={[10]} style={{ flexWrap:"nowrap" }}>
                <Col><StoreAvatar {...store} onUpdate={console.log} /></Col>
                <Col flex="auto">
                    <Space direction='vertical'>
                        <div>
                            <div>{store.title} <a href={`${env.REACT_APP_HOST_URL}/store/${store.slug}`} target="_blank"><Icon icon="external-link-alt" /></a></div>
                            <div><StoreStatusTag store={store} style={{margin:"0"}} /></div>
                        </div>
                        <div><b>Availability:</b> {store?.availability_days?.toString().replaceAll(",", ", ").toUpperCase()}</div>
                        <div><b>Order Alerts Email:</b> {store?.order_alerts_email || "Not configured"}</div>
                        <div>
                            <div style={{ fontWeight:"bold" }}>About Store</div>
                            {store.description}
                        </div>
                    </Space>
                </Col>
            </Row>
        }
        
        {mode =='edit-store-profile' && <div style={{width:"100%"}}>
            <StoreForm initialValues={store} onUpdate={(v) => {
                updateStoreData(v)
                toggleEditMode();
            }} />
        </div>}
    </>)
}
StoreProfile.propTypes = {
    store: PropTypes.object.isRequired,
}


export default StoreProfile;