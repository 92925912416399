import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { Icon, Button, SelectStaff } from 'Common/components'
import { dateToUtc, formToFilter } from 'Common/scripts/Functions'
import { connect } from "react-redux";
import { generalStatus, staffTypes } from 'configs/constants';
// import { verifyRole, isSuperAdmin } from 'Common/scripts/Security';

const StaffFilter = props => {
    const onSubmit = values => {
        let extra_filter = {}
        if (values.created_at_range){
            extra_filter = {
                created_at_range: [
                    dateToUtc(values.created_at_range[0], { tz: props.default_timezone }), 
                    dateToUtc(values.created_at_range[1]), { tz: props.default_timezone }],
            }
        }

        let filter = formToFilter(values, { tz: props.default_timezone })
        props.onSearch({ ...filter, ...extra_filter });
    }

    // const _staffTypes = staffTypes.filter(o => {
    //     if(props.user.store_id){
    //         return (o._id == "picker" || o._id == "till-dispatcher" || o._id == "store-supervisor");
    //     }
    //     return (o._id == "driver");
    // })

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='StaffFilterForm' hideDevBlock={true} fields={props.defaultValue} style={{ padding: 0 }}>
            <Row className="pagination-filter-row">
                <Col flex="auto" className="filters-column">
                    <FormFieldGroup compact style={{ padding: 0, margin: 0 }}>
                        <FormField type="text" prefix={<Icon icon="user" color="#CCC" />} name="search.keywords" placeholder="keyword to search..." label="Keyword search" width="200px" compact allowClear />
                        {/* <FormField type="select" name="type" label="Type" data={_staffTypes} width="200px" compact allowClear size="small" /> */}
                        <SelectStaff name="type" label="Type" width="200px" compact allowClear />
                        <FormField type="select" data={generalStatus} name="status" placeholder="Status" label="Status" width="200px" compact allowClear />
                    </FormFieldGroup>
                </Col>
                <Col className="go-column">
                    <Button className="send_button" htmlType="submit"><Icon icon="search" /></Button>
                </Col>
            </Row>
        </FormComponent>
    </div>)

}
StaffFilter.propTypes = {
    onSearch: PropTypes.func.isRequired,
    exclude: PropTypes.array,
    accTypes: PropTypes.array,
    // default_timezone: PropType.string.isRequired,
}
// export default StaffFilter;

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { 
        default_timezone: grocer_storeadmin.default_timezone,
        user: { ...grocer_storeadmin.user, rights: grocer_storeadmin.rights }
    };
}
export default connect(mapStateToProps)(StaffFilter);
