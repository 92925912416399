import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
// import Api from '...'
import { REQUEST_BRANCH, receiveBranch } from './actions';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* branch(action) {
   try {
      // do API call/
      // const user = yield call(Api.fetchUser, action.payload.userId);
      yield put( receiveBranch('Branch from redux-saga') );
      // yield put({type: "RECEIVE_BRANCH", text: 'Branch from redux-saga'});
   } catch (e) {
      yield put( receiveBranch("Catch branch from redux-saga") );
   }
}

// function* login(action) {
//    try {
//       // do API call/
//       // const user = yield call(Api.fetchUser, action.payload.userId);
//       yield put( loginUser('Branch from redux-saga') );
//       // yield put({type: "RECEIVE_BRANCH", text: 'Branch from redux-saga'});
//    } catch (e) {
//       yield put( loginUser("Catch branch from redux-saga") );
//    }
// }

// /*
//   Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
//   Allows concurrent fetches of user.
// */
// function* mySaga() {
//   yield takeEvery("USER_FETCH_REQUESTED", fetchUser);
// }

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
export default function* mySaga() {
  yield takeLatest("REQUEST_BRANCH", branch);
  // yield takeLatest("LOGIN_USER", login);
}
