import React from 'react'
import PropTypes from 'prop-types';
import { Layout, Menu, Col, Row, Popover, Space, Alert, Image } from 'antd';
import { Icon, Button, ToggleButton, Avatar, StoreStatusTag } from 'Common/components';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

const { Header } = Layout;


const avatarPop = ({ user }) => {
    let avatarPath = user?.avatar_thumb ? process.env.REACT_APP_DATA_URL + '/' + user.avatar_thumb : false;

    return (
        <div style={{ width: '300px' }}>
            <Row style={{ flexWrap: "nowrap" }}>
                <Col flex="90px"><Avatar src={avatarPath} size={80}><span style={{ textTransform: "capitalize" }}>{user.fname[0]}</span></Avatar></Col>
                <Col flex="auto">
                    <Space size="small" direction="vertical" style={{ width: "100%" }}>
                        <Link to="/profile">Profile</Link>
                        <Link to="/bug-report">Bug Report</Link>
                        <hr color="#EEE" />
                        <Button type="danger" href="/logout" block>Logout</Button>
                    </Space>
                </Col>
            </Row>

            {/* <Button href='/logout'>Log out</Button> */}
            {/* <Button href="/logout">Logout</Button> */}
        </div>
    )
}

const TopMenu = ({ menu, style }) => {
    if (!menu) return <div />;

    // console.log("window.location.pathname: ", window.location.pathname)
    // let activeMenuItem = menu.findIndex(o => o.path == window.location.pathname);

    let activeMenuItem = menu.find(o => {
        // console.log(`${o.path} == ${window.location.pathname}`)
        // return String(window.location.pathname).indexOf(o.path) > -1;
        return o.path == window.location.pathname;
    });
    // if (!activeMenuItem) activeMenuItem = 0;


    return (
        // <Menu theme="dark" mode="horizontal" selectedKeys={[`${activeMenuItem}`]} defaultSelectedKeys={[`${activeMenuItem}`]} style={style || {}}>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[`${activeMenuItem ? activeMenuItem.path :''}`]} style={style || {}}>
            {menu.filter(o => (!o.hide)).map((item, i) => {
                return <Menu.Item key={item.path}><Link to={item.menuPath || item.path}>{item.title}</Link></Menu.Item>
            })}
        </Menu>

    )
}

const LayoutHeaderComp = ({ menu, user, token, rights, store, settings }) => {
    let avatarPath = user?.avatar_thumb ? process.env.REACT_APP_DATA_URL + '/' + user.avatar_thumb : false;

    if (!user || !user._id || !token || !rights) {
        window.location.href = "/logout";
        return (<Alert message="User not found" description={<>
            <Button href='/logout'>Log out</Button>
        </>} type="error" showIcon />)
    }

    return (<>
        <Header className="site-layout-header">
            <Row gutter={[10]} align="middle">
                {store && <Col>
                    <Row gutter={[10]} align="middle">
                        <Col><div className='store-title'>
                            <div className='title'>{store.title}</div>
                            <div className='barcode'>{store.barcode}</div>
                        </div></Col>
                        <Col><StoreStatusTag store={store} /></Col>
                    </Row>
                </Col>}

                <Col flex="auto">
                    <TopMenu menu={menu} style={{ whiteSpace: "normal", flex: 'auto' }} />
                </Col>

                <Col style={{ borderRight: "1px solid #999", color:"#999", marginRight:"20px", paddingRight:"20px" }}>Timezone: {settings.default_timezone}</Col>
                <Col>
                    <div className="column-right">
                        <Popover content={() => avatarPop({ user })} title={<div style={{ lineHeight:"12px" }}>{user.fname} <div style={{ fontSize:"9px" }}>{user.type}</div></div>} trigger="click" placement="bottomRight">
                            <div style={{ marginRight: '10px', textTransform: "capitalize" }}>{user.fname} <Avatar src={avatarPath || undefined}><span style={{ textTransform: "capitalize" }}>{user.fname[0]}</span></Avatar></div>
                        </Popover>
                    </div>
                </Col>
            </Row>
        </Header>
    </>)

    return (<>
        <Header className="site-layout-header">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {store && <Row gutter={[20]} align="middle">
                    <Col>
                        <span className='store-title'>
                            <div className='title'>{store.title}</div>
                            <div className='barcode'>{store.barcode}</div>
                        </span>
                    </Col>
                    <Col><StoreStatusTag store={store} /></Col>
                </Row>}

                <TopMenu menu={menu} style={{ whiteSpace: "normal", flex: 'auto' }} />

                <div className="column-right">
                    <Popover content={() => avatarPop({ user })} title={user.fname} trigger="click" placement="bottomRight">
                        <div style={{ marginRight: '10px', textTransform: "capitalize" }}>{user.fname} <Avatar src={avatarPath || undefined}><span style={{ textTransform: "capitalize" }}>{user.fname[0]}</span></Avatar></div>
                    </Popover>
                </div>
            </div>
        </Header>
    </>)
}
LayoutHeaderComp.propTypes = {
    // collapsed: PropTypes.bool,
    onToggleClick: PropTypes.func,
    menu: PropTypes.array,
    // topMenu: PropTypes.array,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updateSettings: (payload) => dispatch(updateSettings(payload)),
// })
const mapStateToProps = ({ grocer_storeadmin }) => {
    // console.log("grocer_storeadmin: ", grocer_storeadmin)
    return {
        settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        token: grocer_storeadmin.token,
        rights: grocer_storeadmin.rights,
        store: grocer_storeadmin.store,
    };
    // return ({ user: (state.user && state.user.user) || {} });
}

export const LayoutHeader = connect(mapStateToProps)(LayoutHeaderComp);
// export default WithRedux;


