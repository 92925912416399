import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'antd';
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { Button, Icon, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { daysList, storeStatus } from 'configs';
import { updateStore } from 'Store/grocer_storeadmin.actions';


const RECORD_EDIT = loader('src/graphqls/stores/editStore.graphql');
const RECORD = loader('src/graphqls/stores/store.graphql');

const StoreForm = ({ initialValues, onUpdate, editStore, loading, updateStore }) => {
    const [error, setError] = useState(null)

    const onSubmit = async (values) => {
        setError(null);

        const input = {
            _id: Number(initialValues._id),
            title: values.title,
            status: values.status,
            order_alerts_email: values.order_alerts_email,
            inactive_reason: values.inactive_reason,
            availability_days: values.availability_days,
            description: values.description,
        }

        const results = await editStore(input).then((e) => (e.data.editStore)).catch(error => {
            console.log(__error("Query Error: "), error);
            return { error: { message:"Query Error" }}
        });

        if (results.error){
            setError(results.error.message)
            return false;
        }
        if (onUpdate) onUpdate(results);
        updateStore(results)
    }

    if (loading) return <Loader loading={true} />

    return (<>
        {error && <Alert message={error} type="error" showIcon />}

        <FormComponent onSubmit={onSubmit} id='StoreEditForm' fields={{ ...initialValues }} style={{ padding:"0", margin:"-10px"}}
            form_render={formProps => {
                const { values, valid, dirty, submitting } = formProps;

                return (<>
                    <p>Store url: <a href={`${process.env.REACT_APP_HOST_URL}/store/${values.slug}`} target="_blank">{`${process.env.REACT_APP_HOST_URL}/store/${values.slug}`} <Icon icon="external-link-alt" /></a></p>
                    <Row>
                        <Col flex="auto"><FormField type="text" name="title" label="Store Title" validate={rules.required} /></Col>
                        <Col flex="150px"><FormField type="select" name="status" label="Status" data={storeStatus} validate={rules.required} /></Col>
                    </Row>
                    
                    {values.status=='inactive' && <FormField type="text" name="inactive_reason" label="Store Inactive reason" validate={rules.required} />}
                    <FormField data={daysList} mode="multiple" type="select" name="availability_days" label="Availability" placeholder="sar, sun, mon" validate={rules.required} />
                    <FormField type="text" name="order_alerts_email" label="Order Alerts Email" />
                    <FormField type="textarea" name="description" label="About Your Sore" validate={rules.required} />

                    <div style={{padding:"20px"}}><Button type="primary" htmlType="submit" loading={submitting} disabled={!dirty || !valid}>Save</Button></div>
                </>)
            }}
        />
    </>)

}
StoreForm.propTypes = {
    initialValues: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editStore: (args) => mutate({
                variables: { input: { ...args } },
                fetchPolicy: "no-cache"
            }),
        })
    }),

)(StoreForm);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ initialValues }) => {
            return { 
                variables: { id: initialValues._id },
                fetchPolicy: "no-cache"
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, store, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, initialValues:store, queryErrors: error, }
        },
    }),
)(WithApollo);

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateStore: (payload) => dispatch(updateStore(payload)),
})
// const mapStateToProps = ({ grocer_storeadmin }) => {
//     return { user: grocer_storeadmin.user };
// }
const WithRedux = connect(null, mapDispatchToProps)(EditWrapper);

export default WithRedux;
