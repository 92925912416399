import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
// import { Form, Field } from 'react-final-form'
import { Row, Col, message, Modal, Alert } from 'antd';
import { Drawer, Heading, Button, Icon, UserSelection, BasketSelection, DevBlock, IconButton, Avatar, Loader } from 'Common/components'
import { Select, FormField, FormComponent, FormFieldGroup, composeValidators, rules } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { notFoundReasons } from 'configs/constants';


export const Status_In_Review = ({ onCancel, onSubmit, order }) => {
    const [initialState, set_setinitialState] = useState(null);

    useEffect(() => {
        // , pickup_data: order.items
        let _initialState = {
            _id: order._id,
            status: "in-review",
            pickup_data: {
                products: order.items.map(o=>{
                    return {
                        product_id: o.product_id,
                        title: o.title,
                        cart_qty: o.cart_qty,
                        attributes: o.attributes,
                        picture_thumb: o.picture_thumb,
                        qty_picked: 0, // o.qty_picked,
                        decline_notes: undefined, // (o.not_found) ? o.decline_notes : undefined,
                    }
                })
            }
        }
        set_setinitialState(_initialState);
    }, [order])


    const on_submit = async (values) => {
        // console.log("on_submit()", values)
        let error = false;
        if (values.pickup_data.products.find(o => (o.not_found && o?.decline_notes?.length < 1))) error = "Please select a decline reason for unavailable item";
        if (!error && values.pickup_data.products.find(o => (o.qty_picked == 0 && !o.not_found))) error = `Product (${values.pickup_data.products.find(o => (o.qty_picked == 0 && !o.not_found)).title}) qty can not be 0`

        if (error){
            alert(error);
            return false;
        }

        const input = {
            _id: Number(order._id),
            status: "in-review",
            pickup_data: {
                products: values.pickup_data.products.map(prod => ({
                    product_id: Number(prod.product_id),
                    title: prod.title,
                    qty_picked: prod.not_found ? 0 : prod.qty_picked,
                    decline_notes: (prod.not_found) ? prod.decline_notes : undefined,
                })),
                baskets: values.pickup_data.picker_baskets.map(bas => ({
                    _id: Number(bas),
                    // title: "",
                    // barcode: "",
                })),
                pickup_by: { 
                    _id: Number(values.pickup_data.pickup_by._id), 
                    // name: values.pickup_data.pickup_by.name
                },
                // pickup_at
                // updated_at
            }
        }

        return onSubmit(input);
    }

    if (!initialState) return <Loader loading={true}>Loading initial state...</Loader>

    return (<>
        <Alert message={<>Are you sure to change order status to <b>In-Review</b>?</>}
            description={<>
                <p>Following actions will be performed</p>
                <ol>
                    <li>Update Pickup data</li>
                    <li>Remove any Dispatch data</li>
                    <li>Update order status</li>
                </ol>
            </>}
            type="warning" showIcon />

        <FormComponent onSubmit={on_submit} id='OrderStatusUpdateForm' fields={initialState} debug={true}
            mutators={{
                ...arrayMutators,
                addItems: (newValueArray, state, tools) => {
                    let product_id = newValueArray[0].product_id;
                    let qty_picked = newValueArray[0].qty_picked;
                    let cart_qty = newValueArray[0].cart_qty;

                    if (qty_picked >= cart_qty) qty_picked = cart_qty;
                    else qty_picked += 1;

                    let pickup_data = state.formState.values.pickup_data;
                    let products = state.formState.values.pickup_data.products.slice();

                    products = products.map(o=>{
                        if (o.product_id == product_id) return { ...o, qty_picked }
                        return o;
                    })
                    Object.assign(pickup_data, { products })

                    tools.changeValue(state, 'pickup_data', () => pickup_data)
                },
                removeItems: (newValueArray, state, tools) => {
                    let product_id = newValueArray[0].product_id;
                    let qty_picked = newValueArray[0].qty_picked;
                    let cart_qty = newValueArray[0].cart_qty;

                    if (qty_picked <= 0) qty_picked = cart_qty;
                    else qty_picked -= 1;

                    let pickup_data = state.formState.values.pickup_data;
                    let products = state.formState.values.pickup_data.products.slice();

                    products = products.map(o => {
                        if (o.product_id == product_id) return { ...o, qty_picked }
                        return o;
                    })
                    Object.assign(pickup_data, { products })

                    tools.changeValue(state, 'pickup_data', () => pickup_data)
                },
            }}

            form_render={formProps => {
                const { values, submitting, form, valid, dirty, errors } = formProps;

                return (<>
                    <div>
                        <UserSelection 
                            style={{ maxWidth: "400px" }}
                            name="pickup_data.pickup_by._id" preload={true}
                            filter={{ type: 'picker' }}
                            label="Select the picker" placeholder="Search picker..."
                            validate={composeValidators(rules.required)}
                        />
                    </div>

                    <div>
                        <BasketSelection style={{ maxWidth: "400px" }}
                            mode="multiple" name="pickup_data.picker_baskets"
                            preload={{ category: 'pickup', order_id: null }}
                            filter={{ category: 'pickup', order_id: null }}
                            label="Select pickup baskets" placeholder="Search baskets..." validate={composeValidators(rules.required)} />
                    </div>

                    <FieldArray name="pickup_data.products">
                        {({ fields }) => {

                            return (<div>
                                {fields.map((name, index) => {
                                    let thisField = fields.value[index];
                                    console.log("thisField: ", thisField)

                                    return (<div key={index} style={{ border: "1px solid #EEE", padding: "10px", marginBottom: "5px", borderRadius: "5px" }}>
                                        <Row align="middle" gutter={[20]}>
                                            <Col style={{ width: "50px", fontSize: "32px" }}>{thisField.cart_qty}</Col>
                                            <Col>X</Col>
                                            <Col>
                                                <Row align="middle">
                                                    <Col><FormField type="switch" name={`${name}.not_found`} /></Col>
                                                    <Col style={{ fontWeight: "bold", color: "red" }}>Not Found!</Col>
                                                </Row>
                                                {!thisField.not_found ? <>
                                                    <Row align="middle">
                                                        <Col><IconButton onClick={() => form.mutators.removeItems(thisField)} icon="minus" size="large" color="green" /></Col>
                                                        <Col><FormField type="number" name={`${name}.qty_picked`} style={{ width: "80px" }} max={thisField.cart_qty} min={0} size="large"
                                                            validate={composeValidators(rules.required, rules.isEqual(thisField.cart_qty))}
                                                        /></Col>
                                                        <Col><IconButton onClick={() => form.mutators.addItems(thisField)} icon="plus" size="large" color="green" /></Col>
                                                    </Row>
                                                </> : <>
                                                    {notFoundReasons.map((o, i) => {
                                                        return <FormField type="checkbox" name={`${name}.decline_notes[]`} value={o.title} key={i}>{o.title}</FormField>
                                                    })}
                                                </>}
                                            </Col>
                                            <Col style={{ margin: "0 10px" }}>
                                                <Avatar size={80} shape="square" src={thisField.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${thisField.picture_thumb}` : null} icon={<Icon icon="image" />} />
                                            </Col>
                                            <Col flex="auto">
                                                <div>{thisField.title}</div>
                                                {thisField?.attributes?.map((attr, i) => (<span key={i}>{i > 0 ? ',' : ''} {attr.value}{attr.code}</span>))}
                                            </Col>
                                        </Row>

                                    </div>)

                                })}
                            </div>)
                        }}
                    </FieldArray>

                    <div style={{ height: '30px' }} />
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white-outline" disabled={submitting}>Cancel</Button></Col>
                        <Col><Button loading={submitting} disabled={!valid || !dirty} htmlType="submit">Update Order Status</Button></Col>
                    </Row>

                </>)
            }}
        />

    </>)
}
Status_In_Review.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}