import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { Alert } from 'antd';


export const Status_Picking = ({ onCancel, onSubmit, order }) => {

    return (<>
        <Alert message="Action not allowed!" type="error" showIcon />
    </>)
}
Status_Picking.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
