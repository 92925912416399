import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
// import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'

const USERS = loader('src/graphqls/product/products.graphql');


const ProductSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    var lastFetchId = 0;
    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;

        setState({ kw: value, loading: true })

        let filter = JSON.stringify({ search: { keywords: value } });

        props.client.query({ query: USERS, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                setState({ kw: value, loading: false })
                return;
            }

            if (e.data.products) setList(e.data.products.map(o => ({ _id: o._id, title: o.title, raw: o })))
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: products : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (
        <>
            <FormField validate={props.validate}
                type={props.mode || 'select'}
                name={props.name}
                label={props.label}
                data={list}
                placeholder="Search Products"
                addNull={false}
                // keyMap="value=>text"
                // onChange={handelChange}
                // defaultValue={props.defaultValue && props.defaultValue._id}
                inputProps={{
                    onSelect: props.onSelect || undefined, // console.log,
                    onDeselect: props.onDeselect || undefined, // console.log,
                    loading: state.loading,
                    // mode: props.mode || undefined, // tags, multiple
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: state.loading ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchData,
                    // onChange:handelChange,
                }}
            />
        </>
    )
}
ProductSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
}

export const ProductSearch = withApollo(ProductSearchComp);
export default ProductSearch;
