import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Heading, Icon, IconButton, GMap, DevBlock } from 'Common/components'
import { Row, Col, Drawer, message, Divider, Steps, Button } from 'antd';
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import icon_location_red from 'assets/icon_location_red.png';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import { sleep } from 'Common/scripts/Functions';

const UPDATE_ORDER_ADDRESS = loader('src/graphqls/orders/updateOrderAddress.graphql');


class EditOrderLocation extends Component {
    constructor(props){
        super(props);
        this.onCenterChange = this.onCenterChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = { isVisible: true, center: false, loading:false, busy:false }
    }

    onClose(){
        this.setState({ isVisible: !this.state.isVisible })
        if (this.props.onClose) this.props.onClose();
    }

    onCenterChange(_center){
        console.log("onCenterChange()");
        this.setState({ center: _center })
    }

    async onSubmit(values){
        // console.log("onSubmit()", values)
        const { order } = this.props;

        const data = {
            order_id: order._id,
            address:{
                _id: values._id,
                user_id: order.customer._id,
                full_address: values.full_address,
                // city: String
                geo_point: {
                    type: "Point",
                    coordinates: this.state.center,
                }
                // delivery_instructions: values.delivery_instructions,
            },
            // verified:'no'
        }


        this.setState({ busy: true })
        const result = await this.props.updateOrderAddress(data).then(r=>{
            if (r.data.updateOrderAddress.error){
                message.error(r.data.updateOrderAddress.error.message);
                console.log(__error("ERROR: "), r)
            }
            if (r.data.updateOrderAddress.success?.message) message.success(r.data.updateOrderAddress.success.message);
            return r.data.updateOrderAddress;
        }).catch(err=>{
            message.error("Unable to proceed your request at the moment.");
            console.log(__error("ERROR: "), err)
            return { error:{message:"Unable to complete your request at the moment."}}
        })

        if (result.error){
            this.setState({ busy: false })
            return;
        }

        if (this.props.onAddressUpdate){
            sleep(500).then(r=>{
                this.props.onAddressUpdate();
            })
        }

    }


    render(){
        const { isVisible, loading, busy } = this.state;
        const { order } = this.props;
        const shipping_address = JSON.parse(order.shipping_address);
        // console.log("order: ", order)

        // console.log("order.shipping_address: ", shipping_address.full_address)


        return(<>
            <Drawer
                closable={true}
                destroyOnClose={true}
                footer={<Row>
                    <Col flex="auto"><Button onClick={()=>this.onClose()} theme="white">Cancel</Button></Col>
                    <Col>
                        {/* <Button type="primary" disabled={!this.state.center}>Save</Button> */}
                        <Button loading={loading || busy} type="primary" disabled={!this.state.center} onClick={() => {
                            document.getElementById('CustomerForm').dispatchEvent(new Event('submit', { cancelable: true }))
                        }}>Save</Button>
                    </Col>
                </Row>}
                height="100%" width="100%"
                placement="top"
                title={`Edit Address :: ${order.customer.fname}`}
                visible={isVisible}
                onClose={() => this.onClose()}
            >
                <FormComponent onSubmit={this.onSubmit} id='CustomerForm' loading={loading} 
                fields={{ full_address: shipping_address.full_address, _id: shipping_address._id }} hideDevBlock={true}
                    // mutators={{
                    //     addItems: (newValueArray, state, tools) => {
                    //         tools.changeValue(state, 'items', () => newValueArray[0])
                    //     },
                    //     removeItems: (newValueArray, state, tools) => {
                    //         tools.changeValue(state, 'items', () => newValueArray[0])
                    //     },
                    // }}
                    form_render={formProps => {
                        const { values } = formProps;

                        return (<>
                            <FormField type="text" name="full_address" label={"Full Address"} placeholder="Full Address" validate={composeValidators(rules.required, rules.minChar(10))} />

                            {/* <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                                <div><b>{order.customer.fname}</b></div>
                                <div>{order.customer.email}</div>
                                <div>{order.customer.phone}</div>
                            </div> */}

                            {!shipping_address && !shipping_address?.geo_point?.coordinates && <>
                                <div style={{ padding: "0 10px", marginTop: "-10px", color: "red" }}>
                                    <b>Invalid Shipping address</b>
                                    <div>{order.shipping_address}</div>
                                </div>
                            </>}

                        </>)
                    }}
                />

                {shipping_address && shipping_address?.geo_point?.coordinates && <>
                    <div style={{ width: '100%', height: 'calc(100% - 100px)', position: "relative" }}>
                        <GMap
                            zoom={11}
                            center={{ lat: shipping_address.geo_point.coordinates[0], lng: shipping_address.geo_point.coordinates[1] }}
                            showDeliveryZones
                            onCenterChange={this.onCenterChange}
                        >
                            <div
                                style={{ backgroundColor: 'red' }}
                                lat={shipping_address.geo_point.coordinates[0]}
                                lng={shipping_address.geo_point.coordinates[1]}
                            >Children</div>
                        </GMap>
                        <div style={{ position: "absolute", top: "50%", zIndex: 100, width: "100%", borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }} />
                        <div style={{ position: "absolute", top: 0, left: "50%", zIndex: 100, height: "100%", borderRight: "1px solid rgba(255, 255, 255, 0.2)" }} />
                        <div style={{ position: "absolute", top: "50%", marginTop: "-40px", left: "50%", marginLeft: "-15px", zIndex: 100, }}><img src={icon_location_red} alt="You" width="30px" /></div>
                    </div>
                </>}


            </Drawer>
        </>)
    }
}

EditOrderLocation.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    onAddressUpdate: PropTypes.func,
}


const WithApollo = compose(
    graphql(UPDATE_ORDER_ADDRESS, {
        props: ({ mutate }) => ({
            updateOrderAddress: (args) => mutate({
                variables: { input: args }
            }),
        })
    }),
)(EditOrderLocation);


export default WithApollo;