import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, Heading, Loader } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { checkRights } from 'Common/scripts/Security';
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper'
import SlotsList from '../components/slots_list';
import SlotsForm from '../components/slots_form';
import { withApollo } from 'react-apollo';
import { Alert } from 'antd';
import { sleep } from 'Common/scripts/Functions';

const LIST_DATA = loader('src/graphqls/delivery_slot/deliverySlots.graphql');

const SlotsManager = props => {
    const [{ visible, fields }, setState] = useState({ visible: false, fields: null });
    const [called, setCalled] = useState(false)
    const [error, setError] = useState(false)
    const [busy, setBusy] = useState(false)
    const [slotsArray, setSlotsArray] = useState(null)
    
    useEffect(() => {
        if (called) return;
        fetchData();
    }, [])

    const fetchData = async () => {
        setError(false);
        setBusy(true);
        setCalled(true)

        const resutls = await props.client.query({ 
                query: LIST_DATA,
                fetchPolicy: "no-cache",
            }).then(r => (r?.data?.deliverySlots))
            .catch(err => {
                console.log(__error("Error: "), err);
                return { error: { message: "Unable to fetch slots" } }
            })
        setBusy(false);


        if (!resutls || resutls.error) {
            setError((resutls && resutls?.error?.message) || "Invalid response");
            return false;
        }

        setSlotsArray(resutls);
    }
    const onUpdate = () => {
        fetchData()
        if (visible) toggleDrawer();
    };
    
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args && args.fields || null });
    };

    if (error) return <Alert type='error' message={error} showIcon />
    if (busy) return <Loader loading={true} center />

    return (<>
        <ContentArea>
            {checkRights(props.rights, 'dslots-manage') && <h4>Slots Manager
                <div className="buttons"><Button onClick={toggleDrawer} size="small">Add Delivery Slot</Button></div>
            </h4>}

            {!slotsArray && <Alert type='info' message="No records found!" showIcon />}

            {slotsArray && <SlotsList slotsArray={slotsArray}
                onUpdate={onUpdate}
                onEditRecord={(item) => toggleDrawer({ fields: item })}
                onAddRecord={(args) => toggleDrawer({ newRecordFor: args })}
            />}

        </ContentArea>

        {visible && <SlotsForm onClose={toggleDrawer} onUpdate={onUpdate} showform={visible} fields={fields} />}

    </>)
}

const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(withApollo(SlotsManager));
// export default SlotsManager;
