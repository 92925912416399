import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Popconfirm, Divider } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button } from 'Common/components'
import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { publishStatus, genders, storeStatus, daysList } from 'configs';

const RECORD_ADD = loader('src/graphqls/stores/addStore.graphql');
const RECORD_EDIT = loader('src/graphqls/stores/editStore.graphql');
const RECORD_DELETE = loader('src/graphqls/stores/deleteStore.graphql');

const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));
const defaultFilter = { 
    user: { gender: "male" },
    store: { status: 'online' }
}

const StoreAddForm = ({ onSubmit, loading, busy }) => {
    return (<>
        <FormComponent onSubmit={onSubmit} id='StoresForm' loading={loading || busy} fields={defaultFilter} debug={true}
            form_render={({ values, submitting, valid }) => {

                return (<>
                    <Divider>Owner Account Info</Divider>
                    <Row>
                        <Col flex="auto" />
                        <Col flex="200px"><FormField type="select" name="user.gender" label="Gender" data={genders} validate={rules.required} /></Col>
                    </Row>
                    <Row>
                        <Col flex="auto"><FormField type="text" name="user.fname" label="First Name" placeholder="First Name" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                        <Col flex="50%"><FormField type="text" name="user.lname" label="Last Name" placeholder="Last Name" /></Col>
                    </Row>
                    <Row>
                        <Col flex="50%"><FormField type="text" name="user.email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} /></Col>
                        <Col flex="auto"><FormField type="text" name="user.phone" label="Phone" placeholder="Mobile number" validate={composeValidators(rules.required, rules.minChar(4))} /></Col>
                    </Row>
                    <Row>
                        <Col flex="auto">
                            <FormField type="password" name="user.password" label="Password" />
                        </Col>
                        <Col flex="50%">
                            <FormField type="password" name="user.confirm_pwd" label="Confirm Password" />
                        </Col>
                    </Row>


                    <Divider>Store Info</Divider>
                    <Row>
                        <Col flex="auto"><FormField type="text" name="store.title" label="Store Title" placeholder="Store title" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                        <Col flex="150px"><FormField type="select" name="store.status" label="Status" className={values?.store?.status == 'online' ? "active" : "inactive"} data={storeStatus} validate={rules.required} /></Col>
                    </Row>
                    {values.store.status =='inactive' && 
                        <FormField type="text" name="store.inactive_reason" label="Inactivity reason" placeholder="Please provide store inactivity reason" validate={composeValidators(rules.required)} />
                    }
                    <FormField onChange={filterSlug} type="text" name="store.slug" label="Store Slug (no space)" placeholder="unique name for your store" validate={composeValidators(rules.required, rules.nospace, rules.minChar(3))} />
                    <FormField data={daysList} mode="multiple" type="select" name="availability_days" label="Availability" placeholder="sar, sun, monday" validate={composeValidators(rules.required)} />
                    <FormField type="textarea" name="store.description" label="Description" placeholder="store description" validate={composeValidators(rules.required)} />

                </>)
            }}
        />
    </>)
}

const StoreEditForm = ({ onSubmit, loading, busy, fields }) => {
    return (<>
        <FormComponent onSubmit={onSubmit} id='StoresForm' loading={loading || busy} fields={fields} debug={true}
            form_render={formProps => {
                const { values, submitting } = formProps;

                return (<>
                    <Divider>Store Info</Divider>
                    <FormField type="select" name="status" label="Status" className={values.status == 'online' ? "active" : "inactive"} data={storeStatus} validate={rules.required} />
                    {values.status == 'inactive' &&
                        <FormField type="text" name="inactive_reason" label="Inactivity reason" placeholder="Please provide store inactivity reason" validate={composeValidators(rules.required)} />
                    }
                    <FormField type="text" name="title" label="Title" placeholder="Store title" validate={composeValidators(rules.required, rules.minChar(3))} />
                    <FormField onChange={filterSlug} type="text" name="slug" label="Slug (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(3))} />
                    <FormField data={daysList} mode="multiple" type="select" name="availability_days" label="Availability" placeholder="sar, sun, monday" validate={composeValidators(rules.required)} />
                    <FormField type="textarea" name="description" label="Description" placeholder="store description" validate={composeValidators(rules.required)} />

                </>)
            }}
        />
    </>)
}

const StoreFormWrapper = props => {
    let fields = { ...defaultFilter, ...props.fields };
    const [busy, setBusy] = React.useState(false);
    const { loading } = props;

    const addStore = async (values) => {
        const { onClose, addStore } = props;
        const _id = props.fields ? props.fields._id : false;

        if (values.user.password && (values.user.password != values.user.confirm_pwd)) {
            message.error("Password missmatch");
            return { error: { message:"Password missmatch" }};
        }


        let filteredValues = {
            title: values.store.title,
            slug: values.store.slug,
            description: values.store.description,
            status: values.store.status,
            inactive_reason: values.store.inactive_reason,
            availability_days: values.availability_days,

            gender: values.user.gender,
            lname: String(values.user.lname).trim(),
            fname: String(values.user.fname).trim(),
            email: String(values.user.email).trim(),
            phone: String(values.user.phone).trim(),
            password: String(values.user.password).trim(),
        };


        setBusy(true);
       
        var resutls = await addStore(filteredValues).then((e) => {
            return e.data.addStore;
        }).catch(error => {
            console.log(__error("Query Error"), error);
            return { error: { message: "Query Error" } }
        });

        setBusy(false);

        if (resutls.error) message.error(resutls.error.message);
        else onClose(resutls);

        return resutls;

    }

    const updateStore = async (values) => {
        const { onClose, editStore } = props;
        const _id = props.fields ? props.fields._id : false;

        let filteredValues = {
            title: values.title,
            slug: values.slug,
            description: values.description,
            // category: values.category,
            // country: values.country,
            // barcode: values.barcode,
            status: values.status,
            inactive_reason: values.inactive_reason,
            availability_days: values.availability_days,
            // logo: values.logo,
            // logo_thumb: values.logo_thumb,
            _id: props.fields._id
        };

        setBusy(true);

        var resutls = await editStore({ ...filteredValues, _id: _id }).then((e) => {
            return e.data.editStore;
        }).catch(error => {
            console.log(__error("Query Error"), error);
            return { error: { message: "Query Error" } }
        });
       
        setBusy(false);

        if (resutls.error) message.error(resutls.error.message);
        else onClose(resutls);

    }

    return (
        <Drawer width={600} destroyOnClose maskClosable={false} placement="right"
            bodyStyle={{ padding: "10px" }}
            onClose={props.onClose}
            visible={props.showform}
            footer={<>
                <span></span>
                <Button loading={loading || busy} type="primary" onClick={() => {
                    document.getElementById('StoresForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`${props.fields && props.fields._id ? 'Edit' : 'Add'} Store`}
        ><>
            {(fields && fields._id) 
                ? <><StoreEditForm onSubmit={updateStore} loading={loading} busy={busy} fields={fields} /></> 
                : <><StoreAddForm onSubmit={addStore} loading={loading} busy={busy} fields={fields} /></>
            }
        </></Drawer>
    )
}

StoreFormWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editStore: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addStore: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteOffer: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(StoreFormWrapper);


export default withApollo(WithApollo);