import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { Icon, Button, Loader, IconButton, UserSelection, ZoneSelection } from 'Common/components'
import { dateToUtc } from 'Common/scripts/Functions'
import moment from 'moment';
import _ from 'lodash'
// import debounce from 'lodash/debounce';
import { walletStatus } from 'configs/constants';

export const prepareFilterForMongo = (values, { tz }) => {
    let filter = Object.assign({}, { ...values });

    if (filter.created_at) {
        let d = moment(filter.created_at).set({ 'hour': 0, 'minute': 0, 'second': 0 });
        Object.assign(filter, { created_at: dateToUtc(d, { tz }) })
    }

    for (let a in values) {
        let key = a;

        // remove null
        if (values[a] == null || values[a] == 'null') {
            delete filter[a];
            continue;
        }

        // remove empty array
        if (_.isArray(values[a]) && values[a].length < 1) {
            delete filter[a];
            continue;
        }

        // convert * to .
        if (a.indexOf("*") > -1) {
            key = a.replaceAll("*", ".");
            Object.assign(filter, { [key]: values[a] })
            delete filter[a];
        }
    }

    if (filter.status) Object.assign(filter, { status: { $in: filter.status } })
    if (filter['user._id']){
        let uid = filter['user._id'].map(o=>Number(o));
        Object.assign(filter, { ['user._id']: { $in: uid } })
        // Object.assign(filter, { ['user._id']: { $in: filter['user._id'] } })
    }

    return filter;

}

export const WalletFilter = ({ onSearch, defaultValue, loading }) => {
    const onSubmit = values => {
        const filter = prepareFilterForMongo(values);
        // console.log("filter: ", filter);
        onSearch(filter);
    }

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='CustomerFilterForm' hideDevBlock={true} fields={defaultValue} style={{ padding: 0 }} loading={loading}>
            <Row style={{ flexWrap:'nowrap' }}>
                <Col flex="auto" style={{padding:'5px 10px'}}>
                    <Row>
                        <Col flex="250px"><FormField type="select" mode="multiple" data={walletStatus} name="status" placeholder="Status" label="Status" compact allowClear /></Col>
                        <Col flex="200px"><FormField type="text" name="order_serial" placeholder="Order Serial / ID" label="Order Serial / ID" allowClear compact /></Col>
                        {/* <Col flex="250px"><UserSelection filter={{ type:'driver' }} mode="multiple" name="user*_id" label="Driver" placeholder="Search driver" allowClear compact /></Col> */}
                        {/* <Col flex="250px"><ZoneSelection 
                            filter={{ category: 'delivery-zones' }} 
                            name="zone_id" label="Delivery Zone" preload allowClear compact />
                        </Col> */}
                        <Col flex="150px"><FormField type="date" name="created_at" placeholder="Date" label="Date" allowClear compact /></Col>
                    </Row>
                </Col>
                <Col className='go-column' style={{ display:'flex', alignItems:"center"}}>
                    <Button className="send_button" loading={loading} htmlType="submit"><Icon icon="search" /></Button>
                </Col>
            </Row>
        </FormComponent>
    </div>)

}

WalletFilter.propTypes = {
    onSearch: PropTypes.func.isRequired,
    defaultValue: PropTypes.object,
    loading: PropTypes.bool,
}
export default WalletFilter;
