import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table as _Table } from 'antd';
// import { useHistory, useParams, useLocation } from "react-router-dom";
import DevBlock from './DevBlock';


/*****************
    <Table
        columns={columnsArray}
        dataSource={userRolesQuery ? userRolesQuery.edges : null}
        title={() => <span>Header</span>}
        footer={() => 'Footer'}
        rowKey={record => record._id}
        expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
        }}
    />
 */
export const Table = props => {
    let _props = { ...props };
    delete _props.debug;
    delete _props.pageSize;
    delete _props.total;
    delete _props.current;
    delete _props.onChange;

    if (!props.rowKey) _props = Object.assign(_props, { rowKey: (data) => data._id });

    const onPaginationChange = (page, pageSize) => {
        let new_pagination = { ...pagination, page, pageSize };
        setPagination(new_pagination)

        props.onChange(new_pagination)
    }

    const [pagination, setPagination] = useState({
        disabled: false,
        hideOnSinglePage: false,
        // pageSizeOptions: [2, 10, 20, 30, 40, 50],
        current: props?.pagination?.current || 1, 
        defaultCurrent: props?.pagination?.defaultCurrent || props?.pagination?.pageSize || 1, 
        pageSize: props?.pagination?.pageSize || 25, 
        defaultPageSize: props?.pagination?.defaultPageSize || props?.pagination?.current, 
        total: props?.pagination?.total || 0,

        // defaultPageSize: 2,
        position: ["bottomCenter"],
        // total: props?.pagination?.total || 0,
        onChange: onPaginationChange
    });

    useEffect(() => {
        setPagination({ ...pagination, current: props?.pagination?.current || 1 });
    //   return () => { effect };
    }, [props?.pagination?.current])

    if (!props.rowClassName && !props.disableStatusHighight) {
        _props = Object.assign(_props, {
            rowClassName: (record => {
                return record.status == 'disabled' ? 'disabled-table-row' : "";
            })
        })
    }

    return (<>
        <_Table 
            {..._props}
            columns={props.columns}
            // dataSource={props.dataSource} 
            pagination={props.pagination === false ? false : { ...pagination, total: props?.pagination?.total || 0, showSizeChanger:false }} 
            bordered={props.bordered===false ? false : true}
            // rowKey={(v)=>{
            //     console.log("v._id: ", v._id)
            //     return v._id;
            // }}
        />

        {props.debug && <>
            <DevBlock title="dataSource" obj={props.dataSource} />
        </>}
    </>)

}


Table.propTypes = {
    dataSource: PropTypes.array,
    debug: PropTypes.bool,
    bordered: PropTypes.bool,
    updateHistory: PropTypes.bool,
    scroll: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    loading: PropTypes.bool,
    showHeader: PropTypes.bool,
    rowClassName: PropTypes.func,
    disableStatusHighight: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    // pagination: PropTypes.object,
    rowKey: PropTypes.func,
    title: PropTypes.func,
    footer: PropTypes.func,
    pagination: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.object
            ]),
    // prop: PropTypes.type.isRequired
}
