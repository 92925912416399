import React, { Component } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock, ListHeader, DeleteButton } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';
import PaymentMethodForm from './payment_methods_form';

const LIST_DATA = loader('src/graphqls/payment_methods/paymentMethodsQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/payment_methods/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/payment_methods/deletePaymentMethod.graphql');

class ListComp extends Component {
    state = {
        busy: false,
        pagination: { current: 1 },
        filter: {},
        showform: false, editNode:null,
    };

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;
        if (!nextProps.paymentMethodsQuery) return;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "paymentMethodsUpdated",
                _subscriptionType: "array",
                _queryName: "paymentMethodsQuery",
                _typename: "PaymentMethods",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deletePaymentMethod(id).then(r => {
            if (r && r.data && r.data.deletePaymentMethod && r.data.deletePaymentMethod.error) {
                message.error(r.data.deletePaymentMethod.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        { title: 'ID', dataIndex: '_id' },
        { title: 'Title', dataIndex: 'title', render: (text, record) => {
                return (<div className='show-menu-on-hover'>
                    <Row gutter={[5]} style={{ flexWrap: "nowrap" }}>
                        <Col style={{ lineHeight: "14px" }}>{text}</Col>

                        <Col className='hidden-menu'>
                            <IconButton icon="pen" onClick={() => this.toggleDrawer(record)} />
                            <DeleteButton onClick={() => this.handleDelete(record?._id)} />
                        </Col>
                    </Row>
                </div>)
            }
        },
        { title: 'Key', dataIndex: 'ref_key' },
        { title: 'Status', dataIndex: 'status' },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        const input = {
            pageSize: pagination.pageSize,
            current: pagination.page,
        }

        this.doSearch(input)
    };

    async doSearch(args = {}) {
        let limit = args?.pageSize || defaultPageSize;
        let current = args?.current || 1;
        let skip = limit * (current - 1);

        let filter = args.filter || this.state.filter;

        let input = {
            limit, skip,
            filter: JSON.stringify(filter),
        }

        let newState = { busy: true, filter, pagination: { current } }
        this.setState(newState)

        const resutls = await this.props.loadMoreRows(input).then(r => {
            return r?.data?.paymentMethodsQuery;
        }).catch(err => {
            console.log(__error("Query Error: "), err);
            return { error: { message: "Query Error" } }
        })

        this.setState({ busy: false });
        if (resutls.error) message.error(resutls.error.message);

        return;
    }

    onViewChnaged(filter) {
        this.doSearch({ filter });
    }

    toggleDrawer(_editNode=null){
        this.setState({ showform: !this.state.showform, editNode: _editNode })
    }

    render() {
        const { loading, paymentMethodsQuery, queryErrors } = this.props;
        const { pagination, busy, showform, editNode } = this.state;

        if (paymentMethodsQuery && paymentMethodsQuery.error) return <Alert message={paymentMethodsQuery.error.message} type="error" showIcon />

        return (<>
            <ContentArea>
                <Row>
                    <Col flex="auto">
                        <ListHeader title="Payment Methods" sub={<>{paymentMethodsQuery ? paymentMethodsQuery?.totalCount : "0"} records found</>} />
                    </Col>
                    <Col><Button onClick={() => this.toggleDrawer()}>Add Payment Method</Button></Col>
                </Row>
                

                <Table debug={false}
                    loading={loading} 
                    columns={this.columns}
                    dataSource={paymentMethodsQuery ? paymentMethodsQuery?.edges : null}
                    total={paymentMethodsQuery ? paymentMethodsQuery?.totalCount : 0}
                    pageSize={defaultPageSize}
                    current={pagination.current || 1}
                    rowClassName={(record => (record.status == 'disabled' ? 'disabled-table-row' : ""))}
                    onChange={this.handleTableChange}
                />
            </ContentArea>

            <PaymentMethodForm 
                onClose={() => this.toggleDrawer(null)} 
                showform={showform} 
                fields={editNode}
                onSuccess={() => this.toggleDrawer(null)} 
            />
        </>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({}) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, paymentMethodsQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = ({ limit, skip, filter, others }) => {

                let vars = { first: limit, after: skip, filter, others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.paymentMethodsQuery.totalCount
                    const newEdges = fetchMoreResult.paymentMethodsQuery.edges
                    const pageInfo = fetchMoreResult.paymentMethodsQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        paymentMethodsQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'paymentMethodsQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, paymentMethodsQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deletePaymentMethod: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

export default WithApollo;
