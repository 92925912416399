import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constatns'


// addReducers({ admins: reducers });
addModule(
    {
        permission_required:'wallet-access',
        path: `${ROOT}`, component: Home, icon: 'wallet', title: "Wallets", exact: 0, hideNav: 0,
        // subMenu: SubMenu()
    },
)
