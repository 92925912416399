import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";

import Sales from './containers/Sales'
import Transactions from './containers/Transactions'
import AccStatement from './containers/AccStatement'

import { ROOT } from './constants.js';

export const menuArray = props => ([
    { path: `${ROOT}`, menuPath: `${ROOT}`, exact: true, title: 'Sales', component: Sales },
    { path: `${ROOT}/acc_statement`, menuPath: `${ROOT}/acc_statement`, exact: false, title: 'Acc. Statement', component: AccStatement },
    // { path: `${ROOT}/transactions`, menuPath: `${ROOT}/transactions`, exact: false, title: 'Transactions', component: Transactions },
])

/**
 * AccountsHome
 */
export const AccountsHome = props => {
    let routs = menuArray();

    return (
        <LayoutWrapper topMenu={routs}>
            <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>
    )

}

// AccountsHome.propTypes = {
//   // login: PropTypes.object.isRequired,
// }

export default AccountsHome;
