/* eslint-disable react/no-typos, no-unused-vars */
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { __error, __hilight, __info } from './consoleHelper';


const updateQueryObject = ({ action, prev, node, pageInfo, _typename, _queryName }) => {
  /*
  totalCount: Int
  edges: []
  pageInfo: {
    endCursor: ID
    hasNextPage: Boolean
  }
  */
  if (!prev){
    alert("Missing previous data")
    console.log(__error("Missing previous data"));
    return false;
  }
  if (!_queryName){
    alert("Missing Query Name data")
    console.log(__error("Missing Query Name data"));
    return false;
  }
  if (!node){
    alert("Missing Node")
    console.log(__error("Missing Node"));
    return false;
  }

  var _edges = { $set: [] }
  var _totalCount = prev[_queryName]?.totalCount || 0;
  var _pageInfo = pageInfo || prev[_queryName]?.pageInfo || { 
    endCursor: 0, 
    hasNextPage: false, 
    __typename: "PageInfo", // _typename || prev[_queryName]?.pageInfo?.__typename 
  }
  var __typename = _typename || prev[_queryName]?.__typename;
  // var index = prev[_queryName]?.edges ? prev[_queryName]?.edges?.findIndex(item => (item._id == node._id)) : -1;
  var index = prev[_queryName]?.edges?.findIndex(item => (item._id == node._id));



  
  if (!action) console.log(__info("Missing action, making empty records"));

  if (action == 'CREATED'){
    _totalCount = _totalCount > 0 ? Number(_totalCount+1) : 0;
    // _edges = { $unshift: [node] };
    _edges = { $push: [node] };
  }

  if(action=="DELETED"){
    if (index<0){
      console.log(__info("Record not found to be deleted"));
      return prev;
    }

    _totalCount = _totalCount > 0 ? Number(_totalCount - 1) : 0;
    _edges = { $splice: [[index, 1]] };
  }

  if (action =="UPDATED"){
    if (index < 0) {
      console.log(__info("Record not found to be updated"));
      return prev;
    }

    let prev_edges = prev[_queryName].edges.slice();
      prev_edges.splice(index, 1, node);

    _edges = { $set: prev_edges };
  }


  return update(prev, {
    [_queryName]: {
      totalCount: { $set: _totalCount },
      edges: _edges, //: { $set: edges || prev[_queryName].edges || [] },
      pageInfo: { $set: { 
        endCursor: _pageInfo.endCursor, // (pageInfo && pageInfo.endCursor) || prev[_queryName]?.pageInfo?.endCursor || 0, 
        hasNextPage: _pageInfo.hasNextPage, // (pageInfo && pageInfo.hasNextPage) || prev[_queryName]?.pageInfo?.hasNextPage || false, 
        __typename: _pageInfo.__typename,
      } },
      __typename: { $set: __typename }
    }
  });

}

const updateQueryArray = ({ action, prev, node, pageInfo, _typename, _queryName }) => {
  if (!prev){
    alert("Missing previous data")
    console.log(__error("Missing previous data"));
    return false;
  }
  if (!_queryName){
    alert("Missing Query Name data")
    console.log(__error("Missing Query Name data"));
    return false;
  }
  if (!node){
    alert("Missing Node")
    console.log(__error("Missing Node"));
    return false;
  }

  var _nodes = prev[_queryName]?.slice() || [];
  var __typename = _typename || prev[_queryName]?.__typename;
  var index = prev[_queryName]?.findIndex(item => (item._id == node._id));

  if (!action) console.log(__info("Missing action, making empty records"));
 
  if (action == 'CREATED'){
    _nodes.push(node)
  }

  if(action=="DELETED"){
    if (index<0){
      console.log(__info("Record not found to be deleted"));
      return prev;
    }
    _nodes.splice(index, 1);
  }

  if (action =="UPDATED"){
    if (index < 0) {
      console.log(__info("Record not found to be updated"));
      return prev;
    }
    _nodes.splice(index, 1, node);
  }

  return {
    [_queryName]: _nodes
  }

}






/******* Usage ******
*params
_subscriptionType:
  * type = String
  * values = array, object, simple-array
_subscribeToMore:
  * type: Function
_document:
  * type: graphQL Query doc
_variables:
  * type: query variables object
_subscriptionName:
  * type: string
  * name of the subsscription channel
_queryName:
  * type: String
  * name of the query to update on sub-event
_typename:
  * type: String
  * __typename value
debug:
  * type: boolean


const { loading, events, loadMoreRows, subscribeToMore } = nextProps;
if(!this.subscription && !this.state.events){
  this.subscription = new SubscriptionHandler({
    _subscribeToMore: subscribeToMore,
    _document: QUERY_SUBSCRIPTION,
    _variables: {},
    _subscriptionName: "eventsUpdated",
    _subscriptionType: "simple-array",
    _queryName: "events",
    _typename: "Events",
    debug:true
  });
}
****************/
export class SubscriptionHandler{
  constructor(props){
    this.props = props;
    this.subscription = null;
    this.init();

    this.updateQuery = this.updateQuery.bind(this);
    this.updateArray = this.updateArray.bind(this);
    this.updateObject = this.updateObject.bind(this);
  }

  componentWillReceiveProps(nextProps){
    console.log("SubscriptionHandler() : ", nextProps);
  }

  verifyProps() {
    // console.log("verifyProps()", this.props);
    
    const { _subscribeToMore, _document, _variables, _subscriptionName, _queryName, _typename } = this.props;
    let error = false;
    if(!_subscribeToMore) error = "Missing subscribeToMore";
    if(!_document) error = "Missing query document";
    if(!_variables) error = "Missing variables";
    if(!_subscriptionName) error = "Missing _subscriptionName";
    if(!_queryName) error = "Missing _queryName";
    if(!_typename) error = "Missing __typename";

    if(error){
      console.log(__error(error));
      return false;
    }

    return true;
  }

  // ver: 9/11/2022
  updateArray(prev, { subscriptionData: { data } }) {
    // if (this.props.debug) console.log("SubscriptionHandler > updateArray: ", "\n", { data }, "\n", { prev });
    const { _subscriptionName, _queryName, _typename  } = this.props;
    
    /// validate query type/
    if(_.isArray(prev[_queryName])){
      console.log(__error("Query format is an array, must be an Object {totalCount, *edges, pageInfo}"));
      return;
    }

    let prev_data = prev; // {  totalCount: Int, edges: [ProductListData], pageInfo: PageInfo }
    const mutation = data[_subscriptionName].mutation;
    var new_node = data[_subscriptionName].node;

    if (!data[_subscriptionName]){
      console.log(__error("Missing sub data"), data)
      return prev;
    }

    // ############### Create Empty Data structure of records are empty

    if (prev_data[_queryName]?.edges?.length < 1 || !prev_data[_queryName]?.edges || !_.isArray(prev_data[_queryName]?.edges)){
      prev_data = updateQueryObject({ prev:prev_data, node: new_node, _queryName })
    }



    if (mutation == "CREATED") {
      console.log(__hilight(`updateArray > ${mutation} received`));
      prev_data = updateQueryObject({ action: mutation, prev:prev_data, node: new_node, _queryName })
    }

    if (mutation == "DELETED") {
      console.log(__hilight(`updateArray > ${mutation} received`));

      prev_data = updateQueryObject({ action: mutation, prev:prev_data, node: new_node, _queryName })
    }

    if (mutation == "UPDATED") {
      console.log(__hilight(`updateArray > ${mutation} received`));
      prev_data = updateQueryObject({ action: mutation, prev:prev_data, node: new_node, _queryName })
    }

    return prev_data;
  }

  // ver: 9/11/2022
  updateSimpleArray(prev, { subscriptionData: { data } }) {
    if (this.props.debug) console.log("SubscriptionHandler > updateSimpleArray: ", "\n", { data }, "\n", { prev });
    const { _subscriptionName, _queryName, _typename, _subscriptionType  } = this.props;
   

    /// validate query type/
    if (!_.isArray(prev[_queryName])) {
      console.log(__error("Query format is not array"));
      return;
    }

    let prev_data = prev; // {  totalCount: Int, edges: [ProductListData], pageInfo: PageInfo }
    const mutation = data[_subscriptionName].mutation;
    var new_node = data[_subscriptionName].node;

    if (!data[_subscriptionName]) {
      console.log(__error("Missing sub data"), data)
      return prev;
    }



    if (mutation == "CREATED") {
      console.log(__hilight(`updateSimpleArray > ${mutation} received`));
      return updateQueryArray({ action: mutation, prev: prev_data, node: new_node, _queryName })
    }

    if (mutation == "DELETED") {
      console.log(__hilight(`updateSimpleArray > ${mutation} received`));

      return updateQueryArray({ action: mutation, prev: prev_data, node: new_node, _queryName })
    }

    if (mutation == "UPDATED") {
      console.log(__hilight(`updateSimpleArray > ${mutation} received`));
      return updateQueryArray({ action: mutation, prev: prev_data, node: new_node, _queryName })
    }

    return prev_data;

    /*

    let prev_data = prev;
    const mutation = data[_subscriptionName].mutation;

    var new_node = data[_subscriptionName].node;
    var prev_nodes = prev[_queryName];
    if (!prev_nodes) {
      console.log(__error(`Query (${_queryName}) results not available`));
      return;
    }
    const index = prev_nodes.findIndex(item => item._id == new_node._id);
        
    /// validate query type/
    if(_subscriptionType=='simple-array'){ }
    if (!_.isArray(prev_nodes)){
      console.log(__error("Query format is not an Array"));
      return;
    }

    if(!data[_subscriptionName]) return prev;


    if(data[_subscriptionName].mutation=="CREATED"){
      console.log(__hilight("Subscription CREATED received"));

      let obj = { };
      obj[_queryName] = { $unshift: [new_node] };

      prev_data = update(prev, obj);
    }

    if(data[_subscriptionName].mutation=="DELETED"){
      console.log(__hilight("Subscription DELETE received"));
      if(index<0){
        console.log(__error('Matching index not found'));
        return prev;
      }

      if (index < 0) return prev;

      let obj = {};
      obj[_queryName] = { $splice: [[index, 1]] }

      prev_data = update(prev, obj);
    }

    if(data[_subscriptionName].mutation=="UPDATED"){
      console.log(__hilight("Subscription UPDATE received"));
      if(index<0){
        console.log(__error('Matching index not found'));
        return prev;
      }
      prev_data = prev_nodes.splice(index, 1, new_node);
      // prev_data = prev[_queryName].splice(index, 1, new_node);
    }

    return prev_data;
    */
  }

  updateObject(prev, { subscriptionData: { data } }) {
        console.log("updateObject()");
    
        const { _subscriptionName, _queryName, _typename  } = this.props;
        if(this.props.debug) console.log("SubscriptionHandler > updateObject: ", data);

        if(!data[_subscriptionName]) {
          console.log(__error(`No subscription data found in ${_subscriptionName}`));
          return prev
        };

    let prev_data = prev;
        const mutation = data[_subscriptionName].mutation;
        console.log(__hilight(`Subscription ${mutation} received`));
    const prev_nodes = prev[_queryName];
        const new_node = {...data[_subscriptionName].node, __typename: _typename};

        // verify _id match/
    if (prev_nodes._id != new_node._id){
      console.log(__error(`Subscription _id did not match: ${prev_nodes._id} == ${new_node._id}`));
            return false;
        }

        if(mutation=="CREATED"){
          console.log(__error("Single node subscription should not have mutation=CREATED, check subscription publish method."));
        }

        if(mutation=="DELETED"){
          prev_data = {};
        }

        if(mutation=="UPDATED"){
          let obj = {};
              obj[_queryName] = new_node
          return obj;
        }

    return prev_data;
  }

  // updateQuery(prev, { subscriptionData: {data} }){
  updateQuery(prev, subInfo) {
    if (this.props.debug) console.log("updateQuery(): \n prev:  ", prev, "\n subInfo:  ", subInfo);
    
    const { _subscriptionType  } = this.props;

    if(!subInfo.subscriptionData.data[this.props._subscriptionName]){
      console.log(__error(`Subscription Data missing for ${this.props._subscriptionName}`));
      return false;
    }

    if(_subscriptionType && _subscriptionType=='object'){
      return this.updateObject(prev, subInfo);
    }
    else if (_subscriptionType == 'simple-array') {
      return this.updateSimpleArray(prev, subInfo);
    }
    else {
      return this.updateArray(prev, subInfo);
    }

  }

  init(){
    const { _subscribeToMore, _document, _variables, _queryName, onSubscriptionReceive  } = this.props;

    if(!this.verifyProps()) return;

    // Subscribe or re-subscribe
    if (_.isNull(this.subscription))
    {
          console.log(__hilight(`Subscribing to ${_queryName}`));

          this.subscription = _subscribeToMore({
            document: _document,
            variables: _variables,
            fetchPolicy: "no-cache",
            updateQuery: (prev, subInfo) => {
              let results = this.updateQuery(prev, subInfo);
              if (onSubscriptionReceive) onSubscriptionReceive(results);
              return results
            },
            onError: (err) => console.log(__error(JSON.stringify(err, 0, 2))),
          });
    }

    return this.subscription;
  }//init

  unsubscribe(){
    this.subscription();
  }

}

SubscriptionHandler.propTypes = {
  onSubscriptionReceive:PropTypes.func,
  _subscribeToMore:PropTypes.func.isRequired,
  _document: PropTypes.object.isRequired,
  _variables: PropTypes.object.isRequired,
  _subscriptionName: PropTypes.string.isRequired,
  _queryName: PropTypes.string.isRequired,
  _typename: PropTypes.string.isRequired,
  _subscriptionType: PropTypes.string.isRequired, //  array, object, simple-array
  debug: PropTypes.boolean,
};


export default SubscriptionHandler;
