import React from 'react'
import PropTypes from 'prop-types';
import { Button, Heading } from 'Common/components'
import { ContentArea } from 'Layout_v1';
import VehiclesList from '../components/vehicles_list';
import VehicleForm from '../components/vehicle_form';


export const Vehicles = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args ? args.fields : null });
    };

    return (<ContentArea>
        <VehiclesList onAddRecord={() => toggleDrawer()} onEditRecord={(item) => toggleDrawer({ fields: item })} />
        {visible && <VehicleForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </ContentArea>)
}
export default Vehicles;