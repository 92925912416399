import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error, __hilight } from 'Common/scripts/consoleHelper'
import { message, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { FormField } from 'Common/components/Form'

const GET_BRANDS = loader('src/graphqls/brand/brands.graphql');

/*************
    <BrandSelector name="brand" label="Brand" addnull black_list_ids={(props.fields && props.fields._id) ? `${props.fields._id}` : null}
        data={[{
            _id: JSON.stringify({ _id: fields.brand._id, title: fields.brand._title }),
            title: fields.parent_cat_title
        }]}
    />
 */
const BrandSelectorComp = props => {
    const setNull = (arr) => {
        if (!props.addnull) return arr;

        let nullval = { _id: null, title: " " };
        if (arr) return arr.unshift(nullval);
        return [nullval];
    }

    const [{ fetching, data, value }, setState] = React.useState({ data: props.data || setNull(), value: [], fetching: false });

    var lastFetchId = 0;
    const _fetchBrand = value => {
        if (!value || value.length < 1) return;

        // console.log('fetching Product category: ', value);
        lastFetchId += 1;
        const fetchId = lastFetchId;
        setState({ data: setNull(), fetching: true, value });

        props.client.query({
            query: GET_BRANDS,
            variables: { filter: JSON.stringify({ search: {title:value} }) },
            fetchPolicy: "no-cache",
        })
            .then(e => {
                if (e.data.brands && (e.data.brands.error || e.errors)) {
                    console.log("ERROR: ", e);
                    message.error((e.data.brands.error && e.error.message) || (e.errors && e.errors[0].message));
                    setState({ data: setNull(), fetching: false, value: [] });
                    return;
                }
                
                if (fetchId !== lastFetchId) return;
                let data;
                if (!e.data.brands){
                    data = [];
                }else{
                    data = (props.dataHandlr) ? props.dataHandlr([...e.data.brands]) : [...e.data.brands];
                }
                setNull(data);
                setState({ data, fetching: false });
                
            }).catch(err => {
                console.log(__error("API Call ERROR: brands : "), err);
                message.error("Request ERROR");
                setState({ data: setNull(), fetching: false, value: [] });
            })

    };
    const fetchBrand = debounce(_fetchBrand, 800);

    const handleChange = value => {
        setState({ value, data: [], fetching: false });
    };

    return (
        <div>
            <FormField validate={props.validate}
                type="select" name={props.name} label={props.label || "Brand"}
                data={data}
                placeholder="Search Brand"
                // keyMap="value=>text"
                inputProps={{
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: fetching ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchBrand,
                    onChange: handleChange,
                    // onSelect: (e, e2) => console.log(e2), 
                    onSelect: props.onSelect, //key, e{children, key, label, style, value}
                }}
            />
        </div>
    )
}
BrandSelectorComp.propTypes = {
    dataHandlr: PropTypes.func,
    // showform: PropTypes.bool.isRequired,
    addnull: PropTypes.bool,
    data: PropTypes.array.isRequired,
    // black_list_ids: PropTypes.string, // comma saperated values
}

export const BrandSelector = withApollo(BrandSelectorComp);
export default BrandSelector;
