import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Button, DeleteButton, Heading, ListHeader, Table, IconButton, DevBlock, Loader } from 'Common/components'
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';
import { loader } from 'graphql.macro';
import moment from 'moment';
import VouchersForm from '../components/voucher_form';

import { ContentArea } from 'Layout_v1';
import { defaultPageSize } from 'configs';
import { __error, __yellow } from 'Common/scripts/consoleHelper';
import { Tag, message, Tabs, Space, Row, Col, Modal, Alert } from 'antd';
import { withApollo } from 'react-apollo';
import { sleep, utcToDate } from 'Common/scripts/Functions';
import VoucherFilter from '../components/VoucherFilter';

const LIST_DATA = loader('src/graphqls/discount_voucher/discountVoucherQuery.graphql');
const RECORD_DELETE = loader('src/graphqls/discount_voucher/delete.graphql');
const RECORD_MULTIPLE_DELETE = loader('src/graphqls/discount_voucher/deleteMultipleDiscountVoucher.graphql');

const defaultPagination = { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 };

const renderActivationDate = (text, record) => {
    if (!text) return null;
    return moment(text).format("DD-MM-YYYY")
}

class Vouchers extends Component{
    state = {
        drawerInfo: false,
        bulkDrawer: false,
        called: false,
        busy: false,
        error: false,
        queryFilter: {},
        pagination: { ...defaultPagination },
        discountVoucherQuery: null,
        selectedRowKeys: [],
        showContentModal: false,
    }

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.pageNavigation = this.pageNavigation.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.doBulkDelete = this.doBulkDelete.bind(this);
        this.exportSelectedCodes = this.exportSelectedCodes.bind(this);
    }

    componentDidMount() {
        if (this.state.called) return;
        this.fetchData({ pagination: defaultPagination })
    }


    async fetchData(args) {
        console.log(__yellow("fetchData()"))
        const { pagination, selectedCat } = this.state;
        const { client } = this.props;

        this.setState({ called: true, busy: true })

        let current = args && args?.pagination?.page || 1;
        let first = pagination.pageSize;
        let after = (current - 1) * pagination.pageSize;
        
        let filter = {}; // { "category.key": selectedCat.key };
        Object.assign(filter, args && args.filter || {})

        console.log("filter: ", filter)
        filter = JSON.stringify(filter)

        let resutls = await client.query({ query: LIST_DATA, variables: { first, after, filter } })
        .then((r) => (r?.data?.discountVoucherQuery))
        .catch(err => {
            console.log(__error("Query Error"), err)
            message.error("Query Error")
            return { error: { message: "Query Error" } }
        })

        window.scrollTo({
            top: 0,
            // left: 100,
            behavior: "smooth",
        });

        this.setState({
            busy: false,
            pagination: { ...pagination, current, total: resutls && resutls.totalCount || 0 },
            discountVoucherQuery: (!resutls || resutls.error) ? null : resutls,
        })
    }

    onSuccess() {
        this.setState({ drawerInfo: false })
        this.fetchData();
    }

    onSearch(queryFilter) {
        this.setState({ queryFilter }, ()=>{
            this.fetchData({ pagination: defaultPagination, filter: queryFilter })
        })
    }

    pageNavigation(pagination) {
        const { queryFilter } = this.state;
        this.fetchData({ pagination, filter: queryFilter })
    }

    onSelectChange(newSelectedRowKeys) {
        this.setState({ selectedRowKeys: newSelectedRowKeys })
    }

    async doBulkDelete() {
        const { selectedRowKeys, queryFilter } = this.state;

        this.setState({ busy: true })

        const results = await this.props.client.mutate({ mutation: RECORD_MULTIPLE_DELETE, variables: { ids: selectedRowKeys } })
            .then(({ data }) => data.deleteMultipleDiscountVoucher)
            .catch(err => {
                console.log(__error("Request Error:"), err)
                return { error: { message: "Request Error" } };
            })

        if (results.error) {
            this.setState({ busy: false })
            message.error(results.error.message);
            return false;
        }

        message.success(`${selectedRowKeys.length} Records deleted`)

        this.setState({ selectedRowKeys: [] }, () => {
            this.fetchData({ filter: queryFilter })
        })
    }

    async handleDelete(id) {
        this.setState({ busy: true })

        const results = await this.props.client.mutate({ mutation: RECORD_DELETE, variables: { id } }).then(({ data }) => data.deleteDiscountVoucher)
            .catch(err => {
                console.log(__error("Query Error:"), err)
                return { error: { message: "Query Error" } };
            })
        this.setState({ busy: false })

        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        this.fetchData();
        message.success("Record deleted")
    }

    exportSelectedCodes() {
        const { selectedRowKeys, discountVoucherQuery } = this.state;
        let resutls = discountVoucherQuery?.edges?.filter(o => {
            return selectedRowKeys.includes(o._id);
        })
        this.setState({ showContentModal: resutls });
    }




    render(){
        const { rights } = this.props;
        const { selectedRowKeys, busy, error, categoriesArray, selectedCat, discountVoucherQuery, pagination, bulkDrawer, showContentModal, drawerInfo } = this.state;
        
        const columns = [
            // { title: 'ID', dataIndex: '_id', width: '80px' },
            {
                title: 'Title', dataIndex: 'title', render: (text, rec) => (<>
                    <Row className='nowrap show-menu-on-hover'>
                        <Col>
                            {text}
                            {moment().isSameOrAfter(utcToDate(rec.deactivation_date)) && <Tag color="red">Expired</Tag>}
                        </Col>
                        <Col className='hidden-menu'>
                            {checkRights(rights, 'd-vouchers-manage') && <IconButton onClick={() => this.setState({ drawerInfo: rec })} icon="pen" />}
                            {checkRights(rights, 'd-vouchers-delete') && <DeleteButton onClick={() => this.handleDelete(rec._id)} />}
                        </Col>
                    </Row>

                    <div style={{ fontSize:"12px" }}>{rec.details}</div>
                </>)
            },
            { title: 'Loyalty Plan', dataIndex: 'loyalty_plan', width: '80px', align: "center", render: (__, rec) => (rec?.loyalty_plan ? "YES" : "") },
            { title: 'Category', dataIndex: 'category.val', width: '150px', align: "center", render: (__, rec) => (rec?.category?.val) },
            { title: 'Type', dataIndex: 'voucher_type.val', width: '100px', align: "center", render: (__, rec) => (rec?.voucher_type?.val) },
            { title: 'Payment Method', dataIndex: 'payment_method.title', width: '150px', align: "center", render: (__, rec) => (rec?.payment_method?.title) },
            { title: 'Code', dataIndex: 'code', width: '200px', align: "center" },
            { title: 'Used', dataIndex: 'used', width: '80px', align: "center" },
            { title: 'Discount', dataIndex: 'discount', render: (text, record) => `${record.discount_value} ${record.discount_type}`, width: '100px', align: "center" },
            { title: 'Activation', dataIndex: 'activation_date', render: renderActivationDate, width: 120, align: "center" },
            {
                title: 'Deactivation', dataIndex: 'deactivation_date', render: (text, record) => {
                    if (record.enable_end_date == 'no') return null;
                    if (!text) return null;
                    return (<>{utcToDate(text).format("DD-MM-YYYY")}</>)
                }, width: 120, align: "center"
            },
            { title: 'Status', dataIndex: 'status', width: '90px', align: "center" },
        ];

        const hasSelected = selectedRowKeys.length > 0;


        return (<>
            <ContentArea>

                <ListHeader title="Vouchers" sub={`${discountVoucherQuery && discountVoucherQuery.totalCount || "0"} records found`}
                    right={<Space>
                        {checkRights(rights, 'd-vouchers-manage') && <Button onClick={() => this.setState({ drawerInfo: true })} size="small">Add New Voucher</Button>}
                        {checkRights(rights, 'd-vouchers-bulk') && <Button onClick={() => this.setState({ bulkDrawer: true })} size="small">Create Bulk Vouchers</Button>}
                    </Space>} />

                {error && <Alert message={error} showIcon type='error' />}

                <VoucherFilter onSearch={this.onSearch} />

                <Table loading={busy} columns={columns}
                    dataSource={discountVoucherQuery ? discountVoucherQuery.edges : null}
                    pagination={pagination}
                    rowClassName={(record => (record.status == 'hidden' ? 'disabled-table-row' : ""))}
                    // onChange={(pagination) => fetchData({ pagination })}
                    onChange={this.pageNavigation}
                    rowSelection={{ selectedRowKeys, onChange: this.onSelectChange }}
                />

                {hasSelected && <Space align='middle' direction='horizontal'>
                    {checkRights(rights, 'd-vouchers-delete') && <DeleteButton onClick={this.doBulkDelete}><Button color="red" size="small">Delete Selection</Button></DeleteButton>}
                    {checkRights(rights, 'd-vouchers-export') && <Button onClick={() => this.exportSelectedCodes()} size="small">Export Codes</Button>}
                    <div>{`Selected ${selectedRowKeys.length} items`}</div>
                </Space>}

                {drawerInfo && <VouchersForm showform={drawerInfo !== false}
                    onSuccess={this.onSuccess}
                    onClose={() => this.setState({ drawerInfo: false })}
                    fields={drawerInfo !== true ? drawerInfo : null} />}

                {bulkDrawer && <VouchersForm bulkRecords showform={bulkDrawer !== false}
                    onSuccess={this.onSuccess}
                    onClose={() => this.setState({ bulkDrawer: false })}
                    fields={bulkDrawer !== true ? drawerInfo : null} />}

                <Modal visible={showContentModal !== false} onCancel={() => this.setState({ showContentModal: false })}>
                    {showContentModal !== false && <>
                        <pre>{showContentModal.map(o => (o.code)).toString().replaceAll(",", "\n")}</pre>
                    </>}
                </Modal>

            </ContentArea>
        </>)
    }

}

const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
const WithRedux = connect(mapStateToProps)(Vouchers);
export default withApollo(WithRedux);
