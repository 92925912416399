import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader, Table, DevBlock, PageHeader } from 'Common/components'
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { convertTo12Hrs, utcToDate } from 'Common/scripts/Functions'
import {OrderDetails} from './order_details';
import { Alert } from 'antd';
import { ContentArea } from 'Layout_v1';
import moment from 'moment';

const RECORD = loader('src/graphqls/orders/order.graphql');


export const OrderPreview = props => {
    const { loading, order, history } = props;
    if (loading) return <Loader loading={true} />;

    if (!order || !order._id) return <ContentArea>
            <div style={{ padding: 10 }}><Button onClick={() => history.goBack()}>Back</Button></div>
            <Alert message="Order not found!" type='error' showIcon />
        </ContentArea>

    if (order.error) return <ContentArea>
            <div style={{ padding: 10 }}><Button onClick={() => history.goBack()}>Back</Button></div>
            <Alert message={order.error.message} type='error' showIcon />
        </ContentArea>


    let time_range_utc = null;
    time_range_utc = JSON.parse(order.delivery_slot.time_range_utc);
    time_range_utc = time_range_utc.map(itm => moment(itm, "DD-MM-YYYYTHH:mm"));

    return (<>
        <PageHeader title="Order Details" onBack={history.goBack} style={{margin:"20px"}} />
        <OrderDetails order={order} previewOnly={true} />
    </>)

}

OrderPreview.propTypes = {
    // order: PropTypes.object,
}


const WithApollo = compose(
    graphql(RECORD, {
        options: ({ match }) => {
            return { variables: { id: match.params._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, order, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, order, queryErrors: error, }
        },
    }),
)(OrderPreview);


export default WithApollo;