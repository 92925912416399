import React, { useState } from 'react'
import OrdersList from '../components/orders_list'
import OrderDetails from '../components/order_details';
import { ContentArea } from 'Layout_v1';
import { ROOT, MODULE } from '../constants'
import { useWindowDimensions } from 'Common/scripts/hooks';

export const OrdersOnTill = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const { height, width } = useWindowDimensions();

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        {visible && <OrderDetails onClose={toggleDrawer} showform={visible} order={fields} previewOnly={false} />}

        <ContentArea>
            <OrdersList {...props}
                screen={{ height, width }}
                noActions hideFilter
                title="Orders On Till "
                // path={`${props.match.path}/order`}
                // ${ROOT}/on_till/order/:_id
                path={`${ROOT}/on_till/order`}
                onEditRecord={(item) => toggleDrawer({ fields: item })}
                // filter={JSON.stringify({ status: {$in: ['picked', 'in-review']} })} 
                filter={{ status: { $in: ['picked', 'in-review'] } }}
            />
        </ContentArea>
    </>)
}

export default OrdersOnTill;