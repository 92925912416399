import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
// import { useHistory, useParams } from "react-router-dom";
import { Popconfirm, Alert, message, Row, Col, Divider, Radio, Modal } from 'antd';
import { Loader, Icon, Button, IconButton, Table, DataGrid, Avatar, ProductBarcodeFilter, ListHeader, DeleteButton } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import ProductGridItem from './ProductGridItem'
import ProductFilter from './product_filter'
import HandelOrphenProducts from './HandelOrphenProducts';
import FeatuerdButton from './FeatuerdButton';
import { checkRights } from 'Common/scripts/Security';

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';
// import {ROOT} from '../'

const LIST_DATA = loader('src/graphqls/product/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/product/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/product/delete.graphql');
const { confirm } = Modal;

const defaultFilter = { }

export class ListComp extends Component {
    state = {
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
        pageView: "list",
        filter: {},
        busy:false,
    };

    constructor(props){
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
        this.doSearch = this.doSearch.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                // pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.productsQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify(this.state.filter),
                    others: JSON.stringify({})
                },
                _subscriptionName: "productsUpdated",
                _subscriptionType: "array",
                _queryName: "productsQuery",
                _typename: "Product",
                debug: true
            });
        }

    }

    changeView = (to) => this.setState({ pageView: to });

    async handleDelete(id) {
        this.setState({ busy:true });

        return this.props.deleteProduct(id).then(r => {
            this.setState({ busy: false })

            if (r && r.data && r.data.deleteProduct && r.data.deleteProduct.error) {
                message.error(r.data.deleteProduct.error.message);
                return false;
            }
            message.success("Record deleted")
            return r;
        })
        .catch(error => {
            this.setState({ busy: false })
            
            console.log(__error("ERROR"), error);
            message.error("Unable to delete record")
            return false;
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        let filter = filters ? { ...filters } : { ...this.state.filter }
        Object.assign(filter, { ...defaultFilter, ...this.props.filter })

        let input = {
            limit: pagination.pageSize || defaultPageSize, 
            skip: (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            filter: JSON.stringify(filter),
            // others:{} 
        }

        this.props.loadMoreRows(input).then(r => {
            this.setState({ 
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.productsQuery?.totalCount },
                busy: false,
                filter
            });

            const response = r?.data?.productsQuery;

            if (response.error) {
                console.log(__error("Query Error: "), response);
                message.error(response.error.message);
            }
            if (response.errors) {
                console.log(__error("Query Error: "));
                console.log(response.errors)
                message.error("Query Error");
            }
        })
    };

    renderGridItem = React.forwardRef((args, ref) => {
        return <ProductGridItem {...args}
            toggleGalleryEditor={this.props.toggleGalleryEditor}
            onEditClick={this.props.onEditRecord}
            onDeleteClick={this.handleDelete}
        />
    })

    doSearch(args) {
        this.handleTableChange({ page: 1 }, args.filter);
    }

    columns = () => {
        let cols = [
            // { width: 150, title: 'store_id', dataIndex: 'store_id' },
            // { title: 'ID', dataIndex: '_id', width: '80px', align: 'left' },
            {
                title: 'Name', dataIndex: 'title', render: (text, record) => {
                    return (<Row gutter={16} align="middle" className='show-menu-on-hover'>
                        <Col><Avatar tooltip={record._id} size={40} shape="square" src={record.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.picture_thumb}` : null} icon={<Icon icon="image" />} /></Col>
                        <Col><b>{record.title}</b><br />{record.barcode}</Col>
                        <Col className='hidden-menu'>
                            {checkRights(this.props.rights, 'product-manage', false) && 
                                <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} />
                            }
                            {checkRights(this.props.rights, 'product-delete', false) && 
                                <DeleteButton onClick={() => this.handleDelete(record?._id)} />
                            }
                            {checkRights(this.props.rights, 'product-manage', false) && 
                                <Button onClick={() => this.props.toggleGalleryEditor(record)} size="small" shape="round">Picture Gallery</Button>
                            }
                        </Col>
                    </Row>)
                }
            }
        ]

        if(checkRights(this.props.rights, 'prodlicol-cat', false))
            cols.push({ title: 'Category', dataIndex: 'category', render: (__, rec) => (<>{rec?.category?.title}</>) })
        if (checkRights(this.props.rights, 'prodlicol-store', false))
            cols.push({ title: 'Store', dataIndex: 'store', render: (__, rec) => (<>{rec?.store?.title}</>) })

        if (checkRights(this.props.rights, 'product-manage', false)){
            cols.push({
                title: 'Featured', dataIndex: 'featured', width: '80px', align: 'center', render: (__, rec) => (<FeatuerdButton prod={rec} />)
            })
        }

        cols.push({ title: 'Price', dataIndex: 'price', width: '80px', align: 'center' })
        cols.push({
            title: 'Qty', dataIndex: 'available_qty', width: '80px', align: 'center', render: (__, rec) => {
                if (rec.available_qty < 1) return <div style={{ backgroundColor: '#CCC', borderRadius: '10px', fontSize: '10px', padding: '0px', fontWeight: "bold" }}>Sold Out</div>
                return rec.available_qty;
            }
        })
        cols.push({ title: 'Not Serving', dataIndex: 'not_serving', width: '90px', align: 'center' })
        cols.push({ title: 'Status', dataIndex: 'status', width: '100px', align: 'center' })

        return cols;
    }



    render() {
        const { loading, productsQuery, queryErrors, type } = this.props;
        const { pagination, pageView, busy } = this.state;
        // let canManage = checkRights(this.props.rights, 'product-manage');

        return (<>
            <ContentArea>
                <ListHeader 
                    title={<>{this.props.title || 'Product Catalogue'} <span>{this.props.titlePostfix}</span></>} 
                    sub={<>Total {productsQuery && productsQuery.totalCount || 0} products</>} 
                    right={<>
                        {!this.props.hideAddProduct && <>
                            <Button onClick={this.props.toggleDrawer}>Add New Product</Button>
                            <Divider type="vertical" />
                        </>}
                        <Radio.Group value={pageView} buttonStyle="solid" onChange={({ target }) => this.changeView(target.value)}>
                            <Radio.Button value="list"><Icon icon='th-list' /></Radio.Button>
                            <Radio.Button value="grid"><Icon icon='th-large' /></Radio.Button>
                        </Radio.Group>
                    </>}
                />

                {(type == 'OrphenProducts' && this.props?.productsQuery?.totalCount>0) && <>
                    <HandelOrphenProducts list={this.props?.productsQuery?.edges} />
                </>}

                {!this.props.hideFilter && <Row>
                    <Col flex="auto"><ProductFilter 
                        exclude={this.props?.searchFilterConfig?.exclude} 
                        // onSearch={(filter) => this.doSearch({ filter })} 
                        onChange={this.doSearch}
                        defaultValue={defaultFilter} 
                    /></Col>
                    <Col flex="350px" style={{ paddingLeft: 20 }}>
                        <ProductBarcodeFilter onEditClick={this.props.onEditRecord} />
                    </Col>
                </Row>}

                {pageView == "grid" && <>
                    <DataGrid 
                        loading={loading || busy}
                        gridItem={this.renderGridItem}
                        dataSource={productsQuery ? productsQuery.edges : null}
                        total={productsQuery ? productsQuery?.totalCount : 0}
                        pageSize={defaultPageSize}
                        current={pagination.current || 1}
                        onChange={this.handleTableChange}
                    />
                </>}

                {pageView == "list" &&
                    <Table 
                        pagination={pagination}
                        loading={loading || busy}
                        columns={this.columns()}
                        dataSource={productsQuery ? productsQuery?.edges : null}
                        // total={productsQuery ? productsQuery?.totalCount : 0}
                        // pageSize={defaultPageSize}
                        // current={pagination.current || 1}
                        rowClassName={(record => {
                            return record.status == 'hidden' ? 'disabled-table-row' : "";
                        })}
                        onChange={this.handleTableChange}
                    />
                }

            </ContentArea>

        </>)
     
    }
}
ListComp.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    toggleGalleryEditor: PropTypes.func.isRequired,
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            let after = 0;
            const { pageNum } = props?.match?.params || {};
            let _defaultPageSize = props.defaultPageSize || defaultPageSize;
            after = ((pageNum || 1) - 1) * _defaultPageSize;
            
            return { variables: { first: _defaultPageSize, after: after, filter: JSON.stringify(props.filter) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productsQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = ({ limit, skip, filter, others }) => {
                let vars = { first: limit, after: skip, filter, others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {                    
                    const totalCount = fetchMoreResult?.productsQuery?.totalCount || 0
                    const newEdges = fetchMoreResult?.productsQuery?.edges
                    const pageInfo = fetchMoreResult?.productsQuery?.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        productsQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'ProductsQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery }).then(r => {
                    if (r.error || r.errors) {
                        message.error(`Query Error!`);
                        console.log(__error("QUERY ERROR"), r);
                    }
                    return r;
                });
            }

            return { loading, productsQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteProduct: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

// export default WithApollo;

const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(WithApollo);

