import React from 'react'
import OrdersList from '../components/orders_list'
import { __error } from 'Common/scripts/consoleHelper'
import OrderDetails from '../components/order_details';
import { BarcodeScanner } from 'Common/components'
import { ContentArea } from 'Layout_v1';
import { useWindowDimensions } from 'Common/scripts/hooks';


export const ReadyToDispatch = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const [scanned, setScanned] = React.useState(false)
    const { height, width } = useWindowDimensions();

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    const handleScan = (barcode) => {
        console.log("********** Ready To Dispatch Scanned *******", barcode);
        setScanned(barcode)
        
        // const _id = data.replace("PROD-", "");
        // let prod = this.state.order.items.find(O => O._id == _id);
        // if (!prod) return;

        // this.confirmProduct(prod)
    }
    // const handleError = (err) => {
    //     console.error(__error("Scann Error: "), err)
    // }

    return (<>
        {/* <TillConfirmation onClose={toggleDrawer} showform={visible} order={fields} /> */}
        {/* <BarcodeReader onError={handleError} onScan={handleScan} /> */}
        {visible && <OrderDetails previewOnly onClose={toggleDrawer} showform={visible} order={fields} />}
        <BarcodeScanner onScan={handleScan} />

        <ContentArea>
            {/* {scanned && <p>{scanned}</p>} */}
            <OrdersList 
                showBarcode
                screen={{ height, width }}
                noActions
                title="Ready To Dispatch"
                // path={`${props.match.path}/order`}
                onEditRecord={(item) => toggleDrawer({ fields: item })} 
                filter={{ status: { $in: ['ready-to-dispatch']} }} 
                // filter={JSON.stringify({ status: { $in: ['ready-to-dispatch']} })} 
                hideFilter={true} 
            />
        </ContentArea>

    </>)
}

export default ReadyToDispatch;