/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
// import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import { updateUserSettings } from './local_store/reducer';
import { ROOT, SubMenu } from './constants';

import UsersList from './containers/users'
import { sleep } from 'Common/scripts/Functions';

const GET_MY_SETTINGS = loader('src/graphqls/user_settings/get_my_setting.graphql');

export const menuArray = props => ([
  { path: `${ROOT}`, exact: true, title: 'Users', component: UsersList },
])

/**
 * Home
 */
const Home = props => {
  const [loading, setLoading] = useState(props.loading)

  useEffect(() => {
    // return () => { };
    if (props.loading || !props?.get_my_setting?._id){
      if (loading) setLoading(false);
      return;
    }

    let user_settings = props?.get_my_setting?.value ? JSON.parse(props.get_my_setting.value) : false;

    props.updateUserSettings(user_settings); // _customers

    sleep(100).then(r=>{
      setLoading(false)
    })

  }, [props.loading])

  console.log({ loading })

  return (<LayoutWrapper topMenu={null} loading={loading}>
    {!loading && <UsersList {...props} />}
  </LayoutWrapper>)

  // let routs = menuArray();

  // return (
  //   <LayoutWrapper topMenu={null}>
  //     <Switch>
  //       {routs.map((item, i) => {
  //         if (!item.component) return null;
  //         return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
  //       })}
  //       <Route key={999} component={E404} />
  //     </Switch>
  //   </LayoutWrapper>
  // )

}

Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

const WithApollo = compose(
  graphql(GET_MY_SETTINGS, {
    options: (props) => {
      return {
        variables: { category: "customer" },
        fetchPolicy: "no-cache",
      };
    },
    props: ({ ownProps, data }) => {
      const { loading, get_my_setting, error } = data;
      if (error) console.log(__error("error"), error);
      return { loading, get_my_setting, queryErrors: error, }
    },
  }),

)(Home);


const mapDispatchToProps = (dispatch, ownProps) => ({
  updateUserSettings: (payload) => dispatch(updateUserSettings(payload)),
})
// const mapStateToProps = ({ settings_customers }) => {
//   return {
//     settings_customers,
//   };
// }
const WithRedux = connect(null, mapDispatchToProps)(WithApollo);

export default WithRedux;