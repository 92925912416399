import React, { useState, useEffect } from 'react'
// import { Button } from 'Common/components'
import BasketsList from './components/baskets_list';
import BasketForm from './components/basket_form';
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper';
import { message } from 'antd';

const LIST_DATA = loader('src/graphqls/baskets/baskets.graphql');

export const Baskets = ({ client }) => {
    const [{ visible, fields }, setState] = useState({ visible: false, fields: null });
    const [baskets, setBaskets] = useState(null)
    const [loading, setLoading] = useState(false)

    const toggleDrawer = (args={}) => {
        setState({ visible: !visible, fields: args?.fields || null });
    };

    useEffect(() => {
        if (baskets) return;
        fetchData()
    }, [])

    const fetchData = async(args) => {
        setLoading(true)
        const reuslt = await client.query({ query: LIST_DATA, variables: { filter: (args && args.filter) || JSON.stringify({}) } }).then(e => (e?.data?.baskets))
            .catch(err => {
                console.log(__error("API Call ERROR: baskets : "), err);
                return { error: { message:"Unable to load baskets"}}
            })
        setLoading(false)
        
        if (!reuslt || reuslt.error){
            message.error(reuslt && reuslt.error.message || "Unable to fetch data");
            return;
        }

        setBaskets(reuslt);
    }

    const onSuccess = () => {
        toggleDrawer()
        fetchData()
    }

    return (<>
        {visible && <BasketForm onSuccess={onSuccess} onClose={toggleDrawer} showform={visible} fields={fields} />}
        <BasketsList loading={loading} baskets={baskets} 
            onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddClick={() => toggleDrawer()}
            fetchData={fetchData}
        />

    </>)
}

export default withApollo(Baskets);