/* eslint-disable eqeqeq */
import React from 'react';
// import PropTypes from 'prop-types';
import LocalStorage from 'Common/scripts/LocalStorage';
import { connect } from "react-redux";
import { loginUser, logoutUser } from 'Store/grocer_storeadmin.actions';
import { sleep } from 'Common/scripts/Functions';


/**
 * Logout
 */
const Logout = props => { // eslint-disable-line react/prefer-stateless-function
  React.useEffect(() => {
    
    LocalStorage.logout();
    
    sleep(50).then(r=>{
      props.logoutUser();
    });

    // window.location.href = "/";

  }, [props])


  return <div>Logging out...</div>

}

const mapDispatchToProps = (dispatch, ownProps) => ({
  logoutUser: (payload) => dispatch(logoutUser(payload)),
})
const mapStateToProps = ({ grocer_storeadmin }) => {
  return { grocer_storeadmin };
  // return ({ user: (state.user && state.user.user) || {} });
}

const WithRedux = connect(mapStateToProps, mapDispatchToProps)(Logout);
export default WithRedux;