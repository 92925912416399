import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Progress, message, Upload, Modal, Card, Alert, Col, Divider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import _ from 'lodash';
import axios from 'axios';
import { Loader, IconButton, Button, Icon } from "../";
import { __warning, __error, __success } from 'Common/scripts/consoleHelper';
// import { sleep } from 'Common/scripts/Functions';


export const ThumbnailComp = ({ thumbUrl, srcUrl, handlePreview, status, onRemovePress, response, loading }) => {
    if (srcUrl && srcUrl.length < 10) return null;

    // check if its a upload response or archived image from DB
    let _thumbUrl = response ? `${process.env.REACT_APP_DATA_URL}/${response.thumbUrl}` : thumbUrl;
    if (_thumbUrl && _thumbUrl.indexOf('http') < 0) _thumbUrl = `${process.env.REACT_APP_DATA_URL}/${_thumbUrl}`

    let _srcUrl = response ? `${process.env.REACT_APP_DATA_URL}/${response.srcUrl}` : srcUrl;
    if (_srcUrl && _srcUrl.indexOf('http') < 0) _srcUrl = `${process.env.REACT_APP_DATA_URL}/${_srcUrl}`

    return (<div className='gallery-item'><Loader loading={loading}>
        <div className='img-wrapper'>{_thumbUrl && <img src={_thumbUrl} />}</div>
        <div className='contents'>
            {handlePreview && <div className='eye-icon'><Icon icon="eye" color="#FFF" size="2x" /></div>}
            {onRemovePress && <div className='action-bar'><IconButton icon="trash-alt" onClick={onRemovePress} /></div>}
            {handlePreview && <div className='preview-bt' onClick={() => handlePreview(_srcUrl)} />}
        </div>
    </Loader></div>)

}

export const UploadField = (props) => {
    const { onUploadComplete, onDeleteClicked, buttonLabel, data, name, type, listType, limit, fileList, showCount, label } = props;
    const [uploladList, set_uploladList] = useState([]);
    const [previewImage, set_previewImage] = useState(false);
    const [busy, setBusy] = useState(false);

    if (!limit || limit<1) return <Alert message="limit is missing" showIcon type="error" />
    if (!listType) return <Alert message="listType is missing" showIcon type="error" />
    if (!name) return <Alert message="name is missing" showIcon type="error" />
    if (!type) return <Alert message="type is missing" showIcon type="error" />
    if (!data) return <Alert message="data is missing" showIcon type="error" />


    const onUploadProgress = ({ total, loaded }) => {
        console.log("onUploadProgress()", { total, loaded })
        // let percent = Math.round(loaded / total * 100).toFixed(2);
    }

    const onSuccess = (response, file) => {
        set_uploladList([])
        onUploadComplete(response);
    }

    const customRequest = async (args) => {
        set_uploladList([...uploladList, args.file])
        const { action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials, } = args;

        // eslint-disable-next-line no-undef
        const formData = new FormData();
        if (data) {
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });
        }
        formData.append(filename, file);
        formData.append("name", props.name);
        // if custom size if available
        if (props.thumbSize) formData.append("thumbSize", props.thumbSize);

        const results = await axios.post(action, formData, { withCredentials, headers, onUploadProgress })
            .then(({ data: response }) => onSuccess(response, file))
            .catch(onError);

        return {
            abort() {
                console.log('upload progress is aborted.');
            },
        };
    }

    const onRemove = async (args) => {
        setBusy(true)
        
        let input = { ...args, name, data }
        const resutls = await onDeleteClicked(input).then(r => (r))
            .catch(error => {
                console.log(__error("ERROR"), error);
                return { error:{message:"Request Error!"}}
            });

        setBusy(false)

        if (!resutls || resutls.error) return false;
    }

    const uploadProps = {
        data: props.data || {},
        action: props.action || `${process.env.REACT_APP_API_URL}/upload/assets`,
        disabled: false,
        multiple: false,
        // data: props.data,
        accept: ".png,.jpg,.jpeg",
        method: props.method || "post",
        listType: props.listType || "picture-card",

        // beforeUpload: (file, fileList) => {
        //     console.log("beforeUpload: ", { file, fileList });
        //     return false; // return false will stop auto upload process
        // },
        // onRemove: (file) => console.log("onRemove: ", file),
        // onError: (v) => console.log("onError: ", v),
        onSuccess: onSuccess,
        onProgress: (v) => console.log("onProgress: ", v),
        // onPreview: (file) => console.log("onPreview: ", file),
        // onStart: (v) => console.log("onStart: ", v),
        customRequest: customRequest,

        // onChange({ file, fileList }) {
        //     console.log("onChange: ", { file, fileList })
        //     return true
        //     // if (file.status !== 'uploading') console.log(file, fileList);
        // },
        defaultFileList: [
            // {
            //     uid: '1',
            //     name: 'xxx.png',
            //     status: 'done',
            //     response: 'Server Error 500',
            //     // custom error message to show
            //     url: 'http://www.baidu.com/xxx.png',
            // },
        ],
        fileList:[],
        showUploadList: {
            showRemoveIcon: false,
            removeIcon: <div onClick={(e) => console.log(e, 'custom removeIcon event')}>Delete</div>,
        },

        customRequest: customRequest,
    };

    var totalItems = (fileList ? fileList.length : 0) + (uploladList ? uploladList.length : 0);

    return (<>
        <Loader loading={busy}>
            <div className="form-field upload-image">
                {/* {props.label && <label>{props.label}</label>}
                {totalItems < limit && <Upload {...uploadProps}><div className='item-placeholder'>{buttonLabel || 'Upload'}</div></Upload>}
                {props.showCount && <div>{uploladList.length} / {props.limit}</div>} */}

                <div className={`upload-gallery`}>
                    {label && <label>{label}</label>}
                    <div className='thumbnail-holder'>

                        {fileList && fileList.map((item, i) => (<ThumbnailComp 
                            handlePreview={() => set_previewImage(item.srcUrl)} 
                            onRemovePress={!onDeleteClicked ? false : () => onRemove({ ...item.response, ...item })}
                            {...item} key={i} />)
                        )}

                        {(uploladList && uploladList.length>0) && <>
                            {uploladList.map((item, i)=>{
                                return <ThumbnailComp loading={true} key={i} />
                            })}
                        </>}

                        {totalItems < limit && <div className='item-placeholder'>
                            <Upload.Dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p>{buttonLabel || "Click or drag file to this area to upload"}</p>
                            </Upload.Dragger>
                        </div>}

                        {/* {totalItems < limit && <Upload {...uploadProps}><div className='item-placeholder'>{buttonLabel || 'Upload'}</div></Upload>} */}

                    </div>

                    {showCount && <p align="center">{totalItems} / {limit}</p>}

                </div>

            </div>

            <Modal visible={!(previewImage == false)} title={""} footer={false} onCancel={() => set_previewImage(false)}>
                {previewImage && <img alt="example" style={{ width: '100%' }} src={previewImage} />}
            </Modal>
        </Loader>
    </>)
}


export default UploadField;