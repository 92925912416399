import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'


// addReducers({ admins: reducers });
addModule(
  {
    permission_required: "vehicles-access",
    path: `${ROOT}`, component: Home, icon: 'truck', title: "Vehicle", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
