import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { Loader, Icon, Button, IconButton, Table, DevBlock, ListHeader, DeleteButton } from 'Common/components';
import { Tree, message, Row, Col, Popconfirm } from 'antd';
import { sleep, filterArrayByObjVal, constructCategoryArray } from 'Common/scripts/Functions'

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';
import { Link } from 'react-router-dom';

const LIST_DATA = loader('src/graphqls/product_cat/productCats.graphql');
const RECORD_DELETE = loader('src/graphqls/product_cat/delete.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/product_cat/subscription.graphql');


export class TreeList extends Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "productCatsUpdated",
                _subscriptionType: "simple-array",
                _queryName: "productCats",
                _typename: "ProductCat",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteProductCat(id).then(r => {
            if (r && r.data && r.data.deleteProductCat && r.data.deleteProductCat.error) {
                message.error(r.data.deleteProductCat.error.message);
                return false;
            }
            message.success("Record deleted")
            // this.onDataUpdate('delete');
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        {
            title: 'Category Title', dataIndex: 'title', key: 'title', className: 'show-menu-on-hover-', render:(__, rec)=>{
            return (<Row className='show-menu-on-hover'>
                <Col>{rec.title} ({rec.prodCounts || '0'})</Col>
                <Col className='hidden-menu'>
                    <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                    <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                </Col>
            </Row>)

        }},
        { title: 'Status', dataIndex: 'status', key: 'status', width: '90px', align: 'center', },
    ];


    render() {
        const { loading, productCats, queryErrors, onAddClick } = this.props;

        if (loading) return <Loader loading={true} />
        // if (!productCats) return <div>No data available</div>

        return (<ContentArea>
            <ListHeader title="Product Categories" 
                sub={`Total ${(productCats && productCats.length) || 0} records found`}
                right={<>
                    <Button onClick={onAddClick}>Add New Category</Button>
                </>}
            />

            <Table
                columns={this.columns}
                dataSource={productCats}
                pagination={false}
            />
        </ContentArea>)

    }
}

TreeList.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: JSON.stringify({  }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productCats, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter, others) => {
                let vars = { filter: filter, others: others };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.productCats
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery, fetchPolicy: "no-cache" });
            }
            return { loading, productCats, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteProductCat: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(TreeList);

// export default WithApollo;
export default withApollo(WithApollo);

