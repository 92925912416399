import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql, gql } from 'react-apollo';
import { loader } from 'graphql.macro';
import { checkRights } from 'Common/scripts/Security';
import compose from 'lodash.flowright';
import { Row, Col } from 'antd';
import { Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize, staffTypes } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import StaffFilter from 'Common/components/StaffFilter';
import { connect } from "react-redux";
import { ContentArea } from 'Layout_v1';
import { verifyRole, isSuperAdmin } from 'Common/scripts/Security';

const LIST_DATA = loader('src/graphqls/user/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/user/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/user/delete.graphql');

const defaultFilter = {
    type: { $in: staffTypes.map(o=>o._id) }
}

class ListComp extends Component {
    state = { 
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
        busy: false
    };
    subscription = false;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.usersQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify(defaultFilter),
                    others: JSON.stringify({})
                },
                _subscriptionName: "usersUpdated",
                _subscriptionType: "array",
                _queryName: "usersQuery",
                _typename: "User",
                debug: true
            });
        }

    }

    // handleDelete(id) {
    //     this.props.deleteUser(id).then(r => {
    //         if (r && r.data && r.data.deleteUser && r.data.deleteUser.error) {
    //             message.error(r.data.deleteUser.error.message);
    //             return false;
    //         }
    //         message.success("Record deleted")
    //     })
    //         .catch(error => {
    //             console.log(__error("ERROR"), error);
    //             message.error("Unable to delete record")
    //         })
    // }

    renderTitleColumn = (text, record) => {
        let code=null;// = record.verification_code;
        if (record.phone_confirmed < 1) {
            code = record.verification_code;
        }

        return (<Row gutter={16}>
            <Col><Avatar size={40} shape="square" src={record.avatar_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.avatar_thumb}` : null} icon={<Icon icon="image" />} /></Col>
            <Col><div>
                <div>{record.fname} {record.lname || ""}</div>
                <div>{record.email}</div>
                {/* {code && <div>{code}</div>} */}
            </div></Col>
        </Row>)
    }

    columns = () => {
        let _columns = [
            // { title: 'ID', dataIndex: '_id', width:"100px" },
            {
                title: 'Title', dataIndex: 'name', render: (text, record) => {
                    let code = null;// = record.verification_code;
                    if (record.phone_confirmed < 1) code = record.verification_code;

                    return (<Row className='show-menu-on-hover' gutter={[10]}>
                        <Col><Avatar size={40} tooltip={record._id} shape="square" src={record.avatar_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.avatar_thumb}` : null} /></Col>
                        <Col>
                            <div>{record.fname} {record.lname || ""}</div>
                            <div>{record.email}</div>
                        </Col>
                        <Col className='hidden-menu'>
                            {checkRights(this.props.rights, 'staff-manage') &&
                                <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} />
                            }
                            {checkRights(this.props.rights, 'staff-delete') && <DeleteButton onClick={() => this.handleDelete(record?._id)} />}
                        </Col>
                    </Row>)
                }
            },

            { title: 'Type', dataIndex: 'type', width: "150px", align: "center" },
            { title: 'Status', dataIndex: 'status', width: "80px", align: "center" }
        ];

        if (isSuperAdmin(this.props.user)) _columns.push({ title: 'Store', dataIndex: 'store.title', width: "150px", align: "center", render: (__, rec) => (rec?.store?.title) })

        return _columns;
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ busy: true })

        let filter = filters ? JSON.stringify({ ...filters, ...defaultFilter, _id: { $ne: this.props.user._id } }) : this.props.queryString;

        this.props.loadMoreRows(
            pagination.pageSize || defaultPageSize,
            (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            filter,
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        ).then(r => {
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.usersQuery?.totalCount },
                busy: false
            })
        })
    };


    doSearch(filter) {
        this.handleTableChange({ page:1 }, filter)

        // this.setState({ busy: true })
        // this.props.loadMoreRows(
        //     this.state.pagination.pageSize || defaultPageSize, 0,
        //     JSON.stringify({ ...defaultFilter, ...filter, _id: { $ne: this.props.user._id } })
        // ).then(r => {
        //     this.setState({ busy: false })
        //     if (r.data && r.data.usersQuery)
        //         this.setState({ pagination: { ...this.state.pagination, current: 1, total: r.data.usersQuery.totalCount } })
        //     return r;
        // })
    }


    render() {
        const { loading, usersQuery, queryErrors } = this.props;
        const { pagination } = this.state;

        const is_uper_admin = isSuperAdmin(this.props.user)

        return (<>
            <ContentArea>
                <ListHeader title="Staff" 
                    sub={<>Total {usersQuery && usersQuery.totalCount || '0'} records</>} 
                    right={<>
                        {checkRights(this.props.rights, 'staff-manage') &&
                            <Button onClick={this.props.onAddRecord}>Add Staff</Button>
                        }
                        </>}
                    />
                <StaffFilter onSearch={(filter) => this.doSearch(filter)} />

                <Table loading={loading}
                    columns={this.columns()}
                    dataSource={usersQuery ? usersQuery.edges : null}
                    pagination={pagination}
                    rowClassName={(record => {
                        return record.status == 'disabled' ? 'disabled-table-row' : "";
                    })}
                    onChange={this.handleTableChange}
                    expandable={!is_uper_admin ? false : {
                        expandedRowRender: record => <p style={{ margin: 0 }}>{String(record.rights).replaceAll(",", ", ")}</p>,
                        rowExpandable: record => (is_uper_admin),
                    }}
                />
            </ContentArea>

        </>)
    }
}
const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ ...defaultFilter, _id: { $ne: Number(props.user._id) }}) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, usersQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.usersQuery.totalCount
                    const newEdges = fetchMoreResult.usersQuery.edges
                    const pageInfo = fetchMoreResult.usersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        usersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, usersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id) => mutate({
                variables: { id }
            }),
        })
    }),


)(ListComp);


// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updateSettings: (payload) => dispatch(updateSettings(payload)),
// })
const mapStateToProps = ({ grocer_storeadmin: { user, rights } }) => {
    return { user, rights }
}
const WithRedux = connect(mapStateToProps)(WithApollo);
export default WithRedux;
