import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { FormField, FormComponent, FormFieldGroup, SubmitButton } from 'Common/components/Form'
import { formToFilter } from 'Common/scripts/Functions'
import { connect } from "react-redux";
import { Icon, Button, Loader } from 'Common/components';


const StoreFilterComp = ({ onSearch, loading, defaultValue, filterType, default_timezone }) => {

    const onSubmit = values => {
        let filter = formToFilter(values, { tz: default_timezone })
        onSearch(filter);
    }

    if(loading) return <Loader loading={true} />

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='StoreFilterForm' fields={defaultValue} debug={false}>
            <Row className="pagination-filter-row">
                <Col flex="auto" className="filters-column">
                    <Row className='no-wrap'>
                        <Col flex="300px"><FormField type="text" prefix={<Icon icon="search" color="#CCC" />} name="search.keywords" placeholder="keyword to search..." compact allowClear size="large" /></Col>
                        {/* {filterType == "all" && <Col flex="200px"><UserTypeSelection name="type" preload compact size="large" allowClear /></Col>} */}
                    </Row>
                </Col>
                <Col className="go-column">
                    <Button className="send_button" loading={loading} htmlType="submit"><Icon icon="search" /></Button>
                </Col>
            </Row>
        </FormComponent>
    </div>)

}
StoreFilterComp.propTypes = {
    onSearch: PropTypes.func.isRequired,
    // filterType: PropTypes.string.isRequired,
    // exclude: PropTypes.array,
    // accTypes: PropTypes.array,
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.default_timezone };
}
export const StoreFilter = connect(mapStateToProps)(StoreFilterComp);
export default StoreFilter;
