import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Heading, Icon, IconButton, GMap, DevBlock } from 'Common/components'
// import { parseJson } from 'Common/scripts/Functions'
import { __error } from 'Common/scripts/consoleHelper'
import EditOrderLocation from './EditOrderLocation';
import { checkRights } from 'Common/scripts/Security';
import { connect } from 'react-redux'

// const GEO_ZONES = loader('src/graphqls/geo_zone/geoZones.graphql');

const CustomerInformation = (props) => {
    const { order, user, rights } = props;
    const shipping_address = order.shipping_address;
    const [shippingZone, set_shippingZone] = useState(null)
    const [editLocation, setEditLocation] = useState(false)

    const onAddressUpdate = () => {
        setEditLocation(false);
        window.location.reload();
    }


    return (<>
        <div className="grid-block">
            <Heading>
                Shipping Zone {shippingZone ? `(${shippingZone})` : null} 
                {/* {!props.previewOnly && <IconButton onClick={() => setEditLocation(!editLocation)} icon="pen" />} */}
            </Heading>

            {checkRights(rights, "show-customers-adderss", false) && <>
                {!shipping_address && !shipping_address?.geo_point?.coordinates && <>
                    <div style={{ padding: "0 10px", marginTop: "-10px", color: "red" }}>
                        <b>Invalid Shipping address</b>
                    </div>
                </>}
            </>}

            {editLocation && <EditOrderLocation order={order} onClose={() => setEditLocation(false)} onAddressUpdate={onAddressUpdate} /> }

            {!editLocation && <>
                {shipping_address && shipping_address?.geo_point?.coordinates && <>
                    <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                        {shipping_address.verified == 'yes' && <div style={{ color: 'green' }}><Icon icon={'check'} style={{ marginRight: '2px' }} />Verified</div>}
                        <div>
                            {shipping_address.full_address}, {shipping_address.city}
                        </div>
                        {shipping_address.delivery_instructions && <div style={{ marginTop: "20px" }}><i><pre>{shipping_address.delivery_instructions}</pre></i></div>}
                        <div style={{ width: '100%', height: '400px', display:"flex" }}>
                            <GMap
                                // markers={[{ ...shipping_address, position: shipping_address.geo_point.coordinates }]}
                                markers={[{ ...shipping_address, position: { lat: shipping_address.geo_point.coordinates[0], lng: shipping_address.geo_point.coordinates[1] } }]}
                                zoom={11}
                                center={{ lat: shipping_address.geo_point.coordinates[0], lng: shipping_address.geo_point.coordinates[1] }}
                                onZoneMatch={(matchFound) => {
                                    // console.log("matchFound: ", matchFound)
                                    set_shippingZone(matchFound.title)
                                    if (props.onZoneMatch) props.onZoneMatch(matchFound);
                                }}
                                showDeliveryZones
                            />
                        </div>
                    </div>
                </>}
            </>}


        </div>
    </>)
}
CustomerInformation.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
}

const mapStateToProps = ({ grocer_storeadmin: { user, rights, store } }) => {
    return { user, store, rights };
}
export default connect(mapStateToProps)(CustomerInformation);
