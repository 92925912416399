import React from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Button, Icon } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col, Alert } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'

const UPDATE_HISTORY = loader('src/graphqls/orders/update_history.graphql');



export const StatusForm = props => {
    const [loading, setLoading] = React.useState(false);

    const onSubmit = values => {

        setLoading(true);
        
        props.updateOrderHistory(props.order._id, values.note).then((e) => {
            setLoading(false)
            if (e.data.updateOrderHistory.error) {
                let err = e.data.updateOrderHistory.error;
                console.log(__error("Reponse ERROR: "), err);
                message.error(err.message);
                return false;
            }
            message.success("Success");
            //onClose(e.data.updateOrderHistory);
        }).catch(error => {
            setLoading(false)
            console.log(__error("Request ERROR: "), error);
            message.error("Query Error");
        });
        
    }

    return (< div className="quick-status-form">
        <FormComponent onSubmit={onSubmit} id='StatusForm' hideDevBlock={true} fields={{ status: props.order.status}}>
            <FormFieldGroup compact>
                {/* <Row style={{ display:"flex" }}>
                    <Col flex="auto"><FormField type="text" width="100%" name="note" compact /></Col>
                    <Col><FormField type="select" width={"130px"} name="status" data={orderStatus} compact /></Col>
                    <Col><Button compact>Send</Button></Col>
                </Row> */}
                <FormField disabled={loading} type="text" className="note_field" name="note" validate={composeValidators(rules.required, rules.maxChar(1000))}  compact />
                {/* <FormField disabled={loading} type="select" className="status_field" width="130px" name="status" data={orderStatus} compact /> */}
                <Button className="send_button" loading={loading} htmlType="submit">Send</Button>
            </FormFieldGroup>
        </FormComponent>
    </div>)
}
StatusForm.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
}



const WithApollo = compose(
    graphql(UPDATE_HISTORY, {
        props: ({ mutate }) => ({
            updateOrderHistory: (_id, note) => mutate({
                variables: { _id, note }
            }),
        })
    }),
)(StatusForm);


export default WithApollo;