import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { Link } from 'react-router-dom';
import { Popconfirm, Alert, message, Row, Col, Tabs } from 'antd';
import { Avatar, DevBlock, Loader, Icon, IconButton, PageHeader, DataGrid } from 'Common/components';
import { ContentArea } from 'Layout_v1';
import StoreInfo from '../components/StoreInfo';
import OrdersList from 'Modules/Orders/components/orders_list';

const RECORD = loader('src/graphqls/stores/store.graphql');
const env = process.env;

class StoreDetails extends Component {
    state = { activeTab:'orders' }

    render() {
        const { loading, store } = this.props;
        const { activeTab } = this.state;
        if (loading) return <Loader loading={true} />

        return (<>
            <ContentArea transparent style={{padding:"0", margin:"0"}}>
                {/* <PageHeader title={store.title} /> */}

                <Row style={{ flex: 1, flexWrap:"nowrap" }}>
                    <Col flex="300px">
                        <StoreInfo store={store} />
                    </Col>

                    <Col flex="auto">
                        <div style={{margin:"10px 0 0 20px"}}>
                            <Tabs onChange={(v)=>this.setState({ activeTab:v })} size="large" tabBarStyle={{ marginBottom: "0", paddingBottom: "0" }} type="card">
                                <Tabs.TabPane tab="Orders" key="orders" />
                                <Tabs.TabPane tab="Products" key="products" />
                                <Tabs.TabPane tab="Offers" key="offers" />
                                <Tabs.TabPane tab="Banners" key="banners" />
                                <Tabs.TabPane tab="Timeslots" key="timeslots" />
                                <Tabs.TabPane tab="Staff" key="staff" />
                                <Tabs.TabPane tab="Vehicle" key="vehicle" />
                                <Tabs.TabPane tab="Customers" key="customers" />
                            </Tabs>
                        </div>

                        <ContentArea style={{ margin: "0", marginRight:"5px" }}>
                            {activeTab=='orders' && <>
                                <OrdersList
                                    // onEditRecord={(item) => toggleDrawer({ fields: item })} 
                                    noActions={true} />
                            </>}

                            {activeTab =='products' && <>
                                products
                            </>}

                            {activeTab =='products' && <>
                                products
                            </>}

                            {activeTab =='offers' && <>
                                offers
                            </>}

                            {activeTab =='banners' && <>
                                banners
                            </>}

                            {activeTab =='timeslots' && <>
                                timeslots
                            </>}

                            {activeTab =='staff' && <>
                                staff
                            </>}

                            {activeTab =='vehicle' && <>
                                vehicle
                            </>}

                            {activeTab =='customers' && <>
                                customers
                            </>}

                        </ContentArea>

                    </Col>
                </Row>
            </ContentArea>

            {/* <DevBlock obj={store} /> */}
        </>)
    }
}

const WithApollo = compose(
    graphql(RECORD, {
        options: ({ match }) => {
            return { variables: { id: match.params._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, store, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, store, queryErrors: error }
        },
    }),

)(StoreDetails);

export default WithApollo;