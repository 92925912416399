import React, { Component } from 'react'
import { message, Row, Col } from 'antd';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, IconButton } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'

const VALID_PIN = "786112233"

export default class PinForm extends Component {
    state = { busy:false }
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values){
        if (values.pin != VALID_PIN) return message.error("Invalid Code!");

        this.props.onSuccess()

    }

  render() {
      const { busy } = this.state;

    return (
        <FormComponent onSubmit={this.onSubmit} id='UniquePinForm' loading={busy} fields={{ pin: "" }}
            form_render={formProps => {
                const { values } = formProps;

                return (<>
                    <Row>
                        <Col><FormField type="text" name="pin" _label="Pin Code" placeholder={"Enter unique pin code"} validate={rules.required} compact /></Col>
                        <Col style={{paddingTop:"0px"}}><Button loading={busy} htmlType="submit">Validate</Button></Col>
                    </Row>
                    
                </>)
            }}
        />

    )
  }
}
