import React from 'react'
import { Col, Row } from 'antd';
import { Table, Barcode, DeleteButton, Icon } from 'Common/components'
import { Link } from 'react-router-dom';
import { checkRights, verifyRole } from 'Common/scripts/Security';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { lightOrDark, utcToDate } from 'Common/scripts/Functions'
import { defaultDateFormat, orderStatus } from 'configs/constants';
import OrderDetails from './order_details';
import moment from 'moment';


const OrderTable = (props) => {
    const { loading, columns, dataSource, pagination, handleTableChange, details_path, onEditRecord, showBarcode, showActions, settings, user, store, rights } = props;
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = _fields => setState({ visible: !visible, fields: _fields || null })

    const createColumns = args => {
        const cols = [];
        if (!columns) return cols

        if (columns.includes("_id")) cols.push({ width: 100, title: 'ID', dataIndex: '_id' })
        if (columns.includes("serial")) cols.push({
            width: 200, title: 'Serial', dataIndex: 'serial', render: (__, record) => {
                return (<Row className='show-menu-on-hover' gutter={[10]}>
                    <Col>
                        {details_path ? <Link className="a" to={`${details_path}/${record._id}`}>{record.serial}</Link> : 
                            onEditRecord ? <div className="a" onClick={() => onEditRecord(record)}>{record.serial}</div> :
                                <div onClick={() => toggleDrawer(record)} className='a'>{record.serial}</div>
                        }

                        {/* {details_path && <Link className="a" to={`${details_path}/${record._id}`}>{record.serial}</Link>} */}
                        {/* {(onEditRecord) && <div className="a" onClick={() => onEditRecord(record)}>{record.serial}</div>} */}

                        {record?.items && <div>Total Items: {record.items.length}</div>}
                        {showBarcode && <Barcode value={`${record.serial}`} width={1} height={50} displayValue={true} />}
                    </Col>
                    {showActions && <Col className='hidden-menu'>
                        {checkRights(rights, 'orders-delete', true) && <DeleteButton onClick={() => this.handleDelete(record?._id)} />}
                    </Col>}
                    
                </Row>)
            }
        })
        if (columns.includes("picker_baskets")) cols.push({
            width: 200, title: 'Baskets', dataIndex: 'picker_baskets', render: (text, { dispatch_data, pickup_data }) => {
                let dispatch_baskets = dispatch_data ? dispatch_data.baskets : null;
                let picker_baskets = pickup_data ? pickup_data.baskets : null;

                return <div>
                    {!dispatch_baskets && picker_baskets && <>
                        <div><b>Picker</b></div>
                        {picker_baskets.map((basket, i) => {
                            return <div key={`picker_b_${i}`} className="basketLab">
                                <div className="label" style={{ backgroundColor: basket.color || "#FFFFFF", color: basket.color ? (lightOrDark(basket.color) == 'light' ? "#000000" : "#FFFFFF") : '#000000' }}>{basket.title}</div>
                            </div>
                        })}
                    </>}

                    {dispatch_baskets && <>
                        <div><b>Dispatch</b></div>
                        {dispatch_baskets.map((basket, i) => {
                            return <div key={`picker_b_${i}`} className="basketLab">
                                <div className="label" style={{ backgroundColor: basket.color || "#FFFFFF", color: basket.color ? (lightOrDark(basket.color) == 'light' ? "#000000" : "#FFFFFF") : '#000000' }}>{basket.title}</div>
                            </div>
                        })}
                    </>}
                </div>
            }
        })
        if (columns.includes("address")) cols.push({
            width: 300, title: 'Address', dataIndex: 'address', render: (text, { shipping_address }) => {
                if (!shipping_address) return null;
                return (<div>
                    <span style={{ fontSize: "18px", paddingRight: "10px" }}><Icon icon="map-marker-alt" color={shipping_address.verified == "yes" ? "#60A52C" : "#FF311A"} /></span>
                    <b>{shipping_address.label}: </b>{shipping_address.full_address}, {shipping_address.city}
                </div>)
            }
        })
        if (columns.includes("created_at")) cols.push({
            width: 140, title: 'Order Date', dataIndex: 'created_at', render: (txt, rec) => (<>
                {utcToDate(txt).fromNow()}
                <br />
                {utcToDate(rec.created_at).format("DD MMM HH:mm")}
            </>)
        })
        if (columns.includes("delivery_slot")) cols.push({
            width: 160, title: 'Delivery Slot', dataIndex: 'delivery_slot', render: (__, rec) => {
                if (!rec?.delivery_slot?.from_date) return <div>Invalid Date</div>

                let d1 = utcToDate(rec.delivery_slot.date);
                let time_range_utc = JSON.parse(rec.delivery_slot.time_range_utc).map(o => (moment(o, "DD-MM-YYYYTHH:mm")));

                let fromDate = d1.clone().set({ 'hour': time_range_utc[0].format("HH"), 'minute': time_range_utc[0].format("mm"), second: 0 })
                let toDate = d1.clone().set({ 'hour': time_range_utc[1].format("HH"), 'minute': time_range_utc[1].format("mm"), second: 1 })

                return (<>
                    <div><b>{d1.fromNow()}</b></div>
                    <div>{d1.format("Do MMM YYYY")}</div>
                    <div><b>{`${fromDate.format("HH:mm")} ~ ${toDate.format("HH:mm")}`}</b></div>
                </>)

            }
        })
        if (columns.includes("zone")) cols.push({ width: 300, title: 'Zone', dataIndex: 'zone', render: (__, rec) => (<span>{rec?.connectedZone?.title}</span>) })
        if (columns.indexOf("status")) cols.push({
            width: 150, title: 'Status', dataIndex: 'status', align: "center", render: (text, record) => {
                return <>{orderStatus({ user:props.user, store: props.store }).find(o => o._id == text)?.title}</>
            }
        })
        if (columns.includes("paymentMethod")) cols.push({ width: 200, title: 'Payment method', dataIndex: ['paymentMethod', 'title'], align: 'center' })
        if (columns.includes("payment_status")) cols.push({ width: 100, title: 'Payment status', dataIndex: 'payment_status', align: 'center' })
        if (columns.includes("store")) cols.push({ width: 100, title: 'Store', dataIndex: ['store', 'title'], align: 'center' })
        if (columns.includes("grandTotal")) cols.push({ width: 90, title: 'Total', dataIndex: ['invoice', 'total'], align: 'center', render: (text, rec) => (text || rec.invoice.total) })
        if (columns.includes("grand_total")) cols.push({ width: 90, title: 'Grand total', dataIndex: ['invoice','grand_total'], align: 'center' })

        return cols;
    }

    const _columns = createColumns()
    let tableW = 0;
    columns.forEach(col => {
        tableW += col.width || 0;
    });


    return (<>
        {visible && <OrderDetails onClose={toggleDrawer} showform={visible} order={fields} previewOnly={true} />}

        <Table loading={loading}
            columns={_columns}
            dataSource={dataSource || null}
            pagination={pagination || false}
            onChange={handleTableChange}
            // rowSelection={rowSelection}
            // scroll={{ scrollToFirstRowOnChange: true, x: tableW + 50, y: -370 }}
            // scroll={{ x: tableW + 50, y: -370 }}
            scroll={{ x: tableW + 50, y: 500 }}
        />

    </>)
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        store: grocer_storeadmin.store,
        rights: grocer_storeadmin.rights,
    };
}
export default connect(mapStateToProps)(withRouter(OrderTable));
