// https://github.com/FortAwesome/react-fontawesome
// https://www.npmjs.com/package/@fortawesome/react-fontawesome
// https://fontawesome.com/v5/search?q=tick&m=free
/*************
 * size => xs, lg, 6x
 * fixedWidth
 * inverse
 * listItem
 * rotation={90}
 * flip="horizontal" => horizontal, vertical, both
 * spin
 * pause
 * border
 * pull="left" => left, right
 * <FontAwesomeIcon icon="spinner" size="xs" />
 * <FontAwesomeIcon icon={['fad', 'stroopwafel']} swapOpacity />
 */

import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { Button } from './Button';
// import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faInfo, faInfoCircle, faStore, faStoreAlt,
    faMapMarkerAlt, faBarcode, faChartLine,
    faAngleUp, faAngleDown, faBell, faFile,
    faCircle, faQuestionCircle,
    faCalculator, faCalendarAlt,
    faCaretRight, faCaretLeft, faChevronLeft, faAngleLeft,
    faCogs, faComment,
    faEllipsisV, faExclamation, faExclamationTriangle,
    faCheckSquare, faPrint,
    faCheck, faTimes, faTrashAlt,
    faKey, faUser, faUserShield, faLightbulb,
    faLock, faLockOpen,
    faPen, faSearch, faShoppingCart,
    faShoppingBag, faShuttleVan, faTruck, faTruckLoading, faDolly, faDonate, faShippingFast, faClock,
    faTh, faThLarge, faThList,
    faLaptopCode, faDesktop, faSignOutAlt,
    faBoxes, faCubes, faWallet,
    faUpload, faEye, faImage,
    faMinus, faPlus, faPhoneSquare,
    faTags, faBullhorn, faBan,
    faCopyright, faAddressBook, faCog, faGripHorizontal, faGripVertical, faBars, faExternalLinkAlt, faEnvelope, faCashRegister, faLink, faUserAstronaut
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
    faInfo, faInfoCircle, faStore, faStoreAlt,
    faMapMarkerAlt, faBarcode, faChartLine,
    faAngleUp, faAngleDown, faBell, faFile,
    faCircle, faQuestionCircle,
    faCalculator, faCalendarAlt,
    faCaretRight, faCaretLeft, faChevronLeft, faAngleLeft,
    faCogs, faComment, faCog,
    faEllipsisV, faExclamation, faExclamationTriangle,
    faCheckSquare, faPrint,
    faCheck, faTimes, faTrashAlt,
    faKey, faUser, faUserShield, faUserAstronaut, faLightbulb,
    faLock, faLockOpen,
    faPen, faSearch, faShoppingCart,
    faShoppingBag, faShuttleVan, faTruck, faTruckLoading, faDolly, faDonate, faShippingFast, faClock,
    faTh, faThLarge, faThList,
    faLaptopCode, faDesktop, faSignOutAlt,
    faBoxes, faCubes, faWallet,
    faUpload, faEye, faImage,
    faMinus, faPlus, faPhoneSquare,
    faTags, faBullhorn, faBan,
    faCopyright, faAddressBook, faEnvelope,
    faGripHorizontal, faGripVertical, faBars, faExternalLinkAlt, 
    faCashRegister, faLink
)
// console.log("library: ", library.definitions.fas);


function verifyIconAvailability(icon_name) {
    let icons = library.definitions.fas;
    return icons[icon_name] ? true : false;
}

/********
 * size{xs | lg | 6x}
 * icon={name}
 * fixedWidth={boolean}
 * inverse={boolean}
 * listItem={boolean}
 * rotation={number}
 * flip={horizontal | vertical | both}
 * spin={boolean} // animation
 * pulse={boolean} // animation
 * border={boolean}
 * pull={left | right}
 * className={string}
 * 
 * <Icon icon="bell" />
 */
export const Icon = React.forwardRef((_props, ref) => {
    // return <FontAwesomeIcon icon={props.icon} />
    // let props = {..._props}
    let props = { ..._props };
    
    // let className = `${!props.skipstyle && "anticon"} ${props.className||""}`;// props.skipstyle ? "" : "anticon" + props.className || "";
    let className = `${props.anticon && "anticon"} ${props.className||""}`;// props.skipstyle ? "" : "anticon" + props.className || "";
    delete props.skipstyle;
    delete props.className;
    delete props.anticon;

    if (!verifyIconAvailability(props.icon)) return <span ref={ref} {...props} className={`${className}`}>{props.icon || "ICON"}</span>;
    return <FontAwesomeIcon ref={ref} {...props} className={`${className}`} />
    // return <FontAwesomeIcon icon="search" color="green" />
});

// export const _Icon = _props => {
//     // return <FontAwesomeIcon icon={props.icon} />
//     // let props = {..._props}
//     let props = { ..._props };
    
//     let className = `${!props.skipstyle && "anticon"} ${props.className}`;// props.skipstyle ? "" : "anticon" + props.className || "";
//     delete props.skipstyle;
//     delete props.className;

//     if (!verifyIconAvailability(props.icon)) return <span {...props} className={`${className}`}>ICON</span>;
//     return <FontAwesomeIcon {...props} className={`${className}`} style={{color:"#CCCCCC"}} />
// }

// export const Icon = _props => {
//     // return <FontAwesomeIcon icon={props.icon} />
//     // let props = {..._props}
//     let props = { ..._props };
//     delete props.skipstyle;

//     let className = `${!props.skipstyle && "anticon"} ${props.className}`;// props.skipstyle ? "" : "anticon" + props.className || "";
//     // delete props.className;

//     if (!verifyIconAvailability(props.icon)) return <span {...props} className={`${className}`}>ICON</span>;
//     return <FontAwesomeIcon {...props} className={`${className}`} />
// }

export default Icon;

