import React from 'react'
import { Heading } from 'Common/components'


const ActivityInfo = ({ order }) => {
    
    const getPicker = () => {
        if (!order?.pickup_data?.pickup_by?._id) return null;

        return <>
            <Heading>Picker</Heading>
            <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                <div>Picked by: <b>{order.pickup_data.pickup_by.name || "Picker Not found"}</b></div>
                {order.pickup_data.baskets && <>
                    <div>Baskets: {order.pickup_data.baskets.map((o, i) => <div className="basketLab" key={i}>{o.title}</div>)}</div>
                </>}
            </div>
        </>
    }

    const getDispatcher = () => {
        if (!order?.till_by?._id) return null;
        return <>
            <Heading>Till</Heading>
            <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                <div>Dispatched by: <b>{order?.till_by?.name || "Till information not found"}</b></div>
                {order.dispatch_baskets && <>
                    <div>Baskets: {order.dispatch_baskets.map((o, i) => <div className="basketLab" key={i}>{o.title}</div>)}</div>
                </>}
            </div>

        </>
    }

    const getDriver = () => {
        if (!order?.dispatch_by?._id) return null;

        return <>
            <Heading>Driver</Heading>
            <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                <div>Delivered by: <b>{order?.dispatch_by?.name || "Driver Not found"}</b></div>
            </div>

        </>
    }

    const picker_data = getPicker();
    const dispatch_data = getDispatcher();
    const driver_data = getDriver();

    if (!picker_data && !dispatch_data && !driver_data) return null;

    return (
        <div className="grid-block">
            {picker_data}
            {dispatch_data}
            {driver_data}
        </div>
    )
}

export default ActivityInfo;