import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Loader, UserSelection } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import { publishStatus } from 'configs/constants';

const ZONES = loader('src/graphqls/geo_zone/geoZones.graphql');
const RECORD = loader('src/graphqls/vehicle/vehicle.graphql');
const RECORD_EDIT = loader('src/graphqls/vehicle/editVehicle.graphql');
const RECORD_ADD = loader('src/graphqls/vehicle/addVehicle.graphql');
const RECORD_DELETE = loader('src/graphqls/vehicle/deleteVehicle.graphql');

const defaultValues = {
    status: 'published',
}

const VehicleForm = props => {
    const [busy, setBusy] = React.useState(false);
    const { loading, vehicle, geoZones, loadingZones } = props;

    const loadingContents = loadingZones || loading;

    const fields = { ...defaultValues, ...vehicle }

    if (fields.drivers && fields.drivers.length>0){
        Object.assign(fields, {
            drivers: fields.drivers.map(o => ({ _id: o._id, name: o.name })),
            drivers_ids: fields.drivers.map(o => (o._id))
        })
    }

    const onSubmit = values => {
        const { onClose, editVehicle, addVehicle } = props;
        const _id = fields ? fields._id : false;
        // const _setFields = setFields;

        setBusy(true);

        let filteredValues = {
            title: values.title,
            registration_no: values.registration_no,
            zone: values.zone,
            status: values.status,
            drivers: values.drivers,
        };


       
        if (_id) {
            editVehicle({ ...filteredValues, _id }).then((e) => {
                setBusy(false)
                if (e.data.editVehicle.error) {
                    let err = e.data.editVehicle.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editVehicle);
            }).catch(error => {
                setBusy(false)
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addVehicle(filteredValues).then((e) => {
                setBusy(false);
                if (e.data.addVehicle.error) {
                    let err = e.data.addVehicle.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.addVehicle);
            }).catch(error => {
                setBusy(false);
                console.log(error);
                message.error("Query Error");
            });
        }

    }


    return (
        <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
            bodyStyle={{ padding: "0px" }}
            onClose={props.onClose}
            visible={props.showform}
            footer={<>
                <span />
                <Button loading={busy} type="primary" onClick={() => {
                    document.getElementById('VehicleForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`${fields && fields._id ? 'Edit' : 'Add'} Vehicle`}
        ><>

            {loadingContents && <><Loader loading={true} /></>}

            {!loadingContents &&
                <FormComponent onSubmit={onSubmit} id='VehicleForm' loading={busy} fields={fields}
                    mutators={{
                        selectDriver: (newValueArray, state, tools) => {
                            let node = newValueArray[0];
                            let drivers = state.formState.values.drivers || [];
                                drivers.push({ _id: node.value, name: node.children })

                            tools.changeValue(state, 'drivers', () => drivers);
                        },
                        de_selectDriver: (newValueArray, state, tools) => {
                            let drivers = state.formState.values.drivers || [];
                            let drivers_ids = state.formState.values.drivers_ids || [];
                                drivers = drivers.filter(o => (drivers_ids.includes(o._id)) )

                            tools.changeValue(state, 'drivers', () => drivers)
                        },
                    }}
                    form_render={formProps => {
                        const { values, form } = formProps;

                        return (<>
                            <FormField type="select" name="status" label="Status" className={values.status == 'published' ? "active" : "inactive"} data={publishStatus} validate={rules.required} />
                            <FormField type="text" name="title" label="Vehicle Name" placeholder="Vehicle title" validate={composeValidators(rules.required, rules.minChar(2))} />
                            <FormField type="text" name="registration_no" label="Registration No." placeholder="Vehicle Reg. No." validate={composeValidators(rules.required, rules.minChar(2))} />
                            <FormField type="select" name="zone" label="Delivery Zone" data={geoZones ? geoZones.map(item=>({ title:item.title, _id:item._id })) : []} validate={rules.required} />
                            <UserSelection preload
                                filter={{ type: 'driver' }} label="Drivers" name='drivers_ids' mode='multiple' 
                                onSelect={(txt, node) => form.mutators.selectDriver(node)} 
                                onDeselect={(txt, node) => form.mutators.de_selectDriver(node)}
                                defaultValues={values.drivers ? values.drivers.map(o=>({ _id:o._id, title:o.name })) : []}
                                />
                        </>)
                    }}
                />
            }

        </></Drawer>
    )
}

VehicleForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editVehicle: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addVehicle: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteVehicle: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),

    graphql(ZONES, {
        options: ({ fields }) => {
            return {
                variables: { filter: JSON.stringify({ category: 'delivery-zones' })},
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, geoZones, error } = data;
            if (error) console.log(__error("error"), error);
            return { loadingZones:loading, geoZones, queryErrors: error, }
        },
    }),

)(VehicleForm);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, vehicle, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, vehicle, queryErrors: error, }
        },
    }),
)(WithApollo);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;

