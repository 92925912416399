import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT} from './constants'
// export const SubMenu = props => ([
//   { path: `${ROOT}/countries`, exact: true, title: 'Countries', component: Countries },
//   { path: `${ROOT}/agreement`, exact: true, title: 'Agreement', component: Agreement },
//   { path: `${ROOT}/tags`, exact: true, title: 'Tags', component: Tags },
// ])

// addReducers({ admins: reducers });
addModule(
  {
    permission_required:'brands-access',
    path: `${ROOT}`, component: Home, icon: 'copyright', title: "Brands", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
