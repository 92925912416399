/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'Common/components';
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import { ROOT } from './constants';

import ProdCategories from './containers/ProdCategories'
import ProdTypes from './containers/ProdTypes'
import ProdAttributes from './containers/ProdAttributes'
import Catalogue from './containers/Catalogue'
import OutOfStock from './containers/OutOfStock'
import FeaturedProducts from './containers/FeaturedProducts'
// import OrphenProducts from './containers/OrphenProducts'


export const menuArray = props => ([
  // { path: `${ROOT}/page/:pageNum?`, menuPath: `${ROOT}/page/1`, exact: false, title: 'Catalogue', component: Catalogue },
  { path: `${ROOT}/catalogue`, menuPath: `${ROOT}/catalogue`, exact: false, title: 'Catalogue', component: Catalogue },
  { 
    permission_required: "manage-product-outofstock",
    path: `${ROOT}/out-of-stock`, exact: true, title: 'Out of Stock', component: OutOfStock 
  },
  // { 
  //   // permission_required: "manage-product-outofstock",
  //   path: `${ROOT}/orphen`, exact: true, title: 'Orphen Products', component: OrphenProducts 
  // },
  { 
    permission_required:"manage-product-categories",
    path: `${ROOT}/categories`, exact: true, title: 'Categories', component: ProdCategories 
  },
  { 
    permission_required:"manage-product-types",
    path: `${ROOT}/types`, exact: true, title: 'Types', component: ProdTypes 
  },
  { 
    permission_required:"manage-product-attributes",
    path: `${ROOT}/attributes`, exact: true, title: 'Attributes', component: ProdAttributes 
  },
  { 
    permission_required:"manage-featured-products",
    path: `${ROOT}/featured-products`, exact: true, title: 'Featured Products', component: FeaturedProducts
  },
])



/**
 * Home
 */
export const Home = props => {
  let routs = menuArray(); 

  return (
    <LayoutWrapper topMenu={menuArray()}>
      <Switch>
        {routs.map((item, i) => {
          if (!item.component) return null;
          return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
        })}
        <Route key={999} component={E404} />
      </Switch>
    </LayoutWrapper>
  )

}


Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

export default Home;