import React from 'react'
import PropTypes from 'prop-types';
import { Button } from 'Common/components'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Drawer, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

const RECORD_EDIT = loader('src/graphqls/user_role/edit.graphql');
const RECORD_ADD = loader('src/graphqls/user_role/add.graphql');


export const TypeForm = props => {
    const [loading, setLoading] = React.useState(false);

    const onSubmit = values => {
        const { fields, onClose, editUserRole, addUserRole } = props;
        const _id = fields ? fields._id : false;

        setLoading(true);
        // console.log("values: ", values); return false;

        let filteredValues = {
            title: values.title,
            acc_type: values.acc_type,
        };


        if (_id) {
            editUserRole({ ...filteredValues, _id: _id }).then((e) => {
                setLoading(false)
                if (e.data.editUserRole.error) {
                    let err = e.data.editUserRole.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editUserRole);
            }).catch(error => {
                setLoading(false)
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addUserRole(filteredValues).then((e) => {
                setLoading(false);
                if (e.data.addUserRole.error) {
                    let err = e.data.addUserRole.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.addUserRole);
            }).catch(error => {
                setLoading(false);
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    return (
            <Drawer width={400} destroyOnClose maskClosable={false} placement="right"
                onClose={props.onClose}
                visible={props.showform}
                bodyStyle={{}}
                footer={
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('TypeForm')
                            .dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Submit</Button>
                }
                title={`${props.fields && props.fields._id ? 'Edit' : 'Add'} Account Type`}
            >

                <FormComponent onSubmit={onSubmit} id='TypeForm' loading={loading} fields={props.fields} enterSubmit={true}>
                    <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                    <FormField type="text" name="acc_type" label="Type Key (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} />
                    {/* <Button type="primary" htmlType="submit">Submit</Button> */}
                </FormComponent>

            </Drawer>
    )
}

TypeForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}


const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUserRole: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addUserRole: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(TypeForm);

export default WithApollo;