import React from 'react';
import PropTypes from 'prop-types';
import { jsonStringify } from 'Common/scripts/Functions';
import _ from 'lodash'

/**
 * DevBlock
 **** this will print anything only in dev environment
 */
export const DevBlock = ({ obj, title, children }) => { // eslint-disable-line react/prefer-stateless-function
  if (process.env.NODE_ENV !== 'development') return null;

    return (<>
      <pre className="dev_block">
        {title && <div style={{ fontWeight:"bold", fontSize:"18px" }}>{title}</div>}
        {_.isString(obj) ? obj : jsonStringify(obj, 0, 2)}
      </pre>
      {children}
    </>);
}
DevBlock.propTypes = {
  // prop: PropTypes.type.isRequired
  title: PropTypes.string,
  obj: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]) 
}

export default DevBlock;
