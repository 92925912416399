import React, { Component } from 'react'
import { ContentArea, E404, LayoutWrapper } from "Layout_v1";
import OutOfStock from "Modules/Products/containers/OutOfStock";
import { ListHeader, PageHeader, OrdersMiniList, Button } from 'Common/components';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { Alert } from 'antd';

class StoreDashboard extends Component {
  render() {
    const {user, store} = this.props;
      if (!store || !store._id) return (<LayoutWrapper>
          <Alert message="Store not found!" type='error' showIcon />
      </LayoutWrapper>)
    
    return (<>
        <LayoutWrapper>
            <PageHeader title="Dashboard" style={{ margin: "10px" }} right={<Button href="/order_dashboard">Order Dashboard</Button>} />

            <ContentArea>
                <ListHeader title="Latest Orders" />
                <OrdersMiniList 
                  filter={{ }} 
                  // sort={{ _id:-1 }}
                  limit={5}
                  // hidePagination
                  />
            </ContentArea>

            <OutOfStock
                // titlePostfix={<><Link style={{ fontSize: "14px", color:'orange' }}  to={`/products/out-of-stock`}>show all</Link></>} 
                defaultPageSize={100}
                hideFilter={true}
                hideAddProduct={true}
            />

        </LayoutWrapper>

    </>)
  }
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    let { user, store } = grocer_storeadmin;
    return { user, store }
}
export default connect(mapStateToProps)(withRouter(StoreDashboard));
