import React from 'react'
// import { Button } from 'Common/components'
import OrdersList from '../components/orders_list'
import OrderDetails from '../components/order_details';
import { withRouter } from 'react-router-dom';
import { ContentArea } from 'Layout_v1';
import { useWindowDimensions } from 'Common/scripts/hooks';
// import { withRouter } from "react-router";



const Orders = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });

    const { height, width } = useWindowDimensions();
    
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        {visible && <OrderDetails statusUpdator={true} onClose={toggleDrawer} showform={visible} order={fields} />}
        <ContentArea>
            <OrdersList {...props} 
                screen={{ height, width }}
                onEditRecord={(item) => toggleDrawer({ fields: item })} 
                _path={`${props.match.path}/details`} noActions={true} />
        </ContentArea>
    </>)
}

export default withRouter(Orders);