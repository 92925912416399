import React from 'react'
import Login from 'Modules/Login/Home'
import { connect } from "react-redux";
import './styles/styles.scss';

const WrapperComp = ({ user, token, children }) => {
    return (!user || !user._id || !token) ? <Login /> : <>{children}</>
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return ({
        user: grocer_storeadmin.user,
        token: grocer_storeadmin.token
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export const Wrapper = connect(mapStateToProps, null)(WrapperComp);
export default Wrapper;

