import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Alert } from 'antd';
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Button, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import { updateUser } from 'Store/grocer_storeadmin.actions';

// const RECORD = loader('src/graphqls/user/user.graphql');
const RECORD_EDIT = loader('src/graphqls/user/edit.graphql');


const UserForm = ({ initialValues, onUpdate, editUser, loading, on_updateUser }) => {
    const [error, setError] = useState(null)

    const onSubmit = async (values) => {
        setError(null);
        if (values.password && (values.password != values.confirm_pwd)) {
            message.error("Password missmatch");
            return false;
        }

        const input = {
            _id: Number(initialValues._id),
            fname: values.fname,
            lname: values.lname,
            phone: values.phone,
            // email: values.email,
        }
        if (values.password) Object.assign(input, { password: values.password });

        const results = await editUser(input).then((e) => (e.data.editUser)).catch(error => {
            console.log(__error("Query Error: "), error);
            return { error: { message:"Query Error" }}
        });

        if (results.error){
            setError(results.error.message)
            return false;
        }
        if (onUpdate) onUpdate(results);
        on_updateUser({ user: results })
    }

    if (loading) return <Loader loading={true} />

    return (<>
        {error && <Alert message={error} type="error" showIcon />}

        <FormComponent onSubmit={onSubmit} id='UserEditForm' fields={{ ...initialValues }} style={{ padding:"0", margin:"-10px"}}
            form_render={formProps => {
                const { values, submitting, dirty, valid } = formProps;

                return (<>
                    <FormField type="text" name="fname" label="First Name" validate={rules.required} />
                    <FormField type="text" name="lname" label="Last Name" validate={rules.required} />
                    <FormField type="text" disabled={true} name="email" label="Email Address (user login)" />
                    <FormField type="text" name="phone" label="Phone" placeholder="Mobile number" validate={composeValidators(rules.required, rules.minChar(8))} />
                    <FormField type="checkbox" name="editPwd">Update Password?</FormField>

                    {values.editPwd && <>
                        <FormField type="password" name="password" label="Password (optional)" />
                        <FormField type="password" name="confirm_pwd" label="Confirm Password" disabled={!values.password} />
                    </>}

                    <div style={{ padding: "20px" }}><Button type="primary" htmlType="submit" loading={submitting} disabled={!dirty || !valid}>Save</Button></div>

                </>)
            }}
        />
    </>)

}

UserForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } },
                fetchPolicy: "no-cache"
            }),
        })
    }),
    // graphql(DELETE_USER_AVATAR, {
    //     props: ({ mutate }) => ({
    //         deleteUserAvatar: (id, fields) => mutate({
    //             variables: { id, fields }
    //         }),
    //     })
    // }),

)(UserForm);


// const EditWrapper = compose(
//     graphql(RECORD, {
//         options: ({ initialValues }) => {
//             return { 
//                 variables: { id: initialValues._id },
//                 fetchPolicy: "no-cache"
//             };
//         },
//         props: ({ ownProps, data }) => {
//             const { loading, user, error } = data;

//             if (error) console.log(__error("error"), error);

//             return { loading, user, queryErrors: error, }
//         },
//     }),
// )(WithApollo);

const mapDispatchToProps = (dispatch, ownProps) => ({
    on_updateUser: (payload) => dispatch(updateUser(payload)),
})
// const mapStateToProps = ({ grocer_storeadmin }) => {
//     return { user: grocer_storeadmin.user };
// }
const WithRedux = connect(null, mapDispatchToProps)(WithApollo);

export default WithRedux;
