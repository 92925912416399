import React, { Component, useState, useEffect } from 'react'
import { Table, Button, Heading, Loader, IconButton, Icon, DevBlock } from 'Common/components'
import { Row, Col, message, Popconfirm, Alert } from 'antd'
import { __error } from 'Common/scripts/consoleHelper'
import { convertTo24Hrs, convertTo12Hrs, utcToDate, sortArrayByVal } from 'Common/scripts/Functions'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { graphql, useLazyQuery, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { checkRights } from 'Common/scripts/Security';
import { connect } from "react-redux";
import moment from 'moment'


const RECORD_DELETE = loader('src/graphqls/delivery_slot/delete.graphql');

const dayArray = ['sat', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri'];

const ListComp = ({ client, onEditRecord, rights, slotsArray, onUpdate }) => {
    const [error, setError] = useState(false)
    const [busy, setBusy] = useState(false)
    const [tableData, setTableData] = useState(null)
    const [columns, setColumns] = useState([])

    useEffect(() => {
        setupTableColumns(slotsArray);
        setupTableData(slotsArray);
    }, [slotsArray])

    const getUniqueSlots = (deliverySlots) => {
        let unique_slots = []

        deliverySlots.forEach(element => {
            let time_range_utc = JSON.parse(element.time_range_utc);
            let slot = JSON.stringify({
                time_range_utc: time_range_utc,
                start_time: element.start_time,
                end_time: element.end_time
            });

            if (unique_slots.indexOf(slot) < 0) unique_slots.push(slot);
        });

        return unique_slots;
    }

    const setupTableColumns = (deliverySlots) => {
        let _columns = [];
        let slots = getUniqueSlots(deliverySlots);

        slots.forEach(element => {
            let dates = JSON.parse(element);
            dates.time_range_utc = [moment(dates.time_range_utc[0], "DD-MM-YYYYTHH:mm"), moment(dates.time_range_utc[1], "DD-MM-YYYYTHH:mm")]

            _columns.push({
                title: <>
                    {dates.time_range_utc[0].format('HHmm')} - {dates.time_range_utc[1].format('HHmm')} 
                    <br /><small>({dates.time_range_utc[0].format('hh:mm A')} - {dates.time_range_utc[1].format('hh:mm A')})</small>
                </>,
                dataIndex: `${dates.time_range_utc[0].format('HHmm')}-${dates.time_range_utc[1].format('HHmm')}`,
                width: 200,
                className: "timeslot-col",
                align: "center"
            })
        });

        _columns = sortArrayByVal(_columns, 'dataIndex', 'desc')
        // Add first/Day column
        _columns.splice(0, 0, { title: 'Day', width: 80, dataIndex: 'day', key: 'day', fixed: 'left', className: "day-title-column" });
        setColumns(_columns)
    }

    const handleDelete = async (id) => {
        let results = await client.mutate({ mutation: RECORD_DELETE, variables: { id } })
            .then(r => (r?.data?.deleteDeliverySlot))
            .catch(err=>{
                console.log(__error("Error: "), err)
                return { error: { message:"Unable to delete record" } }
            })
        if (!results || results.error){
            message.error(results?.error?.message || "Invalid reponse")
            return false;
        }

        onUpdate();
        message.success("Record deleted");
    }

    const setupDayRow = (day, deliverySlots) => {
        let row = {};
        let _deliverySlots = deliverySlots.slice();

        _deliverySlots.forEach(slot => {
            let blockedArray = slot.blocked_dates && slot.blocked_dates.indexOf(",") ? slot.blocked_dates.split(",") : slot.blocked_dates ? [slot.blocked_dates] : null;

            if (slot.day == day) {
                let dates = JSON.parse(slot.time_range_utc);
                dates = dates.map(itm => (moment(itm, "DD-MM-YYYYTHH:mm")))
                let slotKey = `${dates[0].format('HHmm')}-${dates[1].format('HHmm')}`

                let cellHTML = <div key={`cell_${slot._id}`}>
                    <div className="action-bar">
                        {checkRights(rights, 'dslots-manage') && <IconButton onClick={() => onEditRecord(slot)} icon="pen" />}
                        {checkRights(rights, 'dslots-delete') &&
                            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(slot._id)}>
                                <IconButton icon="trash-alt" />
                            </Popconfirm>
                        }
                    </div>
                    <div className="col-content">
                        <div>Max order limit: {slot.max_order_limit || 0}</div>
                        {blockedArray &&
                            blockedArray.map((item, i) => {
                                return <span className="block-date-holder" key={`bd_${i}`}>{item} <Icon icon="ban" /></span>
                            })
                        }
                    </div>
                </div>

                row[`${slotKey}`] = cellHTML;
            }
        });
        return row;
    }

    const setupTableData = (deliverySlots) => {
        let _tableData = [];
        dayArray.forEach(day => {
            let row = { day: day, _id: day }; // _id will be used as unique row key to avoide React error
            row = Object.assign(row, setupDayRow(day, deliverySlots));
            _tableData.push(row);
        });

        setTableData(_tableData)
    }

 
    if (error) return <Alert type='error' message={error} showIcon />
    if (busy) return <Loader loading={true} />
    if (!slotsArray) return <Alert type='info' message="No records found!" showIcon />


    return (<>
        <Table className="delierySlotsTable" columns={columns} dataSource={tableData} pagination={false} scroll={{ x: "100%" }} />
    </>)
}

const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
export default connect(mapStateToProps)(withApollo(ListComp));
