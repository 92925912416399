import React from 'react'
import PaymentMethodsList from './components/payment_methods_list';


export const PaymentMethods = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = (args={}) => {
        setState({ visible: !visible, fields: args?.fields || null });
    };

    return (<>
        <PaymentMethodsList onEditRecord={(item) => toggleDrawer({ fields: item })} onAddClick={() => toggleDrawer()} />
    </>)
}
export default PaymentMethods;