import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import Logout from './logout';
import { ROOT } from './constants'

// addReducers({ admins: reducers });
addModule(
  { path:`${ROOT}`, component:Home, icon:'login', title:"Login", exact:1, hideNav:1 },
  // { path:`${ROOT}`, component:Home, icon:<Icon type='desktop' />, title:"Dashboard", exact:1, hideNav:0 },
)
addModule(
  { path: `/logout`, component: Logout, icon:'sign-out-alt', title:"Logout", exact:1, hideNav:1 },
)
