import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import ClockLoader from "react-spinners/ClockLoader";

// https://ant.design/components/spin/

/***
 * Spin.setDefaultIndicator(indicator: ReactNode)

 * <Spin 
 * spinning={boolean}
 * tip={string}
 * size={small | default | large}
 * wrapperClassName={className}
 * delay={number}
 */

/**
   //  custom icon
   import { LoadingOutlined } from '@ant-design/icons';
   const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
   <Spin indicator={antIcon} />
 */

export const Loader = props => {
  const propTypes = {
    loading: PropTypes.bool.isRequired,
    center: PropTypes.bool,
  }

  let _props = { ...props };
  delete _props.center;
  delete _props.loading;
  // if (props.loading===true) _props = Object.assign(_props, {spinning:props.loading})
  _props = Object.assign(_props, {spinning:props.loading || false})

  if (props.center) {
    return <div style={{ flex:1, 
      display:"flex", flexDirection:"column",
      alignItems:"center", justifyContent:"center",
      ...props.style
    }}><Spin {..._props} indicator={<ClockLoader color="green" size={Number(props.size) || 50} />} /></div>
    // return <div style={{ flex:1, 
    //   display:"flex", flexDirection:"column",
    //   alignItems:"center", justifyContent:"center",
    //   ...props.style
    // }}><Spin {..._props} size="large" /></div>
  }


  return <Spin {..._props} indicator={<ClockLoader color="green" size={Number(props.size) || 50} />} />
};
export default Loader;