import React from 'react'
import PropTypes from 'prop-types';

import UserList from '../components/user_list';
import UserForm from '../components/user_form';


export const Users = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <UserList 
            onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddRecord={toggleDrawer}
        />

        {visible && <UserForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </>)
}
export default Users;