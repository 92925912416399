import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
// import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import _ from 'lodash'

const GET_LIST = loader('src/graphqls/key_value_pairs/keyValuePairs.graphql');
const catType = "voucher-types";

const VoucherTypeSelectionComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        return () => console.log;
    }, [props.preload])

    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
    
        var filter = { type: catType };
        if (_.isString(value)){
            setState({ kw: value, loading: true })
            Object.assign(filter, { ...props.filter, search: { keywords: value } })
            // filter = JSON.stringify({ ...props.filter, search:{keywords: value} });
        }
        else if(value){
            setState({ loading: true })
            Object.assign(filter, { ...props.filter, ...props.preload })
            // filter = JSON.stringify({ ...props.filter, ...props.preload });
        }

        filter = JSON.stringify(filter);


        props.client.query({ query: GET_LIST, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                setState({ kw: value, loading: false })
                return;
            }

            if (e.data.keyValuePairs) setList(e.data.keyValuePairs.map(o => ({ _id: o.key, title: o.val, raw: o })) )
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: keyValuePairs : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField validate={props.validate}
            type={props.mode || 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder={props.placeholder || "Search Voucher Types"}
            addNull={false}
            compact={props.compact}
            size={props.size}
            style={{...props.style, width:"100%"}}
            allowClear={props.allowClear}
            // keyMap="value=>text"
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                mode: props.mode || undefined, // tags, multiple
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                showSearch: props.disableSearch === true ? false : true,
                onSearch: props.disableSearch === true ? undefined : fetchData,

                // onChange:handelChange,
            }}
        />
    </>)
}
VoucherTypeSelectionComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    filter: PropTypes.object,
    disableSearch: PropTypes.bool,
}

export const VoucherTypeSelection = withApollo(VoucherTypeSelectionComp);
export default VoucherTypeSelection;
