import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';

const LIST_DATA = loader('src/graphqls/agreement/agreements.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/agreement/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/agreement/delete.graphql');


export class ListComp extends Component {
    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "agreementsUpdated",
                _subscriptionType: "simple-array",
                _queryName: "agreements",
                _typename: "Agreement",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteAgreement(id).then(r => {
            if (r && r.data && r.data.deleteAgreement && r.data.deleteAgreement.error) {
                message.error(r.data.deleteAgreement.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    renderActions = (text, record) => {
        return (
            <span className="action_buttons">
                <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                {record.deleteable > 0 &&
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                        <IconButton icon="trash-alt" />
                    </Popconfirm>
                }
            </span>
        )
    }

    columns = [
        { title: 'Name', dataIndex: 'title', width: '200px' },
        { title: 'Notes', dataIndex: 'note' },
        { title: 'Status', dataIndex: 'status', width: '100px', align: 'center' },
        {
            title: 'Actions',
            dataIndex: '',
            render: this.renderActions,
            className: 'actions-column',
            align: 'right',
            width: '100px'
        },
    ];

    render() {
        const { loading, agreements, queryErrors } = this.props;

        return (
            <>
                <Table loading={loading}
                    columns={this.columns}
                    dataSource={agreements ? agreements : null}
                    pagination={false}
                />
            </>
        )
    }
}

ListComp.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, agreements, error, fetchMore, subscribeToMore } = data;

            if (error) {
                message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
                console.log(__error("error"), error);
                // error.networkError.name
                // error.networkError.response.status
                // error.networkError.response.statusText
                console.log(__error("ERROR DETAILS"), error.networkError.result.errors);

            }

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.agreements
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, agreements, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteAgreement: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

export default WithApollo;
// 