import React, { Component } from 'react'
import { Dropdown as _Dropdown, Menu, Space } from 'antd';
import Icon from './Icon';


export const Dropdown = ({ data, title, icon, onClick, disabled=false }) => {
    const menu = (
        <Menu>
            {data.map((item, i) => (<Menu.Item onClick={() => onClick(item)} disabled={item.disabled ? true : false} key={i}>{item.title}</Menu.Item>))}
        </Menu>
    );

    let theIcon = !icon ? null : <span style={{ display:"inline-block", border:"1px solid #EEE", padding:"5px 10px", marginRight:"10px", borderRadius:"5px" }}><Icon icon={icon} /></span>

    return (<>
        <_Dropdown overlay={menu} disabled={disabled}>
            <a onClick={(e) => e.preventDefault()}>{theIcon}{title}</a>
        </_Dropdown>
    </>)
}
