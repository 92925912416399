import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { generalStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'

const RECORD = loader('src/graphqls/tags/tag.graphql');
const RECORD_EDIT = loader('src/graphqls/tags/edit.graphql');
const RECORD_ADD = loader('src/graphqls/tags/add.graphql');


export class FormComp extends Component {
    // fields = { status: "disabled" };

    constructor(props) {
        super(props);
        this.state = { loading: false, fields: props.fields ? props.fields : { status: "disabled"} }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = _values => {
        // console.log("onSubmit()", _values); return;

        const { tag, onClose, editTag, addTag } = this.props;
        const { fields } = this.state;
        // let fields = fields ? { ...fields } : tag ? { ...tag } : {}
        let values = { ..._values }

        const _id = fields ? fields._id : false;

        this.setState({ loading: true });

        let filteredValues = {
            title: values.title
        };

        if (_id) {
            editTag({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({ loading: false })
                if (e.data.editTag.error) {
                    let err = e.data.editTag.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editTag);
            }).catch(error => {
                this.setState({ loading: false })
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addTag(filteredValues).then((e) => {
                this.setState({ loading: false });
                if (e.data.addTag.error) {
                    let err = e.data.addTag.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                console.log("e.data.addTag: ", e.data.addTag);

                // _fields = Object.assign(_fields, { ...e.data.addTag })
                // _setFields({ ...e.data.addTag });
                onClose(e.data.addTag);
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                message.error("Query Error");
            });
        }

    }


    render() {
        console.log("this.props: ", this.props);
        
        const { onClose, tag, showform } = this.props;
        const { fields, loading } = this.state;

        console.log("fields: ", fields);
        

        // this.fields = __fields ? { ...__fields } : tag ? { ...tag } : this.fields;

        return (
            <Drawer width={"300px"} destroyOnClose maskClosable={false} placement="right"
                // loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ 
                    padding:"10px"
                    // backgroundColor: "#f0f2f5" 
                    }}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('TagForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${fields && fields._id ? 'Edit' : 'Add'} Tag`}
            >
                <FormComponent onSubmit={this.onSubmit} id='TagForm' loading={loading} fields={{ ...fields }}
                    form_render={({ values }) => {
                        return <>
                            <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                        </>
                    }}
                />

            </Drawer>
        )
    }
}


FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editTag: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addTag: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);

// const EditWrapper = compose(
//     graphql(RECORD, {
//         options: ({ fields }) => {
//             return { variables: { id: fields._id } };
//         },
//         props: ({ ownProps, data }) => {
//             const { loading, tag, error } = data;

//             if (error) {
//                 message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
//                 console.log(__error("error"), error);
//                 // error.networkError.name
//                 // error.networkError.response.status
//                 // error.networkError.response.statusText
//                 console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
//             }

//             return { loadingEditNode: loading, tag, queryErrors: error, }
//         },
//     }),
// )(WithApollo);
// export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />

export default WithApollo;