export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const RESET_SETTINGS = 'RESET_SETTINGS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_USER = 'UPDATE_USER';

export const updateSettings = payload => ({ type: UPDATE_SETTINGS, payload });
export const resetSettings = payload => ({ type: RESET_SETTINGS, payload });

export const loginUser = payload => ({ type: LOGIN_USER, payload });
export const logoutUser = payload => ({ type: LOGOUT_USER, payload });
export const setLoading = payload => ({ type: SET_LOADING, payload });

export const updateStore = payload => ({ type: UPDATE_STORE, payload });
export const updateUser = payload => ({ type: UPDATE_USER, payload });
