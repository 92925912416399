import React from 'react'
import { Layout } from 'antd';
import { appText } from 'configs';

const { Footer } = Layout;


export const LayoutFooter = props => {
        return (
            <Footer className="site-footer">{appText.footer_text}</Footer>
        )
}
