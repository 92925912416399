import React from 'react'
import { Result, Button } from 'antd';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col } from 'antd';

export const E404 = props => {
    // let location = useLocation();
    let history = useHistory();
    
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            
            extra={<Row justify="center"><Col flex="250px"><Row>
                <Col><Button type="primary" onClick={() => history.goBack()}>Back</Button></Col>
                <Col flex="auto" />
                <Col><Button type="primary" onClick={() => history.push('/')}>Home</Button></Col>
            </Row></Col></Row>}
            // history.push(loc)
            // extra={<Button type="primary" href="/">Back Home</Button>}
        />
    )

    // return (
    //     <div>
    //         <div>{window.location.href}</div>
    //         <div>{props.code || "Error 404"}</div>
    //         <div>Sorry, the page you visited does not exist</div>
    //     </div>
    // )
}

export default E404;
