import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { graphql, useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
// import moment from 'moment';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Space, Modal } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton, Barcode } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { verifyRole } from 'Common/scripts/Security';
import { sleep } from 'Common/scripts/Functions';
import StoreFilter from './StoreFilter';

const LIST_DATA = loader('src/graphqls/stores/storesQuery.graphql');
const RECORD_DELETE = loader('src/graphqls/stores/deleteStore.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/stores/subscription.graphql');
const RESET_STORE = loader('src/graphqls/stores/reset_store.graphql');

const ConfrimationComp = ({ store_id, onCancel }) => {
    const [busy, setBusy] = useState(false);

    const [reset_store] = useMutation(RESET_STORE, { variables: { _id: Number(store_id) } });


    const processReset = async() => {
        setBusy(true)
        // console.log("processReset()", store_id)
        // await sleep(2000)

        let result = await reset_store({ variables: { store_id: Number(store_id) } })
        setBusy(false);

        if (result?.data?.reset_store?.error){
            message.error(result.data.reset_store.error.message)
            return;
        }

        onCancel();
    }

    return (<Loader loading={busy}>
        <p>This will reset all data, removing all orders and stats, do you still want to continue?</p>

        <Row>
            <Col><Button onClick={onCancel}>No</Button></Col>
            <Col flex="auto" />
            <Col><Button onClick={processReset} color="red">Yes</Button></Col>
        </Row>

    </Loader>)
}


export class ListComp extends Component {
    state = { 
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total:0 },
        busy: false, showConfirmation:false };
    subscription = false;
    modal;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                // pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.storesQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "storesUpdated",
                _subscriptionType: "array",
                _queryName: "storesQuery",
                _typename: "Stores",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteStore(id).then(r => {
            if (r && r.data && r.data.deleteStore && r.data.deleteStore.error) {
                message.error(r.data.deleteStore.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }


    columns = () => {
        // console.log("this.props: ", this.props)

        return [
            { title: 'ID', dataIndex: '_id', width: '80px' },
            {
                title: 'Title', dataIndex: 'title', render: (text, rec) => {
                    return (<Row className='show-menu-on-hover'>
                        <Col>
                            {rec.title}
                            <div><Barcode height={25} value={rec.barcode} displayValue={true} /></div>
                        </Col>
                        <Col className='hidden-menu'>
                            <Space>
                                <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                                <Button onClick={() => this.props.on_showStoreLocations(rec?._id)} size="small">Locations</Button>
                                {verifyRole('delete-store', this.props.user) && <DeleteButton onClick={() => this.handleDelete(rec?._id)} />}
                                {verifyRole('reset-store', this.props.user) && <Button onClick={() => this.setState({showConfirmation:rec?._id})} color="red" size="small">Reset Store Data</Button>}
                            </Space>
                        </Col>
                    </Row>)
                }
            },
            {
                title: 'Owner', dataIndex: 'owner.name', className: 'show-menu-on-hover', render: (text, rec) => {
                    return (<Row>
                        <Col>{rec.owner.name}</Col>
                        <Col className='hidden-menu'>
                            <IconButton icon="pen" onClick={() => this.props.onEditOwner(rec.owner)} />
                        </Col>
                    </Row>)
                }
            },
            // { title: 'Owner', dataIndex: 'owner.name', className: 'show-menu-on-hover', render: (__, record)=>{
            //     return(<>
            //         <Row>
            //             <Col>{record.owner.name}</Col>
            //             <Col className='hidden-menu'>
            //                 <Button onClick={() => this.props.onEditOwner(record.owner)} icon={<Icon icon='pen' />} size='small'>Edit</Button>
            //             </Col>
            //         </Row>
            //     </>)
            // } },
            { title: 'Status', dataIndex: 'status', width: "100px", },
        ]
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ busy:true })

        let filter = filters ? JSON.stringify(filters) : this.props.queryString;

        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.page - 1),
            filter,
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        ).then(r=>{
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.storesQuery?.totalCount },
                busy: false
            })
        })
    };

    onSearch = (filter) => {
        console.log("onSearch()", filter)
        this.handleTableChange({ page: 1, pageSize: this.state.pagination.pageSize }, filter);
    }

    render() {
        const { loading, storesQuery, queryErrors } = this.props;
        const { pagination, busy, showConfirmation } = this.state;

        return (<>
            <ListHeader title="Stores" sub={storesQuery && `Total ${storesQuery.totalCount} stores found`} right={<><div className="buttons"><Button onClick={this.props.toggleDrawer}>Add New Store</Button></div></>} />

            <StoreFilter onSearch={this.onSearch} />

            <Table loading={loading || busy}
                columns={this.columns()}
                dataSource={storesQuery ? storesQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status != 'online' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
                // rowClassName={(record => {
                //     return record.status == 'hidden' ? 'disabled-table-row' : "";
                // })}
                // title={() => <span>Header</span>}
                // footer={() => 'Footer'}
                // expandable={{
                //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                //     // rowExpandable: record => record.name !== 'Not Expandable',
                // }}
            />


            <Modal title="Reset Confirmation!" closable={false} width="500px" maskClosable={false} destroyOnClose visible={showConfirmation !== false} footer={false} onCancel={() => this.setState({ showConfirmation:false })}>
                {showConfirmation && <ConfrimationComp onCancel={() => this.setState({ showConfirmation: false })} store_id={showConfirmation} />}
            </Modal>

        </>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0 } };
        },
        props: ({ ownProps, data }) => {
            const { loading, storesQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.storesQuery.totalCount
                    const newEdges = fetchMoreResult.storesQuery.edges
                    const pageInfo = fetchMoreResult.storesQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        storesQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'Stores'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, storesQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteStore: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updateSettings: (payload) => dispatch(updateSettings(payload)),
// })
const mapStateToProps = ({ grocer_storeadmin }) => {
    return { user: { ...grocer_storeadmin.user, rights: grocer_storeadmin.rights } };
}
const WithRedux = connect(mapStateToProps, null)(WithApollo);


export default WithRedux;