import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { FormField, FormComponent, FormFieldGroup, SubmitButton } from 'Common/components/Form'
import { formToFilter } from 'Common/scripts/Functions'
import { connect } from "react-redux";
import { Icon, Button, Loader, VoucherCategorySelection, VoucherTypeSelection } from 'Common/components';


const VoucherFilterComp = ({ onSearch, loading, defaultValue, filterType, default_timezone }) => {

    const onSubmit = values => {
        let filter = formToFilter(values, { tz: default_timezone })
        onSearch(filter);
    }

    if(loading) return <Loader loading={true} />

    return (<div className="pagination-filter" style={{ paddingLeft:"5px" }}>
        <FormComponent onSubmit={onSubmit} id='StoreFilterForm' fields={defaultValue} debug={false}>
            <Row className="-pagination-filter-row" gutter={[5]} align="middle">
                <Col flex="300px"><FormField type="text" prefix={<Icon icon="search" color="#CCC" />} name="search.keywords" placeholder="keyword to search..." compact allowClear size="large" /></Col>
                <Col flex="250px"><VoucherCategorySelection name="category*key" preload disableSearch allowClear compact size="large" /></Col>
                <Col flex="250px"><VoucherTypeSelection name="voucher_type*key" preload disableSearch allowClear compact size="large" /></Col>
                <Col flex="300px"><FormField type="switch" name="loyalty_plan" compact label="Loyalty Plan" /></Col>

                <Col flex="auto" />
                <Col className="go-column">
                    <Button className="send_button" style={{ margin:"0 5px" }} loading={loading} htmlType="submit"><Icon icon="search" /></Button>
                </Col>
            </Row>
        </FormComponent>
    </div>)

}
VoucherFilterComp.propTypes = {
    onSearch: PropTypes.func.isRequired,
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.default_timezone };
}
export const VoucherFilter = connect(mapStateToProps)(VoucherFilterComp);
export default VoucherFilter;
