import React from 'react';
import { Select } from 'antd';
const {OptGroup} = Select;

export const genders = [
  { title: "Male", _id: "male" },
  { title: "Female", _id: "female" },
]

export const defaultDateFormat = "DD-MM-YYYY"
export const defaultDateTimeFormat = "DD-MM-YYYY HH:mm"

export const generalStatus = [{ title: "Enabled", _id: 'enabled' }, { title: "Disabled", _id: 'disabled' }];

export const daysList = [{ title: 'Sat', _id: 'sat' }, { title: 'Sun', _id: 'sun' }, { title: 'Mon', _id: 'mon' },
  { title: 'Tue', _id: 'tue' }, { title: 'Wed', _id: 'wed' }, { title: 'Thu', _id: 'thu' }, { title: 'Fri', _id: 'fri' }]

export const taxTypes = [{ title: 'Fix', _id: 'fix' }, { title: '%', _id: '%' }]

export const yesNoStatus = [{ title: 'Yes', _id: 'yes' }, { title: 'No', _id: 'no' }]

export const booleanStatus = [{ title: 'Yes', _id: 1 }, { title: 'No', _id: 0 }]

export const discountTypes = [{ title: 'Fixed Amount', _id: 'fix' }, { title: 'Percent', _id: '%' }]
export const storeStatus = [{ title: 'Online', _id: 'online' }, { title: 'Offline', _id: 'offline' }, { title: "Inactive", _id:"inactive" }]

export const attributesDisplayTypes = [{ title: "Single", _id: "single" }, { title: "Multi", _id:"multi"}];

export const geoZoneCategories = [
  { title: 'Service Area', _id: "service-area" },
  { title: 'Delivery Zones', _id: "delivery-zones" },
]

// export const voucherCategories = [
//   { title: 'General', _id: "general" },
//   { title: 'Loyalty Plan', _id: "loyalty_plan" },
//   { title: 'Delivery', _id: "delivery" },
// ]

export const walletStatus = [
  { title: 'In-wallet', _id: "in-wallet" },
  { title: 'Paid-off', _id: 'paid-off' },
  { title: 'Partially-paid', _id: 'partially-paid' },
]

// export const serviceCities = [
//   { title: 'Lahore', _id: "lahore", coordinates: [{ lat:31.52443022759592, lng:74.35772741448616 }] },
//   { title: 'Sahiwal', _id: 'sahiwal', coordinates: [{ lat:30.669168798330947, lng:73.1108723773994 }] },
// ]

export const basketCategories = [
  { title: 'Pickup', _id: "pickup" },
  { title: 'Dispatch', _id: 'dispatch' },
]

export const locationTypes = [
  { title: 'Country', _id: "country" },
  { title: 'City', _id: 'city' },
]

export const discountRequirements = [
  { title: 'None', _id: "none" },
  { title: 'Min Order Total', _id: 'min_order_total' },
  { title: 'Min Items Qty.', _id: 'min_order_item_qty' },
]

export const orderStatus = (args) => {
  const { user, store } = args || {};
  var isStore = (store && store?._id) ? true : false;

  return [
    // { title: 'Unconfirmed', _id: 'unconfirmed' },
    { title: 'New', _id: 'new', icon: "circle" },
    { divider: <hr color="#EEE" /> },
    { title: 'Picking', _id: 'picking', icon: 'dolly' },
    { title: 'In Review/Picked', _id: 'in-review', icon: 'th-list' },
    { title: 'Ready To Dispatch', _id: 'ready-to-dispatch', icon: "circle", color: 'green' },
    { title: 'Dispatched', _id: 'dispatched', icon: 'shipping-fast' },
    { title: 'Delivered', _id: 'delivered', icon: 'check' },
    { title: 'Completed', _id: 'completed', icon: "check", color: "green" },
    { divider: <hr color="#EEE" /> },
    { title: 'Pending', _id: 'pending', icon: "clock", color: "orange" },
    { title: 'Canceled', _id: 'canceled', icon: "times", color: "red" },
    { title: 'Declined', _id: 'declined', icon: "times", color: "orange" },
    // { title: 'Refunded', _id: 'refunded', icon: "donate", color: "orange" }
  ].filter(o=>{
    if (isStore){
      return ['new', 'picking', 'in-review', 'ready-to-dispatch', 'dispatched', 'delivered', 'completed', 'canceled', 'declined', 'refunded'].includes(o._id);
    }
    return true;
  });
}
export const orderPaymentStatus = [ { title: 'Unpaid', _id: 'unpaid' }, { title: 'Paid', _id: 'paid' } ]


export const publishStatus = [{ title: "Published", _id: 'published' }, { title: "Hidden", _id: 'hidden' }];

export const staffTypes = [
  { title: "Picker", _id: 'picker' }, 
  { title: "Driver", _id: 'driver' },
  { title: "Till Dispatcher", _id: 'till-dispatcher' },
  { title: "Store Supervisor", _id: 'store-supervisor' }
];

export const GOOGLE_API_KEY = "AIzaSyAXROby7PEree2w4gB9UkNOspY2y2vjclo";





/*****************************
 * PAGE Layout Settings
 * 
******************************* */
export const pageOptionsArray = {
  "home-page": {
    banners: ["top-slider", "top-banner", "mid-banner", "bottom-banner"],
    products: ["featured-products", "top-products"],
  }
}





export const notFoundReasons = [
  { title: 'Out of stock', _id: 'out_of_stock' },
  { title: 'Customer requested the refund', _id: 'refund_requested' },
  { title: 'No adequate replacements', _id: 'no_replacement' },
  { title: 'Other', _id: 'other' },
]
