import React, { useState } from 'react'
import PropTypes from 'prop-types';
// import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
// import compose from 'lodash.flowright';
import { Row, Col, Divider, message, Cascader, Input, AutoComplete, Avatar } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { DevBlock, Icon, Button, Loader, IconButton } from 'Common/components'
import _ from 'lodash';
import { withApollo } from 'react-apollo';


const PRODUCTS = loader('src/graphqls/product/products.graphql');

const renderItem = (id, title, picture_thumb) => ({
    value: title,
    product: { _id:id, title, picture_thumb },
    label: (<Row align="middle">
                <Col style={{paddingRight:5}}>{<Avatar src={picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${picture_thumb}` : null} icon={<Icon icon="image" />} />}</Col>
                <Col flex="auto">{title}</Col>
            </Row>),
});


const ProductBarcodeFilterComp = props => {
    const [busy, setBusy] = useState(false)
    const [results, setResults] = useState([])

    const searchProduct = value => {
        console.log("searchProduct()", value);

        setResults([
            {
                disabled: true,
                label: <Row><Col flex="auto">Search Results</Col><Col><Loader loading={true} /></Col></Row>,
                // options: [renderItem('AntDesign', 10000), renderItem('AntDesign UI', 10600)],
            }
        ]);

        
        props.client.query({
            query: PRODUCTS,
            variables: { filter: JSON.stringify({ barcode: value }) },
            fetchPolicy: "no-cache",
        })
            .then(e => {
                setResults([])

                if (e.data.products.error || e.errors) {
                    console.log("ERROR: ", e);
                    message.error((e.data.products.error && e.error.message) || (e.errors && e.errors[0].message));
                    return;
                }

                if (!e.data.products || e.data.products.length < 1 || !e.data.products[0] || !e.data.products[0]._id){
                    message.error("Invalid barcode");
                    return;
                }

                const product = e.data.products[0];

                setResults([
                    {
                        disabled: false,
                        label: <b>Search results</b>,
                        // label: <Row align="middle" onClick={() => props.onEditClick(product)}>
                        //     <Col style={{paddingRight:5}}>{<Avatar src={product.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${product.picture_thumb}` : null} icon={<Icon icon="image" />} />}</Col>
                        //     <Col flex="auto">{product.title}</Col>
                        // </Row>,
                        options: [renderItem(product._id, product.title, product.picture_thumb)],
                        // options: [{ value: e.data.products[0]._id, label: e.data.products[0].title }],
                    }
                ])

            }).catch(err => {
                console.log(__error("API Call ERROR: PRODUCTS : "), err);
                message.error("Request ERROR");
                setResults([]);
            })
    }
    // const debounced = (value) => _.debounce((value) => searchProduct(value), 1000, { 'maxWait': 1000 });
    const debounced = _.debounce(searchProduct, 1000, { 'maxWait': 5000, trailing:true });

    const onTextUpdate = value => {
        if (!value || value.length<3) return;
        console.log("onTextUpdate", value)

        debounced(value);
    }

    // if(props.loading) return <Loader loading={true} />

    return (<div className="pagination-filter" style={{ borderRadius: 5 }}>
        <Row className="pagination-filter-row" style={{ padding: "3px 10px" }} align="middle">
            {/* <Col style={{color:"#999", paddingRight:10}}><Icon icon="barcode" color="black" size={"2x"} />Barcode search</Col> */}
            <Col><Icon icon="barcode" color="#999" size={"4x"} /></Col>
            {/* <Col flex="auto"><Search placeholder="Barcode Search" onSearch={onTextUpdate} loading={busy} /></Col> */}
            {/* <Col flex="atuo">
                <Cascader
                    // value={} label=""
                    options={options}
                    onChange={onChange}
                    placeholder="Please select"
                    showSearch={{ filter }}
                />
            </Col> */}
            <Col flex="auto">
                <AutoComplete
                    defaultActiveFirstOption
                    onSearch={onTextUpdate}
                    onSelect={(__, val) => props.onEditClick(val.product)}
                    // onSelect={(__, val) => console.log(val.product)}
                    dropdownClassName="certain-category-search-dropdown"
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={300}
                    // placeholder="Search Barcode"
                    options={results}
                >
                    <Input.Search size="large" placeholder="Search Barcode" />
                </AutoComplete>
            </Col>
        </Row>
    </div>)

}
ProductBarcodeFilterComp.propTypes = {
    onEditClick: PropTypes.func.isRequired,
    // exclude: PropTypes.array,
}

export const ProductBarcodeFilter = withApollo(ProductBarcodeFilterComp);

// export default ProductFilter;
