import React, { Component } from 'react'
import { Button, Loader } from 'Common/components'
import axios from 'axios';
import { mainModule } from 'process';

export default class TestSms extends Component {
    constructor(props) {
        super(props)
        this.state = { busy:false, result:null }
        this.testSMS = this.testSMS.bind(this);
    }

    testSMS = async () => {
        console.log("testSMS()");
        this.setState({busy:true});

        const result = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/mail/send',
            data: { 
                app: "web-admin",
                to: "bluevision@gmail.com",
                subject:"test mail subject",
                template: "registration",
                data:{
                    user:{
                        fname: "Test fname", 
                        email: "bv@main.com"
                    },
                }
            }
        })
            .then(async function (response) {
                console.log("RESPONSE: ", response);
                if(response.data.error){
                    return response.data.error.message;
                    // this.setState({ result: response.data.error.message })
                }
                return "Response received";
            })
            .catch(function (error) {
                console.log("ERROR: ", error);
                return "Netowrk Error";
                // this.setState({ result: "Netowrk Error" })
                // handelResponse({ error: { message: 'Netowrk Error' } });
            });

        this.setState({ busy: false, result });

    }


    render() {
        const { busy, result } = this.state;

        return (
            <div>
                <Button loading={busy} onClick={()=>this.testSMS()}>Test SMS</Button>

                <p>{result}</p>
            </div>
        )
    }
}
