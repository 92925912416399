import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, VoucherTypeSelection, VoucherCategorySelection, SelectPaymentMethods } from 'Common/components'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField, SubmitButton } from 'Common/components/Form'
import { message, Row, Col, Divider } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { generalStatus, discountTypes, publishStatus, discountRequirements } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper';
import { connect } from "react-redux";

const RECORD_BULK_ADD = loader('src/graphqls/discount_voucher/addBulkDiscountVoucher.graphql');
const RECORD_ADD = loader('src/graphqls/discount_voucher/add.graphql');
const RECORD_EDIT = loader('src/graphqls/discount_voucher/edit.graphql');
const RECORD_DELETE = loader('src/graphqls/discount_voucher/delete.graphql');
const DELETE_IMG = loader('src/graphqls/discount_voucher/delete_img.graphql');


const defaultFields = { 
    status: "hidden", 
    // category: "general", 
    requirements: { type: "none" }, 
    activation_date: moment(), 
    deactivation_date:moment().add(7, "days")
};

const VoucherForm = ({ store, bulkRecords, fields, onClose, onSuccess, addBulkDiscountVoucher, addDiscountVoucher, editDiscountVoucher, deleteDiscountVoucherPicture }) => {
    const [loading, setLoading] = useState(false);
    const [initialValues, set_initialValues] = useState({ ...defaultFields, ...fields });

    const onSubmit = async values => {
        const _id = fields ? fields._id : false;

        let input = {
            category: {
                key: values.category.key,
                val: values.category.val,
                type: values.category.type,
            },
            title: values.title,
            status: values.status,
            code: values.code,
            voucher_type: {
                key: values.voucher_type.key,
                val: values.voucher_type.val,
                type: values.voucher_type.type,
            },
            discount_type: values.discount_type,
            discount_value: values.discount_value,
            payment_method: values?.payment_method?._id && {
                title: values.payment_method.title,
                ref_key: values.payment_method.ref_key,
                id: values.payment_method._id,
            },
            limit_usage: values.limit_usage || 0,
            limit_one_per_customer: values.limit_one_per_customer ? 1 : 0,

            activation_date: values.activation_date,
            deactivation_date: values.deactivation_date,

            details: values.details,
            loyalty_plan: values.loyalty_plan ? true : false,
        }
        if (!_id) Object.assign(input, { loyalty_plan: values.loyalty_plan ? true : false })
        if (_id) delete input.code;

        if (values?.requirements?.type == 'none') Object.assign(input, { requirements: { type: "none", value: 0 }})
        else if (values?.requirements?.type) Object.assign(input, { requirements: { type: values.requirements.type, value: values.requirements.value } })

        if (bulkRecords) return doMultipleRecords({ voucher: input, qty: Number(values.qty) })
        
        // input.requirements = (!values.requirements || values.requirements.type == "none") ? { type: "none", value: 0 } : { type: values.requirements.type, value: values.requirements.value }
        // if (values.code && !values._id) input = Object.assign(input, { code: values.code });

        if (_id) return doUpdate({ ...input, _id: Number(_id) })
        else return addSingleRecord(input);
    }

    const doUpdate = async (values) => {
        const resutls = await editDiscountVoucher(values).then((e) => (e.data.editDiscountVoucher))
            .catch(error => {
                console.log(__error("Query Error: "), error)
                return { error: { message: "Query Error" } }
            });

        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }
        return onSuccess(resutls);
    }

    const addSingleRecord = async (values) => {
        const resutls = await addDiscountVoucher(values).then((e) => (e.data.addDiscountVoucher))
            .catch(error => {
                console.log(__error("Query Error: "), error)
                return { error: { message: "Query Error" } }
            });

        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }
        return onSuccess(resutls);
    }

    const doMultipleRecords = async (values) => {
        const resutls = await addBulkDiscountVoucher(values).then((e) => (e.data.addBulkDiscountVoucher))
            .catch(error => {
                console.log(__error("Query Error: "), error)
                return { error: { message: "Query Error" } }
            });
        // console.log("resutls: ", resutls)

        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }

        message.success(resutls.success.message);
        return onSuccess(resutls);
    }


    const onFileDeleteClicked = async (fileInfo) => {
        let srcField = fileInfo.name
        let src = fileInfo.srcUrl;
        let thumb = fileInfo.thumbUrl;
        let thumbField = fileInfo.name + "_thumb";

        if (fileInfo.name !== "picture") return { error: { message: "Invalid action" } };

        setLoading(true)
        const resutls = await deleteDiscountVoucherPicture(fields._id, `${srcField},${thumbField}`).then((e) => (e.data.deleteDiscountVoucherPicture))
            .catch(err => {
                console.log(__error("Query Error"), err)
                return { error: { message: "Query Error" } };
            });
        setLoading(false)

        if (resutls.error) message.error(resutls.error.message);
        return resutls;

    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='DiscountVoucherForm' loading={loading} fields={initialValues}
            mutators={{
                onCateChange: (newValueArray, state, tools) => {
                    let val = newValueArray[0]?.['item-data']?.raw || undefined;
                    tools.changeValue(state, 'category', () => val)
                },
                onTypeChange: (newValueArray, state, tools) => {
                    let val = newValueArray[0]?.['item-data']?.raw || undefined;
                    tools.changeValue(state, 'voucher_type', () => val)
                },
                onPaymentMethodChange: (newValueArray, state, tools) => {
                    let data;
                    if (!newValueArray || !newValueArray[0] || !newValueArray[0]?.value) console.log("empty")
                    else data = newValueArray[0]['item-data']?.raw; //?.ref_key
                    tools.changeValue(state, 'payment_method', () => data)
                },

            }}

            form_render={({ form, values, invalid, submitting }) => {
                // if (store && store._id)

                return (<>
                    <Row>
                        <Col span={24}><FormField 
                            // disabled={values._id ? true : false} 
                            type="switch" name="loyalty_plan" label="Reserve this voucher for Loyalty Plan only" /></Col>

                        {bulkRecords && <Col span={3}><FormField type="number" name="qty" label="Qty *" min={1} max={1000} validate={rules.required} /></Col>}

                        <Col span={bulkRecords ? 7 : 10}>
                            <VoucherCategorySelection type="text" label="Category *" name="category.key" preload disableSearch 
                                filter={(store && store._id) ? { key:"store-vouchers" } : undefined} 
                                validate={rules.required}
                                onSelect={(txt, node) => form.mutators.onCateChange(node)}
                            />
                        </Col>
                        <Col span={10}><VoucherTypeSelection type="text" label="Voucher Type *" name="voucher_type.key" preload disableSearch validate={rules.required}
                            onSelect={(txt, node) => form.mutators.onTypeChange(node)}
                            filter={(store && store._id) ? { key: { $nin: ['delivery'] } } : { key: { $nin: ['discount']}}}
                        /></Col>
                        <Col span={4}><FormField type="select" name="status" label="Status *" className={values.status == 'published' ? "active" : "inactive"} data={publishStatus} validate={rules.required} /></Col>

                    
                        <Col span={8}><FormField type="text" name="title" label="Title *" placeholder="Voucher Name" validate={composeValidators(rules.required, rules.minChar(4))} /></Col>
                        {!values._id && <Col span={6}><FormField disabled={values._id ? true : false} type="text" name="code" label="Voucher Code" placeholder="Voucher Code" /></Col>}

                        <Col span={10}>
                            {['discount', 'delivery'].includes(values?.voucher_type?.key) && <FormFieldGroup compact>
                                <FormField type="select" name="discount_type" label="Discount Type *" placeholder="Discount Type" width="220px" compact data={discountTypes} validate={rules.required} />
                                <FormField type="number" name="discount_value" width="80px" label=" " compact validate={rules.required} step={1} min={0} max={10000} />
                            </FormFieldGroup>}
                        </Col>

                        <Col span={24}><Heading>Limitations</Heading></Col>

                        <Col span={12}><SelectPaymentMethods preload allowClear
                            onChange={(e, callback) => {
                                if (!e) form.mutators.onPaymentMethodChange();
                                callback(e)
                            }}
                            placeholder="Apply on all methods"
                            onSelect={(txt, node) => form.mutators.onPaymentMethodChange(node)}
                            // onDeselect={(txt, node) => form.mutators.onPaymentMethodChange(node)}
                            label="Selected payment method only" name="payment_method._id" />
                        </Col>
                        <Col span={12}>
                            <FormFieldGroup compact>
                                <FormField type="select" name="requirements.type" label="Order Limitations *" placeholder="Limitations by order" width="220px" compact data={discountRequirements} validate={rules.required} />
                                <FormField type="number" name="requirements.value" width="80px" label={(values?.requirements?.type && values.requirements.type !== 'none') ? "*" : " "} validate={(values?.requirements?.type && values.requirements.type !== 'none') ? rules.required : undefined} compact step={1} min={0} max={10000} />
                            </FormFieldGroup>
                        </Col>

                        <Col span={12}>
                            <Divider style={{ color: "black", marginBottom: 0 }}>User Limit</Divider>
                            <Row>
                                <Col flex="150px"><FormField type="number" disabled={values.limit_one_per_customer > 0} name="limit_usage" width="120px" label="Usage Limit" step={1} min={0} max={10000} /></Col>
                                <Col flex="auto" style={{ paddingTop: "24px" }}><FormField type="switch" name="limit_one_per_customer" label="Limit One Per Customer" /></Col>
                            </Row>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Divider style={{ color: "black", marginBottom: 0 }}>Date Limit</Divider>
                            <Row>
                                <Col><FormField type="date" name="activation_date" label="Activation Dates *" validate={rules.required} /></Col>
                                <Col><FormField type="date" name="deactivation_date" label="Deactivation Dates *" validate={rules.required} /></Col>
                            </Row>
                        </Col>

                        <Col span={24}><div style={{ padding: "10px 100px" }}><Divider /></div></Col>


                    </Row>


                    {/* {['discount', 'delivery'].includes(values?.voucher_type?.key) && <></>}
                    {['gift'].includes(values?.voucher_type?.key) && <></>}
                    {['payment-method'].includes(values?.voucher_type?.key) && <></>} */}

                    <FormField type="textarea" name="details" label="Details" validate={composeValidators(rules.required, rules.minChar(4))} />

                    {fields && fields._id && <div className="grid-block">
                        <Heading>Discount Voucher Image</Heading>
                        <UploadField type="picture" listType="picture-card" limit={1}
                            onDeleteClicked={onFileDeleteClicked}
                            buttonLabel="Voucher Picture"
                            name="picture"
                            fileList={(fields.picture) ? [{ srcUrl: fields.picture ? `${process.env.REACT_APP_DATA_URL}/${fields.picture}` : null, thumbUrl: fields.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${fields.picture_thumb}` : null }] : null}
                            data={{ fieldsJson: JSON.stringify({ _id: fields._id }), action: "discount_voucher.picture" }}
                        />
                    </div>}

                    <div style={{ textAlign:"center", padding:"20px" }}><SubmitButton disabled={invalid} block loading={submitting}>Save</SubmitButton></div>

                </>)
            }}
        />

    </>)

}

const VoucherFormWrapper = props => {
    let fields = {  ...defaultFields }
    if (props.fields) Object.assign(fields, { 
        ...props.fields,
        activation_date: moment(props.fields.activation_date),
        deactivation_date: moment(props.fields.deactivation_date),
        limit_one_per_customer: (props.fields.limit_one_per_customer > 0),
    });


    return (
        <Drawer width={800} destroyOnClose maskClosable={false} placement="right" bodyStyle={{ padding:"15px" }}
            onClose={props.onClose}
            visible={props.showform !== false}
            footer={false}
            // footer={<>
            //     <span></span>
            //     <Button loading={loading} type="primary" onClick={() => {
            //         document.getElementById('DiscountVoucherForm').dispatchEvent(new Event('submit', { cancelable: true }))
            //     }}>Save</Button>
            // </>}
            title={`${props?.fields?._id ? 'Edit' : 'Add'} ${props.bulkRecords ? ' Multiple ' : ''} Voucher`}
        >
            {props.showform !== false && <VoucherForm {...props} fields={fields} />}
        </Drawer>
    )
}

VoucherFormWrapper.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    bulkRecords: PropTypes.bool,
}

const WithApollo = compose(
    graphql(RECORD_BULK_ADD, {
        props: ({ mutate }) => ({
            addBulkDiscountVoucher: (input) => mutate({
                variables: { input }
            }),
        })
    }),


    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addDiscountVoucher: (input) => mutate({
                variables: { input }
            }),
        })
    }),
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editDiscountVoucher: (input) => mutate({
                variables: { input }
            }),
        })
    }),
    graphql(DELETE_IMG, {
        props: ({ mutate }) => ({
            deleteDiscountVoucherPicture: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteDiscountVoucher: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(VoucherFormWrapper);

const mapStateToProps = ({ grocer_storeadmin: { rights, user, store } }) => {
    return ({ rights, store });
}
const WithRedux = connect(mapStateToProps)(WithApollo);

export default WithRedux;