import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { __error } from 'Common/scripts/consoleHelper'
// import { Col, Row } from 'antd';
// import { Avatar, DevBlock, UserAvatar } from 'Common/components';
import { utcToDate } from 'Common/scripts/Functions';
import { IconButton, PageHeader } from 'Common/components';
import UserForm from './user_form';


const UserProfile = ({ profile, updateProfileData }) => {
    const [mode, setMode] = useState(false);

    const toggleEditMode = (_mode = null) => {
        if (mode == _mode) return setMode(null);
        setMode(_mode);
    }

    return (<div>
        <PageHeader title={<>User Profile 
            {mode ? 
                <IconButton onClick={() => toggleEditMode()} icon="times" /> : 
                <IconButton onClick={() => toggleEditMode('edit-user-profile')} icon="pen" />
            }
        </>} />

        {mode == "edit-user-profile" ? <>
            <UserForm initialValues={profile} onUpdate={(v) => {
                toggleEditMode();
                updateProfileData(v)
            }} />
        </> : <>
            <div>{profile.fname}</div>
            <div>{profile.email}</div>
            <div>Member since {utcToDate(profile.created_at).format('YYYY-MM-DD')}</div>
            <div>{profile.phone}</div>
        </>}

    </div>)

}
UserProfile.propTypes = {
    profile: PropTypes.object.isRequired,
}


export default UserProfile;