/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { E404, LayoutWrapper } from "Layout_v1";

import HooksList from './containers/hooks'

/**
 * Home
 */
export const Home = props => {

  return (
    <LayoutWrapper topMenu={null}>
      <HooksList {...props} />
    </LayoutWrapper>
  )

}

// Home.propTypes = {
//   // login: PropTypes.object.isRequired,
// }

export default Home;