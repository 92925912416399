import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql, gql } from 'react-apollo';
import { loader } from 'graphql.macro';
// import moment from 'moment';
import compose from 'lodash.flowright';
import { Row, Col, Alert } from 'antd';
import { Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize, staffTypes } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
// import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import StaffFilter from 'Common/components/StaffFilter';
import { connect } from "react-redux";
import { ContentArea } from 'Layout_v1';
// import { verifyRole, isSuperAdmin } from 'Common/scripts/Security';

const LIST_DATA = loader('src/graphqls/webhooks/webhooksQuery.graphql');
const RECORD_DELETE = loader('src/graphqls/webhooks/deleteWebhook.graphql');
// const QUERY_SUBSCRIPTION = loader('src/graphqls/user/subscription.graphql');

const defaultFilter = {
    // type: { $in: staffTypes.map(o=>o._id) }
}

class ListComp extends Component {
    state = { pagination: { current: 1, pageSize: defaultPageSize } };
    subscription = false;

    // componentWillReceiveProps(nextProps) {
    //     const { subscribeToMore } = nextProps;

    //     // consider loading complete
    //     if (this.props.loading && !nextProps.loading) {
    //         this.setState({
    //             pagination: {
    //                 total: nextProps?.webhooksQuery?.totalCount || 0
    //             }
    //         })
    //     }

    //     if (!this.subscription) {
    //         this.subscription = new SubscriptionHandler({
    //             _subscribeToMore: subscribeToMore,
    //             _document: QUERY_SUBSCRIPTION,
    //             _variables: {
    //                 filter: JSON.stringify(defaultFilter),
    //                 others: JSON.stringify({})
    //             },
    //             _subscriptionName: "usersUpdated",
    //             _subscriptionType: "array",
    //             _queryName: "webhooksQuery",
    //             _typename: "User",
    //             debug: true
    //         });
    //     }

    // }

    columns = () => {
        let _columns = [
            // { title: 'ID', dataIndex: '_id', width:"100px" },
            { title: 'Title', dataIndex: 'name', render: (text, record) => (<div>{record.title}</div>) },
            { title: 'URL', dataIndex: 'url' },
            { title: 'Status', dataIndex: 'status', width: "80px", align: "center" }
        ];

        // if (isSuperAdmin(this.props.user)) _columns.push({ title: 'Store', dataIndex: 'store.title', width: "150px", align: "center", render: (__, rec) => (rec?.store?.title) })

        return _columns;
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.current - 1),
            this.props.queryString
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        )
    };

    doSearch(filter) {
        this.setState({ busy: true })
        this.props.loadMoreRows(
            this.state.pagination.pageSize || defaultPageSize, 0,
            JSON.stringify({ ...defaultFilter, ...filter })
        ).then(r => {
            this.setState({ busy: false })
            if (r.data && r.data.webhooksQuery)
                this.setState({ pagination: { ...this.state.pagination, current: 1, total: r.data.webhooksQuery.totalCount } })
            return r;
        })
    }


    render() {
        const { loading, webhooksQuery, queryErrors } = this.props;
        const { pagination } = this.state;
        // const is_uper_admin = isSuperAdmin(this.props.user)

        return (<>
            <ContentArea>
                {webhooksQuery?.error?.message && <Alert message={webhooksQuery.error.message} type='error' showIcon />}

                <ListHeader title="Webhooks" 
                    sub={<>Total {webhooksQuery && webhooksQuery.totalCount || '0'} records</>} 
                    // right={<><Button onClick={this.props.onAddRecord}>Add Webhook</Button></>}
                    />
                {/* <StaffFilter onSearch={(filter) => this.doSearch(filter)} /> */}

                <Table loading={loading}
                    columns={this.columns()}
                    dataSource={webhooksQuery ? webhooksQuery.edges : null}
                    pagination={pagination}
                    rowClassName={(record => {
                        return record.status == 'offline' ? 'disabled-table-row' : "";
                    })}
                    onChange={this.handleTableChange}
                    // expandable={!is_uper_admin ? false : {
                    //     expandedRowRender: record => <p style={{ margin: 0 }}>{String(record.rights).replaceAll(",", ", ")}</p>,
                    //     rowExpandable: record => (is_uper_admin),
                    // }}
                />
            </ContentArea>

        </>)
    }
}
const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ ...defaultFilter }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, webhooksQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.webhooksQuery.totalCount
                    const newEdges = fetchMoreResult.webhooksQuery.edges
                    const pageInfo = fetchMoreResult.webhooksQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        webhooksQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, webhooksQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteWebhook: (id) => mutate({
                variables: { id }
            }),
        })
    }),


)(ListComp);


// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updateSettings: (payload) => dispatch(updateSettings(payload)),
// })
const mapStateToProps = ({ grocer_storeadmin }) => {
    return { user: { ...grocer_storeadmin.user, rights:grocer_storeadmin.rights } };
}
const WithRedux = connect(mapStateToProps)(WithApollo);
export default WithRedux;
