import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
// import {Icon} from 'common/components'
import Tags from './containers/Tags';
// import Countries from './containers/Countries';
import Agreement from './containers/Agreement';
import PageSettings from './containers/PageSettings';
import SystemConfigurations from './containers/SystemConfigurations';
import Baskets from './Baskets';
import GeoZones from './GeoZones';
import Locations from './Locations';
import PaymentMethods from './PaymentMethods';
import Offices from './offices';
import StoreSettings from './StoreSettings';
import { ROOT } from './constants'


export const SubMenu = props => ([
  { permission_required:"settings-systemconfigs",
    path: `${ROOT}/system_config`, exact: true, title: 'System Configuration', component: SystemConfigurations 
  },
  { permission_required:"payment-methods",
    path: `${ROOT}/payment_methods`, exact: true, title: 'Payment Methods', component: PaymentMethods
  },
  { permission_required:"settings-pagesettings",
    path: `${ROOT}/page_settings`, exact: true, title: 'Page Settings', component: PageSettings 
  },
  // { path: `${ROOT}/countries`, exact: true, title: 'Countries', component: Countries },
  { permission_required:"settings-aggrements",
    path: `${ROOT}/agreement`, exact: true, title: 'Agreement', component: Agreement 
  },
  { permission_required:"settings-tags",
    path: `${ROOT}/tags`, exact: true, title: 'Tags', component: Tags 
  },
  { permission_required:"manage-pickup-baskets,manage-delivery-baskets",
    path: `${ROOT}/baskets`, exact: true, title: 'Baskets', component: Baskets 
  },
  { permission_required:'settings-geo-zones',
    path: `${ROOT}/geo_zones`, exact: true, title: 'Geo Zones', component: GeoZones 
  },
  { permission_required:'settings-locations',
    path: `${ROOT}/locations`, exact: true, title: 'Locations', component: Locations 
  },
  { // permission_required:'settings-locations',
    path: `${ROOT}/offices`, exact: true, title: 'Offices', component: Offices 
  },
  { permission_required:'store-settings',
    path: `${ROOT}/store_settings`, exact: true, title: 'Store Settings', component: StoreSettings
  },
])

// addReducers({ admins: reducers });
addModule(
  {
    permission_required: "settings-access",
    path: `${ROOT}`, component: Home, icon: 'cogs', title: "Settings", exact: 0, hideNav: 0,
    subMenu: SubMenu()
  },
)
