import React from 'react'
import { Button } from 'Common/components'

import AgreementList from '../components/agreement_list';
import AgreementForm from '../components/agreement_form';



export const Agreement = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (
        <>
            <h4>
                Agreements
                <div className="buttons"><Button onClick={toggleDrawer} size="small">Add New Agreement</Button></div>
            </h4>

            <AgreementForm onClose={toggleDrawer} showform={visible} fields={fields} />

            <AgreementList onEditRecord={(item) => toggleDrawer({ fields: item })} />
        </>
    )
}
export default Agreement;