import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
// import moment from 'moment';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar } from 'Common/components';
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';

const LIST_DATA = loader('src/graphqls/banners/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/banners/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/banners/delete.graphql');


class ListComp extends Component {
    state = {
        busy: false,
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
    };
    subscription = false;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.bannersQuery?.totalCount || 0
                }
            })
        }


        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "bannersUpdated",
                _subscriptionType: "array",
                _queryName: "bannersQuery",
                _typename: "Banners",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteBanner(id).then(r => {
            if (r && r.data && r.data.deleteBanner && r.data.deleteBanner.error) {
                message.error(r.data.deleteBanner.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    renderActions = (text, record) => {
        return (
            <span className="action_buttons">
                {checkRights(this.props.rights, 'banners-manage') && 
                    <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                }
                {(record.deleteable == 'yes' && checkRights(this.props.rights, 'banners-delete')) && 
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                        <IconButton icon="trash-alt" />
                    </Popconfirm>
                }
            </span>
        )
    }

    columns = [
        // { title: 'ID', dataIndex: '_id', width: '80px' },
        { title: 'Title', dataIndex: 'title' },
        { title: 'Key', dataIndex: 'key' },
        { title: 'Status', dataIndex: 'status' },
        // { title: 'Date', dataIndex: 'created_at', render: (text, record) => getTimeDifference(record.created_at) },
        {
            title: 'Actions',
            dataIndex: '',
            render: this.renderActions,
            className: 'actions-column',
            align: 'right',
            width: '50px'
        },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ busy: true })
        let filter = filters ? JSON.stringify(filters) : this.props.queryString;

        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.page - 1),
            filter,
        ).then(r => {
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.bannersQuery?.totalCount },
                busy: false
            })
        })
    };


    render() {
        const { loading, bannersQuery, queryErrors } = this.props;
        const { pagination } = this.state;


        return (<>
            <Table loading={loading}
                columns={this.columns}
                dataSource={bannersQuery ? bannersQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status == 'hidden' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
            // rowClassName={(record => {
            //     return record.status == 'hidden' ? 'disabled-table-row' : "";
            // })}
            // title={() => <span>Header</span>}
            // footer={() => 'Footer'}
            // expandable={{
            //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
            //     // rowExpandable: record => record.name !== 'Not Expandable',
            // }}
            />
        </>)
    }
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0 } };
        },
        props: ({ ownProps, data }) => {
            const { loading, bannersQuery, error, fetchMore, subscribeToMore } = data;

            if (error) {
                message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
                console.log(__error("error"), error);
                // error.networkError.name
                // error.networkError.response.status
                // error.networkError.response.statusText
                console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
            }

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.bannersQuery.totalCount
                    const newEdges = fetchMoreResult.bannersQuery.edges
                    const pageInfo = fetchMoreResult.bannersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        bannersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, bannersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteBanner: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(WithApollo);
