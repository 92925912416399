import React, { useState, useEffect } from 'react'
import { Drawer, Heading, IconButton, Loader } from 'Common/components';
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col, Divider } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { sleep } from 'Common/scripts/Functions';

const RECORD = loader('src/graphqls/product/product.graphql');
const DELETE_PROD_IMG = loader('src/graphqls/product/delete_prod_img.graphql');
const DELETE_PROD_GALL_IMG = loader('src/graphqls/product_gallery/delete.graphql');

const env = process.env;

const GalleryForm = ({ onClose, showform, product, loading, deleteProductImg, deleteProdGallItem }) => {
    const [busy, setBusy] = useState(false);
    const [product_data, setData] = useState(null);

    useEffect(() => {
        setData(product);
        // product_data.gallery
    }, [product])

    if (loading) return <Loader loading={true} />
    if (!product_data) return null;

    const onFileDeleteClicked = async (fileInfo) => {
        let srcField = fileInfo.name
        let src = fileInfo.srcUrl;
        let thumb = fileInfo.thumbUrl;
        let thumbField = fileInfo.name + "_thumb";
        var results;

        await sleep(1000);

        if (fileInfo.name == "product_gallery_img") {
            if (!fileInfo.uid) return { error: { message: "Invalid record ID" } }

            setBusy(true);
            results = await deleteProdGallItem(fileInfo.uid).then((e) => (e.data.deleteProdGallItem)).catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message: "Query Error" } };
            });
            setBusy(false);

            if (results.error){
                message.error(results.error.message);
                return false;
            }

            let gallery = product_data?.gallery?.slice() || [];
            gallery = gallery.filter(o => o._id != results._id)

            setData({ ...product_data, gallery })
            return results;

        }
        else if (fileInfo.name == "picture") {
            setBusy(true);
            results = await deleteProductImg(product_data._id, `${srcField},${thumbField}`).then((e) => (e.data.deleteProductImg)).catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message: "Query Error" } };
            });
            setBusy(false);

            if (results.error) {
                message.error(results.error.message);
                return false;
            }
            // let _fileList = fileList.filter(o => !(o.srcUrl == args.srcUrl || args.srcUrl.indexOf(o.srcUrl) > -1));
            
            setData({ ...product_data, picture:undefined, thumbUrl:undefined })
            return results;
        }

        message.error("Invalid action");
        return { error: { message: "Invalid action" } };
    }

    const onProdPicUpload = (val) => {
        console.log("onGalleryUpload() ", val);

        if (val.error) {
            message.error(val.error.message);
            return val
        }

        setData({ ...product_data, 
            picture: val.srcUrl,
            picture_thumb: val.thumbUrl
        })
    }

    const onGalleryUpload = val => {
        console.log("onGalleryUpload() ", val);

        if (val.error){
            message.error(val.error.message);
            return val
        }

        var gallery = product_data?.gallery?.slice() || [];
        gallery.push({ img: val.srcUrl, thumb: val.thumbUrl, _id: val._id })

        setData({
            ...product_data,
            gallery
        })

    }

    const setFilePaths = (file) => (`${env.REACT_APP_DATA_URL}/${file}`);

    return (<>
        <FormComponent onSubmit={console.log} id='ProductGalleryForm' loading={busy} fields={{}} enterSubmit={false}
            form_render={({ values }) => {
                return (<>
                    <Divider>Product Default Picture</Divider>
                    <UploadField name="picture" type="picture" listType="picture-card" limit={1}
                        onUploadComplete={onProdPicUpload}
                        onDeleteClicked={onFileDeleteClicked}
                        // buttonLabel="Product default picture"
                        fileList={product_data.picture ? [{ srcUrl: setFilePaths(product_data.picture), thumbUrl: setFilePaths(product_data.picture_thumb) }] : null}
                        data={{ fieldsJson: JSON.stringify({ _id: product_data._id }), action: "product_picture" }}
                    />

                    <Divider>Product Gallery</Divider>
                    <UploadField name="product_gallery_img" type="picture" listType="picture-grid" limit={5} showCount
                        onUploadComplete={onGalleryUpload}
                        onDeleteClicked={onFileDeleteClicked}
                        // buttonLabel="Add image"
                        fileList={!(product_data && product_data.gallery) ? null :
                            product_data.gallery.map(item => ({ srcUrl: setFilePaths(item.img), thumbUrl: setFilePaths(item.thumb), uid: item._id }))
                        }
                        data={{ fieldsJson: JSON.stringify({ _id: product_data._id }), action: "product_gallery_img" }}
                    />

                </>)
            }}
        />

    </>)
}
const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ product }) => {
            return {
                variables: { id: product._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, product, error } = data;

            if (error) console.log(__error("error"), error);
            return { loading, product, queryErrors: error, }
        },
    }),

    graphql(DELETE_PROD_IMG, {
        props: ({ mutate }) => ({
            deleteProductImg: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(DELETE_PROD_GALL_IMG, {
        props: ({ mutate }) => ({
            deleteProdGallItem: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),

)(GalleryForm);




const ProductGalleryEditor = (props) => {   
    return (<>
        <Drawer title="Edit Product Pictures" placement="right" onClose={props.onClose} visible={props.showform} width="600px">
            {props.showform && <EditWrapper {...props} />}
        </Drawer>
    </>)
}


export default ProductGalleryEditor;