import { ListHeader } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import React, { Component } from 'react'

export default class Transactions extends Component {
  render() {
    return (<>
      <ContentArea>
        <ListHeader
          title={<>{'Transactions'} <span>{this.props.titlePostfix}</span></>}
          sub={<>Total 000 records</>}
        // right={<>
        //     <Button onClick={this.props.toggleDrawer}>Add New Product</Button>
        //     <Divider type="vertical" />
        // </>}
        />


        <ol>
          <li>Previous transactions</li>
          <li>New transactions request</li>
          <li>In process transactions</li>
          <li>This month Completed transactions</li>
        </ol>


      </ContentArea>
    </>)
  }
}
