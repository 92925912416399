import React from 'react'
import { formToFilter } from 'Common/scripts/Functions'
import { geoZoneCategories, serviceCities } from 'configs/constants';
import { Row, Col, Divider, message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { FormField, FormComponent, FormFieldGroup, DateField } from 'Common/components/Form'
import { Icon, Button, LocationSelection } from 'Common/components'
import { connect } from "react-redux";

const GeoZonesFilter = props => {
    const onSubmit = values => {
        let filter = formToFilter(values, { tz: props.default_timezone })
        props.onSearch(filter);
    }

    const defaultValues = { 
        category: undefined, // geoZoneCategories[0]._id, 
        city: undefined, // serviceCities[0]._id,
        ...props.defaultValue 
    }

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='GeoZonesFilterForm' hideDevBlock={true} fields={defaultValues} style={{ padding: 0 }}
            form_render={({ values })=>{
                return(<>
                    <Row className="pagination-filter-row">
                        <Col flex="auto" className="filters-column">
                            {/* <FormFieldGroup compact style={{ padding: 0, margin: 0 }}> */}
                            <FormField type="select" data={geoZoneCategories} name="category" placeholder="Category" label="Category" width="200px" compact allowClear size="small" />
                            {/* <FormField type="select" data={serviceCities} name="city" placeholder="City" label="City" width="200px" compact allowClear size="small" /> */}
                            <LocationSelection name="city" placeholder="City" label="City" style={{width:"150px"}} compact allowClear size="small" preload />
                            {/* </FormFieldGroup> */}
                        </Col>
                        <Col className="go-column">
                            <Button className="send_button" loading={props.loading} htmlType="submit"><Icon icon="search" /></Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </div>)

}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.default_timezone };
}
export default connect(mapStateToProps)(GeoZonesFilter);
