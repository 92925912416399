import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'antd';
import { Button, UserSelection } from 'Common/components'
import { FormComponent, rules } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'


export const Status_Dispatched = ({ onCancel, onSubmit, order }) => {
    const on_submit = async (values) => {
        const input = {
            _id: order._id,
            status: "dispatched",
            dispatch_by: values.dispatch_by, // { _id: values.dispatch_by._id, name: values.dispatch_by.name }
        }

        return onSubmit(input);
    }

    // return <Alert
    //     message={<>Order can only be dispatched from till</>}
    //     type="warning" showIcon />


    return (<>
        <Alert 
            message={<>Are you sure to change order status to <b>Dispatched</b>?</>} 
            description={<>
                <p>Following actions will be performed</p>
                {/* <ol>
                    <li>Remove any Pickup data</li>
                    <li>Remove any Dispatch data</li>
                    <li>Update order status</li>
                </ol> */}
            </>} 
            type="warning" showIcon />

        <FormComponent onSubmit={on_submit} id='OrderStatusUpdateForm' fields={{ status: "dispatched", pickup_data: order.items }} debug={true}
            mutators={{
                onDriverSelect: (newValueArray, state, tools) => {
                    let driver = newValueArray[0];
                    tools.changeValue(state, 'dispatch_by', () => driver)
                },
            }}

            form_render={formProps => {
                const { values, submitting, form, valid, dirty } = formProps;

                return (<>
                    <div>
                        <UserSelection
                            style={{ maxWidth: "400px" }}
                            name="dispatch_by._id" 
                            preload={true}
                            filter={{ type: 'driver' }}
                            label="Select the driver" placeholder="Search driver..."
                            validate={rules.required}
                            onSelect={(_1, _2) => form.mutators.onDriverSelect({ name: _2.label, _id: _2.value })}
                        />
                    </div>


                    <div style={{ height: '30px' }} />
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white-outline" disabled={submitting}>Cancel</Button></Col>
                        <Col><Button loading={submitting} disabled={!valid} htmlType="submit">Update Order Status</Button></Col>
                    </Row>

                </>)
            }}
        />

    </>)
}
Status_Dispatched.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

// export const Status_Dispatched = withApollo(Status_DispatchedComp);