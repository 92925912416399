import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Avatar } from './Avatar'
import { __error } from 'Common/scripts/consoleHelper'
import { Alert, message } from 'antd';

const UPDATE_AVATAR = loader('src/graphqls/user/updateAvatar.graphql');

const UserAvatarComp = ({ _id, avatar, size, updateAvatar, onUpdate, alt, tooltip }) => {
    const uploadAvatar = async (val) => {
        if (!val) return true;

        const results = updateAvatar(_id, val).then(r => (r.data.updateAvatar)).catch(err=>{
            console.log(__error("Query Error: "), err);
            return { error:{ message:"Query Error!" } }
        })

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        if (onUpdate) onUpdate(results);

        return results;
    }

    if (onUpdate && !_id) return <Alert message="User ID not found for avatar" type="error" showIcon />
    
    return (<Avatar uploadAvatar={onUpdate && uploadAvatar} size={size || 80} src={avatar || undefined} alt={alt || undefined} tooltip={tooltip || undefined} />)
}
UserAvatarComp.propTypes = {
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    size: PropTypes.number,
    onUpdate: PropTypes.func,
    alt: PropTypes.string,
    tooltip: PropTypes.string,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


export const UserAvatar = compose(
    graphql(UPDATE_AVATAR, {
        props: ({ mutate }) => ({
            updateAvatar: (id, avatar) => mutate({
                variables: { id: Number(id), avatar }
            }),
        })
    }),
)(UserAvatarComp);

