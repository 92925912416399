import React, { Component } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Icon } from 'Common/components'
import { Col, Row } from 'antd'

const IconHolder = ({icon}) => {
    return <div style={{ border:"1px solid #DDD", borderRadius:"5px", padding:"10px", textAlign:"center", width:"100px"}}>
        <Icon icon={icon} size="2x" />
        <p style={{fontSize:"10px"}}>{icon}</p>
    </div>
}

export default class IconsList extends Component {
    
    render() {
        console.log("library.definitions: ", library.definitions)

        let fas = Object.keys(library.definitions.fas);
        console.log("fas: ", fas)

        return (<>
            <h1>IconsList</h1>

            <Row gutter={[10, 10]}>
                {fas.map((item, i)=>{
                    return <Col key={i}><IconHolder icon={item} /></Col>
                })}
            </Row>
        </>)
    }
}
