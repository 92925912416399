import React from 'react'
import PropTypes from 'prop-types';
import UserList from '../components/user_list';
import UserForm from '../components/user_form';
import { ContentArea } from 'Layout_v1';


export const Users = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args ? args.fields : null });
    };

    return (<ContentArea>
        <UserList onAddRecord={() => toggleDrawer()} onEditRecord={(item) => toggleDrawer({ fields: item })} />
        <UserForm onClose={toggleDrawer} showform={visible} fields={fields} />
    </ContentArea>)
}
export default Users;