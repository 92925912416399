import React, { useState, useEffect } from 'react'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField, SubmitButton } from 'Common/components/Form'
import { Drawer, Button, Heading, Icon, ListHeader, DevBlock, Loader, Table, IconButton, DeleteButton } from 'Common/components'
import { message, Row, Col, Space, Modal, Alert } from 'antd';
import { ContentArea } from 'Layout_v1';
import { checkRights } from 'Common/scripts/Security';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
// import { useLazyQuery } from 'react-apollo';

const ADD_CAT = loader('src/graphqls/key_value_pairs/addKeyValuePair.graphql');
const DEL_CAT = loader('src/graphqls/key_value_pairs/deleteKeyValuePair.graphql');
const EDIT_CAT = loader('src/graphqls/key_value_pairs/editKeyValuePair.graphql');
const GET_LIST = loader('src/graphqls/key_value_pairs/keyValuePairs.graphql');

const catType = "voucher-types";

const CatForm = ({ initialValues, onCancel, onSuccess, client }) => {
    const [error, setError] = useState(null);

    const onSubmit = async (values) => {
        setError(null);

        const input = {
            type: catType,
            key: values.key,
            val: values.val,
            details: values.details,
        }

        if (initialValues && initialValues._id) return updateRecord({ ...input, _id: initialValues._id })
        return addRecord(input)
    }

    const addRecord = async (input) => {
        let resutls = await client.mutate({ mutation: ADD_CAT, variables: { input } }).then(r => (r?.data?.addKeyValuePair))
            .catch(err => {
                console.log(__error("Error: "), err)
                return { error: { message: "Unable to process your request at the moment" } }
            })

        if (!resutls || resutls.error) {
            setError((resutls && resutls?.error?.message) || "Invalid response!")
            return true;
        }

        onSuccess(resutls);
        return true;
    }

    const updateRecord = async (input) => {
        let resutls = await client.mutate({ mutation: EDIT_CAT, variables: { input } }).then(r => (r?.data?.editKeyValuePair))
            .catch(err => {
                console.log(__error("Error: "), err)
                return { error: { message: "Unable to process your request at the moment" } }
            })

        if (!resutls || resutls.error) {
            setError((resutls && resutls?.error?.message) || "Invalid response!")
            return true;
        }

        onSuccess(resutls);
        return true
    }


    return (<>
        <FormComponent onSubmit={onSubmit} id='KeyValye_Pair' fields={initialValues}
            form_render={formProps => {
                const { values, invalid, submitting } = formProps;

                return (<>
                    {error && <Alert message={error} type="error" showIcon />}

                    <Row gutter={[10, 5]}>
                        <Col span={12}><FormField type="text" name="val" label="Type title" validate={rules.required} compact /></Col>
                        <Col span={12}><FormField type="text" name="key" label="Type Key" validate={rules.required} compact /></Col>
                        <Col span={24}><FormField type="text" name="details" label="Details" validate={composeValidators(rules.maxChar(128))} compact /></Col>
                        {/* <Col><FormField type="text" name="type" label="Type" validate={rules.required} compact /></Col> */}
                        <Col span={24} style={{ paddingTop: "20px" }}><SubmitButton disabled={invalid} block loading={submitting}>Save</SubmitButton></Col>
                    </Row>
                </>)
            }}
        />
    </>)
}

function VoucherTypes({ client, rights }) {
    const [showCatForm, set_showCatForm] = useState(false);
    const [dataArray, set_dataArray] = useState(null);
    const [error, setError] = useState(null);
    const [busy, setBusy] = useState(false);
    const [called, setCalled] = useState(false);

    useEffect(() => {
        if (called) return;
        setCalled(true)
        fetchList();
    }, [])

    const fetchList = async () => {
        setBusy(true);
        const resutls = await client.query({ 
                query: GET_LIST, 
                variables: { 
                    filter: JSON.stringify({ type: catType }) 
                } })
                .then(r => (r?.data?.keyValuePairs))
                .catch(err => {
                    console.log(__error("Error: "), err)
                    return { error: { message: "Query Error!" } }
                })
        setBusy(false);

        if (!resutls || resutls.error) {
            setError((resutls && resutls?.error?.message) || "Empty Response!")
            return;
        }

        set_dataArray(resutls)
    }

    const onSuccess = () => {
        fetchList();
        set_showCatForm(false);
    }

    const onDelPress = async(id) => {
        let resutls = await client.mutate({ mutation: DEL_CAT, variables: { id } }).then(r => (r?.data?.deleteKeyValuePair))
            .catch(err => {
                console.log(__error("Error: "), err)
                return { error: { message: "Unable to process your request at the moment" } }
            })

        if (!resutls || resutls.error) {
            setError((resutls && resutls?.error?.message) || "Invalid response!")
            return true;
        }

        onSuccess(resutls);
        return true
    }

    const columns = [
        // { title: 'ID', dataIndex: '_id', width: '80px' },
        {
            title: 'Title', dataIndex: 'val', render: (text, rec) => (<><Row className='nowrap show-menu-on-hover'>
                <Col>{rec.val}</Col>
                {checkRights(rights, 'd-vouchers-typ') && <Col className='hidden-menu'>
                    <IconButton onClick={() => set_showCatForm(rec)} icon="pen" />
                    <DeleteButton onClick={() => onDelPress(rec._id)} />
                </Col>}
            </Row>
            {rec.details && <div style={{ fontStyle:"italic", fontSize: "11px" }}>{rec.details}</div>}
            </>)
        },
        { title: 'Key', dataIndex: 'key', width: 200 },
        // { title: 'Type', dataIndex: 'type', width: 200 },
    ];

    

    return (<ContentArea>
        <ListHeader title="Voucher types" sub={`${(dataArray && dataArray.length) || 0} records found`}
            right={<Space>
                {checkRights(rights, 'd-vouchers-typ') && <Button onClick={() => set_showCatForm(true)} size="small">Add New Type</Button>}
                {/* {checkRights(rights, 'd-vouchers-bulk') && <Button onClick={() => this.setState({ bulkDrawer: true })} size="small">Create Bulk Vouchers</Button>} */}
            </Space>} />

        {error && <Alert message={error} type="error" showIcon />}

        <Table loading={busy}
            columns={columns}
            dataSource={dataArray || null}
            pagination={false}
        />

        <Modal title="Voucher Type Form" visible={showCatForm !== false} onCancel={() => set_showCatForm(false)} footer={false}>
            {showCatForm && <CatForm initialValues={showCatForm !== false ? showCatForm : {}} onCancel={() => set_showCatForm(false)} onSuccess={onSuccess} client={client} />}
        </Modal>

    </ContentArea>)
}


const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
export default connect(mapStateToProps)(withApollo(VoucherTypes));
