/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'Common/components';
// import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import UniqueCustomers from './containers/UniqueCustomers'
import PinForm from './containers/PinForm'

/**
 * StoreStats Home
 */
const StoreStatsHome = props => {
    // const [verifiesAcess, set_verifiesAcess] = React.useState(false)


    return (<LayoutWrapper topMenu={null}>
        <UniqueCustomers />

        {/* {!verifiesAcess && <PinForm onSuccess={() => set_verifiesAcess(true)} />}
        {verifiesAcess && <UniqueCustomers />} */}
        
    </LayoutWrapper>)
    
    // let routs = SubMenu();
    // return (
    //     <LayoutWrapper topMenu={null}>
    //         <Switch>
    //             {routs.map((item, i) => {
    //                 if (!item.component) return null;
    //                 return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
    //             })}
    //             <Route key={999} component={E404} />
    //         </Switch>
    //     </LayoutWrapper>
    // )

}

StoreStatsHome.propTypes = {
    // login: PropTypes.object.isRequired,
}

export default StoreStatsHome;