import React from 'react'
import PropTypes from 'prop-types';
import { Field } from 'react-final-form'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// https://draftjs.org/docs/api-reference-data-conversion/
// https://jpuri.github.io/react-draft-wysiwyg/#/demo
import debounce from 'lodash/debounce';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export const HtmlField = props => {
    let _inputProps = { ...props.inputProps };

    const getInitialState = initialHTMLMarkup => {
        if (!initialHTMLMarkup || initialHTMLMarkup.length<7) return EditorState.createEmpty();

        // const sampleMarkup = '<b>Bold text</b>, <i>Italic text</i><br/ ><br />' + '<a href="http://www.facebook.com">Example link</a>';
        const __blocksFromHtml = htmlToDraft(initialHTMLMarkup);
        const { contentBlocks, entityMap } = __blocksFromHtml;
        const __contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const __editorState = EditorState.createWithContent(__contentState);
        return __editorState;
    }

    const [editorState, setEditorState] = React.useState(getInitialState(props.data)); //EditorState.createEmpty()

    const onUpdate = props.onUpdate ? debounce(props.onUpdate, 800) : false;
    
    const onEditorStateChange = new_editorState => {
        setEditorState(new_editorState)
        let raw_contents = convertToRaw(new_editorState.getCurrentContent());
        let htmlContents = draftToHtml(raw_contents);
        if (onUpdate) onUpdate(htmlContents)
        // setContents(htmlContents)
    }

    return (
        <Field {...props}>
            {({ input, meta }) => {

                return (
                    <div className={`form-field ${!props.compact && "field-margins"} html`}>
                        {props.label && <label>{props.label}</label>}
                        <Editor
                            toolbar={props.toolbar || {}}
                    
                            wrapperStyle={props.wrapperStyle || {}}
                            editorStyle={props.editorStyle || {}}
                            toolbarStyle={props.toolbarStyle || {}}

                            wrapperClassName="field-html"
                            editorClassName="field-html-editor"
                            toolbarClassName="field-html-toolbar"

                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            // editorRef={setEditorReference}
                        />
                        {/* <Input {...input} style={{display:"none"}} type="textarea" /> */}

                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )

}
HtmlField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
}

export default HtmlField;
