import React from 'react'
import { Button } from 'Common/components'

import GeoZoneList from './components/geoZone_list';
import GeoZoneForm from './components/geoZone_form';

export const GeoZones = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        {visible && <GeoZoneForm onClose={toggleDrawer} showform={visible} fields={fields} 
            // center={fields ? { lat: fields.lat, lng: fields.lng } : undefined} 
        />}

        <GeoZoneList onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddClick={() => toggleDrawer({})}
        />
    </>)
}
export default GeoZones;