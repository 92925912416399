import React, { useState } from 'react'
import OrdersList from '../components/orders_list'
import { ContentArea } from 'Layout_v1';
import { useWindowDimensions } from 'Common/scripts/hooks';

export const OrdersPicking = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const { height, width } = useWindowDimensions();

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <ContentArea>
            <OrdersList 
                screen={{ height, width }}
                noActions
                title="Orders Picking"
                path={`${props.match.path}/order`}
                onEditRecord={(item) => toggleDrawer({ fields: item })} 
                filter={{ status: "picking" }} 
                hideFilter={true} 
            />
        </ContentArea>
    </>)
}

export default OrdersPicking;