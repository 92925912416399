import React, { Component } from 'react'
import { DevBlock, Heading, ListHeader, Loader } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { gql } from 'graphql.macro';
import { withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper';
import { Alert, Col, Row } from 'antd';
import { connect } from "react-redux";

const GET_STORE = gql`
    query getStoreAccounts {
      getStoreAccounts {
        _id
        title

        dataset{
          order_count
          canceled_order_count
          declined_order_count
          suspended_order_count
          delivered_order_count
          total_sales
          payable_to_grocer
        }
      }
    }`

class AccStatement extends Component {
  state = { laoding: false, error: null, data:null }

  componentDidMount(){
    this.fetchData();
  }

  async fetchData(){
    this.setState({ loading: true, error: null });

    const results = await this.props.client.query({
      query:GET_STORE, variables:{ id:Number(0) }, fetchPolicy: "no-cache",
    }).then(r => (r.data.getStoreAccounts)).catch(err => {
      console.log(__error("Query Error"), err);
      return { error: { message: "Query Error" } };
    });

    if (!results || results.error){
      this.setState({ loading: false, error: results ? results.error.message : "No response received" });
      return;
    }

    this.setState({ loading: false, data: results });

  }


  render() {
    const { loading, data, error } = this.state;
    const { currency } = this.props;

    if (loading) return <Loader loading={loading} center />
    if (error) return <Alert message={error} type='error' showIcon />
    if (!data) return <Alert message={"No data found!"} type='error' showIcon />

    return (<>
      <ContentArea transparent>
        {error && <Alert message={error} type='error' showIcon />}

        <Row>
          <Col flex="auto">
            
            <div className='info-block'>

              <ListHeader
                title={<>{'Account Statement'}</>}
                // sub={<>Total 000 records</>}
                // right={<>
                //     <Button onClick={this.props.toggleDrawer}>Add New Product</Button>
                //     <Divider type="vertical" />
                // </>}
              />

              {/* <ol>
                <li>Current Balance</li>
                <li>Pending amount</li>
                <li>transaction history</li>
              </ol> */}


            </div>





          </Col>
          <Col style={{ minWidth:"450px", paddingLeft:"10px" }}>
            {data && <>

              <div className='info-block'>
                <h2 className='mb-0'><span>Total Sales <span style={{ color: "#000" }}>{currency} {data.dataset.total_sales}</span></span></h2>
              </div>

              <div className='info-block'>
                <h2 className='mb-0'>Orders</h2>
                <Row>
                  <Col align="center" span={8}>
                    <div>{data.dataset.order_count}</div>
                    <div className='h1'>Total</div>
                  </Col>
                  <Col align="center" span={8}>
                    <div>{data.dataset.delivered_order_count}</div>
                    <div className='h1'>Delivered</div>
                  </Col>
                  <Col align="center" span={8}>
                  </Col>

                  <Col align="center" span={8}>
                    <div>{data.dataset.canceled_order_count}</div>
                    <div className='h1'>Canceled</div>
                  </Col>
                  <Col align="center" span={8}>
                    <div>{data.dataset.declined_order_count}</div>
                    <div className='h1'>Declined</div>
                  </Col>
                  <Col align="center" span={8}>
                    <div>{data.dataset.suspended_order_count}</div>
                    <div className='h1'>Suspended</div>
                  </Col>
                </Row>                
              </div>
              
              {/* <div className='info-block'>
                <h2 className='mb-0'>Cash Details</h2>
                <Row>
                  <Col align="center" span={8}>
                    <div>{currency} {data.opening_balance}</div>
                    <div className='h1'>Opening Balance</div>
                  </Col>
                  <Col align="center" span={8}>
                    <div>{currency} {data.expected_balance}</div>
                    <div className='h1'>Pending</div>
                  </Col>
                  <Col align="center" span={8}>
                    <div>{currency} {data.in_trans_balance}</div>
                    <div className='h1'>Reserved</div>
                  </Col>
                </Row>                
              </div> */}

            </>}            
          </Col>
        </Row>

        <DevBlock obj={data} />


        {/* {data &&  <>
          <Row gutter={[10, 10]}>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Total Orders</Heading>
              {currency} {data.order_count}
            </Col>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Canceled Orders</Heading>
              {currency} {data.suspended_order_count}
            </Col>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Delivered Orders</Heading>
              {currency} {data.delivered_order_count}
            </Col>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Total Sales</Heading>
              {currency} {data.total_sales}
            </Col>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Opening Balance</Heading>
              {currency} {data.opening_balance}
            </Col>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Pending Payments</Heading>
              {currency} {data.expected_balance}
            </Col>
            <Col align="center" className='acc-infobox' flex="auto">
              <Heading orientation="center">Reserved Payments</Heading>
              {currency} {data.in_trans_balance}
            </Col>
          </Row>
        </>} */}

      </ContentArea>
    </>)
  }
}

const WithApollo = withApollo(AccStatement);

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updateSettings: (payload) => dispatch(updateSettings(payload)),
// })
const mapStateToProps = ({ grocer_storeadmin }) => {
  // console.log("grocer_storeadmin: ", grocer_storeadmin.settings.currency)
  return {
    currency: grocer_storeadmin?.settings?.currency,
  };
}

export default connect(mapStateToProps)(WithApollo);
