import { Col, Row } from 'antd'
import React, { Component } from 'react'
import Button from './Button'
import Icon from './Icon'

export const ListHeader = ({ title, sub, right, style }) => {
    return (<div style={{ marginBottom: "10px", ...style }}>
        <Row align='middle'>
            <Col flex="auto">
                <div style={{ fontSize: "26px", lineHeight: "20px", color: "#262C2D" }}>{title}<div style={{ fontSize: "12px", color: "#000" }}>{sub}</div></div>
            </Col>
            {right && <Col>{right}</Col>}
        </Row>
    </div>)
}


export const PageHeader = ({ title, sub, right, style, onBack }) => {
    return (<div style={{ marginBottom: "10px", ...style }}>
        <Row align='middle' gutter={[10]}>
            {onBack && <Col><div className='a' onClick={() => onBack()}><Icon icon="angle-left" /> Back</div></Col>}
            <Col flex="auto">
                <div style={{ fontSize: "26px", lineHeight: "20px", color:"#4989FF" }}>{title}<div style={{ fontSize: "12px", color: "#000" }}>{sub}</div></div>
            </Col>
            {right && <Col>{right}</Col>}
        </Row>
    </div>)
}


export const StoreStatusTag = ({ store, style }) => {
    let tagStyle = {
        backgroundColor: "white", color: "black", borderRadius: "10px", fontSize: "12px", margin: "0 10px", padding: "2px 10px"
    }
    let status = store.status;

    if (store.status == 'inactive'){
        Object.assign(tagStyle, { backgroundColor: "yellow", color: "black" });
        status = "Store Inactive"
    }
    if (store.status == 'offline'){
        Object.assign(tagStyle, { backgroundColor: "red", color: "white" });
        status = "Store Offline"
    }
    if (store.status == 'online'){
        Object.assign(tagStyle, { backgroundColor: "green", color: "white" })
        status = "Store Online"
    }

    return <span style={{ ...tagStyle, ...style }}>{status}</span>
}