/*******
https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu
 */
import React, { Component } from 'react'
import PropTypes, { array } from 'prop-types';
import { Drawer, Button, LocationSelection, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { geoZoneCategories, publishStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from 'configs/constants'

const LOCATIONS = loader('src/graphqls/locations/locations.graphql');
const RECORD = loader('src/graphqls/geo_zone/geoZone.graphql');
const GEO_ZONES = loader('src/graphqls/geo_zone/geoZones.graphql');
const RECORD_EDIT = loader('src/graphqls/geo_zone/edit.graphql');
const RECORD_ADD = loader('src/graphqls/geo_zone/add.graphql');

// const allowNumShapes = 1;
// const numAllowed_delivery_zones = 1;
// const numAllowed_service_zones = 1;

class FormComp extends Component {
    // fields = { status: "disabled" };
    static defaultProps = {
        center: {
            lat: -15.38773584583862,
            lng: 28.323153652297975,
        },
        zoom: 13
    };

    map = null;
    maps = null;
    mapRef = null;
    activePolygon = null;
    // deleteMenu = null;

    constructor(props) {
        super(props);

        this.state = { 
            city: this.props.city,
            shapes: [],
            // default_shapes: (props.fields && props.fields.coordinates) ? [omitAllTypeName(props.fields.coordinates)] : [],
            default_shapes: null,
            busy: false, 
            fields: { status: "published", category:"service-area" },
            loadingRelatedZones: false, static_shapes:null,
            // polygon: (props.fields && props.fields.coordinates) ? [omitAllTypeName(props.fields.coordinates)] : null,

            loadingEditData: props.fields ? true : false,
        }
        this.onSubmit = this.onSubmit.bind(this);
        // this.onPolygonUpdate = this.onPolygonUpdate.bind(this);
        this.resetPolygon = this.resetPolygon.bind(this);
        this.addShape = this.addShape.bind(this);
        this.loadRelatedZones = this.loadRelatedZones.bind(this);
        this.createStaticShape = this.createStaticShape.bind(this);
    }

    componentDidMount(){
        const{ fields, client } = this.props;
        if (!fields) return;

        client.query({ query: LOCATIONS, variables: { code: fields.city } }).then(e => {
            const results = e.data.locations;

            if (results.error) {
                message.error(__error("ERROR "), results.error.message);
                return;
            }

            this.setState({ city: results[0], loadingEditData:false })
        }).catch(err => {
            console.log(__error("API Call ERROR: locations : "), err);
            message.error("Request ERROR");
        })

    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.loading && !this.props.loading && this.props.geoZone && this.props.geoZone._id){
            const { geoZone } = this.props;
            
            const _state = {
                shapes: [],
                default_shapes: geoZone.polygon?.coordinates ? [geoZone.polygon.coordinates[0].map(o => ({ lat: o[0], lng: o[1] }))] : null,
                fields: geoZone ? geoZone : { status: "published", category: "service-area" },
            }
            this.setState({ ..._state });
        }
    }

    onSubmit = _values => {
        const { onClose, editGeoZone, addGeoZone } = this.props;
        const { fields } = this.state;
        let values = { ..._values }

        if (!this.state.shapes || this.state.shapes.length < 1) {
            alert("Forgot to add zone!");
            return;
        }

        const coordinates = this.state.shapes[0].getPath().getArray().map(p => ({ lat: p.lat(), lng: p.lng() }));
        // confirm start and end point are same/ this is required for mongodb geo query ($geoIntersects)
        let fristP = coordinates[0];
        let lastP = coordinates[coordinates.length - 1];
        if (fristP.lat != lastP.lat && fristP.lng != lastP.lng) {
            coordinates.push(fristP);
        }

        const filteredValues = {
            title: values.title,
            category: values.category,
            // coordinates: this.state.shapes[0].getPath().getArray().map(p => ({ lat: p.lat(), lng: p.lng() })), // this.state.polygon,
            coordinates,
            polygon: {
                type: 'Polygon',
                // coordinates: [this.state.shapes[0].getPath().getArray().map(p => ([ p.lat(), p.lng() ]))],
                coordinates: [coordinates.map(o => ([o.lat, o.lng]))]
            },
            status: values.status,
            city: values.city,
        };


        const _id = fields ? fields._id : false;
        this.setState({ busy: true });

        if (_id) {
            editGeoZone({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({ busy: false })
                if (e.data.editGeoZone.error) {
                    let err = e.data.editGeoZone.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editGeoZone);
            }).catch(error => {
                this.setState({ busy: false })
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addGeoZone(filteredValues).then((e) => {
                this.setState({ busy: false });
                if (e.data.addGeoZone.error) {
                    let err = e.data.addGeoZone.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                console.log("e.data.addGeoZone: ", e.data.addGeoZone);

                onClose(e.data.addGeoZone);
            }).catch(error => {
                this.setState({ busy: false });
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    onGoogleApiLoaded = ({map, maps, ref}) => {
        const { polygon } = this.state;
        
        this.map = map;
        this.maps = maps;
        this.mapRef = ref;
        
        if (polygon) this.createShape(polygon)
        this.initDrawing()

        if(!this.state.static_shapes) this.loadRelatedZones(this.props.fields);

        // center the map to current polygon/
        if (this.props.fields && this.props.fields._id && this.props.fields.polygon && this.props.fields.polygon.coordinates){
            var bounds = new this.maps.LatLngBounds();
            this.props.fields.polygon.coordinates[0].forEach(el=>{
                bounds.extend( new this.maps.LatLng(el[0], el[1]) );
            })
            this.setCenter(bounds.getCenter())
        }

    }

    setCenter(centerPoint){
        if (this.map) this.map.setCenter(centerPoint);
    }
    
    initDrawing = map => {
        var drawingManager = new this.maps.drawing.DrawingManager({
            drawingMode: this.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: this.maps.ControlPosition.TOP_CENTER,
                drawingModes: ["polygon"]
            },
            polygonOptions: {
                fillColor: "green",
                fillOpacity: 0.3,
                strokeWeight: 2,
                strokeColor: "#61dafb",
                clickable: true,
                draggable: true,
                editable: true,
                zIndex: 1
            }
        });
        drawingManager.setMap(this.map);

        const default_shapes = this.state.default_shapes ? this.state.default_shapes.slice() : [];
        default_shapes.forEach(el=>{
            this.createShape(el);
        })

        // handel custom draw
        this.maps.event.addListener(drawingManager, "overlaycomplete", e => {
            const shapes = this.state.shapes ? this.state.shapes.slice() : [];

            // only add one editable shape on canvas
            if (shapes.length >= 1) {
                e.overlay.setMap(null);
                message.warning(`You can only add 1 zone(s)`);
                return;
            }
            // if (this.state.category =='service-area'){
            //     if (shapes.length >= numAllowed_service_zones) {
            //         e.overlay.setMap(null);
            //         message.warning(`You can only add ${numAllowed_service_zones} zone(s)`);
            //         return;
            //     }
            // }
            // if (this.state.category =='delivery-zones'){
            //     if (shapes.length >= numAllowed_delivery_zones) {
            //         e.overlay.setMap(null);
            //         message.warning(`You can only add ${numAllowed_delivery_zones} zone(s)`);
            //         return;
            //     }
            // }

            this.addShape(e.overlay)
        });
    };

    resetPolygon() {
        if (this.state.shapes && this.state.shapes.length > 0) {
            this.state.shapes.forEach(polygon => {
                console.log(polygon)
                polygon.setMap(null)
            });

            // redeploy static shapes
            const static_shapes = this.state.static_shapes || false;
            if (static_shapes) static_shapes.map(o=>{
                o.setMap(this.map);
                return;
            }) 

            this.setState({ shapes: [] })
        }

    }

    addDragListeners = polygon => {
        const draggingPolygon = this.getShapeRef(polygon);

        this.maps.event.addListener(polygon, "dragstart", () => {
            this.activePolygon = this.getShapeRef(polygon);
        });
        this.maps.event.addListener(polygon, "dragend", () => {
            this.updatePolygon(polygon);
        });
    };

    getShapeRef = polygon => {
        const shapes = this.state.shapes || [];
        const currPoints = polygon
            .getPath()
            .getArray()
            .map(p => ({ lat: p.lat(), lng: p.lng() }));
        let currshape = shapes.find(s => JSON.stringify(s.points) === JSON.stringify(currPoints));

        return currshape;
    };

    updatePolygon = polygon => {
        if (!this.activePolygon) return;

        const shapes = this.state.shapes || [];
        this.activePolygon.points = polygon.getPath().getArray().map(p => ({ lat: p.lat(), lng: p.lng() }));
    };

    createShape(paths, options={}) {
        if (!paths){
            console.log(__error("Path not found"))
            return;
        }

        const theShape = new this.maps.Polygon({
            paths,
            fillColor: options.fillColor || "green", // #FF0000
            fillOpacity: options.fillOpacity || 0.35,
            strokeColor: options.strokeColor || "#61dafb", // FF0000
            strokeOpacity: options.strokeOpacity || 0.8,
            strokeWeight: options.strokeWeight || 2,
            draggable: options.draggable===false ? false : true,
            editable: options.editable === false ? false : true,
            clickable: options.clickable === false ? false : true,
            zIndex: options.zIndex || 1
        });

        if(options.addShape!==false) this.addShape(theShape);
        theShape.setMap(this.map);



        /**
         * A menu that lets a user delete a selected vertex of a path.
         */
        const maps = this.maps;
        class DeleteMenu extends maps.OverlayView {
            div_;
            divListener_;
            constructor() {
                super();
                this.div_ = document.createElement("span");
                this.div_.className = "map-editor-delete-menu";
                this.div_.innerHTML = "Delete";

                const menu = this;

                maps.event.addDomListener(this.div_, "click", () => {
                    menu.removeVertex();
                });
            }
            onAdd() {
                const deleteMenu = this;
                const map = this.getMap();

                this.getPanes().floatPane.appendChild(this.div_);
                // mousedown anywhere on the map except on the menu div will close the
                // menu.
                this.divListener_ = maps.event.addDomListener(
                    map.getDiv(),
                    "mousedown",
                    (e) => {
                        if (e.target != deleteMenu.div_) {
                            deleteMenu.close();
                        }
                    },
                    true
                );
            }
            onRemove() {
                if (this.divListener_) {
                    maps.event.removeListener(this.divListener_);
                }

                this.div_.parentNode.removeChild(this.div_);
                // clean up
                this.set("position", null);
                this.set("path", null);
                this.set("vertex", null);
            }
            close() {
                this.setMap(null);
            }
            draw() {
                const position = this.get("position");
                const projection = this.getProjection();

                if (!position || !projection) {
                    return;
                }

                const point = projection.fromLatLngToDivPixel(position);

                // this.div_.style = { 
                //     ...this.div_.style, 
                //     top: point.y + "px",
                //     left: point.x + "px",
                //     backgroundColor: "white",
                //     position: "absolute",
                // }

                this.div_.style.top = point.y + "px";
                this.div_.style.left = point.x + "px";
                // this.div_.style.backgroundColor = "white";
                // this.div_.style.position = "absolute";
            }
            /**
             * Opens the menu at a vertex of a given path.
             */
            open(map, path, vertex) {
                console.log("open: ", vertex)
                this.set("position", path.getAt(vertex));
                this.set("path", path);
                this.set("vertex", vertex);
                this.setMap(map);
                this.draw();
            }
            /**
             * Deletes the vertex from the path.
             */
            removeVertex() {
                const path = this.get("path");
                const vertex = this.get("vertex");

                if (!path || vertex == undefined) {
                    this.close();
                    return;
                }

                path.removeAt(vertex);
                this.close();
            }
        }
        this.deleteMenu = new DeleteMenu();



        this.maps.event.addListener(theShape, "contextmenu", (e) => {
            if (e.vertex == undefined) return;

            this.deleteMenu.open(this.map, theShape.getPath(), e.vertex);
        });

        return theShape;
    }

    addShape(polygon){
        const shapes = this.state.shapes ? this.state.shapes.slice() : [];
        shapes.push(polygon);
        this.addDragListeners(polygon)
        this.setState({ shapes });
    }

    createStaticShape(coordinates, options={}){
        const theShape = this.createShape(coordinates, {
            fillColor: options.fillColor || 'red',
            fillOpacity: options.fillOpacity || 0.10,
            draggable: options.draggable || false,
            editable: options.editable || false,
            clickable: options.clickable || false,
            addShape: options.addShape==undefined ? true : false,
        });
        if (!theShape) return;
        // theShape.setMap(this.map);

        const static_shapes = this.state.static_shapes ? this.state.static_shapes.slice() : [];
            static_shapes.push(theShape);
        this.setState({ static_shapes });
    }

    removeStaticShapes(){
        const static_shapes = this.state.static_shapes;
        if (!static_shapes) return;

        static_shapes.forEach(o=>{
            o.setMap(null);
        })

        this.setState({ static_shapes:null });
    }

    loadGeoZones(filter, _id){
        const _filter = { ...filter };
        if (_id) Object.assign(_filter, { _id: { $nin: [_id] } });

        return this.props.client.query({ query: GEO_ZONES, variables: { filter: JSON.stringify(_filter) } }).then(e => {
            return e;
        }).catch(err => {
            console.log(__error("API Call ERROR: related zones : "), err);
            return { error:{message:"Unable to fetch related zones"}}
        })
    }
    
    async loadRelatedZones(values){
        if (!values || !values.city || !values.category) return;
        if (!this.map) return;

        const { city, category } = values;
        const _id = this.state?.fields?._id;// (fields && fields._id) ? fields._id : false;

        this.setState({ loadingRelatedZones: true });
        this.removeStaticShapes();

        if (category == 'delivery-zones'){
            // load related service zone
            const relatedServiceZones = await this.loadGeoZones({ city:city.code, category:'service-area' }, _id).then(r => {
                if ((r.error && r.error.message) || (r.errors && r.errors[0].message)) {
                    message.error((r.error && r.error.message) || (r.errors && r.errors[0].message));
                    return false;
                }
                return r.data.geoZones;
            }).catch(error => {
                message.error("Error in fetching related zones");
                return false;
            })

            if (relatedServiceZones && relatedServiceZones.length>0){
                relatedServiceZones.forEach(geoZone => {
                    this.createStaticShape(geoZone.polygon.coordinates[0].map(o => ({ lat: o[0], lng: o[1] })), { fillColor: 'blue', addShape:false });
                })
            }
        }

        if (category == 'service-area'){ }

        const relatedZones = await this.loadGeoZones({ city:city.code, category }, _id).then(r=>{
            if ((r.error && r.error.message) || (r.errors && r.errors[0].message)){
                message.error((r.error && r.error.message) || (r.errors && r.errors[0].message));
                return false;
            }
            return r.data.geoZones;
        }).catch(error=>{
            message.error("Error in fetching related zones");
            return false;
        })

        if (relatedZones && relatedZones.length>0){
            relatedZones.forEach(geoZone => {
                this.createStaticShape(geoZone.polygon.coordinates[0].map(o => ({ lat: o[0], lng: o[1] })), { addShape:false });
            })
        }
        this.setState({ loadingRelatedZones: false })

    }

    moveToCity(city){
        // const __city = serviceCities.find(o => o._id==_city);
        // if (!__city || !__city._id){
        //     message.error("City not found!");
        //     return false;
        // }

        this.setCenter(city.coordinates[0]);
    }
    


    render() {
        const { onClose, showform, loading, geoZone } = this.props;
        const { fields, busy, loadingRelatedZones, loadingEditData } = this.state;

        // if (loadingEditData) return <p>loadingEditData</p>

        // if (fields && (loading && loading_location)){
        //     console.log("Loading....")
        // }
        // else{
        //     console.log("Loading complete", { location: this.props.location })
        // }



        return (
            <Drawer _width={"450px"} height="100%" destroyOnClose maskClosable={false} placement="top"
                // loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5", padding:0 }}
                footer={<>
                    <span></span>
                    <Button loading={busy || loading || loadingEditData} disabled={!this.state.shapes || this.state.shapes.length<1} type="primary" onClick={() => {
                        document.getElementById('GeoZoneForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                // title={`${fields && fields._id ? 'Edit' : 'Add'} GeoZone`}
                title={<>
                    {`${fields && fields._id ? 'Edit' : 'Add'} GeoZone`}
                    <Loader loading={loadingRelatedZones} />
                    </>}
            >
                {(loading || loadingEditData) && <Loader loading={true} />}

                {(!loading && !loadingEditData) && <>
                    <FormComponent onSubmit={this.onSubmit} id='GeoZoneForm' loading={busy} fields={{ ...fields }}
                        form_render={({ values }) => {
                            return <>
                                <div className="grid-block" style={{flex:1}}>
                                    <Row>
                                        <Col flex="auto"><FormField type="text" name="title" label="Title" validate={composeValidators(rules.required)} /></Col>
                                        <Col flex="200px"><FormField type="select" data={geoZoneCategories} onChange={(_category, callback)=>{
                                            callback(_category);
                                            this.setState({ category: _category }, ()=>{
                                                this.loadRelatedZones({ ...values, city:this.state.city, category: _category })
                                            })
                                        }} name="category" label="Category" validate={composeValidators(rules.required)} /></Col>
                                        <Col flex="150px"><LocationSelection name="city" label="City" preload 
                                            onSelect={(v, ar) => {
                                                let _city = ar['item-data'].raw;
                                                
                                                console.log(_city);

                                                this.setState({ city: _city }, () => {
                                                    this.moveToCity(_city);
                                                    this.loadRelatedZones({ ...values, city: _city });
                                                })

                                            }}
                                        /></Col>
                                        <Col flex="130px"><FormField type="select" data={publishStatus} name="status" label="Status" validate={composeValidators(rules.required)} /></Col>
                                    </Row>

                                    {this.state.city && <div style={{ height: 'calc(100vh - 230px)', width: '100%' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ 
                                                key: GOOGLE_API_KEY,
                                                libraries: ['drawing'], // 'DrawingManager'],
                                            }}
                                            options={{
                                                // editable: true,
                                                // draggable: true,
                                                // drawingControl:true,
                                                // panControl: false,
                                                // mapTypeControl: false,
                                                // drawingMode: google.maps.drawing.OverlayType.MARKER,
                                                // drawingControlOptions: {
                                                //     position: google.maps.ControlPosition.TOP_CENTER,
                                                //     drawingModes: [
                                                //         google.maps.drawing.OverlayType.MARKER,
                                                //         google.maps.drawing.OverlayType.CIRCLE,
                                                //         google.maps.drawing.OverlayType.POLYGON,
                                                //         google.maps.drawing.OverlayType.POLYLINE,
                                                //         google.maps.drawing.OverlayType.RECTANGLE,
                                                //     ],
                                                // },
                                            }}
                                            defaultCenter={this.state?.city?.coordinates[0] || this.props.center}
                                            defaultZoom={this.props.zoom}
                                            // onClick={this.onPolygonUpdate}
                                            // googleMapLoader={console.log}
                                            onGoogleApiLoaded={this.onGoogleApiLoaded}
                                            // yesIWantToUseGoogleMapApiInternals
                                        >
                                            {/* <AnyReactComponent
                                                lat={59.955413}
                                                lng={30.337844}
                                                text="My Marker"
                                            /> */}

                                            {/* <div style={{ position: "absolute", top: "500px", right: "500px" }}><Button onClick={this.resetPolygon}>Reset</Button></div> */}
                                        </GoogleMapReact>
                                        <div style={{ position: "absolute", top: "150px", right: "30px" }}><Button disabled={!this.state.shapes || this.state.shapes.length < 1} onClick={this.resetPolygon}>Reset</Button></div>
                                    </div>}
                                   
                                </div>
                            </>
                        }}
                    />
                </>}

            </Drawer>
        )
    }
}
FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    geoZone: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editGeoZone: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addGeoZone: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, geoZone, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, geoZone, queryErrors: error, }
        },
    }),

    // graphql(LOCATIONS, {
    //     options: ({ fields }) => {
    //         return {
    //             variables: { code: fields.city },
    //             fetchPolicy: "no-cache",
    //         };
    //     },
    //     props: ({ ownProps, data }) => {
    //         const { loading, locations, error } = data;
    //         if (error) console.log(__error("error"), error);
    //         return { loading_location: loading, location: locations ? locations[0] : null, queryErrors: error, }
    //     },
    // }),
)(WithApollo);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default withApollo(Wrapper);

