import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
// import moment from 'moment';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton, DevBlock } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { verifyRole } from 'Common/scripts/Security';
import { connect } from "react-redux";

const LIST_DATA = loader('src/graphqls/vehicle/vehicleQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/vehicle/sub_vehiclesUpdated.graphql');
const RECORD_DELETE = loader('src/graphqls/vehicle/deleteVehicle.graphql');


export class ListComp extends Component {
    state = {
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
        busy: false,
    };
    subscription = false;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.vehicleQuery?.totalCount
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "vehiclesUpdated",
                _subscriptionType: "array",
                _queryName: "vehicleQuery",
                _typename: "Vehicle",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteVehicle(id).then(r => {
            if (r && r.data && r.data.deleteVehicle && r.data.deleteVehicle.error) {
                message.error(r.data.deleteVehicle.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        // { title: 'ID', dataIndex: '_id', width: '80px' },
        { title: 'Title', dataIndex: 'title', className: 'show-menu-on-hover', render: (text, record) => {
            return (<Row className='show-menu-on-hover' gutter={[10]}>
                <Col>{text}</Col>
                <Col className='hidden-menu'>
                    {verifyRole('manage-vehicle', this.props.user) && <>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} />
                        <DeleteButton onClick={() => this.handleDelete(record?._id)} />
                    </>}
                </Col>
            </Row>)
        } },
        { title: 'Drivers', dataIndex: 'driver', render:(txt, rec)=>{
            if (!rec.drivers) return;
            return rec.drivers.map(({ _id, name }, i)=>{
                return <span style={{border:'1px solid #CCC', backgroundColor:'#EEE', borderRadius:'3px', margin:'3px'}} key={i}>{name}</span>
            })
        } },
        { title: 'Reg. No.', dataIndex: 'registration_no', width: '200px' },
        { title: 'Status', dataIndex: 'status', width:'100px' },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ busy: true })

        let filter = filters ? JSON.stringify({ ...filters }) : this.props.queryString;

        this.props.loadMoreRows(
            pagination.pageSize || defaultPageSize,
            (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            filter,
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        ).then(r => {
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.vehicleQuery?.totalCount },
                busy: false
            })
        })
    };


    render() {
        const { loading, vehicleQuery, queryErrors } = this.props;
        const { pagination } = this.state;


        return (<>
            <ListHeader title="Vehicles"
                sub={<>Total {(vehicleQuery && vehicleQuery.totalCount) || 0} Vehicles</>}
                right={<Button onClick={this.props.onAddRecord}>Add Vehicle</Button>}
            />

            <Table loading={loading}
                columns={this.columns}
                dataSource={vehicleQuery ? vehicleQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status == 'hidden' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
            // rowClassName={(record => {
            //     return record.status == 'hidden' ? 'disabled-table-row' : "";
            // })}
            // title={() => <span>Header</span>}
            // footer={() => 'Footer'}
            // expandable={{
            //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
            //     // rowExpandable: record => record.name !== 'Not Expandable',
            // }}
            />
        </>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0 } };
        },
        props: ({ ownProps, data }) => {
            const { loading, vehicleQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.vehicleQuery.totalCount
                    const newEdges = fetchMoreResult.vehicleQuery.edges
                    const pageInfo = fetchMoreResult.vehicleQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        vehicleQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, vehicleQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteVehicle: (id) => mutate({
                variables: { id }
            }),
        })
    }),


)(ListComp);

const mapStateToProps = ({ grocer_storeadmin }) => {
    // console.log("===== grocer_storeadmin: ", grocer_storeadmin)
    return { user: { ...grocer_storeadmin.user, rights: grocer_storeadmin.rights } };
}
export default connect(mapStateToProps)(WithApollo);
// export default WithApollo;