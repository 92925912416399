import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader, DevBlock, IconButton } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField, SubmitButton } from 'Common/components/Form'
import { message, Row, Col, Modal, Alert } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { dateToUtc, sleep } from 'Common/scripts/Functions';


const GET_SETTINGS = loader('src/graphqls/settings/settings.graphql');
const ADD_SETTINGS = loader('src/graphqls/settings/addSettings.graphql');
const EDIT_SETTINGS = loader('src/graphqls/settings/editSettings.graphql');


const FieldFormEditorComp = ({ cat, edit_field, onSuccess, onCancel, client }) => {
    const [fields, setFields] = useState(null)
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        if (!edit_field || fields) return;
        fetchData();

        return () => {
            setFields(null)
        }
    }, [edit_field])

    const fetchData = async() => {
        setBusy(true)
        let resutls = await client.query({ query: GET_SETTINGS }).then(({ data }) => data.settings);
        setFields(resutls.find(o => o.code == edit_field))
        setBusy(false)
    }
    
    const onSubmit = async(values) => {
        let input = {
            // _id: Number(values._id),
            tooltip: values.tooltip,
            title: values.title,
            value: values.value ? String(values.value) : undefined,
            code: values.code,
            cat, //: values.cat,
            sub_cat: values.sub_cat,
            type: values.type,
            validator_function: values.validator_function,
        }
        if (values.type == "date" && values.value) Object.assign(input, { value: dateToUtc(values.value) })
        if (values.type == "date_time" && values.value) Object.assign(input, { value: dateToUtc(values.value) })
        if (values.type == "select" && values.options) Object.assign(input, { value: JSON.stringify(values.options) })
        if (values._id) Object.assign(input, { _id: Number(values._id) })


        if (values._id) return updateSettings(input);
        else return addSettings(input)
    }

    const updateSettings = async (input) => {
        let resutls = await client.mutate({ mutation: EDIT_SETTINGS, variables: { input } }).then(({ data }) => (data.editSettings))
        if (resutls.error) {
            alert(resutls.error.message);
            return false;
        }

        onSuccess()
        return false;
    }

    const addSettings = async (input) => {
        let resutls = await client.mutate({ mutation: ADD_SETTINGS, variables: { input } }).then(({ data }) => (data.addSettings))
        .catch(err => {
            console.log(__error("Query Error: "), err)
            return { error: { message:"Query Error" }}
        })
        if (resutls.error){
            alert(resutls.error.message);
            return false;
        }

        onSuccess()
        return false;
    }


    if (busy) return <Loader loading={true} center />


    return (<>
        <FormComponent onSubmit={onSubmit} id='SettingsFieldEditorForm' fields={{ ...fields }}
            mutators={{
                ...arrayMutators,
                onFieldTypeChange: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'value', () => undefined)
                    tools.changeValue(state, 'options', () => undefined)
                },
            }}

            form_render={({ values, form, submitting }) => {
                return <>
                    <Row>
                        <Col flex="150px">
                            <FormField
                                onChange={(v, callback) => {
                                    form.mutators.onFieldTypeChange()
                                    callback(v)
                                }}
                                data={[
                                    { _id: "text", title: "Text field" },
                                    { _id: "number", title: "Number field" },
                                    { _id: "switch", title: "Switch" },
                                    { _id: "textarea", title: "Textarea" },
                                    { _id: "select", title: "Select" },
                                    { _id: "date", title: "Date" },
                                    { _id: "date_time", title: "Date & Time" },
                                    { _id: "email", title: "Email" },
                                ]}
                                type="select" name="type" label="Type" validate={composeValidators(rules.required)} />
                        </Col>
                        {/* <Col flex="auto">
                            {values.type && <FormField type="select" data={categories} name="cat" label="Cat" validate={composeValidators(rules.required)} />}
                        </Col> */}
                        <Col>
                            {values.type && <FormField type="text" name="sub_cat" label="Sub-cat" />}
                        </Col>
                    </Row>


                    {(values.type && values.sub_cat) && <>
                        <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required)} />
                        {values.type=="text" && <FormField type="text" name="value" label="Value"  />}
                        {values.type =="number" && <FormField type="number" name="value" label="Value" />}
                        {values.type == "switch" && <FormField type="switch" name="value" label="Value" />}
                        {values.type == "textarea" && <FormField type="textarea" name="value" label="Value" />}
                        {values.type == "select" && <>
                            <FieldArray name="options">
                                {({ fields }) => {

                                    return (<div>
                                        {fields.map((name, index) => {
                                            let thisField = fields.value[index];

                                            return (<div key={index} style={{ border: "1px solid #EEE", padding: "10px", marginBottom: "5px", borderRadius: "5px" }}>
                                                <Row align="middle" gutter={[5, 5]}>
                                                    <Col><FormField type="text" compact size="small" label="Label" name={`${name}.label`} /></Col>
                                                    <Col flex="auto"><FormField type="text" compact size="small" label="Value" name={`${name}.value`} /></Col>
                                                    <Col><IconButton onClick={() => fields.remove(index)} size="small" icon="minus" color="red" /></Col>
                                                </Row>

                                            </div>)

                                        })}

                                        <Button onClick={() => fields.push({ id: "", value: "" })} size="small" block type="dashed"><Icon icon="plus" />{` `} Add option</Button>
                                        <div style={{ marginBottom:"50px" }} />

                                    </div>)
                                }}
                            </FieldArray>
                        </>}
                        {values.type == "date" && <FormField type="date" name="value" label="Value" />}
                        {values.type == "date_time" && <FormField type="date" showTime name="value" label="Value" />}
                        {values.type == "email" && <FormField type="email" name="value" label="Value" />}

                        <FormField type="text" name="tooltip" label="Tooltip" validate={composeValidators(rules.required)} />
                        <FormField type="text" name="code" label="Code" validate={composeValidators(rules.required)} />
                        <FormField type="textarea" name="validator_function" label="Validator Function" />

                        <div style={{ height:"20px" }} />

                        <Row>
                            <Col flex="auto"><Button onClick={onCancel} type="default">Cancel</Button></Col>
                            <Col><SubmitButton loading={submitting}>Save</SubmitButton></Col>
                        </Row>

                        <DevBlock obj={values} />
                    </>}
                    
                </>
            }}
        />
    </>)
}
FieldFormEditorComp.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    edit_field: PropTypes.string,
    cat: PropTypes.string.isRequired,
}
const FieldFormEditor = withApollo(FieldFormEditorComp)


const Wrapper = ({ cat, show, edit_field, onCancel, onSuccess }) => {
    // if (!cat) return <Alert message="Category not found" type='error' showIcon />
    // if (!show) return null;
    // store-settings
    return (<Modal title={edit_field ? `Edit Field ${cat}` : `Add Field (${cat})`} visible={show} footer={false} onCancel={onCancel}>
        {!cat && <Alert message="Category not found" type='error' showIcon />}
        {(!cat || !show) ? null : <>
            <FieldFormEditor cat={cat} edit_field={edit_field} onSuccess={onSuccess} onCancel={onCancel} />
        </>}
    </Modal>)
}
export default Wrapper;

