import React, { Component } from 'react'
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright'; 
import { __error } from 'Common/scripts/consoleHelper'
import { orderStatus } from 'configs/constants';
import { Drawer, Heading, Button, Icon, UserSelection, BasketSelection, Dropdown } from 'Common/components'
import { Select, FormField, FormComponent, FormFieldGroup, composeValidators, rules } from 'Common/components/Form'
import { Row, Col, message, Modal } from 'antd';
import { connect } from 'react-redux'
import { Status_Canceled, Status_Completed, Status_Declined, Status_Delivered, Status_Dispatched, Status_In_Review, Status_New, Status_Picking, Status_Ready_To_Dispatch, Status_Unconfirmed } from './status_forms';


const UPDATE_ORDER_STATUS = loader('src/graphqls/orders/orderSatusUpdate.graphql');




class StatusUpdater extends Component {
    state = { busy: false, showConfirmationPop: false, status:null, defaultFields:null }
    constructor(props){
        super(props);
        this.togglePop = this.togglePop.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    componentWillReceiveProps(nextProps){
        const { order } = nextProps;

        const defaultFields = {
            totalPrice: order.totalPrice,
            driver_id: order?.dispatch_by?._id,
            dispatch_baskets: order?.dispatch_baskets?.map(o => o._id),
            picker_id: order?.pickup_by?._id,
            picker_baskets: order?.picker_baskets?.map(o => o._id),
            till_by_id: order?.till_by?._id,
        }

        this.setState({ defaultFields })
    }

    onStatusChanged(status){
        if (!status) return;
        this.setState({ showConfirmationPop: !this.state.showConfirmationPop, status });
    }

    togglePop() {
        this.setState({ busy: false, showConfirmationPop: !this.state.showConfirmationPop })
    }
    
    async onSubmitForm(values){
        const { status } = this.state;
        const { order } = this.props;

        const data = {
            _id: Number(order._id),
            status,
            // picker_baskets: values.picker_baskets,
            // pickup_by: values.pickup_by,
            // pickup_data: values.pickup_data,
        }

        if (values.status == "in-review"){
            // Object.assign(data, { ...values })
            Object.assign(data, {
                pickup_data: values.pickup_data
            })
        }
        else if (values.status =="canceled"){}
        else if (values.status =="declined"){}
        else if (values.status =="delivered"){}
        else if (values.status =="dispatched"){
            // alert("Order can only be dispatched from Driver's App");
            // return false;
        }
        else{
            alert("Unhandeled order status");
            return false;
        }



        this.setState({ busy: true });

        const result = await this.props.orderSatusUpdate(data)
            .then((e) => (e.data.orderSatusUpdate))
            .catch(error => {
                console.log(__error("ERROR: "), error);
                return { error: { message: "Query Error" } }
            });

        this.setState({ busy: false });

        if (result.error) {
            message.error(result.error.message);
        } else {
            message.success("Status updated.");
            this.togglePop();
        }

        return false;

    }


    render() {
        const { title, order } = this.props;
        const { busy, showConfirmationPop, defaultFields } = this.state;
        const statusLocked = (order.status == "declined") || (order.status == "canceled") || (order.status == "completed");

        if (statusLocked) return (<>
            <Heading>Order {order.status}</Heading>
        </>)

        return (<>
            {title && <Heading>{title}</Heading>}

            {/* With Status update option */}
            <div>
                <Dropdown 
                    data={orderStatus({ user: this.props.user, store: this.props.store }).map(o => ({ 
                        ...o, 
                        disabled: (o._id == order.status || o._id == 'picking' || o._id == 'ready-to-dispatch' || o._id == 'new' || o.divider ? true : false),
                        title: o.divider || o.title,
                    })) } 
                    disabled={busy} 
                    onClick={(e) => this.onStatusChanged(e._id)}
                    // icon="ellipsis-v"
                    title={<div style={{ fontSize:"22px" }}>
                        <span style={{ border: "1px solid #DDD", padding: "3px 10px", marginRight:"5px", borderRadius: "5px" }}>
                            <Icon icon="ellipsis-v" />
                        </span>
                        {orderStatus().find(o => o._id == order.status).title}
                    </div>}
                />
                {/* <span style={{ fontSize:"24px" }}>{orderStatus().find(o => o._id == order.status).title}</span> */}
            </div>

            <Drawer height="100%" destroyOnClose maskClosable={true} placement="top"
                onClose={() => this.togglePop()}
                visible={showConfirmationPop}
                bodyStyle={{ backgroundColor: "#FFF", padding: "20px" }}
                title={`Update order status to ${this.state?.status && orderStatus().find(o => o._id == this.state.status).title}`}
                footer={null}
            ><>
                {this.state.status == 'new' && <><Status_New
                    order={order}
                    onSubmit={this.onSubmitForm}
                    onCancel={() => this.togglePop()}
                /></>}

                {this.state.status == 'picking' && <><Status_Picking
                    order={order}
                    onSubmit={this.onSubmitForm}
                    onCancel={() => this.togglePop()}
                /></>}

                {this.state.status == 'in-review' && <>
                    <Status_In_Review
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                </>}

                {this.state.status == 'ready-to-dispatch' && <>
                    <Status_Ready_To_Dispatch
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                    {/* <p>Incomplte function: Change order status to <b>{this.state.status}</b></p> */}
                    {/* <Alert message="Warning" description="This will remove any driver data associated" type="warning" showIcon />
                    <PickupFields order={order} skipBaskets={true} />
                    <TillByField order={order} /> */}
                </>}

                {this.state.status == 'dispatched' && <>
                    <Status_Dispatched
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                    {/* <p>Incomplte function: Change order status to <b>{this.state.status}</b></p> */}
                    {/* <PickupFields order={order} skipBaskets={true} />
                    <TillByField order={order} />
                    <DriverField order={order} preload={true} /> */}
                </>}

                {this.state.status == 'delivered' && <>
                    <Status_Delivered
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                    {/* <p>Incomplte function: Change order status to <b>{this.state.status}</b></p> */}
                    {/* <PickupFields order={order} skipBaskets={true} />
                    <TillByField order={order} skipBaskets={true} />
                    <DriverField order={order} preload={true} /> */}
                </>}

                {this.state.status == 'completed' && <>
                    <Status_Completed
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                </>}

                {this.state.status == 'canceled' && <>
                    <Status_Canceled
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                </>}

                {this.state.status == 'declined' && <>
                    <Status_Declined
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                </>}

                {this.state.status == 'unconfirmed' && <>
                    <Status_Unconfirmed
                        order={order}
                        onSubmit={this.onSubmitForm}
                        onCancel={() => this.togglePop()}
                    />
                </>}

            </></Drawer>

        </>)

    }
}

const WithApollo = compose(
    graphql(UPDATE_ORDER_STATUS, {
        props: ({ mutate }) => ({
            orderSatusUpdate: (input) => mutate({
                variables: { input }
            }),
        })
    }),

    // graphql(ORDER_DELIVERED, {
    //     props: ({ mutate }) => ({
    //         orderDelivered: (args) => mutate({
    //             variables: { input:{...args} }
    //         }),
    //     })
    // }),



)(StatusUpdater);

const mapStateToProps = ({ grocer_storeadmin, grocer_storeadmin: { user, store } }) => {
    return { user, store };
}
export default connect(mapStateToProps)(WithApollo);

// export default WithApollo;
