import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Divider, Radio, Modal } from 'antd';
import { Loader, Icon, Button, IconButton, Table, DataGrid, Avatar, ListHeader, DeleteButton } from 'Common/components';
// import { Select, Input } from 'Common/components/Form'
import { defaultPageSize } from 'configs';
// import { publishStatus, yesNoStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
// import ProductFilter from './ProductFilter'
// import debounce from 'lodash/debounce';

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/product_attributes/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/product_attributes/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/product_attributes/delete.graphql');
const { confirm } = Modal;

const defaultFilter = {}

function showDeleteConfirm(props) {
  confirm({
    title: 'Are you sure delete this record?',
      icon: <Icon icon="exclamation-triangle" />,
    // content: 'Some descriptions',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: props.onOk || console.log,
    onCancel() {
      console.log('Cancel');
    },
  });
}

export class ListComp extends Component {
    state = { pagination: { current: 1, pageSize: 10, }, filter:{} };

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    // ...this.state.pagination,
                    total: nextProps?.productAttributesQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "productAttributesUpdated",
                _subscriptionType: "array",
                _queryName: "productAttributesQuery",
                _typename: "ProductAttributes",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteProductAttributes(id).then(r => {
            if (r && r.data && r.data.deleteProductAttributes && r.data.deleteProductAttributes.error) {
                message.error(r.data.deleteProductAttributes.error.message);
                return false;
            }
            message.success("Record deleted")
        })
        .catch(error => {
            console.log(__error("ERROR"), error);
            message.error("Unable to delete record")
        })
    }

    columns = [
        { title: 'Title', dataIndex: 'title', render: (text, rec) => {
            return (<Row className='show-menu-on-hover'>
                <Col>{rec.title} (<small>{rec.code}</small>)</Col>
                <Col className='hidden-menu'>
                    <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                    <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                </Col>
            </Row>)
        } },
        { title: 'Visible in store', dataIndex: 'show_in_store', render:(text)=> text>0 ? "yes":"no", width: "150px", align:"center" },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            ...this.state,
            pagination: {
                ...this.state.pagination,
                pageSize: pagination.pageSize,
                current: pagination.current
            }
        })
        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.current - 1),
            this.props.queryString
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        )
    };

    doSeaarch(filter) {
        this.props.loadMoreRows(
            this.state.pagination.pageSize || defaultPageSize, 0,
            JSON.stringify(filter)
        ).then(r => {
            if (r.data && r.data.productAttributesQuery) 
                this.setState({ pagination: { ...this.state.pagination, total: r.data.productAttributesQuery.totalCount }})
            return r;
        })
    }


    render() {
        const { loading, productAttributesQuery, queryErrors } = this.props;
        const { pagination, pageView } = this.state;
        
        return (<ContentArea>
            <ListHeader title="Product Attributes" sub={`Total ${(productAttributesQuery && productAttributesQuery.length) || '0'} records found`}
                right={<>
                    <Button onClick={this.props.onAddClick} size="small">Add New Attribute</Button>
                </>}
            />
                
            <Table loading={loading}
                columns={this.columns}
                dataSource={productAttributesQuery ? productAttributesQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status == 'hidden' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
            />

        </ContentArea>)
    }
}

ListComp.propTypes = {
    // toggleDrawer: PropTypes.func.isRequired
    onEditRecord: PropTypes.func
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify(defaultFilter) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productAttributesQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    
                    const totalCount = fetchMoreResult.productAttributesQuery.totalCount
                    const newEdges = fetchMoreResult.productAttributesQuery.edges
                    const pageInfo = fetchMoreResult.productAttributesQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        productAttributesQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery }).then(r => {
                    
                    if (r.error || r.errors) {
                        message.error(`Query Error!`);
                        console.log(__error("QUERY ERROR"), r);
                    }
                    return r;
                });
            }

            return { loading, productAttributesQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteProductAttributes: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

export default WithApollo;
