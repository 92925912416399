import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Popconfirm } from 'antd';
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { defaultPageSize } from 'configs';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, IconButton, Table } from 'Common/components'
import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { publishStatus } from 'configs/constants';
// import ProductFilter from 'Modules/Products/components/product_filter'
import ProductSelector from './product-selector';


const RECORD = loader('src/graphqls/offers/offer.graphql');

const LIST_DATA = loader('src/graphqls/product/products.graphql');
const RECORD_EDIT = loader('src/graphqls/offers/edit.graphql');
const RECORD_ADD = loader('src/graphqls/offers/add.graphql');
const RECORD_DELETE = loader('src/graphqls/offers/delete.graphql');

const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

const defaultFilter = { status: 'published' }



const OfferForm = props => {
    let fields = { ...defaultFilter, ...props.fields };
    const [loaded, setLoaded] = React.useState(false);
    // const [selectedProducts, setSelectedProducts] = React.useState((fields && fields._id > 0) ? fields.items.map(item => ({ _id: item._id })) : []);
    const [selectedProducts, setSelectedProducts] = React.useState(null);
    const [openProdSelector, setOpenProdSelector] = React.useState(false);
    const [busy, setBusy] = React.useState(false);

    // React.useEffect(() => {
    //     if (loaded || !props.fields || !props.fields.items || props.fields.items.length<1) return;

    //     setBusy(true);
    //     setLoaded(true);
    //     let ids = props.fields.items.map(item => (item._id));

    //     props.client.query({
    //         query: LIST_DATA,
    //         variables: { filter: JSON.stringify({ _id: { $in: ids } }) },
    //         fetchPolicy: "no-cache",
    //     })
    //         .then(e => {
    //             if (e.data.products.error || e.errors) {
    //                 console.log("ERROR: ", e);
    //                 message.error(__error("ERROR "), (e.data.products.error && e.error.message) || (e.errors && e.errors[0].message));
    //                 return;
    //             }
    //             setBusy(false);
    //             setSelectedProducts(e.data.products);

    //         }).catch(err => {
    //             console.log(__error("API Call ERROR: PRODUCTS : "), err);
    //             message.error("Request ERROR");
    //             setBusy(false);
    //         })
        
    // })

    React.useEffect(() => {
        if (!props?.fields?._id || (props?.fields?.items?.length < 1) || selectedProducts) return;

        props.client.query({
            query: LIST_DATA,
            variables: { filter: JSON.stringify({ _id: { $in: props.fields.items.map(o=>(Number(o._id))) } }) },
            fetchPolicy: "no-cache",
        })
            .then(e => {
                const response = e.data.products;
                console.log("response: ", response)

                if (response.error) {
                    console.log("ERROR: ", response);
                    return message.error(response.error.message);
                }
                setBusy(false);
                setSelectedProducts(response);

            }).catch(err => {
                console.log(__error("API Call ERROR: PRODUCTS : "), err);
                message.error("Request ERROR");
                setBusy(false);
            })

        //   return () => { };
    }, [props.fields])

    const { loading } = props;

    const columns = [
        {
            title: 'Name', dataIndex: 'title', render: (text, record) => {
                return (<Row gutter={16} style={{flexWrap:"nowrap"}}>
                    <Col><Avatar size={40} shape="square" src={record.picture_thumb || null} icon={<Icon icon="image" />} /></Col>
                    <Col>{record.title}</Col>
                </Row>)
            }
        },
        { title: 'Price', dataIndex: 'price', width: '80px', align: 'center', render: (text, record) => (<span>{props.settings.currency} {text}</span>) },
        { title: 'Qty', dataIndex: 'available_qty', width: '60px', align: 'center' },
        {
            title: ' ',
            dataIndex: '',
            render: (text, record) => {
                return <IconButton icon="minus" onClick={()=>removeProduct(record)} />
            },
            className: 'actions-column',
            align: 'right',
            width: '50px'
        },
    ];

    const onProductSelected = arr => {
        setOpenProdSelector(false);
        if (arr) setSelectedProducts([...selectedProducts, ...arr]);
    }

    const removeProduct = prod => {
        let _selectedProducts = selectedProducts.filter(o => o._id != prod._id);
        setSelectedProducts(_selectedProducts);
    }

    const onSubmit = values => {
        console.log("onSubmit()", values);

        const { onClose, editOffer, addOffer } = props;
        const _id = props.fields ? props.fields._id : false;

        let filteredValues = {
            title: values.title,
            key: values.key,
            // type: values.type,
            // deleteable: values.deleteable,
            items: selectedProducts ? selectedProducts.map(item => ({ _id: item._id })) : [],
            status: values.status,
        };

        // return console.log("filteredValues: ", filteredValues)
        
        setBusy(true);
       
        if (_id) {
            editOffer({ ...filteredValues, _id: _id }).then((e) => {
                setBusy(false)
                if (e.data.editOffer.error) {
                    let err = e.data.editOffer.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editOffer);
            }).catch(error => {
                setBusy(false)
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addOffer(filteredValues).then((e) => {
                setBusy(false);
                if (e.data.addOffer.error) {
                    let err = e.data.addOffer.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editOffer);
            }).catch(error => {
                setBusy(false);
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    return (
        <Drawer width={600} destroyOnClose maskClosable={false} placement="right"
            bodyStyle={{ padding: "0px" }}
            onClose={props.onClose}
            visible={props.showform}
            footer={<>
                <span></span>
                <Button loading={loading || busy} type="primary" onClick={() => {
                    document.getElementById('OffersForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`${props.fields && props.fields._id ? 'Edit' : 'Add'} Offer`}
        >
            <FormComponent onSubmit={onSubmit} id='OffersForm' loading={loading || busy} fields={fields} debug={true}
                mutators={{
                    addItems: (newValueArray, state, tools) => {
                        tools.changeValue(state, 'items', () => newValueArray[0])
                    },
                    removeItems: (newValueArray, state, tools) => {
                        tools.changeValue(state, 'items', () => newValueArray[0])
                    },
                }}
                form_render={formProps => {
                    const { values } = formProps;

                    return (<>
                        <FormField type="select" name="status" label="Status" className={values.status == 'published' ? "active" : "inactive"} data={publishStatus} validate={rules.required} />
                        <FormField type="text" name="title" label="Title" placeholder="Offer title" validate={composeValidators(rules.required, rules.minChar(4))} disabled={(fields && fields._id && fields.deleteable == 'no')} />
                        <FormField onChange={filterSlug} type="text" name="key" placeholder="Offer placeholder key" label="Key (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} disabled={(fields && fields._id && fields.deleteable == 'no')} />
                        <div style={{ padding: "10px" }}>
                            <Heading><span>Product selection</span> <span><IconButton onClick={() => setOpenProdSelector(true)} icon="plus" /></span></Heading>
                            <ProductSelector onClose={() => setOpenProdSelector(false)} isVisible={openProdSelector} onSelect={onProductSelected} exclude={selectedProducts} />

                            <Table
                                // loading={loading || busy}
                                columns={columns}
                                dataSource={selectedProducts}
                                pagination={false}
                                // onChange={handleTableChange}
                                // title={() => <span>{selectedProducts.length || 0} Records Found</span>}
                            />

                        </div>
                    </>)
                }}
            />

        </Drawer>
    )
}

OfferForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    // graphql(LIST_DATA, {
    //     options: props => {
    //         return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify(defaultFilter) } };
    //     },
    //     props: ({ ownProps, data }) => {
    //         const { loading, productsQuery, error, fetchMore, subscribeToMore } = data;

    //         if (error) {
    //             message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
    //             console.log(__error("error"), error);
    //             // error.networkError.name
    //             // error.networkError.response.status
    //             // error.networkError.response.statusText
    //             console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
    //         }

    //         const loadMoreRows = (count, startFrom, filter, others) => {

    //             let vars = { first: count, after: startFrom, filter: filter, others: others };

    //             let updateQuery = (previousResult, { fetchMoreResult }) => {

    //                 const totalCount = fetchMoreResult.productsQuery.totalCount
    //                 const newEdges = fetchMoreResult.productsQuery.edges
    //                 const pageInfo = fetchMoreResult.productsQuery.pageInfo
    //                 return {
    //                     // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
    //                     productsQuery: {
    //                         totalCount,
    //                         // edges: [...previousResult.branchesQuery.edges, ...newEdges],
    //                         edges: newEdges,
    //                         pageInfo,
    //                         __typename: 'UserRole'
    //                     }
    //                 }
    //             }

    //             return fetchMore({ variables: vars, updateQuery: updateQuery }).then(r => {

    //                 if (r.error || r.errors) {
    //                     message.error(`Query Error!`);
    //                     console.log(__error("QUERY ERROR"), r);
    //                 }
    //                 return r;
    //             });
    //         }

    //         return { loading, productsQuery, queryErrors: error, subscribeToMore, loadMoreRows }
    //     },
    // }),

    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editOffer: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addOffer: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteOffer: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(OfferForm);


const mapStateToProps = ({ grocer_storeadmin }) => {
    // props.settings.currency
    return {
        settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        store: grocer_storeadmin.store,
    };
}
export default connect(mapStateToProps)(withApollo(WithApollo));
// export default withApollo(WithApollo);