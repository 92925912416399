import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT, MODULE } from './constants'

// addReducers({ admins: reducers });
addModule(
  {
    permission_required:'offers-access',
    path: `${ROOT}`, component: Home, icon: 'bullhorn', title: "Offers", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
