import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Loader } from 'Common/components'
// import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
// import { generalStatus, walletStatus, genders } from 'configs/constants';
import LocalStorage from 'Common/scripts/LocalStorage';


const RECORD = loader('src/graphqls/driver_wallet/driverWalletRecord.graphql');
const CLEAR_DRIVER_WALLET = loader('src/graphqls/driver_wallet/clearDriverWallet.graphql');
const RECORD_DELETE = loader('src/graphqls/driver_wallet/delete.graphql');

class WalletForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            busy: false,
            thisUser: null,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    // updateFields = (__fileds) => {
    //     this.setState({__fields:{
    //         ...__fileds,
    //         activation_date: __fileds.activation_date ? moment(__fileds.activation_date) : __fileds.activation_date,
    //         deactivation_date: __fileds.deactivation_date ? moment(__fileds.deactivation_date) : __fileds.deactivation_date
    //     }})
    //     document.getElementById('WalletForm').dispatchEvent(new Event('reset', { cancelable: true }))
    // } 

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (prevProps.loading && !this.props.loading)
    //         this.setState({ loading: false, __fields: this.props.user })
    // }

    componentDidMount(){
        LocalStorage.getJsonAsync('user').then(r=>{
            this.setState({ thisUser: `${r.fname || r.lname || r.email} (${r._id})` })
        })
    }

    onSubmit = values => {
        const { onClose, driverWalletRecord, clearDriverWallet, loading } = this.props;
        const { thisUser } = this.state;
        const amount_paid = (values.new_payment || 0);
        const amount_payable = values.amount - amount_paid;

        if (!thisUser) {
            alert("thisUser is missing!");
            return;
        }

        let filteredValues = {
            amount_paid: amount_paid,
            note: values.payment_note,
            _id: driverWalletRecord._id,
            thisUser,
        };

        if (filteredValues.amount<1){
            message.error("No payment made!")
            return false;
        }
        if (!values.confirm_payment) {
            message.error("Unconfirmed payment!");
            return false;
        }

        this.setState({ busy: true })
       
        clearDriverWallet({ ...filteredValues }).then((e) => {
            this.setState({ busy: false })
            if (e.data.clearDriverWallet.error) {
                let err = e.data.clearDriverWallet.error;
                message.error(err.message);
                return false;
            }
            message.success("Wallet Updated!");
            console.log("e.data.clearDriverWallet: ", e.data.clearDriverWallet);

            if (values.print_receipt){
                alert("Wallet cleared, print receipt");
            }

            onClose(e.data.clearDriverWallet);
        }).catch(error => {
            this.setState({ busy: false })
            console.log(error);
            message.error("Query Error");
        });

    }

    render() {
        const { onClose, showform, driverWalletRecord, loading } = this.props;
        const { busy } = this.state;
        // console.log("this.props: ", this.props);

        // var fields = { ...__fields }
        // delete fields.password;
        // fields.phone_confirmed = (fields.phone_confirmed > 0) ? true : false;
        
        var amount_paid = 0;
        var amount_payable = 0;

        if(!loading){
            if (!driverWalletRecord || !driverWalletRecord._id) return <h1>Invalid record</h1>

            amount_paid = (driverWalletRecord.amount_paid || 0);
            amount_payable = driverWalletRecord.amount - amount_paid;
        }

        
        return (
            <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
                bodyStyle={{ padding: "0px" }}
                onClose={onClose}
                visible={showform}
                footer={busy ? null : <>
                    <span></span>
                    <Button loading={busy} disabled={amount_payable<1} type="primary" onClick={() => {
                        document.getElementById('WalletForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${driverWalletRecord && driverWalletRecord._id ? 'Edit' : 'Add'} Wallet`}
            >
                {loading && <Loader loading={true}>loading....</Loader>}

                {!loading && <>
                    <FormComponent onSubmit={this.onSubmit} id='WalletForm' loading={loading} hideDevBlock={true} fields={{ ...driverWalletRecord, new_payment: amount_payable, confirm_payment: false, print_receipt:false }}
                        // mutators={{
                        //     addItems: (newValueArray, state, tools) => { tools.changeValue(state, 'items', () => newValueArray[0]); },
                        //     removeItems: (newValueArray, state, tools) => { tools.changeValue(state, 'items', () => newValueArray[0]); },
                        // }}
                        form_render={formProps => {
                            const { values } = formProps;
                            // const amount_paid = (values.amount_paid || 0);
                            // const amount_payable = values.amount - amount_paid;
        
                            return (<>
                                {/* <FormField type="select" name="status" label="Status" data={walletStatus} validate={rules.required} /> */}

                                <div style={{ border: "1px solid #EEE", backgroundColor: "#EEE", padding:'10px 20px' }}>
                                    <Row>
                                        <Col style={{flex:'auto'}}>Total Aount payable:</Col>
                                        <Col>{values.amount}</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{flex:'auto'}}>Amount paid:</Col>
                                        <Col>{amount_paid}</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{flex:'auto'}}>Current payable:</Col>
                                        <Col>{amount_payable}</Col>
                                    </Row>
                                </div>
                                {values.note && <p style={{ margin: '10px', padding: '10px', border: "1px solid #EEE" }}><span><b>Delivery Notes</b></span><br />{values.note}</p>}

                                {amount_payable>0 && <>
                                    <Row>
                                        <Col><FormField type="number" label="Pay-off" name="new_payment" validate={composeValidators(rules.required, rules.maxValue(amount_payable))} /></Col>
                                        <Col style={{ paddingTop:'25px' }}><FormField type="checkbox" name="confirm_payment" checked={values.confirm_payment} label="Confirm Payment" /></Col>
                                    </Row>
                                </>}
                                
                                {amount_payable > 0 && <FormField type="textarea" name="payment_note" label="Note" placeholder="Notes" />}

                                {amount_payable == values.new_payment && 
                                    <FormField type="checkbox" name="print_receipt" checked={values.print_receipt} label="Print receipt" />
                                }


                            </>)
                        }}
                    />


                    <div style={{ padding: '20px', marginTop: "50px"}}>
                        <h1>History</h1>
                        {driverWalletRecord.history && driverWalletRecord.history.map((_h, i)=>{
                            return (<p key={i} style={{borderBottom:"1px solid #CCC", marginBottom:"15px"}}>
                                <span style={{ color: "#CCC" }}>{moment(_h.created_at).format('DD-MM-YYYY hh:mm A')}</span><br />
                                    {_h.note}
                                </p>)
                        })}
                    </div>

                </>}


    
            </Drawer>
        )
    }
}

WalletForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(CLEAR_DRIVER_WALLET, {
        props: ({ mutate }) => ({
            clearDriverWallet: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteDriverWalletRecord: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(WalletForm);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, driverWalletRecord, error } = data;

            if (error) console.log(__error("error"), error);

            return { loading, driverWalletRecord, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;
// export default WithApollo;