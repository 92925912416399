import React from 'react'
import { Button, PageHeader } from 'Common/components'

import PageSettingsList from '../components/page_settings_list'
import PageSettingsForm from '../components/page_settings_form'
// import { ContentArea } from 'Layout_v1'


export const PageSettings = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        console.log("toggleDrawer()");
        
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <PageSettingsForm onClose={toggleDrawer} showform={visible} fields={fields} />
        <PageSettingsList onEditRecord={(item) => toggleDrawer({ fields: item })} />
    </>)
}
export default PageSettings;