import React from 'react'
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper';
import Catalogue from './Catalogue';

const defaultFilter = { featured: "yes" };

export const FeaturedProducts = React.memo(props => {
    return (<>
        <Catalogue filter={defaultFilter} title="Featured Products" searchFilterConfig={{ exclude: ["featured"] }} />
    </>)

}, ()=>true)

export default withApollo(FeaturedProducts);
