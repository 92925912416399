import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';
import { checkRights } from 'Common/scripts/Security';

const LIST_DATA = loader('src/graphqls/brand/brandQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/brand/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/brand/deleteBrand.graphql');


class ListComp extends Component {
    state = { 
        busy: false,
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
    };
    subscription = false;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.brandQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "brandsUpdated",
                _subscriptionType: "array",
                _queryName: "brandQuery",
                _typename: "Brand",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteBrand(id).then(r => {
            if (r && r.data && r.data.deleteBrand && r.data.deleteBrand.error) {
                message.error(r.data.deleteBrand.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        {
            title: 'Title', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        {checkRights(this.props.rights, 'brand-manage') && <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />}
                        {checkRights(this.props.rights, 'brand-delete') && <DeleteButton onClick={() => this.handleDelete(rec?._id)} />}
                    </Col>
                </Row>)
            }
        },
        { title: 'Status', dataIndex: 'status', width:100 },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ busy: true })
        let filter = filters ? JSON.stringify(filters) : this.props.queryString;

        this.props.loadMoreRows(
            pagination.pageSize || defaultPageSize,
            (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            filter,
        ).then(r => {
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.brandQuery?.totalCount },
                busy: false
            })
        })
    };

    render() {
        const { loading, brandQuery, queryErrors } = this.props;
        const { pagination } = this.state;


        return (<ContentArea>
            <ListHeader title="Brands" sub={`Total ${this.props?.brandQuery?.totalCount || '0'} records found`}
                right={<>
                    {checkRights(this.props.rights, 'brand-manage') && 
                        <Button onClick={this.props.onAddClick} size="small">Add New Brand</Button>
                    }
                </>}
            />

            <Table loading={loading}
                columns={this.columns}
                dataSource={brandQuery ? brandQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status == 'hidden' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
                // rowClassName={(record => {
                //     return record.status == 'hidden' ? 'disabled-table-row' : "";
                // })}
                // title={() => <span>Header</span>}
                // footer={() => 'Footer'}
                // expandable={{
                //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                //     // rowExpandable: record => record.name !== 'Not Expandable',
                // }}
            />
        </ContentArea>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0 } };
        },
        props: ({ ownProps, data }) => {
            const { loading, brandQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.brandQuery.totalCount
                    const newEdges = fetchMoreResult.brandQuery.edges
                    const pageInfo = fetchMoreResult.brandQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        brandQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, brandQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteBrand: (id) => mutate({
                variables: { id }
            }),
        })
    }),


)(ListComp);


const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(WithApollo);
// export default WithApollo;
