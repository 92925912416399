import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/tags/tags.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/tags/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/tags/delete.graphql');


export class ListComp extends Component {
    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "tagsUpdated",
                _subscriptionType: "simple-array",
                _queryName: "tags",
                _typename: "Tags",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteTag(id).then(r => {
            if (r && r.data && r.data.deleteTag && r.data.deleteTag.error) {
                message.error(r.data.deleteTag.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    renderActions = (text, record) => {
        return (
            <span className="action_buttons">
                <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                    <IconButton icon="trash-alt" />
                </Popconfirm>
            </span>
        )
    }

    columns = [
        { title: 'Name', dataIndex: 'title' },
        {
            title: 'Actions',
            dataIndex: '',
            render: this.renderActions,
            className: 'actions-column',
            align: 'right',
            width: '100px'
        },
    ];

    render() {
        const { loading, tags, queryErrors } = this.props;

        return (<ContentArea>
            <ListHeader title="Tags" sub={`Total ${tags && tags.length || '0'} records found`} 
                right={<>
                    <Button onClick={this.props.onAddClick} size="small">Add New Tag</Button>
                </>}
            />

            <Table loading={loading}
                columns={this.columns}
                dataSource={tags ? tags : null}
                pagination={false}
            />
        </ContentArea>)
    }
}

ListComp.propTypes = {
    onAddClick: PropTypes.func.isRequired,
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, tags, error, fetchMore, subscribeToMore } = data;

            if (error) {
                message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
                console.log(__error("error"), error);
                // error.networkError.name
                // error.networkError.response.status
                // error.networkError.response.statusText
                console.log(__error("ERROR DETAILS"), error.networkError.result.errors);

            }

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.tags
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, tags, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteTag: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

export default WithApollo;
//