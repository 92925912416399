import React from 'react'
import { Button } from 'Common/components'

import TagList from '../components/tags_list';
import TagForm from '../components/tags_form';

export const Tags = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        {visible && <TagForm onClose={toggleDrawer} showform={visible} fields={fields} />}
        <TagList onEditRecord={(item) => toggleDrawer({ fields: item })} onAddClick={() => toggleDrawer({})} />
    </>)
}
export default Tags;