import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Modal } from 'antd';
import { Barcode, Loader, Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton } from 'Common/components';
// import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';
// import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import BasketFilter from './BasketFilter'
import { lightOrDark } from 'Common/scripts/Functions'
import { ContentArea } from 'Layout_v1';
// import { BasketForm } from './basket_form';

// const QUERY_SUBSCRIPTION = loader('src/graphqls/baskets/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/baskets/delete.graphql');
const CHECK_STATUS = loader('src/graphqls/baskets/checkBasketStatus.graphql');
const RELEASE_BASKET = loader('src/graphqls/baskets/releaseBasket.graphql');

// const CheckBasketStatusButtonComp = ({ basket, client }) => {
//     const [busy, setBusy] = useState(false);
//     const [checkResult, setResult] = useState(null);

//     const checkStatus = (_basket) => {
//         setBusy(true);

//         client.query({
//             query: CHECK_STATUS,
//             variables: { barcode: _basket.barcode },
//             fetchPolicy: "no-cache",
//         })
//             .then(e => {
//                 setBusy(false)

//                 if (e.errors) {
//                     console.log(__error("ERROR: basket status "), e)
//                     message.error("Query Error!");
//                     return;
//                 }

//                 const result = e.data.checkBasketStatus;

//                 if (result.error) {
//                     console.log(__error("ERROR: "), result)
//                     message.error(result.error.message);
//                     return;
//                 }

//                 setResult(result);
//                 message.info(<div>
//                     <p style={{ fontWeight: "bold" }}>{result.title}</p>
//                     <p>{result.details}</p>
//                 </div>)



//             }).catch(err => {
//                 console.log(__error("Query ERROR: basket status : "), err);
//                 message.error("Request ERROR");
//                 setBusy(false);
//             })


//     }

//     const releaseBasket = (_basket) => {
//         setBusy(true);

//         client.mutate({
//             mutation: RELEASE_BASKET,
//             variables: { barcode: _basket.barcode },
//             fetchPolicy: "no-cache",
//         })
//             .then(e => {
//                 setBusy(false)

//                 if (e.errors) {
//                     console.log(__error("ERROR: basket status "), e)
//                     message.error("Query Error!");
//                     return;
//                 }

//                 const result = e.data.releaseBasket;

//                 if (result.error) {
//                     console.log(__error("ERROR: "), result)
//                     message.error(result.error.message);
//                     return;
//                 }

//                 console.log("result: ", result);

//                 message.success("Released!")



//             }).catch(err => {
//                 console.log(__error("Query ERROR: basket release : "), err);
//                 message.error("Request ERROR");
//                 setBusy(false);
//             })


//     }


//     return <>
//         <Button loading={busy} onClick={() => checkStatus(basket)}>Check Order</Button>
//         {basket.order_id && <>
//             <div>Order ID: {basket.order_id}</div>
//             <div>Taken by ({basket.taken_by._id}) : {basket.taken_by.name}</div>
//             {/* <Button loading={busy} onClick={() => checkStatus(basket)}>Release</Button> */}
//             <Popconfirm title={`Sure to release the basket`} onConfirm={() => releaseBasket(basket)} onCancel={console.log} okText="Yes" cancelText="No">
//                 <Button loading={false} onClick={console.log} type='danger'>Release</Button>
//             </Popconfirm>

//         </>}
//         <div>{checkResult?.details}</div>
//     </>;
// }
// const CheckBasketStatusButton = withApollo(CheckBasketStatusButtonComp);

const BasketsList = ({ baskets, fetchData, loading, releaseBasket, deleteBasket, onEditRecord, onAddClick, rights }) => {
    const on_releaseBasket = async (basket) =>{
        // console.log(`releaseBasket(${basket.barcode})`)
        releaseBasket(basket.barcode)
    }

    const on_deleteBasket = async (id) => {
        deleteBasket(id).then(r => {
            if (r && r.data && r.data.deleteBasket && r.data.deleteBasket.error) {
                message.error(r.data.deleteBasket.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    const columns = () => [
        {
            title: 'Baskets', dataIndex: 'title', render: (text, record) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>
                        <div className="basketLab">
                            <div className="label" style={{ backgroundColor: record.color || "#FFFFFF", color: lightOrDark(record.color) == 'light' ? "#000000" : "#FFFFFF", fontSize: '14px' }}>{record.title}</div>
                            <div className="barcode">
                                <Barcode value={`${record.barcode}`} width={1} height={25} displayValue={true} />
                            </div>
                        </div>
                    </Col>
                    <Col className='hidden-menu'>
                        {checkRights(rights, 'manage-pickup-baskets') && <>
                            <IconButton icon="pen" onClick={() => onEditRecord(record)} />
                            <DeleteButton onClick={() => on_deleteBasket(record?._id)} />
                        </>}
                    </Col>
                </Row>)
            }
        },
        {
            title: 'Status', dataIndex: 'status', render: (__, rec) => {
                if (rec.order_id) return (<>
                    <div>Order ID: {rec.order_id}</div>
                    <div>Picker: {rec?.taken_by?.name}</div>
                    {checkRights(rights, 'manage-pickup-baskets') && <Button onClick={() => on_releaseBasket(rec)}>Release</Button>}
                </>)
                return null
            }
        },
        { title: 'Category', dataIndex: 'category', width:100 },
    ];

    return (<>
        <ContentArea>
            <ListHeader title="Baskets" sub={`Total ${baskets && baskets.length || '0'} records found`}
                right={<>
                    {checkRights(rights, 'manage-pickup-baskets') && <Button onClick={onAddClick}>Add New Basket</Button>}
                </>}
            />

            <BasketFilter onSearch={(val) => (fetchData({ filter: JSON.stringify(val) }))} />
            <Table loading={loading}
                columns={columns()}
                dataSource={baskets ? baskets : null}
                pagination={false}
            />
        </ContentArea>
    </>)
}

// class ListComp extends Component {
//     subscription = null;
//     state = { busy:false, showForm:false, fields:null };

//     componentWillReceiveProps(nextProps) {
//         const { subscribeToMore } = nextProps;

//         if (!this.subscription) {
//             this.subscription = new SubscriptionHandler({
//                 _subscribeToMore: subscribeToMore,
//                 _document: QUERY_SUBSCRIPTION,
//                 _variables: {
//                     filter: JSON.stringify({}),
//                     others: JSON.stringify({})
//                 },
//                 _subscriptionName: "basketsUpdated",
//                 _subscriptionType: "simple-array",
//                 _queryName: "baskets",
//                 _typename: "Baskets",
//                 debug: true
//             });
//         }

//     }

//     handleDelete(id) {
//         this.props.deleteBasket(id).then(r => {
//             if (r && r.data && r.data.deleteBasket && r.data.deleteBasket.error) {
//                 message.error(r.data.deleteBasket.error.message);
//                 return false;
//             }
//             message.success("Record deleted")
//         })
//             .catch(error => {
//                 console.log(__error("ERROR"), error);
//                 message.error("Unable to delete record")
//             })
//     }

//     // renderActions = (text, record) => {
//     //     return (
//     //         <span className="action_buttons">
//     //             <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
//     //             <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
//     //                 <IconButton icon="trash-alt" />
//     //             </Popconfirm>
//     //             {/* <Button onClick={()=>this.checkStatus(record)}>Check Status</Button> */}
//     //             {/* <CheckBasketStatusButton basket={record} /> */}
//     //         </span>
//     //     )
//     // }

//     releaseBasket(basket){
//         console.log(`releaseBasket(${basket.barcode})`)
//         this.props.releaseBasket(basket.barcode)
//     }

//     columns = [
//         {
//             title: 'Baskets', dataIndex: 'title', render: (text, record) => {
//                 return (<Row className='show-menu-on-hover'>
//                     <Col>
//                         <div className="basketLab">
//                             <div className="label" style={{ backgroundColor: record.color || "#FFFFFF", color: lightOrDark(record.color) == 'light' ? "#000000" : "#FFFFFF", fontSize: '14px' }}>{record.title}</div>
//                             <div className="barcode">
//                                 <Barcode value={`${record.barcode}`} width={1} height={25} displayValue={true} />
//                             </div>
//                         </div>
//                     </Col>
//                     <Col className='hidden-menu'>
//                         <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} />
//                         <DeleteButton onClick={() => this.handleDelete(record?._id)} />
//                     </Col>
//                 </Row>)
//             }
//         },
//         { title: 'Status', dataIndex: 'status', render:(__, rec)=>{
//             console.log("rec: ", rec);
//             if (rec.order_id) return (<>
//                 <div>Order ID: {rec.order_id}</div>
//                 <div>Picker: {rec?.taken_by?.name}</div>
//                 <Button onClick={() => this.releaseBasket(rec)}>Release</Button>
//             </>)
//             return null
//         } },

//         // { title: 'Basket', dataIndex: 'barcode', render:(txt, record) => {
//         //     return (<Row><Col style={{marginRight:'5px'}}><div className="basketLab">
//         //         <div className="label" style={{ backgroundColor: record.color || "#FFFFFF", color: lightOrDark(record.color)=='light' ? "#000000" : "#FFFFFF", fontSize:'14px'}}>{record.title}</div>
//         //         <div className="barcode">
//         //             <Barcode value={`${record.barcode}`} width={1} height={25} displayValue={true} />
//         //         </div>
//         //     </div></Col>
//         //     </Row>)
//         // } },
//         { title: 'Category', dataIndex: 'category' },
//         // {
//         //     title: 'Actions',
//         //     dataIndex: '',
//         //     render: this.renderActions,
//         //     className: 'actions-column',
//         //     align: 'right',
//         //     width: '100px'
//         // },
//     ];

//     render() {
//         const { loading, baskets, queryErrors } = this.props;
//         const { busy } = this.state;

//         return (<ContentArea>
//             <ListHeader title="Baskets" sub={`Total ${baskets && baskets.length || '0'} records found`} 
//                 right={<>
//                     <Button onClick={this.props.onAddClick}>Add New Basket</Button>
//                 </>}
//             />

//             <BasketFilter onSearch={(val) => (this.props.loadMoreRows(JSON.stringify(val)))} />
//             <Table loading={loading || busy}
//                 columns={this.columns}
//                 dataSource={baskets ? baskets : null}
//                 pagination={false}
//             />

//             {showForm && <BasketForm onClose={() => this.setState({ showForm: false })} showform={showForm} fields={fields} />}
            

//         </ContentArea>)
//     }
// }
// ListComp.propTypes = {
//     // prop: PropTypes.type.isRequired
//     // onEditRecord: PropTypes.func.isRequired
// }

const WithApollo = compose(
    // graphql(LIST_DATA, {
    //     options: props => {
    //         return { variables: { filter: "" } };
    //     },
    //     props: ({ ownProps, data }) => {
    //         const { loading, baskets, error, fetchMore, subscribeToMore } = data;
    //         if (error) console.log(__error("error"), error);

    //         const loadMoreRows = (filter) => {
    //             let vars = { filter };
    //             let updateQuery = (previousResult, { fetchMoreResult }) => {
    //                 return fetchMoreResult
    //             }
    //             return fetchMore({ variables: vars, updateQuery: updateQuery });
    //         }

    //         return { loading, baskets, queryErrors: error, subscribeToMore, loadMoreRows }
    //     },
    // }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteBasket: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    graphql(RELEASE_BASKET, {
        props: ({ mutate }) => ({
            releaseBasket: (barcode) => mutate({
                variables: { barcode }
            }),
        })
    }),

)(BasketsList);

// export default WithApollo;


const mapStateToProps = ({ grocer_storeadmin: { rights, user} }) => {
    return { rights, user }
}
export default connect(mapStateToProps)(WithApollo);
