import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import moment from 'moment';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/pages/pages.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/pages/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/pages/delete.graphql');


export class ListComp extends Component {
    state = {  };

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "pagesUpdated",
                _subscriptionType: "simple-array",
                _queryName: "pages",
                _typename: "Page",
                debug: true
            });
        }
    }

    handleDelete(id) {
        this.props.deletePage(id).then(r => {
            if (r && r.data && r.data.deletePage && r.data.deletePage.error) {
                message.error(r.data.deletePage.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    renderActions = (text, record) => {
        return (
            <span className="action_buttons">
                <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                {record.deleteable == 'yes' &&
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                        <IconButton icon="trash-alt" />
                    </Popconfirm>
                }
                {/* <Button size="small" onClick={() => console.log(process.env.NODE_PORT)}>Preview</Button> */}
                <Button size="small" onClick={() => window.open(`${process.env.REACT_APP_HOST_URL}/page/${record.slug}`)}>Preview</Button>
            </span>
        )
    }

    columns = [
        {
            title: 'Title', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                        <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                        <Button size="small" onClick={() => window.open(`${process.env.REACT_APP_HOST_URL}/page/${rec.slug}`)} shape="round">Preview</Button>
                    </Col>
                </Row>)
            }
        },
        { title: 'Key', dataIndex: 'slug' },
        { title: 'Status', dataIndex: 'status', width: '80px' },
    ];

    render() {
        const { loading, pages, queryErrors } = this.props;

        return (<ContentArea>
            <ListHeader title="Pages" sub={`Total ${pages && pages.length || '0'} records found`} 
                right={<>
                    <Button onClick={this.props.onAddClick}>Add New Page</Button>
                </>}
            />

            <Table loading={loading}
                columns={this.columns}
                dataSource={pages || null}
                pagination={false}
                rowClassName={(record => {
                    return record.status == 'hidden' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
                // expandable={{
                //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                //     // rowExpandable: record => record.name !== 'Not Expandable',
                // }}
            />
        </ContentArea>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, pages, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter) => {
                let vars = { filter: filter };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.pages
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, pages, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deletePage: (id) => mutate({
                variables: { id }
            }),
        })
    }),


)(ListComp);

export default WithApollo;