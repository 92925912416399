import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { defaultPageSize, orderStatus } from 'configs';
import { __error } from 'Common/scripts/consoleHelper';
import { Alert, Col, Divider, Row, Radio } from 'antd';
import SalesHeader from './sales_header';
import OrderTable from 'Modules/Orders/components/OrderTable';

const LIST_DATA = loader('src/graphqls/accoutns/pendingSales.graphql');
const defaultPagination = {
    defaultCurrent: 1, //this.props.match.params.pageNum || 1,
    current: 1,
    total: 0,
    pageSize: defaultPageSize,
}
const defaultOthers = { sort: { "delivery_slot.from_date": 1 } };



class PendingSales extends Component {
    state = {
        loading: false, error: null, resutls: null,
        pagination: { ...defaultPagination },
        showDetails: false,
    }

    componentDidMount() {
        this.getPendingSales()
    }

    async getPendingSales() {
        const { pagination } = this.state;

        let variables = {
            first: pagination.pageSize, after: Number(pagination.current - 1 * pagination.pageSize),
            filter: JSON.stringify({}),
            others: JSON.stringify(defaultOthers)
        }

        this.setState({ loading: true, error: null });
        const resutls = await this.props.client.query({ query: LIST_DATA, variables }).then(e => (e.data.pendingSales))
            .catch(err => {
                console.log(__error("API Call ERROR: baskets : "), err);
                return { error: { message: "Request ERROR" } }
            })


        if (resutls.error) {
            return this.setState({ loading: false, error: resutls.error.message });
        }

        this.setState({ loading: false, data: resutls });

    }


    render() {
        const { loading, error, data, pagination, listType, showDetails } = this.state;
       
        return (<>
            <SalesHeader value='pending' title="Pending Sales" totalCount={data && data.totalCount} onTypeChange={this.props.onTypeChange} />
            {error && <Alert message={error} showIcon type="error" />}

            <p>--- Filter ---</p>
            <OrderTable
                loading={loading}
                columns={[
                    'serial', 'address', 'created_at', 'delivery_slot', 'zone', 'status', 'paymentMethod', 'payment_status', 'grandTotal',
                    'grand_total', 'store'
                ]}
                dataSource={data ? data.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
            />
            
        </>)
    }
}

const WithApollo = withApollo(PendingSales);
export default WithApollo;
