import React, { Component } from 'react'
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom'
import Loader from './Loader';
import { __error } from 'Common/scripts/consoleHelper';
// import DevBlock from './DevBlock';
import { Table } from './Table';
import { utcToDate } from 'Common/scripts/Functions';
import Icon from './Icon';

const LIST_DATA = loader('src/graphqls/orders/ordersQuery.graphql');

const OrdersMiniListComp = (props) => {
    const { loading, ordersQuery, history } = props;
    
    if(loading) return <Loader loading={true} center />

    const columns = [
        { width: 150, title: 'Serial', dataIndex: 'serial', render: (__, record) => {
                return (<>
                    {record.serial}
                    {/* <Barcode value={`${record.serial}`} width={1} height={50} displayValue={true} /> */}
                    {/* {this.props.path && <Link className="a" to={`${this.props.path}/${record._id}`}>{record.serial}</Link>} */}
                    {/* {(!this.props.path && this.props.onEditRecord) && <div className="a" onClick={() => this.props.onEditRecord(record)}>{record.serial}</div>} */}
                    <div>Total Items: {record.items.length}</div>
                </>)
            }
        },
        {
            width: 'auto', title: 'Address', dataIndex: 'address', render: (text, { shipping_address }) => {
            if (!shipping_address) return null;

            return <div>
                <span style={{ fontSize: "18px", paddingRight: "10px" }}><Icon icon="map-marker-alt" color={shipping_address.verified == "yes" ? "#60A52C" : "#FF311A"} /></span>
                <b>{shipping_address.label}: </b>{shipping_address.full_address}, {shipping_address.city}
            </div>
        }
        },
        { width: 140, title: 'Order Date', dataIndex: 'created_at', render: (txt, rec) => (<>{utcToDate(txt).fromNow()}<br />{utcToDate(txt).format("DD MMM HH:mm")}</>) },
        { width: 100, title: 'Status', dataIndex: 'status' },
        { width: 160, title: 'Delivery Slot', dataIndex: 'delivery_slot', render: (text, rec) => {
            if (!rec?.delivery_slot?.from_date) return <div>Invalid Date</div>
            let from_date = utcToDate(rec.delivery_slot.from_date);
            let to_date = utcToDate(rec.delivery_slot.to_date);

                return (<>
                    <div><b>{from_date.fromNow()}</b></div>
                    {from_date.format("ddd, MMM Do YYYY")}
                    <br /> {from_date.format("HH:mm")} ~ {to_date.format("H:mm")}
                </>)
            } },
        { width: 100, title: 'Total', dataIndex: ['invoice', 'grand_total'] },
    ]

    return (<>
        <Table loading={loading} size="small"
            columns={columns}
            dataSource={ordersQuery ? ordersQuery.edges : null}
            pagination={false}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        console.log("record: ", record)
                        history.push(`/orders/id/${record._id}`)
                    }, // click row
                    // onDoubleClick: event => { }, // double click row
                    // onContextMenu: event => { }, // right button click row
                    // onMouseEnter: event => { }, // mouse enter row
                    // onMouseLeave: event => { }, // mouse leave row
                };
            }}
            rowClassName={() => ("clickable")}
            // onChange={this.handleTableChange}
            // scroll={{ scrollToFirstRowOnChange: true, x: tableW + 50, y: -370 }}
            // scroll={{ x: tableW + 50, y: -370 }}
        />

    </>)
}
OrdersMiniListComp.propTypes = {
    filter: PropTypes.object,
    others: PropTypes.object,
    sort: PropTypes.object,
    limit: PropTypes.number,
    hidePagination: PropTypes.bool,
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            let others = props.others || { sort: { _id: -1 } };
            if (props.sort) Object.assign(others, { sort: props.sort })

            let filter = props.filter || {};
            let first = props.first || props.limit || 10;

            return {
                variables: {
                    first,
                    after: props.after || 0,
                    filter: JSON.stringify(filter),
                    others: JSON.stringify(others),
                },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, ordersQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);
            console.log("ordersQuery: ", ordersQuery)

            const loadMoreRows = (first, after, filter, others) => {

                let vars = { first, after, filter, others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.ordersQuery.totalCount
                    const newEdges = fetchMoreResult.ordersQuery.edges
                    const pageInfo = fetchMoreResult.ordersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        ordersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({
                    variables: vars,
                    fetchPolicy: "no-cache",
                    updateQuery: updateQuery,
                });
            }

            return { loading, ordersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(OrdersMiniListComp);

const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        store: grocer_storeadmin.store,
    };
}
export const OrdersMiniList = connect(mapStateToProps)(withRouter(WithApollo));
