import React, { useState } from 'react'
import { ContentArea } from 'Layout_v1'
import { __error } from 'Common/scripts/consoleHelper';

import TodaySales from '../components/today_sales';
import PendingSales from '../components/pending_sales';
import ConfirmedSales from '../components/confirmed_sales';


const SalesHome = props => {
  const [listType, set_listType] = useState('today')

  const onTypeChange = (listType) => set_listType(listType);

  return (<>
    <ContentArea>
      {listType == 'today' && <TodaySales onTypeChange={onTypeChange} />}
      {listType == 'pending' && <PendingSales onTypeChange={onTypeChange} />}
      {listType == 'confirmed' && <ConfirmedSales onTypeChange={onTypeChange} />}
    </ContentArea>
  </>)
}

export default SalesHome;
