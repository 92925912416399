import React from 'react'
import { formToFilter } from 'Common/scripts/Functions'
import { locationTypes } from 'configs/constants';
import { Row, Col, Divider, message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { FormField, FormComponent, FormFieldGroup, DateField } from 'Common/components/Form'
import { DevBlock, Icon, Button, Loader, IconButton } from 'Common/components'
import { connect } from "react-redux";

const LocationFilter = props => {
    const onSubmit = values => {
        let filter = formToFilter(values, { tz: props.default_timezone })
        props.onSearch(filter);
    }

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='LocationFilterForm' hideDevBlock={true} fields={props.defaultValue} style={{ padding: 0 }} 
            form_render={({ values })=>{
                return(<>
                    <Row className="pagination-filter-row">
                        <Col flex="auto" className="filters-column">
                            {/* <FormFieldGroup compact style={{ padding: 0, margin: 0 }}> */}
                            <FormField type="select" data={locationTypes} name="type" placeholder="Location Type" label="Type" width="200px" compact allowClear size="small" />
                            {/* </FormFieldGroup> */}
                        </Col>
                        <Col className="go-column">
                            <Button className="send_button" loading={props.loading} htmlType="submit"><Icon icon="search" /></Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </div>)

}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return { default_timezone: grocer_storeadmin.default_timezone };
}
export default connect(mapStateToProps)(LocationFilter);
