import React, { Component } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import moment from 'moment';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock, ListHeader, DeleteButton, UserFilter } from 'Common/components';
import { Link } from 'react-router-dom';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { utcToDate } from 'Common/scripts/Functions';
// import FilteredViews from 'Common/FilteredViews';
import { updateUserSettings } from '../local_store/reducer';
import { ContentArea } from 'Layout_v1';
import { checkRights } from 'Common/scripts/Security';

const LIST_DATA = loader('src/graphqls/user/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/user/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/user/delete.graphql');

const defaultFilter = { type: "customer" };


class ListComp extends Component {
    state = { 
        busy: false, 
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
        filter:{},
    };
    // state = { busy: false, pagination: { current: 1, pageSize: defaultPageSize, }, filter:{} };
    subscription = false;
    constructor(props){
        super(props);
        this.doSearch = this.doSearch.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!nextProps.usersQuery) return;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.usersQuery?.totalCount || 0
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify(defaultFilter),
                    others: JSON.stringify({})
                },
                _subscriptionName: "usersUpdated",
                _subscriptionType: "array",
                _queryName: "usersQuery",
                _typename: "User",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteUser(id).then(r => {
            if (r && r.data && r.data.deleteUser && r.data.deleteUser.error) {
                message.error(r.data.deleteUser.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        { title: 'ID', dataIndex: '_id' },
        {
            title: 'Name', dataIndex: 'name', render: (text, record) => {
                let icons = []
                icons.push(<span><Icon style={{ fontSize: "1.0em" }} color={record.phone_confirmed > 0 ? "#308a31" : "#F00"} icon="phone-square" />{record.verification_code}</span>)
                icons.push(<span><Icon style={{ fontSize: "1.0em" }} color={record.email_confirmed > 0 ? "#308a31" : "#F00"} icon="envelope" />{record.email_verification_code}</span>)

                return (<div className='show-menu-on-hover'>
                    <Row gutter={[5]} style={{ flexWrap:"nowrap" }}>
                        <Col><Avatar size={40} shape="square" src={record.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.picture_thumb}` : null} icon={<Icon icon="image" />} /></Col>
                        <Col style={{ lineHeight: "14px" }}>
                            {checkRights(this.props.rights, 'customers-details-preview') ? 
                                <div><Link className="a" to={`/customers/details/${record._id}`} alt={`${record.fname} ${record.lname}`}><span className='ellipsis' style={{ width: "150px" }}>{record?.fname} {record?.lname}</span></Link></div>
                                :
                                <div><span className='ellipsis' style={{ width: "150px" }}>{record?.fname} {record?.lname}</span></div>
                            }
                            {(icons && icons.length>0) && <Row gutter={[5]}>
                                {icons.map((o, i) => (<Col key={i}>{o}</Col>))}
                            </Row>
                            }
                        </Col>

                        <Col className='hidden-menu'>
                            {checkRights(this.props.rights, 'customers-edit') &&
                                <IconButton icon="pen" onClick={() => this.props.onEditRecord(record)} />
                            }
                            {checkRights(this.props.rights, 'customers-delete') &&
                                <DeleteButton onClick={() => this.handleDelete(record?._id)} />
                            }
                        </Col>
                    </Row>
                </div>)
            }
        },

        { title: 'Email', dataIndex: 'email' },
        { title: 'Member Since', dataIndex: 'created_at', width: "120px", align: "center", render: (d, rec) => utcToDate(d).format("YYYY-MM-DD"), width: 120 },
        { title: 'Status', dataIndex: 'status', width: "80px", align: "center" },
        { title: 'Last Login', dataIndex: 'last_login_date', render: (val, rec) => (!val ? null : utcToDate(val).format('YYYY-MM-DD HH:mm')), width: 120 },
        { title: 'Last Order', dataIndex: 'last_order_date', render: (val, rec) => (!val ? null : utcToDate(val).format('YYYY-MM-DD HH:mm')), width: 120 },
        { title: 'Total Orders', dataIndex: 'total_orders', align: "center", width: 90 },
        { title: 'Orders Canceled', dataIndex: 'total_orders_canceled', align: "center", width:90 },
        { title: 'Total Spent', dataIndex: 'total_spent', align: "center", width: 90 },
        // { title: '', className: 'actions-column', align: 'right', width: '50px', dataIndex: '', render: this.renderActions, },
    ];

    handleTableChange = async (pagination, filters, sorter) => {
        this.setState({ busy: true })
        let filter = filters || this.state.filter;

        let input = {
            limit: pagination.pageSize || defaultPageSize, 
            skip: (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            filter: JSON.stringify({ ...filter, ...defaultFilter }),
            // others:{}
        }

        return this.props.loadMoreRows(input).then(r => {
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.usersQuery?.totalCount },
                busy: false,
                filter
            })
        })
    };



    async doSearch(args={}) {
        return this.handleTableChange({ page: 1 }, args.filter)
    }

    onViewChnaged(filter){
        this.doSearch({ filter });
    }


    render() {
        const { loading, usersQuery, queryErrors } = this.props;
        const { pagination, busy } = this.state;

        if (usersQuery && usersQuery.error) return <Alert message={usersQuery.error.message} type="error" showIcon />

        return (<Loader loading={loading || busy}>
            <ContentArea>
                <ListHeader title="Customer" sub={<>Total {usersQuery ? usersQuery?.totalCount : "0"} customers</>} />

                {/* <FilteredViews 
                    settings={this.props.settings_customers} 
                    // default_activeTab={}
                    // left={}
                    // right={}
                    category="customer"
                    updateUserSettings={this.props.updateUserSettings} 
                    onTabClick={({ filter_str, _id, shared }) => {
                        let filter = _.isString(filter_str) ? JSON.parse(filter_str) : filter_str;
                        this.setState({ filter: filter || {} }, ()=>{
                            // this.onViewChnaged(this.state.filter);
                        });
                        this.onViewChnaged(filter);
                        // this.setState({ filter: { filter_str, _id, shared } })
                    }}
                    onfilterUpdate={(v) => this.onViewChnaged(v)}
                /> */}

                <UserFilter onSearch={(v) => this.doSearch({ filter:v })} filterType="customer" />

                <Table debug={false}
                    // loading={loading || busy} 
                    columns={this.columns}
                    dataSource={usersQuery ? usersQuery?.edges : null}
                    // total={usersQuery ? usersQuery?.totalCount : 0}
                    // pageSize={defaultPageSize}
                    // current={pagination.current || 1}
                    pagination={pagination}
                    rowClassName={(record => {
                        return record.status == 'disabled' ? 'disabled-table-row' : "";
                    })}
                    onChange={this.handleTableChange}
                />
            </ContentArea>

        </Loader>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify(defaultFilter) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, usersQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = ({limit, skip, filter, others}) => {

                let vars = { first: limit, after: skip, filter, others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.usersQuery.totalCount
                    const newEdges = fetchMoreResult.usersQuery.edges
                    const pageInfo = fetchMoreResult.usersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        usersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UsersQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, usersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    // graphql(UPDATE_STATUS, {
    //     props: ({ mutate }) => ({
    //         updateOrderStatus: (id, status, status_notes) => mutate({
    //             variables: { id, status, status_notes }
    //         }),
    //     })
    // }),

)(ListComp);


const mapDispatchToProps = (dispatch, ownProps) => ({
    updateUserSettings: (payload) => dispatch(updateUserSettings(payload)),
})
const mapStateToProps = ({ settings_customers, grocer_storeadmin: { rights } }) => {
    return {
        settings_customers,
        rights,
    };
}

const WithRedux = connect(mapStateToProps, mapDispatchToProps)(WithApollo);
export default WithRedux;
