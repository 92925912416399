import React from 'react'
import PropTypes from 'prop-types';
import { Heading } from 'Common/components'
import { Row, Col, Tabs, Radio, Alert } from 'antd';
import RenderCart from './RenderCart'
import { utcToDate } from 'Common/scripts/Functions'
import { connect } from "react-redux";

const ConfirmationInvoice = ({ order, onScanPressed, settings }) => {
    // const [listType, setListType] = React.useState('unconfirmed')

    return(<><div className="grid-block">

        <Row align="middle">
            <Col style={{ marginRight: "10px" }}>Order ID: {order._id}</Col>
            <Col flex="auto"><Heading>Invoice ({order.serial})</Heading></Col>
            <Col style={{ padding: "0 15px" }}>
                {utcToDate(order.created_at).format("DD MMM HH:mm")}
                {/* {utcToDate(order.created_at).format('ddd, MMM Do YYYY ~ hh:mm A')} */}
            </Col>
        </Row>

        <RenderCart
            order={order}
            onScanPressed={onScanPressed}
        />

    </div></>)

}

const InvoicePreview = ({ order, previewOnly, settings }) => {
    let total_savings = 0;
    // calculating
    order.items.forEach(item => {
        if (item.price_was > item.price){
            total_savings += Number(item.price_was - item.price) * item.qty;
        }
    });

    return (<>
        <div className="grid-block">
            <Row align="middle">
                <Col style={{ marginRight: "10px" }}>Order ID: {order._id}</Col>
                <Col flex="auto"><Heading>Invoice # {order.serial}</Heading></Col>
                <Col style={{ padding: "0 15px" }}>{utcToDate(order.created_at).format('ddd, MMM Do YYYY ~ hh:mm A')}</Col>
            </Row>

            {order.status == 'in-review' ? 
                <RenderCart order={order} items={order.items} pickup_data={order.pickup_data} /> : 
                <RenderCart order={order} items={order.items} />}
        </div>
    </>)
}




const RenderInvoice = props => {
    if (props.statusUpdator) return <InvoicePreview {...props} />
    if (props.previewOnly) return <InvoicePreview {...props} />
    if (props.tillConfirmation) return <ConfirmationInvoice {...props} />

    return <Alert message="No invoice type mentioned" type='error' showIcon />
}
RenderInvoice.propTypes = {
    previewOnly: PropTypes.bool,
    order: PropTypes.object.isRequired,
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    // props.settings.currency
    return {
        settings: grocer_storeadmin.settings, user: grocer_storeadmin.user, store: grocer_storeadmin.store,
    };
}
export default connect(mapStateToProps)(RenderInvoice);




