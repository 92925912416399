import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, GMap, Heading, Icon, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { locationTypes } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import icon_location_blue from 'assets/icon_location_blue.png';

const RECORD = loader('src/graphqls/locations/location.graphql');
const RECORD_EDIT = loader('src/graphqls/locations/edit.graphql');
const RECORD_ADD = loader('src/graphqls/locations/add.graphql');

const defaultFields = {
    coordinates: [{
        lat: 31.52443022759592,
        lng: 74.35772741448616
    }], color: "#FFFFFF",
}


export class FormComp extends Component {
    // fields = { status: "disabled" };

    constructor(props) {
        super(props);
        this.state = { loading: false, fields: props.fields ? props.fields : { status: "disabled"} }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = _values => {
        // console.log("onSubmit()", _values); return;

        const { location, onClose, editLocation, addLocation } = this.props;
        const { fields } = this.state;
        // let fields = fields ? { ...fields } : location ? { ...location } : {}
        let values = { ..._values }

        const _id = fields ? fields._id : false;

        this.setState({ loading: true });

        let filteredValues = {
            title: values.title,
            code: values.code,
            coordinates: values.coordinates.map(o => ({ lat: o.lat, lng: o.lng })),  //[values.coordinates],
            type: values.type,
        };

        if (_id) {
            editLocation({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({ loading: false })
                if (e.data.editLocation.error) {
                    let err = e.data.editLocation.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editLocation);
            }).catch(error => {
                this.setState({ loading: false })
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addLocation(filteredValues).then((e) => {
                this.setState({ loading: false });
                if (e.data.addLocation.error) {
                    let err = e.data.addLocation.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.addLocation);
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    onCenterChange(_center) {
        console.log("onCenterChange()", _center);
        // this.setState({ center: _center })
    }



    render() {
        const { onClose, location, showform } = this.props;
        const { fields, loading } = this.state;


        return (
            <Drawer width={"600px"} destroyOnClose maskClosable={false} placement="right"
                // loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5", padding:0 }}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('LocationForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${fields && fields._id ? 'Edit' : 'Add'} Lcoation`}
            >
                <FormComponent onSubmit={this.onSubmit} id='LocationForm' loading={loading} fields={{ ...defaultFields, ...fields }} enterSubmit={true} debug={true}
                    mutators={{ 
                        ...arrayMutators,
                        updateCenter: (newValueArray, state, tools) => {
                            tools.changeValue(state, 'coordinates', () => [{
                                lat: newValueArray[0][0],
                                lng: newValueArray[0][1]
                            }])
                        },

                    }}
                    form_render={({ values, form }) => {
                        return <>
                            <div className="grid-block">
                                <FormField type="select" data={locationTypes} name="type" label="Location Type" validate={composeValidators(rules.required)} />
                                
                                <Row style={{ padding:"0 10px", marginBottom:"15px" }}>
                                    <Col flex="auto"><FormField type="text" name="title" label="Title" validate={composeValidators(rules.required)} compact /></Col>
                                    <Col><FormField type="text" name="code" label="City Code (lhr)" validate={composeValidators(rules.required)} compact /></Col>
                                </Row>

                                <b>Location Center</b>
                                <FieldArray name="coordinates">
                                    {({ fields }) => {
                                        return (<div>
                                            {fields.map((name, index) => {
                                                return (<div key={index}>
                                                    {/* <FormField type="hidden" name={`${name}._id`} /> */}
                                                    <Row style={{ padding: "0 10px", marginBottom: "15px" }}>
                                                        <Col flex="50%"><FormField type="number" disabled name={`${name}.lat`} label="Latitude" validate={composeValidators(rules.required)} compact /></Col>
                                                        <Col flex="auto"><FormField type="number" disabled name={`${name}.lng`} label="Longitude" validate={composeValidators(rules.required)} compact /></Col>
                                                    </Row>
                                                </div>)
                                            })}
                                        </div>)
                                    }}
                                </FieldArray>


                                <div style={{ width: '100%', display:"flex", height: '400px', position: "relative", border:"1px solid #EEE" }}>
                                    <GMap
                                        zoom={12}
                                        center={{ lat: values?.coordinates[0]?.lat, lng: values?.coordinates[0]?.lng }}
                                        onCenterChange={(coords) => form.mutators.updateCenter(coords)}
                                    >
                                        <div
                                            style={{ backgroundColor: 'red' }}
                                            lat={values?.coordinates[0]?.lat}
                                            lng={values?.coordinates[0]?.lng}
                                        >Children</div>
                                    </GMap>
                                    <div style={{ position: "absolute", top: "50%", zIndex: 100, width: "100%", borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }} />
                                    <div style={{ position: "absolute", top: 0, left: "50%", zIndex: 100, height: "100%", borderRight: "1px solid rgba(255, 255, 255, 0.5)" }} />
                                    <div style={{ position: "absolute", top: "50%", marginTop: "-46px", left: "50%", marginLeft: "-19px", zIndex: 100, }}><img src={icon_location_blue} alt="You" width="38px" height="50px" /></div>
                                </div>


                                
                                {/* <Row style={{ padding: "0 10px", marginBottom: "15px" }}>
                                    <Col flex="50%"><FormField type="number" name="coordinates.lat" label="Latitude" validate={composeValidators(rules.required)} compact /></Col>
                                    <Col flex="auto"><FormField type="number" name="coordinates.lng" label="Longitude" validate={composeValidators(rules.required)} compact /></Col>
                                </Row> */}
                            </div>
                        </>
                    }}
                />

            </Drawer>
        )
    }
}


FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editLocation: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addLocation: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);

// const EditWrapper = compose(
//     graphql(RECORD, {
//         options: ({ fields }) => {
//             return { variables: { id: fields._id } };
//         },
//         props: ({ ownProps, data }) => {
//             const { loading, locations, error } = data;

//             if (error) {
//                 message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
//                 console.log(__error("error"), error);
//                 // error.networkError.name
//                 // error.networkError.response.status
//                 // error.networkError.response.statusText
//                 console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
//             }

//             return { loadingEditNode: loading, locations, queryErrors: error, }
//         },
//     }),
// )(WithApollo);
// export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />

export default WithApollo;