import React, { Component } from 'react'
// import BarcodeReader from 'react-barcode-reader'
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { BarcodeScanner } from 'Common/components'



export default class TestScanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            result: 'No result',
        }

        this.handleScan = this.handleScan.bind(this)
    }

    handleScan(data) {
        console.log("********** Test Scanned *******", data);
        this.setState({ result: data, })
    }

    // handleError(err) {
    //     console.log(__error(err))
    //     // console.error(err)
    // }

    render() {
        return (
            <div>
                <BarcodeScanner onScan={this.handleScan} />
                {/* <BarcodeReader
                    // onError={this.handleError}
                    onError={this.handleScan}
                    onScan={this.handleScan}
                /> */}
                <p>result: {this.state.result}</p>
            </div>
        )
    }
}
