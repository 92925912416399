import React, { Component } from 'react'
import { ContentArea } from 'Layout_v1'
import { PageHeader } from 'antd'
import Axios from 'axios'
import { __error } from 'Common/scripts/consoleHelper';
import { Button } from 'Common/components';
import _ from 'lodash';

const API_KEY = "0e4be43b-a37d-4db9-9ff8-ba106d08c785";
const USERNAME = "9053840400";
const PASSWORD = "9053";
const client_id = `grocer`
const login_url = `https://realtime.abc.co.zm:8443/kazangApi/api/login/${client_id}`

export default class KazangApiTest extends Component {
    state = { busy:false} 

    async makeLoginRequest(){
        let postData = {
            username: USERNAME,
            password: PASSWORD,
        }

        this.setState({ busy:true })

        const results = await Axios.post(login_url, postData)
            .then(r => {
                console.log("R: ", r)
                if (_.isString(r.data)){
                    console.log("ERROR: ", r.data)
                    alert(r.data)
                    return { error: { message: r.data }}
                }
                return r?.data?.sessionId || { error:{message:"No session Id found"}};
            })
            .catch((err) => {
                console.log(__error("ERROR: "), err)
            });

        if (results.error){
            this.setState({ busy: false })
            alert(results.error.message);
            return;
        }
        else {
            alert("Session successfull")
            console.log("Session: ", results)
            this.setState({ busy: false })
        }


    }

    render() {
        const { busy } = this.state;

        return (<>
            <PageHeader title="Kazang Api Test" />
            <ContentArea>

                <Button loading={busy} onClick={() => this.makeLoginRequest()}>Test KAzang Api</Button>

            </ContentArea>
        </>)
    }
}
