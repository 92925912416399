import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, ListHeader, DeleteButton } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/product_type/prodTypes.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/product_type/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/product_type/delete.graphql');


export class CategoriesList extends Component {
    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "prodTypesUpdated",
                _subscriptionType: "simple-array",
                _queryName: "prodTypes",
                _typename: "ProductType",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteProductType(id).then(r => {
            if (r && r.data && r.data.deleteProductType && r.data.deleteProductType.error) {
                message.error(r.data.deleteProductType.error.message);
                return false;
            }
            message.success("Record deleted")
        })
        .catch(error => {
            console.log(__error("ERROR"), error);
            message.error("Unable to delete record")
        })
    }

    columns = [
        {
            title: 'Title', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                        <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                    </Col>
                </Row>)
            }
        },
        { title: 'Tax', width: '80px', align: 'center', render: (text, record) => !record.tax ? null : `${record.tax.value} ${record.tax.unit}` },
    ];

    render() {
        const { loading, prodTypes, queryErrors } = this.props;
       
        return (<ContentArea>
            <ListHeader title="Product Types" sub={`Total ${(prodTypes && prodTypes.length) || '0'} records found`} 
                right={<>
                    <Button onClick={this.props.onAddClick} size="small">Add New Type</Button>
                </>}
            />

            <Table loading={loading}
                columns={this.columns}
                dataSource={prodTypes ? prodTypes : null}
                pagination={false}
                // title={() => <span>Header</span>}
                // footer={() => 'Footer'}
                // expandable={{
                //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                //     // rowExpandable: record => record.name !== 'Not Expandable',
                // }}
            />
        </ContentArea>)
    }
}

CategoriesList.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, prodTypes, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.prodTypes
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, prodTypes, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteProductType: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(CategoriesList);

export default WithApollo;
