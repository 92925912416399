import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { generalStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'

const RECORD = loader('src/graphqls/agreement/agreement.graphql');
const RECORD_EDIT = loader('src/graphqls/agreement/edit.graphql');
const RECORD_ADD = loader('src/graphqls/agreement/add.graphql');


export class FormComp extends Component {
    fields = { status: "disabled" };

    constructor(props) {
        super(props);
        this.state = { loading: false, __fields: false, loadingEditNode: props.loadingEditNode }
        this.onSubmit = this.onSubmit.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.loadingEditNode !== state.loadingEditNode) {
            return {
                ...state,
                loadingEditNode: props.loadingEditNode,
            };
        }
        return { ...state };
    }

    onSubmit = _values => {
        // console.log("onSubmit()", _values); return;

        const { agreement, onClose, editAgreement, addAgreement } = this.props;
        const { __fields } = this.state;
        let fields = __fields ? { ...__fields } : agreement ? { ...agreement } : {}
        let values = { ..._values }

        const _id = fields ? fields._id : false;

        this.setState({ loading: true });

        let filteredValues = {
            title: values.title,
            body: values.body,
            note: values.note,
            status: (values.status || values.status == 'enabled') ? "enabled" : "disabled",
            deleteable: (values.deleteable || values.deleteable==1) ? 1 : 0,
        };

        if (_id) {
            editAgreement({ ...filteredValues, _id: _id }).then((e) => {
                this.setState({ loading: false })
                if (e.data.editAgreement.error) {
                    let err = e.data.editAgreement.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editAgreement);
            }).catch(error => {
                this.setState({ loading: false })
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addAgreement(filteredValues).then((e) => {
                this.setState({ loading: false });
                if (e.data.addAgreement.error) {
                    let err = e.data.addAgreement.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                console.log("e.data.addAgreement: ", e.data.addAgreement);

                // _fields = Object.assign(_fields, { ...e.data.addAgreement })
                // _setFields({ ...e.data.addAgreement });
                onClose(e.data.addAgreement);
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                message.error("Query Error");
            });
        }

    }


    render() {
        const { onClose, showform, agreement } = this.props;
        const { __fields, loading, loadingEditNode } = this.state;

        this.fields = __fields ? { ...__fields } : agreement ? { ...agreement } : this.fields;
        if (!this.props.showform) return null;

        return (
            <Drawer height={"100%"} destroyOnClose maskClosable={false} placement="top"
                loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5" }}
                footer={<>
                    <span></span>
                    <Button loading={loading} type="primary" onClick={() => {
                        document.getElementById('AgreementForm').dispatchEvent(new Event('submit', { cancelable: true }))
                    }}>Save</Button>
                </>}
                title={`${this.fields && this.fields._id ? 'Edit' : 'Add'} Agreement`}
            >
                <FormComponent onSubmit={this.onSubmit} id='AgreementForm' loading={loading} fields={{ ...this.fields }}
                    mutators={{
                        setBody: (newValueArray, state, utils) => {
                            utils.changeValue(state, 'body', () => newValueArray[0])
                        },
                    }}
                    // form_render={({ values }) => {
                    form_render={formProps => {
                        const { values } = formProps;

                        return <>
                            {/* <Row gutter={[12, 12]}> */}
                            <Row>
                                <Col flex="70%">
                                    <div className="grid-block">
                                        <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                                        <FormField type="html" data={(this.fields && this.fields.body) ? this.fields.body : null}
                                            onUpdate={(val) => formProps.form.mutators.setBody(val)} name="body" label="Body" />
                                        <FormField type="textarea" rows={2} name="note" label="Notes" />

                                    </div>
                                </Col>
                                <Col flex="30%">
                                    <div className="grid-block">
                                        <FormField className={values.status == 'enabled' ? "active" : "inactive"} type="select" name="status" label="Status" data={generalStatus} validate={rules.required} />
                                        <FormField type="checkbox" label="This agreement can be deleted" name="deleteable" />
                                    </div>
                                </Col>
                            </Row>

                        </>
                    }}
                />

            </Drawer>
        )
    }
}


FormComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    // agreement: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editAgreement: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addAgreement: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(FormComp);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, agreement, error } = data;

            if (error) {
                message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
                console.log(__error("error"), error);
                // error.networkError.name
                // error.networkError.response.status
                // error.networkError.response.statusText
                console.log(__error("ERROR DETAILS"), error.networkError.result.errors);
            }

            return { loadingEditNode: loading, agreement, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />

export default Wrapper;