import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {
    BarcodeScanner, ProdTypeSelector,  
    Barcode, Drawer, Button, Heading, Icon, Loader, DevBlock, CategorySelection } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField, SubmitButton } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
import { publishStatus } from 'configs/constants';
import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import TagsSelector from './TagsSelector'
// import VendorSelector from './VendorSelector'
import BrandSelector from './BrandSelector'
import { checkRights } from 'Common/scripts/Security';
import ProductAttributesSelector from './ProductAttributesSelector';

const RECORD = loader('src/graphqls/product/product.graphql');
const RECORD_EDIT = loader('src/graphqls/product/edit.graphql');
const RECORD_ADD = loader('src/graphqls/product/add.graphql');
const DELETE_PROD_IMG = loader('src/graphqls/product/delete_prod_img.graphql');
const DELETE_PROD_GALL_IMG = loader('src/graphqls/product_gallery/delete.graphql');

const _FormComp = props => {
    // const [busy, setBusy] = React.useState(true);
    const INITIAL_STATE = {
        status: 'hidden', limit_max_qty_in_cart: 0, cost: 0,
        price_was: 0,
        price: 0,
        available_qty: 0,
        expirable: false,
        taxable: false,
        tax_formula: 'percent'
    };
    const { product } = props;

    const [editNode, setEditNode] = React.useState(product ? {
        ...product,
        tags: product.tags ? product.tags.split(",") : null,
        brand_id: product?.brand?._id,
        expirable: product.expirable=='yes',
        taxable: product.taxable == 'yes',
        not_serving: product.not_serving == 'yes',
    } : { ...INITIAL_STATE });

    React.useEffect(() => {
        // console.log("useEffect.props.product: ", props.product);
        /******** 
         * Setup initial values 
         * ****************
        */
       
        if (product && product._id > 0)
       {
            let _editNode = { 
                ...editNode, 
                brand_id: editNode?.brand?._id,
                vendor_id: editNode?.vendor?._id,

                expirable: editNode.expirable == 'yes',
                taxable: editNode.taxable == 'yes',
                not_serving: editNode.not_serving == 'yes',

            };
            // set attributes value for attributes selector
            // if (_editNode.attributes) {
            //     let selected_attribute = {}
            //     _editNode.attributes.forEach(element => {
            //         selected_attribute[`val_${element._id}`] = element.selected_values;
            //     });
            //     _editNode = Object.assign(_editNode, { selected_attribute: selected_attribute });
            // }

            // convert tages into array
            // if (_editNode.tags) _editNode = Object.assign(_editNode, { tags: _editNode.tags.split(",") })

            setEditNode(_editNode);
        }

    }, [product])

    const onSubmit = async (values) => {
        const { onClose, editProduct, addProduct, setLoading } = props;

        const input = {
            attributes: [],
            tax_formula: 'percent', // values.tax_formula,
            available_qty: values.available_qty,
            barcode: values.barcode,
            brand: values.brand ? { _id: values.brand._id, title: values.brand.title } : null,
            cat_id: values.cat_id,
            cost: values.cost,
            details: values.details,
            expirable: values.expirable ? "yes" : "no",
            limit_max_qty_in_cart: values.limit_max_qty_in_cart,
            max_stock_level: values.max_stock_level,
            min_stock_level: values.min_stock_level,
            price: values.price,
            price_was: values.price_was,
            seo_desc: values.seo_desc,
            seo_title: values.seo_title,
            // short_description: values.short_description,
            slug: values.slug,
            status: values.status,
            not_serving: values.not_serving ? "yes" : "no",
            tags: values.tags ? values.tags.toString() : null,
            tax_amount: values.tax_amount,
            tax_application_formula: values.tax_application_formula,
            tax_code: values.tax_code,
            taxable: values.taxable ? "yes" : "no",
            title: values.title,
            type_id: values.type_id,
        }

        if (values.attributes){
            Object.assign(input, { 
                attributes: values.attributes.map(o => ({
                    _id: o._id,
                    title: o.title,
                    slug: o.slug,
                    code: o.code,
                    required: o.required,
                    show_in_store: o.show_in_store,
                    displayAs: o.displayAs,
                    seo_title: o.seo_title,
                    seo_desc: o.seo_desc,
                    value: values?.attribute_values[`val_${o._id}`]
                }))
            })
        }

        // return console.log("onSubmit: ", input)

        
        setLoading(true);
        var results;

        if (editNode && editNode._id>0) {
            results = await editProduct({ ...input, _id: editNode._id }).then((e) => (e.data.editProduct)).catch(error => {
                console.log(__error("ERROR: "), error);
                return { error: { message:"Query Error" } }
            });
            setLoading(false);

            if (results.error){
                message.error(results.error.message);
                return false;
            }

            message.success("Updated");
            onClose(results);

        } else {
            results = await addProduct(input).then((e) => (e.data.addProduct)).catch(error => {
                console.log(__error("ERROR"), error);
                return { error: { message:"Query Error" }}
            });
            setLoading(false);

            if (results.error) {
                message.error(results.error.message);
                return false;
            }

            // setEditNode(results)
            message.success("Added");
            onClose(results);

        }

        return results;

    }

    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

    // const onFileDeleteClicked = async (fileInfo) => {
    //     const { deleteProductImg, deleteProdGallItem, setLoading, product } = props;

    //     // console.log("fileInfo: ", fileInfo);
    //     let srcField = fileInfo.name
    //     let src = fileInfo.srcUrl;
    //     let thumb = fileInfo.thumbUrl;
    //     let thumbField = fileInfo.name + "_thumb";

    //     if (fileInfo.name == "product_gallery_img") {
    //         if (!fileInfo.uid) return { error: { message: "Invalid record ID" } }

    //         setLoading(true);

    //         return deleteProdGallItem(fileInfo.uid).then((e) => {
    //             setLoading(false);
    //             return e.data.deleteProdGallItem;
    //         }).catch(error => {
    //             setLoading(false);
    //             console.log(error);
    //             message.error("Invalid action");
    //             return { error: { message: "Query Error" } };
    //         });
    //     }
    //     else if (fileInfo.name == "picture") {
    //         setLoading(true);

    //         // const postData = { product_id: product._id, action:"deleteProductImg" };
    //         // const action = `${process.env.REACT_APP_API_URL}/remove/assets`;
          
    //         // let response = await axios.post(action, postData)
    //         //     .then(({ data: response }) => {
    //         //         return response;
    //         //     })
    //         //     .catch(error => {
    //         //         console.log("action: ", action)
    //         //         console.log(__error(""), error);
            
    //         //         if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
    //         //             return { error: { message: error.response.data.error.message } };
    //         //         }
    //         //         console.log("error.response: ", error.response);
                
    //         //         return { error: { message: "Removal failed" } };
    //         //     });
        
    //         // console.log("response: ", response)
    //         // if (!response || response.error) return (response && response.error) ? response : { error: { message: "Unable to remove assets" } };
        
            
    //         // return;

    //         return deleteProductImg(product._id, `${srcField},${thumbField}`).then((e) => {
    //             setLoading(false);
    //             if (e.data.deleteProductImg.error) {
    //                 // let err = e.data.deleteProductImg.error;
    //                 // message.error(err.message);
    //                 // return false;
    //             }
    //             return e.data.deleteProductImg;
    //             // message.success("Fl");
    //             // onClose(e.data.editProductCat);
    //         }).catch(error => {
    //             props.setLoading(false);
    //             console.log(error);
    //             message.error("Query Error");
    //             return { error: { message: "Query Error" } };
    //         });
    //     }
    //     else {
    //         message.error("Invalid action");
    //         return { error: { message: "Invalid action" } };
    //     }
    // }

    return (<>
        <FormComponent onSubmit={onSubmit} id='ProductForm' fields={{ ...editNode }} debug={true} enterSubmit={false}
            mutators={{
                setDetails: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'details', () => newValueArray[0])
                },
                setBarocde: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'barcode', () => newValueArray[0])
                },
                setBrand: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'brand', () => ({ _id:newValueArray[0]._id, title:newValueArray[0].title }))
                },
                updateAttributes: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'attributes', () => newValueArray[0])
                },
            }}
            form_render={formProps => {
                const { values, submitting } = formProps;

                return <>
                    <Row style={{ flexDirection: "row", flexWrap: "nowrap" }}>

                        <Col flex="70%">
                            <div className="grid-block">
                                <Heading>Product Info : {values._id}</Heading>
                                <Row gutter={[5]} style={{flexWrap:"nowrap"}}>
                                    {values.picture_thumb && <Col>
                                        <img src={`${process.env.REACT_APP_DATA_URL}/${values.picture_thumb}`} style={{ maxWidth: "150px", maxHeight: "150px" }} />
                                    </Col>}
                                    <Col flex="auto">
                                        <Row>
                                            <Col flex="65%">
                                                <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required, rules.minChar(4))} />
                                            </Col>
                                            <Col flex="auto">
                                                <FormField onChange={filterSlug} type="text" name="slug" label="Slug (no space)" validate={composeValidators(rules.required, rules.nospace, rules.minChar(4))} />
                                            </Col>
                                        </Row>
                                        {/* <FormField type="textarea" rows={2} name="short_description" label="Short Description" validate={composeValidators(rules.required, rules.minChar(4))} /> */}
                                        <FormField type="html" data={(values && values.details) ? values.details : null}
                                            onUpdate={(val) => formProps.form.mutators.setDetails(val)} name="details" label="Details" />
                                    </Col>
                                </Row>

                            </div>

                            <ProductAttributesSelector 
                                formValues={values}
                                type_id={values.type_id} 
                                updateAttributes={formProps.form.mutators.updateAttributes}
                                defaultValues={values.attributes}
                            />

                            <div className="grid-block">
                                <Heading>SEO Details</Heading>
                                <FormField type="text" name="seo_title" label="SEO Title" />
                                <FormField type="text" name="seo_desc" label="SEO Description" />
                                <TagsSelector name="tags" label="Tags" />
                            </div>
                        </Col>

                        <Col flex="30%">
                            <div className="grid-block">
                                <Row>
                                    <Col flex="auto"><FormField className={values.status == 'published' ? "active" : "inactive"} type="select" name="status" label="Status" data={publishStatus} validate={rules.required} /></Col>
                                    <Col style={{ paddingTop: "0px" }}><FormField addLabelSpace color="red" type="switch" name="not_serving" label="Not Serving" /></Col>
                                </Row>

                                <ProdTypeSelector preload name="type_id" label="Product Type" validate={rules.required} data={values && values.type_id ? [values.type] : []} />
                                <CategorySelection name="cat_id" label="Category" preload validate={rules.required} data={values && values.cat_id ? [values.category] : []} />

                                <FormField type="text" label="Barcode" name="barcode" validate={rules.required} />
                                {values.barcode &&
                                    <Barcode value={`${values.barcode}`} height={80} />
                                }

                                <BarcodeScanner onScan={_barcode => (formProps.form.mutators.setBarocde(_barcode))} />
                                
                                <Row>
                                    <Col flex="auto">
                                        <BrandSelector name="brand._id" label="Brand" 
                                            data={(values && values.brand) ? [values.brand] : []} 
                                            onSelect={(___, e2)=>{
                                                formProps.form.mutators.setBrand({_id:e2.value, title:e2.label})
                                            }}
                                        />
                                    </Col>
                                </Row>

                            </div>

                            <div className="grid-block">
                                <Heading>Limits</Heading>
                                <Row>
                                    <Col flex="50%"><FormField type="number" label="Available qty" name="available_qty" 
                                        disabled={!checkRights(props.rights, 'prod-full-access')} 
                                        min={0} _max={10000} _validate={rules.required} /></Col>
                                    <Col flex="50%"><FormField type="number" label="Cart limit" min={0} max={10000} name="limit_max_qty_in_cart" /></Col>
                                    <Col flex="50%"><FormField type="number" label="Min stock level" name="min_stock_level" min={0} /></Col>
                                    <Col flex="50%"><FormField type="number" label="Max stock level" name="max_stock_level" min={0} /></Col>
                                </Row>
                                <FormField type="checkbox" name="expirable">This product is expirable</FormField>
                            </div>

                            <div className="grid-block">
                                <Heading>Cost & Price</Heading>

                                <Row>
                                    <Col>
                                        <FormField type="number" label="Cost"  name="cost"
                                            _validate={rules.required} _step={0.1} _min={0} _max={10000} disabled={!checkRights(props.rights, 'prod-full-access')} />
                                            {/* validate={composeValidators(rules.required2('cost'), rules.required)} /> */}
                                    </Col>
                                    <Col>
                                        <FormFieldGroup compact>
                                            <FormField type="number" name="price" width="80px" label="Price" compact disabled={!checkRights(props.rights, 'prod-full-access')} _validate={rules.required} _step={5} _min={0} _max={10000} />
                                            <FormField type="text" name="suffixprice" width="45px" style={{ borderLeft: 0 }} label="&nbsp;" compact inputProps={{ disabled: true }} defaultValue={props.settings.currency} />
                                            <FormField type="number" name="price_was" width="80px" label="Was" compact disabled={!checkRights(props.rights, 'prod-full-access')} className="linethrough" _step={5} _min={0} _max={10000} />
                                            <FormField type="text" name="suffixwas" width="45px" style={{ borderLeft: 0 }} label="&nbsp;" compact inputProps={{ disabled: true }} defaultValue={props.settings.currency} />
                                        </FormFieldGroup>
                                    </Col>
                                </Row>


                                <Heading>Tax Settings</Heading>
                                <FormField type="checkbox" name="taxable">This product is textable</FormField>
                                {values.taxable && <>
                                    
                                    <Row>
                                        <Col flex="220px"><FormField type="text" label="Tax Code" name="tax_code" /></Col>
                                        <Col flex={'auto'}><FormField data={[
                                            { title: 'Before Discount', _id: 'before-discount' }, { title: 'After Discount', _id: 'after-discount' },
                                        ]} type="select" label="Tax amount to be applied at" name="tax_application_formula" /></Col>
                                    </Row>

                                    <Row style={{flexWrap:"nowrap"}}>
                                        <Col flex="180px">
                                            <FormFieldGroup compact>
                                                <FormField style={{ width: '80px' }} data={[{ title: '%', _id: 'percent' }, { title: 'Fix', _id: 'fix' },]} disabled type="select" label="Tax Formula" compact name="tax_formula" />
                                                <FormField type="number" name="tax_amount" width="80px" label="&nbsp;" compact min={0} />
                                            </FormFieldGroup>
                                        </Col>
                                        <Col flex="auto">
                                            <FormField type="text" name="suffix_tax_total" label=" &nbsp; " inputProps={{ disabled: true }}
                                                defaultValue={`= ${(values.price > 0 && values.tax_amount > 0) && values.tax_formula == 'fix' ? values.tax_amount : Number((values.price / 100) * values.tax_amount).toFixed(2)}`}
                                            />
                                        </Col>
                                    </Row>

                                </>}
                            </div>

                        </Col>

                    </Row>

                </>
            }}
        />

    </>)
}

const FormComp = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editProduct: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addProduct: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(DELETE_PROD_IMG, {
        props: ({ mutate }) => ({
            deleteProductImg: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(DELETE_PROD_GALL_IMG, {
        props: ({ mutate }) => ({
            deleteProdGallItem: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
)(_FormComp);



class ProductComp extends Component {
    state = { busy: false };

    render() {
        const { onClose, showform, fields, product, loading } = this.props;
        const { busy } = this.state;

        const drawerProps = {
            height: "100%",
            destroyOnClose: true,
            maskClosable: false,
            placement: "top",
            onClose: onClose,
            loading: loading,
            visible: showform,
        }

        return (<Drawer bodyStyle={{ backgroundColor: "#f0f2f5" }} {...drawerProps}
            footer={<><span /><SubmitButton loading={loading} formid="ProductForm" /></>}
            title={`${product && product._id ? 'Edit' : 'Add'} Product`}
        >
            <Loader loading={busy}>
                {!loading && <FormComp {...this.props} product={product} setLoading={(_busy) => this.setState({ busy: _busy })} onClose={onClose} />}
            </Loader>
        </Drawer>)
    }

} 
ProductComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
    product: PropTypes.object,
}

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, product, error } = data;

            if (error) console.log(__error("error"), error);
            return { loading, product, queryErrors: error, }
        },
    }),
)(ProductComp);

const ConditionCheck = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <ProductComp {...props} />


const mapStateToProps = ({ grocer_storeadmin }) => {
    return { 
        settings: grocer_storeadmin.settings,
        store: grocer_storeadmin.store,
        rights: grocer_storeadmin.rights 
    };
}
export const Wrapper = connect(mapStateToProps)(ConditionCheck);
export default Wrapper;

