import { ContentArea } from 'Layout_v1'
import React from 'react'
import RolesManegementList from '../components/roles_management_list'

export const RolesManagement = props => {
    return (<ContentArea>
        <RolesManegementList />
    </ContentArea>)
}

export default RolesManagement;
