import React from 'react';
import { addModule, addReducers } from '../connector';

import Home from './Home';

import { ROOT, MODULE } from './constants'

// addReducers({ admins: reducers });
addModule({
  permission_required:'super_admin_only',
  path: `${ROOT}`, component: Home, title: "Super Admin Only", exact: 0, hideNav: 0,
  // subMenu: SubMenu()
})

// addModule({
//   permission_required:'super_admin_only',
//   path: `${ROOT}/icons`, component: IconsList, exact: 0, hideNav: 1,
//   // subMenu: SubMenu()
// })
