import React from 'react';
import { addModule, addReducers } from '../connector';
import { ROOT } from './constants'
import Home from './Home';
import OrderDashboard from './containers/order_dashboard';

// addReducers({ admins: reducers });
addModule(
  { path:`${ROOT}`, component:Home, icon:'desktop', title:"Dashboard", exact:1, hideNav:0 },
  // { path:`${ROOT}`, component:Home, icon:<Icon type='desktop' />, title:"Dashboard", exact:1, hideNav:0 },
)
addModule(
  { path: `${ROOT}order_dashboard`, component: OrderDashboard, title:"Orders Dashboard", exact:1, hideNav:1 },
)
