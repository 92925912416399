import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

import reducers from './reducers'
import mySaga from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// setup store
const store = createStore(
                reducers,
                composeWithDevTools(
                  applyMiddleware(sagaMiddleware),
                  // other store enhancers if any
                ),
                // window._REDUX_DEVTOOLS_EXTENSION_ && window.__REDUX_DEVTOOLS_EXTENSION__()
              );
const persistor = persistStore(store)

export default {store, persistor};



// then run saga
sagaMiddleware.run(mySaga);
