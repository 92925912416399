import React from 'react'
import LocationsList from './components/locations_list';
import LocationForm from './components/location_form';

export const Locations = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
            {visible && <LocationForm onClose={toggleDrawer} showform={visible} fields={fields} />}

            <LocationsList onEditRecord={(item) => toggleDrawer({ fields: item })} 
                onAddClick={() => toggleDrawer({})}
            />
        </>
    )
}
export default Locations;