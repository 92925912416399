import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { __error, __hilight } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { message, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { FormField } from 'Common/components/Form'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';

const LIST_DATA = loader('src/graphqls/product_cat/productCats.graphql');

/*************
    <CategorySelection name="parent_cat" label="Parent Category" addnull black_list_ids={(props.fields && props.fields._id) ? `${props.fields._id}` : null}
        data={[{
            _id: JSON.stringify({ _id: fields.parent_cat_id, title: fields.parent_cat_title }),
            title: fields.parent_cat_title
        }]}
    />
 */

const CategorySelectionComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        // return () => console.log;
    }, [props.preload])

    var lastFetchId = 0;
    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;

        var filter;
        if (_.isString(value)) {
            setState({ kw: value, loading: true })
            // filter = JSON.stringify({ ...props.filter, search: { keywords: value } });
            filter = JSON.stringify({ ...props.filter, search: { keywords: value } });
        }
        else if (value) {
            setState({ loading: true })
            filter = JSON.stringify({ ...props.filter, ...props.preload });
        }

        props.client.query({ query: LIST_DATA, variables: { filter } }).then(e => {
            if (e.errors) {
                console.log(__error("ERROR: "), e);
                return alert("Query Error")
            }
            const response = e.data.productCats;

            if (response.error) {
                console.log(__error("ERROR: "), response);
                message.error(response.error.message);
                setState({ kw: value, loading: false })
                return;
            }

            if (response) setList(response.map(o => ({ _id: o._id, title: o.title, raw: o })))
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: Product Types : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);

    return (
        <>
            <FormField validate={props.validate}
                type={props.mode || 'select'}
                name={props.name}
                label={props.label}
                data={list}
                placeholder={props.placeholder || "Search Product Type"}
                addNull={false}
                compact={props.compact}
                size={props.size}
                style={{ width: "100%", ...props.style }}
                allowClear={props.allowClear}
                // keyMap={props.keyMap || "_id=>title"}
                // onChange={handelChange}
                // defaultValue={props.defaultValue && props.defaultValue._id}
                inputProps={{
                    onSelect: props.onSelect || undefined, // console.log,
                    onDeselect: props.onDeselect || undefined, // console.log,
                    loading: state.loading,
                    mode: props.mode || undefined, // tags, multiple
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: state.loading ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchData,
                    // onChange:handelChange,
                }}
            />
        </>
    )
}
CategorySelectionComp.propTypes = {
    dataHandlr: PropTypes.func,
    // showform: PropTypes.bool.isRequired,
    addnull: PropTypes.bool,
    data: PropTypes.array.isRequired,
    black_list_ids: PropTypes.string, // comma saperated values
}

export const CategorySelection = withApollo(CategorySelectionComp);
export default CategorySelection;
