import React from 'react';
import { addModule, addReducers } from '../connector';

import Home from './Home';
import TestScanner from './containers/test_scanner';
import TestSms from './containers/test_sms';
import PrintTest from './containers/print_test';
import KazangApiTest from './kazang/api_test';
import { ROOT } from './constants'

export const SubMenu = props => ([
  { path: `${ROOT}/test_scanner`, exact: true, title: 'Test Scanner', component: TestScanner },
  { path: `${ROOT}/test_sms`, exact: true, title: 'Test SMS', component: TestSms },
  { path: `${ROOT}/print_test`, exact: true, title: 'Print SMS', component: PrintTest },
  { path: `${ROOT}/kazang_api_test`, exact: true, title: 'Kazang api test', component: KazangApiTest },
])

// addReducers({ admins: reducers });
addModule(
  {
    permission_required: 'super_admin_only',
    path: `${ROOT}`, component: Home, title: "Test", exact: 0, hideNav: 0,
    subMenu: SubMenu()
  },
)
