import React from 'react'
// import PropTypes from 'prop-types';
// import { Button, Heading } from 'Common/components'

import WalletList from '../components/wallet_list';
import WalletForm from '../components/wallet_form';


export const Wallets = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    return (<>
        <WalletList onEditRecord={(item) => toggleDrawer({ fields: item })} />
        {visible && <WalletForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </>)
}
export default Wallets;