import React from 'react'
import { formToFilter } from 'Common/scripts/Functions'
import { basketCategories } from 'configs/constants';
import { Row, Col, Divider, message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { FormField, FormComponent, FormFieldGroup, DateField } from 'Common/components/Form'
import { DevBlock, Icon, Button, Loader, IconButton, BasketCategorySelector } from 'Common/components'
import { connect } from "react-redux";

const BasketFilter = props => {
    const onSubmit = values => {
        let filter = formToFilter(values, { tz: props.default_timezone })
        props.onSearch(filter);
    }

    const { rights, user } = props;
    const _basketCategories = basketCategories.filter(o=>{
        if (user.type=='super-admin') return true;
        if (o._id =='pickup' && rights.includes('manage-pickup-baskets')) return false;
        if (o._id == 'dispatch' && rights.includes('manage-delivery-baskets')) return false;
        return true;
    })

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='BasketFilterForm' hideDevBlock={true} fields={props.defaultValue} style={{ padding: 0 }} 
            form_render={({ values })=>{
                return(<>
                    <Row className="pagination-filter-row">
                        <Col flex="auto" className="filters-column">
                            <BasketCategorySelector name="category" compact={true} style={{width:"200px"}} />
                            {/* <FormField type="select" data={_basketCategories} name="category" placeholder="Category" label="Category" width="200px" compact allowClear size="small" /> */}
                        </Col>
                        <Col className="go-column">
                            <Button className="send_button" loading={props.loading} htmlType="submit"><Icon icon="search" /></Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </div>)

}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        default_timezone: grocer_storeadmin.default_timezone,
        user: grocer_storeadmin.user,
        rights: grocer_storeadmin.rights,
    };
}
export default connect(mapStateToProps)(BasketFilter);
