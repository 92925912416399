import React, { Component } from 'react'
import { Row, Col, Tag } from 'antd';
import { PageHeader, ListHeader } from 'Common/components';
import { utcToDate } from 'Common/scripts/Functions';

export default class UserDetails extends Component {
  render() {
    const { user } = this.props;
      
    return (<>

      <Row>
        <Col flex="auto">
          <PageHeader 
            title={`${user.fname || ""} ${user.lname || ""}`}
            sub={<Tag color={user.status=='enabled' ? "green" : "red"}>{user.status}</Tag>}
          />
        </Col>
        <Col>Memmber Since: {utcToDate(user.created_at).format("Do-MMM-YYYY")}</Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Gender: </Col>
        <Col span={16}>{user.gender}</Col>
        <Col span={24}><hr /></Col>
          
        <Col span={8}>Email: </Col>
        <Col span={16}>{user.email} <Tag color={user.email_confirmed === 1 ? "green" : "red"}>{user.email_confirmed === 1 ? "confirmed" : "un-confirmed"}</Tag></Col>
        <Col span={24}><hr /></Col>
        
        <Col span={8}>Phone: </Col>
        <Col span={16}>{user.phone}</Col>
        <Col span={24}><hr /></Col>

      </Row>
    </>)
  }
}
