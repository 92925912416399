/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Common/components';
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
// import LocalStorage from 'Common/scripts/LocalStorage';

import RolesManagement from './containers/RolesManagement';
import TypeManagement from './containers/TypeManagement';

import { ROOT } from './constants'; 

export const menuArray = props => {
  // return await LocalStorage.getJsonAsync('user').then(user => {
  //   if (user.type == 'super-admin') {
  //     return [
  //       { path: `${ROOT}`, exact: true, title: 'Roles Management', component: RolesManagement },
  //       { path: `${ROOT}/type-management`, exact: true, title: 'Type Management', component: TypeManagement },
  //     ]
  //   } else {
  //     return [
  //       { path: `${ROOT}`, exact: true, title: 'Roles Management', component: RolesManagement },
  //       // { path: `${ROOT}/type-management`, exact: true, title: 'Type Management', component: TypeManagement },
  //     ]
  //   }
  // });

  return ([
    { path: `${ROOT}`, exact: true, title: 'Roles Management', component: RolesManagement },
    { path: `${ROOT}/type-management`, exact: true, title: 'Type Management', component: TypeManagement },
  ])

}

// LocalStorage.getJsonAsync('user').then(user => {
//   if (user.type == 'super-admin'){
//     menuArray.push({ path: `${ROOT}/type-management`, exact: true, title: 'Type Management', component: TypeManagement })
//   }
// });


/**
 * Home
 */
export const Home = props => {
  let routs = menuArray(); 

  return (
    <LayoutWrapper topMenu={menuArray()} >
      <Switch>
        {routs.map((item, i) => {
          if (!item.component) return null;
          return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
        })}
        <Route key={999} component={E404} />
      </Switch>
    </LayoutWrapper>
  )

}


Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

export default Home;