import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { connect } from "react-redux";

const USERS = loader('src/graphqls/user/users.graphql');


const UserSelectionComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        return () => console.log;
    }, [props.preload])

    var lastFetchId = 0; 
    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;
    
        var filter = {};
        if (_.isString(value)){
            setState({ kw: value, loading: true })
            filter = { ...props.filter, search:{keywords: value} };
        }
        else if(value){
            setState({ loading: true })
            filter = { ...props.preload, ...props.filter };
        }

        // add store ID if available
        if (props.store) Object.assign(filter, { store_id:props.store._id })


            props.client.query({ query: USERS, variables: { filter: JSON.stringify(filter) } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                setState({ kw: value, loading: false })
                return;
            }

            if (e.data.users) setList(e.data.users.map(o => ({ _id: o._id, title: o.fname, raw: o })) )
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: users : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (
        <>
            <FormField validate={props.validate}
                type={props.mode || 'select'}
                name={props.name}
                label={props.label}
                data={list}
                placeholder={props.placeholder || "Search Users"}
                addNull={false}
                compact={props.compact}
                size={props.size}
                style={{...props.style, width:"100%"}}
                allowClear={props.allowClear}
                // keyMap="value=>text"
                // onChange={handelChange}
                // defaultValue={props.defaultValue && props.defaultValue._id}
                inputProps={{
                    onSelect: props.onSelect || undefined, // console.log,
                    onDeselect: props.onDeselect || undefined, // console.log,
                    loading:state.loading,
                    mode: props.mode || undefined, // tags, multiple
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: state.loading ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchData,
                    // onChange:handelChange,
                }}
            />
        </>
    )
}
UserSelectionComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    filter: PropTypes.object,
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    console.log("grocer_storeadmin: ", grocer_storeadmin)
    return {
        user: grocer_storeadmin.user,
        store: grocer_storeadmin.store,
        // token: grocer_storeadmin.token,
        // rights: grocer_storeadmin.rights
    };
}
const WithRedux = connect(mapStateToProps)(UserSelectionComp);


export const UserSelection = withApollo(WithRedux);
export default UserSelection;
