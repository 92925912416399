import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Alert, Layout, Menu, message } from 'antd';
import { Icon, Button, ToggleButton, Loader, DevBlock } from 'Common/components';
import { LayoutFooter, SideMenu, LayoutHeader, ContentArea } from './';
import LocalStorage from 'Common/scripts/LocalStorage';
import { connect } from "react-redux";
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper'
import { loader } from 'graphql.macro';
import { updateSettings, logoutUser } from 'Store/grocer_storeadmin.actions';
import { checkRights } from 'Common/scripts/Security';
import { Link, useHistory } from 'react-router-dom';
import { sleep } from 'Common/scripts/Functions';
import { defaultTimeZone } from 'configs';
import moment from 'moment';

// const { Header, Sider, Content, Footer } = Layout;
const SETTINGS = loader('src/graphqls/settings/settings_str.graphql');

const env = process.env;

const LayoutWrapperComp = props => {
    const left_menu_collaps = LocalStorage.getJson('left_menu_collaps');
    const [collapsed, setNavCollaps] = React.useState(left_menu_collaps);
    const [collapsedWidth, setCollapsedWidth] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false); //(!props.settings);

    let history = useHistory();


    useEffect(() => {
        if (history.action !== "POP") return;
        loadSettings()
    }, [history.action])


    useEffect(() => {
        if (props?.settings && loading) return setLoading(false);
        if (loading || props?.settings) return;

        loadSettings();
    }, [props.settings])



    const loadSettings = () => {
        setLoading(true);
        props.client.query({ query: SETTINGS, variables: {} }).then(e => {
            const response = e.data.settings_str;

            if (response.error) {
                console.log(__error("Unable to load settings!!"), response);
                alert(response.error.message);
                return;
            }

            let _response = JSON.parse(response);
            moment.tz.setDefault(_response?.default_timezone || defaultTimeZone);

            props.updateSettings(_response);

        }).catch(err => {
            console.log(__error("API Call ERROR: locations : "), err);

            LocalStorage.logout();
            sleep(50).then(r => {
                props.logoutUser();
            });

            message.error("Session time out, please login again")
            // alert("Request ERROR");
        })
    }

    if (loading) return <Loader loading={true} style={{padding:"100px"}} />

    let topMenu = props.topMenu ? props.topMenu.slice() : null;
    if (topMenu){
        topMenu = topMenu.filter(o=>{
            if (!o.permission_required) return true;
            return checkRights(props.rights, o.permission_required);
        })
    }

    if (['system-admin', 'store-admin', 'store-supervisor', 'store-owner', 'super-admin'].indexOf(props.user.type) < 0  // not in the login category
        && (!props.rights || props.rights.length<1) // no rights
        && props.rights!='*' // not a super-admin
        && (String(props.user.type).indexOf("store") > -1 && !props?.store?._id) // not a store owner
        ){
        return <Alert message="Permissions Denied" description={<>
            <div>You are not allowed to visit this area <Link to="/logout">Log out</Link></div>
        </>} type='error' showIcon />
    }

    // if (!props?.store?._id && props?.rights !== '*' && props.user.type!='') return <Alert message="Permissions Denied" description={<>
    //     <div>You are not a store owner <Link to="/logout">Log out</Link></div>
    // </>} type='error' showIcon />

    return <Layout className="site-layout">
        <SideMenu menu={props.menu}
            collapsed={collapsed}
            setNavCollaps={(val)=>{
                LocalStorage.setJson('left_menu_collaps', val);
                setNavCollaps(val);
            }}
            setCollapsedWidth={setCollapsedWidth} collapsedWidth={collapsedWidth}
        />
        <Layout className="site-layout-content">
            <LayoutHeader menu={topMenu} collapsed={collapsed} />
            {props.loading && <ContentArea><Loader center loading={true} /></ContentArea>}
            {!props.loading && <ContentArea transparent style={{margin:"0", padding:"0"}}>
                {props.children}
                {/* {props.skipContentArea && props.children}
                {!props.skipContentArea && <ContentArea>{props.children}</ContentArea>} */}
            </ContentArea>}
            <LayoutFooter />

            <DevBlock title="Rights: " obj={props.rights} />
        </Layout>

    </Layout>
}

LayoutWrapperComp.propTypes = {
    menu: PropTypes.array,
    topMenu: PropTypes.array,
    skipContentArea: PropTypes.bool,
    loading: PropTypes.bool,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}





const mapStateToProps = ({ grocer_storeadmin }) => {
    let { settings, user, store, rights, token } = grocer_storeadmin;
    return { settings, user, store, rights, token }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    logoutUser: (payload) => dispatch(logoutUser(payload)),
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const LayoutWrapperComp_WithApollo = withApollo(LayoutWrapperComp);
export const LayoutWrapper = connect(mapStateToProps, mapDispatchToProps)(LayoutWrapperComp_WithApollo);

export default LayoutWrapper;
