import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql, gql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Row, Col, Popconfirm, Input, Form, Select } from 'antd';
import { Icon, IconButton, Table, Avatar, PageHeader, Button, ListHeader, UserFilter } from 'Common/components';
import { defaultPageSize, staffTypes } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
// import StaffFilter from 'Common/components/StaffFilter';
import { connect } from "react-redux";

const LIST_DATA = loader('src/graphqls/user/query_all.graphql');
const RECORD_DELETE = loader('src/graphqls/user/delete.graphql');

const defaultFilter = {
    // type: { $in: ['system-admin'] }
    // type: { $in: ['system-admin'] }
}

class ListComp extends Component {
    state = { 
        pagination: { current: 1, defaultPageSize: defaultPageSize, pageSize: defaultPageSize, total: 0 },
        busy: false,
    };

    constructor(props){
        super(props);
        this.doSearch = this.doSearch.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.usersQuery?.totalCount || 0
                }
            })
        }
    }

    handleDelete(id) {
        this.props.deleteUser(id).then(r => {
            if (r && r.data && r.data.deleteUser && r.data.deleteUser.error) {
                message.error(r.data.deleteUser.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    renderTitleColumn = (text, record) => {
        return (<Row gutter={16}>
            <Col><Avatar size={40} shape="square" src={record.avatar_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.avatar_thumb}` : null} icon={<Icon icon="image" />} /></Col>
            <Col><div>
                <div>{record.fname} {record.lname || ""}</div>
                <div>{record.email}</div>
            </div></Col>
        </Row>)
    }

    columns = [
        { title: 'ID', dataIndex: '_id', width:"100px" },
        { title: 'Name', dataIndex: 'name', render:this.renderTitleColumn },
        { title: 'Type', dataIndex: 'type', width: "150px", align: "center" },
        { title: 'Status', dataIndex: 'status', width: "80px", align: "center" },
        { dataIndex: '', title: 'Actions', className: 'actions-column', align: 'right', width: '80px',
            render: (___, record) => (<span className="action_buttons">
                <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                    <IconButton icon="trash-alt" />
                </Popconfirm>
            </span>),
        },
    ];


    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ busy: true })

        let filter = filters ? JSON.stringify({ ...filters, ...defaultFilter }) : this.props.queryString;

        this.props.loadMoreRows(
            pagination.pageSize || defaultPageSize,
            (pagination.pageSize || defaultPageSize) * (pagination.page - 1),
            filter,
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        ).then(r => {
            this.setState({
                pagination: { ...this.state.pagination, current: pagination.page, total: r?.data?.usersQuery?.totalCount },
                busy: false
            })
        })
    };

    

    doSearch(filter) {
        this.handleTableChange({ page: 1 }, filter)

        // let pagination = { current: 1, pageSize: defaultPageSize }
        // this.setState({ busy: true })

        // this.props.loadMoreRows(
        //     pagination.pageSize, 0,
        //     JSON.stringify({ 
        //         ...defaultFilter, 
        //         ...filter, 
        //         _id: { $ne: Number(this.props.user._id) }
        //     })
        // ).then(r => {
        //     this.setState({ busy: false, pagination })
        //     // if (r.data && r.data.usersQuery)
        //     //     this.setState({ pagination: { ...this.state.pagination, current: 1, total: r.data.usersQuery.totalCount } })
        //     // return r;
        // })
    }

    onSubmit(values){
        console.log("onSubmit()", values)
    }

    onSubmitFail(values){
        console.log("onSubmitFail()", values)
    }


    render() {
        const { loading, usersQuery, queryErrors, user } = this.props;
        const { pagination } = this.state;

        return (<>
            <ListHeader title="User" 
                sub={<>Total {(usersQuery && usersQuery.totalCount) || 0} users</>} 
                right={<Button onClick={this.props.onAddRecord}>Add User</Button>} 
            />

            <UserFilter onSearch={this.doSearch} filterType="all" />

            <Table loading={loading}
                columns={this.columns}
                dataSource={usersQuery ? usersQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status == 'disabled' ? 'disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
                expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{String(record.rights).replaceAll(",", ", ")}</p>,
                }}
            />
        </>)
    }
}


const WithApollo = compose(
    graphql(LIST_DATA, {
        options: props => {
            let fitler = { ...defaultFilter, _id: { $ne: Number(props.user._id) } }
            if (props.user.type == 'super-admin') fitler = { _id: { $ne: Number(props.user._id) } }

            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ ...defaultFilter, _id: { $ne: Number(props.user._id) } }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, usersQuery, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.usersQuery.totalCount
                    const newEdges = fetchMoreResult.usersQuery.edges
                    const pageInfo = fetchMoreResult.usersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        usersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, usersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);


const mapStateToProps = ({ grocer_storeadmin }) => {
    // let filter = { 
    //     ...defaultFilter,
    //     type: { $nin: ['super-admin'] }
    // }

    return { user: grocer_storeadmin.user };
}
const WithRedux = connect(mapStateToProps)(WithApollo);
export default WithRedux;
