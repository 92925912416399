import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Modal } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader, DeleteButton } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import LocationFilter from './LocationFilter'
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/locations/locations.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/locations/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/locations/delete.graphql');

export class ListComp extends Component {
    subscription = null;
    state = { busy:false };

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "locationsUpdated",
                _subscriptionType: "simple-array",
                _queryName: "locations",
                _typename: "Locations",
                debug: true
            });
        }

    }

    handleDelete(id) {
        this.props.deleteLocation(id).then(r => {
            if (r && r.data && r.data.deleteLocation && r.data.deleteLocation.error) {
                message.error(r.data.deleteLocation.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    columns = [
        {
            title: 'Location', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                        <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                    </Col>
                </Row>)
            }
        },
        { title: 'Type', dataIndex: 'type' },
        { title: 'Code', dataIndex: 'code' },
    ];

    render() {
        const { loading, locations, queryErrors } = this.props;
        const { busy } = this.state;

        return (<ContentArea>
            <ListHeader title="Locations" sub={`Total ${locations && locations.length || '0'} records found`} 
                right={<>
                    <Button onClick={this.props.onAddClick}>Add New Location</Button>
                </>}
            />

            <LocationFilter onSearch={(val)=>{
                this.props.loadMoreRows(JSON.stringify(val))
            }} />
            <Table loading={loading || busy}
                columns={this.columns}
                dataSource={locations ? locations : null}
                pagination={false}
            />
        </ContentArea>)
    }
}
ListComp.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, locations, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter) => {
                let vars = { filter };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, locations, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteLocation: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(ListComp);

export default WithApollo;


