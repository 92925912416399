import React from 'react'
import { Drawer as _Drawer } from 'antd';
import { Skeleton, List, Avatar } from 'antd';

import { Loader } from './';


export const LoaderSkeleton = props => {
    return (<>
        <Skeleton active loading={props.loading} avatar={props.avatar}
            paragraph={props.paragraph || { rows: 4 }}
            title={props.title}
            round={props.round}
        >
            <List.Item.Meta />
        </Skeleton>
    </>)
}



export const Drawer = props => {
    let _props = { ...props };
    delete _props.footerStyle;
    delete _props.loading;
    delete _props.transparent;

    if (props.transparent) {
        _props.bodyStyle = { ...props.bodyStyle, backgroundColor: "#f0f2f5" };
    }

    if (props.loading) {
        // delete _props.children
        delete _props.footer
        // _props.children = <Loader loading={props.loading} />
        _props.children = <>
            <LoaderSkeleton loading={props.loading} />
            <LoaderSkeleton loading={props.loading} />
        </>
    }
        

    if (props.footerStyle !== 'clear') {
        _props = Object.assign(_props, { footer: <div className="drawer-spread-footer">{_props.footer}</div> });
    }
    if (props.footer === false) Object.assign(_props, { footer:false });
        
    return <_Drawer {..._props} destroyOnClose={!props.no_destroyOnClose} />
}

export default Drawer;
