import React, { useState } from 'react'
import { Icon, IconButton } from 'Common/components';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import { message } from 'antd';

const UPDATE_PRODUCT = loader('src/graphqls/product/edit.graphql');
const UPDATE_FEATURED = loader('src/graphqls/product/setFeaturedProduct.graphql');


const FeatuerdButton = ({ prod, client }) => {
    const [busy, setBusy] = useState(false);

    const updateFeatured = async args => {
        let input = { featured: prod.featured == 'yes' ? 'no' : 'yes', id: Number(prod._id) }
        setBusy(true);
        
        // const resutls = await client.mutate({ mutation: UPDATE_PRODUCT, variables: { input } })
        //     .then(e => (e.data.editProduct)
        //     ).catch(err => {
        //         console.log(__error("Query ERROR: basket release : "), err);
        //         return { error: { message:"Request ERROR"}}
        //     })
        const resutls = await client.mutate({ mutation: UPDATE_FEATURED, variables: input })
            .then(e => (e.data.setFeaturedProduct)
            ).catch(err => {
                console.log(__error("Query ERROR: basket release : "), err);
                return { error: { message:"Request ERROR"}}
            })
            
        setBusy(false);
        if (resutls.error){
            message.error(resutls.error.message);
            return false;
        }
    }

    return (<>
        <IconButton onClick={() => updateFeatured()} loading={busy} icon={<Icon icon="check" size="2x" color={prod.featured=='yes' ? "green" : '#EEE'} />} size="large" />
    </>)
}

export default withApollo(FeatuerdButton);