import React from 'react'
// import PropTypes from 'prop-types';
// import { Button, Heading } from 'Common/components'

import PagesList from '../components/pages_list';
import PageForm from './PageForm';


export const Pages = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args ? args.fields : null });
    };

    return (<>
        <PagesList onEditRecord={(item) => toggleDrawer({ fields: item })} 
            onAddClick={() => toggleDrawer()}
        />

        {visible && <PageForm onClose={toggleDrawer} showform={visible} fields={fields} />}
    </>)
}
export default Pages;