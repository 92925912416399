import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import { Checkbox } from 'Common/components/Form'
import { message, Row, Col } from 'antd';

const LIST_DATA = loader('src/graphqls/product_attributes/productAttributes.graphql');

export const AttributesSelector = props => {
    const { productAttributes, loading, values } = props;
    const [showList, setShowList] = useState(false);

    const toggleDrawer = args => setShowList(!showList);

    const updateAttributeList = (node, doAdd) => {
        let attributes = props?.values?.attributes?.slice() || [];
        if (doAdd) attributes.push(node)
        else attributes = attributes.filter(o=>o._id!=node._id);

        if (props.onChange) props.onChange(attributes);
    }

    if (loading) return <Loader loading={true} />
    if (!productAttributes || productAttributes.length < 1) return <div>No data available</div>

    return(<>
        <div className="grid-block" style={{ margin: "0 0 10px 0" }}>
            <Heading><Button size="small" onClick={() => toggleDrawer()}>Assign Attributes</Button></Heading>
            {values?.attributes?.map((item, i) => {
                return (<div key={i} className="date-row">
                    <span><div>{item.title}</div><small>{item.code}</small></span>
                    <IconButton icon="minus" onClick={() => updateAttributeList(item, false)} />
                </div>)
            })}
        </div>

        <Drawer width={400} destroyOnClose maskClosable={true} placement="right" onClose={toggleDrawer} visible={showList} title={`Select attributes`}><>
            {productAttributes.map((item, i) => {
                return (<Row key={i} className="date-row">
                    <Col flex="30px"><Checkbox name={`__${item._id}`}
                        checked={values?.attributes?.find(o => o._id == item._id)}
                        onChange={(e) => updateAttributeList(item, e.target.checked)}
                    /></Col>
                    <Col flex="auto"><div>{item.title}</div><small>{item.code}</small></Col>
                </Row>)
            })}

        </></Drawer>
    </>)
}
AttributesSelector.propTypes = {
    attributes: PropTypes.array,
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, productAttributes, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.productAttributes
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, productAttributes, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(AttributesSelector);

export default WithApollo;