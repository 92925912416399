import React, { Component } from 'react'
import { E404, LayoutWrapper, ContentArea } from "Layout_v1";
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import OrderHistory from './components/OrderHistory'
import UserDetails from './components/UserDetails';
import { DevBlock, ListHeader } from 'Common/components';
import { Col, Row } from 'antd';
import { utcToDate } from 'Common/scripts/Functions';


const RECORD = loader('src/graphqls/user/user.graphql');

class CustomerDetails extends Component {

  render() {
    //   console.log("this.props: ", this.props.match.params._id)
    const { loading, user } = this.props;

    if (!loading && (!user || !user._id)) return <div>Invalid user!</div>


    return (<LayoutWrapper topMenu={null} loading={loading}>

      {(user && user._id) && <>
        <div style={{ padding:"10px" }}>
          <ListHeader title="Customer Details" />
        </div>

        <ContentArea>
          <UserDetails user={user} />
        </ContentArea>

        <ContentArea>
          <OrderHistory user={user} />
        </ContentArea>

      </>}

    </LayoutWrapper>)
  }
}

const WithApollo = compose(
    graphql(RECORD, {
        options: ({ match }) => {
            return { variables: { id: match.params._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, user, error } = data;

            if (error) console.log(__error("error"), error);

            return { loading, user, queryErrors: error, }
        },
    }),
)(CustomerDetails);

export default WithApollo;

