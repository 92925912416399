import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader, BasketCategorySelector } from 'Common/components'
import { rules, composeValidators, FormField, FormFieldGroup, FormComponent, UploadField, SubmitButton } from 'Common/components/Form'
import { message, Row, Col } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'

const RECORD_ADD = loader('src/graphqls/payment_methods/addPaymentMethod.graphql');
const RECORD_EDIT = loader('src/graphqls/payment_methods/editPaymentMethod.graphql');


export const FormComp = ({ fields, editPaymentMethod, addPaymentMethod, onSuccess, onClose, showform }) => {
   
    const onSubmit = async (values) => {
        const _id = fields ? fields._id : false;

        let input = {
            title: values.title,
            ref_key: values.ref_key,
            status: values.status,
        };

        var results;

        if (_id) {
            Object.assign(input, { _id: Number(fields._id) })
            
            results = await editPaymentMethod(input).then((r) => (r.data.editPaymentMethod))
            .catch(error => {
                console.log(__error("Query Error: "), error);
                return { error: { message:"Query Error" } }
            });
        } else {
            results = await addPaymentMethod(input).then((r) => (r.data.addPaymentMethod))
                .catch(error => {
                    console.log(__error("Query Error: "), error);
                    return { error: { message: "Query Error" } }
                });
        }

        if (results.error){
            message.error(results.error.message);
            return false;
        }
        
        if (onSuccess) onSuccess(results);
        return "reset"
    }

    return (
        <Drawer width={"300px"} destroyOnClose maskClosable={false} placement="right"
            onClose={onClose} visible={showform} bodyStyle={{ backgroundColor: "#f0f2f5", padding:0 }}
            footer={false}
            title={`${fields && fields._id ? 'Edit' : 'Add'} Payment Method`}
        >
            {showform && <>
                <FormComponent onSubmit={onSubmit} id='PaymentmethodsForm' fields={{ color:"#FFFFFF", ...fields }} enterSubmit={true}
                    form_render={({ values, submitting }) => {
                        return <>
                            <div className="grid-block">
                                <FormField type="text" name="title" label="Title" validate={composeValidators(rules.required)} />
                                <FormField type="text" name="ref_key" label="Key" validate={composeValidators(rules.required)} />
                                <FormField type="select" data={[
                                    { title:"Disabled", _id:"disabled" },
                                    { title:"Enabled", _id:"enabled" },
                                ]} name="status" label="Status" validate={composeValidators(rules.required)} />

                                <SubmitButton loading={submitting} style={{ margin:"10px" }}>Save</SubmitButton>
                            </div>
                        </>
                    }}
                />
            </>}
        </Drawer>
    )
}
FormComp.propTypes = {
    addPaymentMethod: PropTypes.func.isRequired,
    editPaymentMethod: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editPaymentMethod: (input) => mutate({
                variables: { input }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addPaymentMethod: (input) => mutate({
                variables: { input }
            }),
        })
    }),
)(FormComp);


export default WithApollo;