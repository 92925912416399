import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, ListHeader } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { ContentArea } from 'Layout_v1';

const LIST_DATA = loader('src/graphqls/page_settings/page_settings.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/page_settings/subscription.graphql');


export class ListComp extends Component {
    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify({}),
                    others: JSON.stringify({})
                },
                _subscriptionName: "pageSettingsUpdated",
                _subscriptionType: "simple-array",
                _queryName: "pageSettings",
                _typename: "PageSettings",
                debug: true
            });
        }

    }

    columns = [
        {
            title: 'Name', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                        {/* <DeleteButton onClick={() => this.handleDelete(rec?._id)} /> */}
                    </Col>
                </Row>)
            }
        },
        { title: 'Key', dataIndex: 'key' },
    ];

    render() {
        const { loading, pageSettings, queryErrors } = this.props;

        return (<ContentArea>
            <ListHeader title="PageSettings" />
            <Table loading={loading}
                columns={this.columns}
                dataSource={pageSettings ? pageSettings : null}
                pagination={false}
            />
        </ContentArea>)
    }
}

ListComp.propTypes = {
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { filter: "" } };
        },
        props: ({ ownProps, data }) => {
            const { loading, pageSettings, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {
                let vars = { first: count, after: startFrom, filter: filter, others: others };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.pageSettings
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, pageSettings, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),


)(ListComp);

export default WithApollo;
