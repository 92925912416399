import React, { useState } from 'react'
import { connect } from "react-redux";
import { checkRights } from 'Common/scripts/Security';

import ProductsList from '../components/product_list'
import ProductForm from '../components/product_form'
import ProductGalleryEditor from '../components/ProductGalleryEditor'

export const Catalogue = React.memo(props => {
    const [{ visible, fields }, setState] = useState({ visible: false, fields: null });
    const [showGalleryEditor, toggleGalleryEditor] = useState(false);

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    let canManage = checkRights(props.rights, 'product-manage');

    return (<>
        <ProductForm onClose={toggleDrawer} showform={visible} fields={fields} />

        <ProductGalleryEditor 
            onClose={()=>toggleGalleryEditor(false)} 
            showform={!(showGalleryEditor == false)} 
            product={showGalleryEditor}
        />

        <ProductsList 
            hideAddProduct={!canManage && !props.hideAddProduct}
            {...props} 
            searchFilterConfig={props.searchFilterConfig} 
            onEditRecord={(item) => toggleDrawer({ fields: item })} 
            toggleDrawer={toggleDrawer} 
            toggleGalleryEditor={toggleGalleryEditor}
        />
    </>)
}, ()=>true)


const mapStateToProps = ({ grocer_storeadmin: { rights } }) => {
    return ({ rights });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     // logoutUser: (payload) => dispatch(logoutUser(payload)),
// })
export default connect(mapStateToProps)(Catalogue);
