import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Avatar as _Avatar, message, Tooltip, Upload } from 'antd';
import { IconButton } from './Button';
import { Loader } from './Loader';
import { __error } from 'Common/scripts/consoleHelper';
import ImgCrop from 'antd-img-crop';

export const Avatar = props => {
    const [fileList, setFileList] = useState([]);
    const [src, setSrc] = useState(null);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setSrc(getSourse());
    }, [props])

    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const startUpload = async (file) => {
        setFileList([file])
        
        let fileObj = await getSrcFromFile(file);
        
        setBusy(true);
        const results = await props.uploadAvatar(fileObj).then(r => (r)).catch(err => {
            console.log(__error("Request Error: "), err);
            return { error: { message: "Request ERROR" } }
        })
        setBusy(false);
        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        setSrc(fileObj);
    }

    const getSourse = () => {
        if (props.src) return props.src;
        if(props.children) return null;

        if (!props.src && props.type == 'avatar') return '/assets/avatar-image-available.png';
        return '/assets/no-image-available.png';
    }

    let children = props.children;
    if (!children && props.alt) children = <span style={{ fontSize: props.size ? `${(props.size / 1.3)}px` : `18px` }}>{String(props.alt).trim()[0]}</span>;
    const size = props.size || undefined;

    let _avatar = <_Avatar style={{border:"1px solid rgba(0, 0, 0, 0.05)"}} src={src || undefined} shape={props.shape || 'circle'} size={size || undefined} alt={props.alt || undefined}>{children}</_Avatar>;

    return <span style={{ position: "relative", display: "inline-block" }}>
        <Loader loading={busy}>
            {props.tooltip && <Tooltip title={props.tooltip}>{_avatar}</Tooltip>}
            {!props.tooltip && _avatar}
        </Loader>

        {props.uploadAvatar && <div style={{ position: "absolute", top: 0, right: 0 }}>
            <ImgCrop rotate>
                <Upload disabled={busy}
                    beforeUpload={(file) => {
                        startUpload(file);
                        return false;
                    }}
                    onRemove={(file) => setFileList([])}
                    showUploadList={false}
                    action={false} //"https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    // listType="picture-card"
                    fileList={fileList}
                    defaultFileList={[]}
                    accept=".jpg,.png,.pdf"
                    // onChange={console.log}
                    // onPreview={onPreview}
                >
                    <IconButton icon="pen" />
                </Upload>
            </ImgCrop>
        </div>}

    </span>
}

Avatar.propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    alt: PropTypes.string,
    tooltip: PropTypes.string,
    uploadAvatar: PropTypes.func,
}
