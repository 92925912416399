import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants'

// addReducers({ admins: reducers });
addModule(
  { 
    permission_required: "account-role-access",
    path: `${ROOT}`, component: Home, icon: 'user-shield', title:"User Rights Management", exact:0, hideNav:0 
  }
)
