import React from 'react'
import PropTypes from 'prop-types';
import { Table, Avatar, Icon, Button, Barcode, IconButton, DevBlock } from 'Common/components'
import { Row, Col, Popover, Table as _Table } from 'antd';
import { connect } from "react-redux";

const Summary = _Table.Summary;
const isdev = process.env.NODE_ENV=='development';

const renderAttributes = (item) => {
    if (!item.attributes) return null;

    return <div style={{fontSize:"11px"}}>{item.attributes.map((o, i) => {
        if (o.value && o.show_in_store == 1) return <span key={i}>{o.title} {o.value} </span>
        return null;
    })}</div>
}

const RenderVoucher = ({ discount, currency }) => {
    // discount.voucher.title

    var message = false

    if (discount.voucher.voucher_type.key == 'discount') {
        if (discount.amount > 0) message = <>{currency} {discount.amount}</>
    }
    if (discount.voucher.voucher_type.key == 'delivery') {
        if (discount.amount > 0) message = <>{currency} {discount.amount}</>
    }
    if (discount.voucher.voucher_type.key == 'gift') {

    }
    // if (discount.voucher.loyalty_plan){}


    return (<Summary.Row style={{ backgroundColor: "#f0f2f5", fontWeight: "bold", color: "green" }}>
        <Summary.Cell index={0} colSpan={1}>
            Reward {discount?.voucher.loyalty_plan ? `(Loyaldy Plan)` : ""}
            <div>{discount.voucher.title}</div>
        </Summary.Cell>
        {/* <Summary.Cell index={0} colSpan={1}>{discount.voucher.title}</Summary.Cell> */}
        <Summary.Cell index={1} colSpan={3}>{message}</Summary.Cell>
    </Summary.Row>)
}


const RenderCart = ({ order, onRowClick, onScanPressed, currency }) => {
    const { pickup_data, dispatch_data, items, status, invoice } = order;
    var totalItems = 0;
    items.forEach(item => {
        let item_dispatched = !dispatch_data ? null : dispatch_data.products.find(o => o.product_id == item.product_id);
        if (order.status == "in-review" && item_dispatched) totalItems += 1;
        else totalItems += 1;
    });
    let totalSavingPercent = (order.total_savings > 0) ? Number((order.total_savings / invoice.total) * 100).toFixed(2) : 0;

    // var invoiceAmountMatch = (order.invoice.grand_total == grand_total);
    
    const columns = [
        { title: 'Product', dataIndex: 'title', render: (text, record) => {
            let notFound = !(pickup_data && pickup_data.products) ? null : pickup_data?.products?.find(o => o.product_id == record.product_id)?.decline_notes;

                return (<>
                    <Row gutter={[12, 0]} align='middle' style={{ flexWrap: "nowrap" }}>
                        <Col flex="50px">
                            <Avatar size={100} shape="square" src={record.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.picture_thumb}` : null} icon={<Icon icon="image" />} />
                            {onScanPressed && <Button style={{ position:"absolute", top:0, left:0 }} onClick={() => onScanPressed(record.barcode)}>Scan code</Button>}
                        </Col>
                        <Col flex="auto">
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {record.title}
                                <Popover placement="right" content={<div className={isdev ? "" : "disable_selection"}>
                                    <Barcode value={`${record.barcode}`} height={20} displayValue={true} />
                                    </div>} title={null} trigger="hover">
                                    <IconButton onClick={console.log} color="blue" icon="info" />
                                </Popover>
                            </div>
                            {renderAttributes(record)}
                            {(notFound && notFound.length > 0) && <div style={{ backgroundColor: "red", color: "white", padding: "0 10px", borderRadius: "50px", textAlign: "center" }}><b>Product not found by picker</b></div>}
                        </Col>
                    </Row>

                    {(notFound && notFound.length > 0) && <div>
                        {notFound.toString().replaceAll(",", ", - ")}
                    </div>}

                </>)
        } },
        { title: <span>Qty<br /><span style={{ fontSize: "10px", whiteSpace: "nowrap" }}>picked / required</span></span>, 
            dataIndex: 'qty', 
            align: "center", 
            width: 80, 
            render: (text, record) => {
                let qty0 = record.cart_qty;
                let qty1 = record.deliverable_qty;
                let qty3 = null;
                let thisDispatch = dispatch_data && dispatch_data?.products?.find(o => o.product_id == record.product_id);
                let thisPick = pickup_data && pickup_data?.products?.find(o => o.product_id == record.product_id);

                if (thisDispatch){
                    qty1 = thisPick.qty_picked;
                    qty3 = thisDispatch && thisDispatch.deliverable_qty || 0;
                }
                else if (thisPick){
                    qty1 = thisPick.qty_picked;
                }
                
                return (<div style={{ lineHeight: "36px", fontSize:"24px" }}>
                    <div>{qty1} / {qty0}</div>
                    {qty3 && <div style={{ fontSize: "52px", color: "green" }}>{qty3 || 0}</div>}
                </div>)

            }
        },
        {
            title: 'Price', dataIndex: 'price', align: "center", width: 80, render: (__, rec) => {
                return <>
                    {rec.price_was && <div className='_price_was'>{rec.price_was}</div>}
                    <div>{rec.price}</div>
                </>
            }
        },
        {
            title: 'Total', dataIndex: 'total', width: 100, align: "right", 
            render: (text, record) => {
                let _total = record.price * record.cart_qty;

                if (order == "new") {
                    _total = record.price * record.cart_qty;
                }
                return <>{_total}</>
            }
        },
    ];

    return (<div>
        <Table size="small"
            columns={columns}
            dataSource={items}
            pagination={false}

            onRow={(row, rowIndex) => {
                if (!onRowClick) return;

                return {
                    onClick: event => {
                        let prod;
                        if (pickup_data) prod = pickup_data.products.find(o => o.product_id == row.product_id);
                        if (prod && !prod.decline_notes && onRowClick) onRowClick(row);
                    },
                    // onDoubleClick: event => { }, // double click row
                    // onContextMenu: event => { }, // right button click row
                    // onMouseEnter: event => { }, // mouse enter row
                    // onMouseLeave: event => { }, // mouse leave row
                };
            }}

            rowClassName={(row) => {
                let picked_item = !pickup_data ? null : pickup_data.products.find(o => o.product_id == row.product_id);
                let dispatched_item = !dispatch_data ? null : dispatch_data.products.find(o => o.product_id == row.product_id);

                if (dispatched_item) {
                    if (dispatched_item.delivery_status == "canceled") return "table-row-red";
                    if (dispatched_item.delivery_status == 'deliverable') return 'selected-table-row';
                }
                if (picked_item) {
                    let notFound = !(pickup_data && pickup_data.products) ? null : pickup_data?.products?.find(o => o.product_id == row.product_id)?.decline_notes;
                    if (notFound && notFound.length > 0) return "table-row-red";
                    if (row.delivery_status == 'canceled') return 'table-row-canceled';
                    if (row.delivery_status == 'deliverable') return 'selected-table-row';
                }

                return ""
            }}

            summary={(rows) => {
                let extraStyle = {}; // (!invoiceAmountMatch) ? { color: "red" } : {}

                return (<>
                    <Summary.Row style={{ backgroundColor: "#f0f2f5" }}>
                        <Summary.Cell index={0} colSpan={1}>Total Savings</Summary.Cell>
                        <Summary.Cell index={1} colSpan={3}>{order.total_savings} ({totalSavingPercent}%)</Summary.Cell>
                    </Summary.Row>
                    <Summary.Row style={{ backgroundColor: "#f0f2f5" }}>
                        <Summary.Cell index={0} colSpan={1}>Subtotal ({totalItems} Items)</Summary.Cell>
                        {/* <Summary.Cell index={2} colSpan={3}>{order.invoice.total}</Summary.Cell> */}
                        <Summary.Cell index={2} colSpan={3}>{invoice.total}</Summary.Cell>
                    </Summary.Row>

                    <Summary.Row style={{ fontWeight:"bold" }}>
                        <Summary.Cell index={0} colSpan={4}>Service Charges</Summary.Cell>
                    </Summary.Row>
                    {invoice.service_charges.map((o, i) => (<Summary.Row style={{ backgroundColor: "#f0f2f5" }} key={i}>
                        <Summary.Cell index={0} colSpan={1}>- {o.type}</Summary.Cell>
                        <Summary.Cell index={1} colSpan={3}>{currency} {o.amount}</Summary.Cell>
                    </Summary.Row>))}

                    {invoice?.discount?.voucher?._id && <RenderVoucher discount={invoice.discount} currency={currency} />}

                    <Summary.Row style={{ backgroundColor: "#f0f2f5", fontWeight: "bold" }}>
                        <Summary.Cell index={0} colSpan={1}>Grand Total</Summary.Cell>
                        <Summary.Cell index={1} colSpan={3}>{currency} {invoice.grand_total}</Summary.Cell>
                    </Summary.Row>

                    <Summary.Row style={{ backgroundColor: "#f0f2f5", ...extraStyle }}>
                        <Summary.Cell index={0} colSpan={4}>
                            <b>Payment Method</b> {order.paymentMethod.title}
                        </Summary.Cell>
                        {/* <Summary.Cell index={1} colSpan={3}>{order.invoice.grand_total}</Summary.Cell> */}
                    </Summary.Row>

                </>);
            }}
        />

    </div>)

}


RenderCart.propTypes = {
    order: PropTypes.object.isRequired,
    onRowClick: PropTypes.func, 
    onScanPressed: PropTypes.func
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        currency: grocer_storeadmin.settings.currency,
    };
}
export default connect(mapStateToProps)(RenderCart);
