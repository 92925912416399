/* eslint-disable eqeqeq */
import _ from 'lodash';
import base64 from 'base-64';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { __error, __yellow } from './consoleHelper'
import { defaultTimeZone } from 'configs';



/*************************** MOngo Record filters FUNCTIONS *******************
 *
*/
export const omitTypeName = (obj) => {
  // console.log("omitTypeName()", obj);
  let o = {};
  for(let b in obj){
    if(b!='__typename')
      o[b] = obj[b];
  }
  // console.log("omitTypeName()", o);
  return o;
}

export const omitAllTypeName = (obj) => {

  if(_.isArray(obj) || _.isObject(obj))
  {
    let o = _.isArray(obj) ? [] : {};
    for(let b in obj)
    {
      if(b!='__typename'){
        o[b] = _.isString(obj[b]) ? obj[b] : omitAllTypeName(obj[b]); //_.isObject(obj) ? omitAllTypeName(obj[b]) : obj[b];
      }
    }
    obj = o;
  }

  return obj;

  // _.isArray()
  // _.isString()
}

export const formToFilter = (_fields, {tz}) => {
  
  const translateFilterValue = (val, {tz}) => {
    if (val instanceof moment) return dateToUtc(val, {tz});

    // if (isNaN(val)) return String(val)
    if (!isNaN(val)) return Number(val)

    // if (_.isInteger(val)){
    //   return Number(val);
    // }
    if (_.isString(val)) return String(val);
    if (val.keywords) return val;

    return false;
  }


  const fields = { ..._fields }
  let temp;
  let filter = {}

  // convert * to .
  for (let a in fields) {
    let key = a;
    if (a.indexOf("*") > -1){
      key = a.replaceAll("*", ".");

      // Object.assign(fields, { [key]: fields[a] })
      Object.assign(fields, { [key]: translateFilterValue(fields[a], {tz}) })
      delete fields[a];
    }
  }

  let keys = Object.keys(fields);


  keys.forEach(element => {
    let val = fields[element];

    if (!val) { }
    // else if (val.keywords) filter[element] = val
    else if (translateFilterValue(val, {tz})) filter[element] = translateFilterValue(val, {tz});
    else {
      temp = {};
      if (val.gt) temp = Object.assign(temp, { "$gt": translateFilterValue(val.gt, {tz}) });
      else if (val.gte) temp = Object.assign(temp, { "$gte": translateFilterValue(val.gte, {tz}) });
      else if (val.lt) temp = Object.assign(temp, { "$lt": translateFilterValue(val.lt, {tz}) });
      else if (val.lte) temp = Object.assign(temp, { "$lte": translateFilterValue(val.lte, {tz}) });
      // else if (val.or){
      //   console.log("val.or: ", val.or)
      //   temp = Object.assign(temp, { "$or": val.or });
      // }
      else {
        console.log(__error("Invalid filter element"), val);
      }
      if (Object.entries(temp).length > 0) filter[element] = temp;
    }
  });

  return filter;
}



/*************************** GENERAL FUNCTIONS *******************
 *
*/
export const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

export const string_to_slug = str => {
  if (!str || str.length < 1) return "";

  //# Ver 1
  // return String(str).replace(/[^a-z0-9]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '').toLowerCase();

  //# Ver 2
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .toString()
    .normalize('NFD')
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // replace whitespace by -
    .replace(/-+/g, '-') // replace dashes
    .replace(/&/g, '-y-') // Replace & with 'and'
    // .replace(/\-\-+/g, '-') // Replace multiple - with single -
    // .replace(/^-+/, "") // trim - from start of text
    // .replace(/-+$/, "") // trim - from end of text
    .trim() // Remove whitespace from both sides of a string

  return str;
}



/*************************** ARRAY FUNCTIONS *******************
 *
*/
export const sortArrayByVal = (arr, name, _order ='asc') => {
  let order = _order ? String(_order).toLowerCase() : "asc";
  if (order == 'asc') return arr.sort((a, b) => (a[name] < b[name]) ? 1 : -1)
  return arr.sort((a, b) => (a[name] > b[name]) ? 1 : -1)
}
function dynamicSort(property) {
  /***
   var People = [
      {Name: "Name", Surname: "Surname"},
      {Name:"AAA", Surname:"ZZZ"},
      {Name: "Name", Surname: "AAA"}
    ];

    People.sort(dynamicSort("Name"));
    People.sort(dynamicSort("Surname"));
    People.sort(dynamicSort("-Surname"));
   */
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}
function dynamicSortMultiple() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   * 
   * 
   People.sort(dynamicSortMultiple("Name", "-Surname"));

   */
  var props = arguments;
  return function (obj1, obj2) {
    var i = 0, result = 0, numberOfProperties = props.length;
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  }
}



export const constructCategoryArray = (allCats, parent = null) => {
  let arr = allCats.filter(word => word['parent_cat_id'] == parent).map(item => ({
    ...item,
    // _id: item._id,
    // title: item.title,
    // slug: item.slug,
    // status: item.status,
    // menu_bg_img: item.menu_bg_img,
    // title_img: item.title_img,
    children: constructCategoryArray(allCats, item._id),
  }))

  return arr;
}


/*************************** JSON FUNCTIONS *******************
 *
*/
export const jsonStringify = (jsonObj, a=0, b=0) => {
  if(!jsonObj) return jsonObj;
  let result = false;

  try{
            if (typeof jsonObj!=="string"){
              result = JSON.stringify(jsonObj, a, b);
            }else{
                result = false;
            }
  }
  catch (error){
      console.error("ERROR : jsonStringify()", error);
  }

  return result;
}

export const parseJson = (jsonString) => {
  let jsonObject = {};

  if(!jsonString) return jsonObject;

  try{
            if (typeof jsonString!=="string"){
                // return false;
            }else{
                jsonObject = JSON.parse(jsonString);
                // return jsonObject;
            }
  }
  catch (error){
            console.log("ERROR : parseJson()", error);
  }

  return jsonObject;
}

export const decode64 = (str) => {
  let _str = "";
  if(!str) return _str;
  try{
            if (typeof str!=="string"){
                // return false;
            }else{
                _str = base64.decode(str);
                // return jsonObject;
            }
  }
  catch (error){   console.log("ERROR : decode64()", error);   }
  return _str;
}



/**** Image functions ************/
/*
getBase64(File Field Object)
*/
export const getBase64 = (file, as='DataURL') => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => resolve(reader);
    reader.onload = () => {
      // console.log("FileReader: ", reader);
      resolve(reader.result)
    }
    reader.onerror = error => reject(error);
  });
}

export const resizeImage = (_image, maxWidth=300, maxHeight=300) => {

    return new Promise(async function(resolve,reject){
      var thumbnailWidth, thumbnailHeight;
      var image = new Image();
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      image.onload = function(){
        //Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
        var thumbnailScale = (image.width / image.height) > (maxWidth / maxHeight) ?
        maxWidth / image.width : maxHeight / image.height;
        thumbnailWidth = image.width * thumbnailScale;
        thumbnailHeight = image.height * thumbnailScale;

        // set its dimension to target size
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        // draw source image into the off-screen canvas:
        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        //Draw border (optional)
        // ctx.rect(0, 0, thumbnailWidth, thumbnailHeight - 1);
        // ctx.strokeStyle = "#555555";
        // ctx.stroke();

        resolve(canvas.toDataURL('image/png', 70));
        // resolve(canvas.toDataURL('image/jpeg', 70));
        // return canvas.toDataURL('image/jpeg', 70);
      }

      image.src = _image;

    });

}



/*************************** DATE FUNCTIONS ******************* 
 * 
*/
// export const dateToMongo = (_date, returnString = true) => {
//   return returnString ? moment_tz.utc(_date).format() : moment_tz.utc(_date);
// }
// export const mongoToDate = (_date) => {
//   return moment_tz.utc(_date).tz("Asia/Karachi");
// }

export const dateToUtc = (_t, new_options) => {
  let offset1 = _t.utcOffset();
  let offset2 = moment().utcOffset();

  let tz = (new_options && new_options.tz) ||  defaultTimeZone;

  if (!new_options || !new_options.tz) {
    console.log(__error("Missing target timezone"));
    alert("Missing target timezone");
    // return false;
  }

  let options = {
    returnAs: "string",
    ...new_options,
    tz
  }

  // let selectedTzOffset = moment.tz(tz).utcOffset();
  // let currentTzOffset = moment.tz(moment.tz.guess()).utcOffset();

  // Replace timezone to CA
  var updatedTimezone = moment.tz(_t.format("DD-MM-YYYY HH:mm"), "DD-MM-YYYY HH:mm", options.tz); // change timezone
  // convert to UTC
  let _utc = updatedTimezone.utc(false); // false = convert time

  if (offset1 !== offset2) {
    console.log(__yellow("dateToUtc > timezone difference found"), { offset1, offset2 })
  }

  return options.returnAs == "string" ? _utc.format() : _utc;
}
export const utcToDate = (utc_string, tz) => {
  let _t = moment(utc_string);
  var updatedTimezone = moment.tz(_t.format("DD-MM-YYYY HH:mm"), "DD-MM-YYYY HH:mm", tz || defaultTimeZone); // change timezone
  return updatedTimezone;

  // return moment(utc_string);
}



export const toUTCDate = timeString => {
  console.log("converting to utc: ", timeString);

  if (!_.isString(timeString)){
    alert("Conversation to UTC failed, must provide time string");
    return;
  }
  // get timeDate only
  let m = moment(timeString).format("YYYY-MM-DD hh:mm:ss");
  // convert to UTC
  m = moment_tz.utc(m, "YYYY-MM-DD hh:mm:ss");
  console.log(m);
  return m;
}



// export const getTimeDifference = _date => {
export const getTimeDifference = dates => {
  // console.log("getTimeDifference()", moment(dates).format());

  if (!dates || !(dates.before || dates.after)) return "Invalid Date";
  // if (!before && !after) return "Invalid Date";

  var _before = moment(dates.before || new Date()); //todays date
  var _after = moment(dates.after || new Date()); // another date
  var duration = moment.duration(_after.diff(_before));

  // console.log(`_before: ${_before} == _after: ${_after}`);

  // if (!_date || _date.length < 10) return "Invalid Date";
  // var before = moment(new Date()); //todays date
  // var after = moment(_date); // another date
  // var duration = moment.duration(after.diff(before));
  // // var duration = moment.duration(now.diff(end));

  // console.log(`>>>>>>>>>>>>>}`);
  // console.log(_before.format('DD-MM-YYYY hh:mm:ss'));
  // console.log(_after.format('DD-MM-YYYY hh:mm:ss'));
  
  // console.log(`year: ${duration.asYears()}`);
  // console.log(`month: ${duration.asMonths()}`);
  // console.log(`weeks: ${duration.asWeeks()}`);
  // console.log(`days: ${duration.asDays()}`);
  // console.log(`hrs: ${duration.asHours()}`);
  // console.log(`min: ${duration.asMinutes()}`);
  
  

  var years = duration.asYears();
  if (years >= 1) return `${Math.floor(years)} Years ago`;

  var months = duration.asMonths();
  if (months >= 1) return `${Math.floor(months)} Months ago`;

  var weeks = duration.asWeeks();
  if (weeks >= 1) return `${Math.floor(weeks)} Weeks ago`;

  var days = duration.asDays();
  if (days >= 1) return `${Math.floor(days)} Days ago`;

  var hours = duration.asHours();
  if (hours >= 1) return `${Math.floor(hours)} Hours ago`;

  var minutes = duration.asMinutes();
  if (minutes >= 1) return `${Math.floor(minutes)} Minutes ago`;

  return `Less than a minute ago`;

}

export const convertTo24Hrs = timeString => {
  let newStr = String(timeString);
  if (newStr.length < 4)
    for (let a = 0; a < (4 - newStr.length); a++)
      newStr = "0" + newStr;
  return newStr;
}

export const convertTo12Hrs = timeString => {
  let _timeString = convertTo24Hrs(timeString);
  return moment(_timeString, ["HHmm"]).format("hh:mm A");
}



/************* Detect if color is dark or light ************** */
export const lightOrDark = (color) => {
  var r,g,b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    )
    );

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  console.log("hsp: ", hsp);

  // Using the HSP value, determine whether the color is light or dark
  // if (hsp > 127.5) return 'light';
  if (hsp > 140) return 'light';
  else return 'dark';

}




// export const isLatLngInZone = (latLngs, lat, lng) => {
//   // latlngs = [{"lat":22.281610498720003,"lng":70.77577162868579},{"lat":22.28065743343672,"lng":70.77624369747241},{"lat":22.280860953131217,"lng":70.77672113067706},{"lat":22.281863655593973,"lng":70.7762061465462}];
//   let vertices_y = new Array();
//   let vertices_x = new Array();
//   let longitude_x = lng;
//   let latitude_y = lat;
//   // latLngs = JSON.parse(latLngs);
//   var r = 0;
//   var i = 0;
//   var j = 0;
//   var c = 0;
//   var point = 0;

//   for (r = 0; r < latLngs.length; r++) {
//     vertices_y.push(latLngs[r].lat);
//     vertices_x.push(latLngs[r].lng);
//   }
//   let points_polygon = vertices_x.length;;

//   for (i = 0, j = points_polygon; i < points_polygon; j = i++) {
//     point = i;
//     if (point == points_polygon)
//       point = 0;
//     if (((vertices_y[point] > latitude_y != (vertices_y[j] > latitude_y)) && (longitude_x < (vertices_x[j] - vertices_x[point]) * (latitude_y - vertices_y[point]) / (vertices_y[j] - vertices_y[point]) + vertices_x[point])))
//       c = !c;
//   }
//   return c;
// }


// const isMarkerIntoZone = (marker, poly = false) => {
//   if (!marker || !marker.geo_point) return;
//   if (!this.maps.geometry) {
//     alert('Geometry not found')
//     return;
//   }

//   const { polygons } = this.state;
//   const coords = marker.geo_point.coordinates;
//   const point = new this.maps.LatLng(coords[0], coords[1]);

//   // const { lat, latitudeDelta, lng, longitudeDelta } = marker.geo_point.coordinates;
//   // const point = new this.maps.LatLng(lat, lng);

//   if (poly) return this.maps.geometry.poly.containsLocation(point, poly)

//   var isInBounds = false;
//   if (polygons && polygons.length > 0) {
//     polygons.forEach(item => {
//       let _isInBounds = this.maps.geometry.poly.containsLocation(
//         point,
//         item.poly
//       )
//       if (_isInBounds) isInBounds = item;
//     })
//   }

//   return isInBounds;

// }





export function scrollToTop() {
  window.scrollTo({
    top: 100,
    // left: 100,
    behavior: "smooth",
  });
  // document.body.scrollTop = 0; // For Safari
  // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}