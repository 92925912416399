import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { loader } from 'graphql.macro';
import { Alert, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, ListHeader, DeleteButton } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
const axios = require('axios');

const LIST_DATA = loader('src/graphqls/user_role/query_all.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/user_role/subscription.graphql');
const RECORD_DELETE = loader('src/graphqls/user_role/delete.graphql');

/**
 * TypeList
 */
export class TypeList extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.state = { selectedRecord: false, showDrawr: false, busy: false, error: null }
        this.handelResponse = this.handelResponse.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: { 
                        filter: JSON.stringify({}), 
                        others: JSON.stringify({}) 
                    },
                _subscriptionName: "userRolesUpdated",
                _subscriptionType: "array",
                _queryName: "userRolesQuery",
                _typename: "UserRole",
                debug: true
            });
        }

    }

    columns = [
        {
            title: 'Title', dataIndex: 'title', render: (text, rec) => {
                return (<Row className='show-menu-on-hover'>
                    <Col>{rec.title}</Col>
                    <Col className='hidden-menu'>
                        <IconButton icon="pen" onClick={() => this.props.onEditRecord(rec)} />
                        <DeleteButton onClick={() => this.handleDelete(rec?._id)} />
                    </Col>
                </Row>)
            }
        },
        { title: 'Type', dataIndex: 'acc_type' },
    ];

    handleDelete = _id => {
        console.log(`handleDelete(${_id})`);
        this.props.deleteUserRole(_id).then(e => { })
    };

    toggleDrawr(record = false) {
        this.setState({ showDrawr: !this.state.showDrawr, selectedRecord: record })
    }

    handelResponse(response) {
        this.setState({ busy: false });

        if (response.error) {
            this.setState({ error: response.error });
            return;
        }
        if (response.data.error) {
            this.setState({ error: response.data.error.message });
            return;
        }
    }

    SyncTypes = args => {
        const { userRolesQuery } = this.props;
        const accountTypes = userRolesQuery.edges.map(t => ({ title: t.title, _id: t.acc_type }) )

        if (this.state.busy) return false;

        this.setState({ busy: true, error: false });
        var handelResponse = this.handelResponse;

        axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/sync/account_types',
            data: { accountTypes: accountTypes }
        })
            .then(async function (response) {
                handelResponse(response);
            })
            .catch(function (error) {
                console.log("ERROR: ", error);
                handelResponse({ error: 'Netowrk Error' });
            });

    }



    render() {
        const { loading, userRolesQuery, queryErrors } = this.props;
        const { showDrawr, selectedRecord, busy, error } = this.state;
        
        if (queryErrors) console.log(__error("queryErrors"), queryErrors);
        if (loading) return <Loader loading={loading} />

        return (<>
            <ListHeader title="Account Types Management" 
                right={<>
                    <Button onClick={this.props.onAddRecord}>Add Account Type</Button>
                </>}
            />

                {error && <Alert message="Error" type="error" description={error} onClose={() => this.setState({ error: false })} showIcon closable />}
                {/* {queryErrors && <Alert type='error' message={`${queryErrors.networkError.name}`} description={`${queryErrors.networkError.response.statusText} : ${queryErrors.networkError.response.status}`} showIcon />} */}

                <Table loading={loading}
                    columns={this.columns}
                    dataSource={userRolesQuery ? userRolesQuery.edges : null}
                    // title={() => <span>Header</span>}
                    // footer={() => 'Footer'}
                    expandable={{
                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                        // rowExpandable: record => record.name !== 'Not Expandable',
                    }}
                />

        </>);
    }
}

TypeList.propTypes = {
    // prop: PropTypes.type.isRequired
    onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0 } };
        },
        props: ({ ownProps, data }) => {
            const { loading, userRolesQuery, error, fetchMore, subscribeToMore } = data;
            
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.userRolesQuery.totalCount
                    const newEdges = fetchMoreResult.userRolesQuery.edges
                    const pageInfo = fetchMoreResult.userRolesQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        userRolesQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, userRolesQuery, queryErrors:error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUserRole: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(TypeList);

export default WithApollo;
