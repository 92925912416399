import React, { Component } from 'react'
import { basketCategories } from 'configs/constants';
import { FormField } from './Form'
import { connect } from "react-redux";

const BasketCategorySelectorComp = ({ rights, user, name, placeholder, label, size, validate, compact, style }) => {
    const _basketCategories = basketCategories.filter(o => {
        if (!user || user.type == 'super-admin') return true;
        
        if (o._id == 'pickup' && !rights.includes('manage-pickup-baskets')) return false;
        if (o._id == 'dispatch' && !rights.includes('manage-delivery-baskets')) return false;
        return true;
    })

    return <FormField type="select" data={_basketCategories} 
        style={style}
        name={name || 'category'} 
        placeholder={placeholder || "Category"} 
        label={label || "Category"} 
        compact={compact} allowClear 
        size={size || "small"} 
        validate={validate}
    />
}

const mapStateToProps = ({ grocer_storeadmin }) => {
    return {
        // settings: grocer_storeadmin.settings,
        user: grocer_storeadmin.user,
        rights: grocer_storeadmin.rights,
        // store: grocer_storeadmin.store,
    };
}
export const BasketCategorySelector = connect(mapStateToProps)(BasketCategorySelectorComp);
