import React, { Component, useState, useEffect } from 'react'
import { graphql, Subscription, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Modal } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';
import { utcToDate } from 'Common/scripts/Functions';
import moment from 'moment';
import { defaultDateTimeFormat } from 'configs';

const GET_UPDATES = loader('src/graphqls/system_updates/getSystemUpdates.graphql');
const START_UPDATES = loader('src/graphqls/system_updates/startSystemUpdates.graphql');


const DataRow = ({ title, date, onClick, data }) => {
    let diff = data.start_date ? utcToDate(data.end_date).diff(utcToDate(data.start_date), 'minutes') : '0';

    return <div style={{ margin: "20px 0", padding: "0 10px", border: "1px solid #EEE", borderRadius: "5px" }}>
        <Row gutter={[10]} align="middle">
            <Col style={{ fontWeight: "bold", fontSize: "16px", minWidth: "400px" }}>{(data && data.title) || title}</Col>
            <Col style={{ padding: "5px" }}><Button onClick={onClick}>Start Update</Button></Col>
            <Col>{(data && data._id && `${utcToDate(data.start_date).format(defaultDateTimeFormat)} ~ ${utcToDate(data.end_date).format(defaultDateTimeFormat)}`) || date}</Col>
            <Col style={{ fontWeight: "bold" }}>{diff} min</Col>
        </Row>
    </div>
}

const SystemUpdates = ({ client }) => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [notes, setNotes] = useState(null);

    const startSystemUpdates = async (type) => {
        setBusy(true);
        setNotes(null);
        setError(null);
        var start_date = moment();

        const resutls = await client.mutate({ mutation: START_UPDATES, variables: { type }, fetchPolicy: "no-cache" })
            .then(r => {
                return r.data.startSystemUpdates;
            }).catch(err => {
                console.log(__error("Query ERROR: basket release : "), err);
                return { error: { message: "Request ERROR" } }
            })

        let sec_diff = moment().diff(start_date, 'seconds');
        let min_diff = moment().diff(start_date, 'minutes');
        let hrs_diff = moment().diff(start_date, 'hours');

        let diff = ""
        if (hrs_diff > 0) diff = `${hrs_diff} hrs`
        else if (min_diff > 0) diff = `${min_diff} min`
        else diff = `${sec_diff} sec`

        // message.info(`Query completed in ${diff}`);
        setNotes(`Query completed in ${diff}`)

        setBusy(false);
        if (resutls.error) {
            setError(resutls.error.message);
            console.log(__error("ERROR "), resutls)
            return;
        }

        if (resutls.success) {
            message.success(resutls.success.message);
        }

        message.success(`${type} Completed`)

    }

    const getSystemUpdates = async args => {
        setBusy(true);
        setError(null);

        const resutls = await client.query({ query: GET_UPDATES }).then(r => {
            return r.data.getSystemUpdates;
        }).catch(err => {
            console.log(__error("Request ERROR: "), err);
            return { error: { message: "Request ERROR" } }
        })

        setBusy(false);
        if (resutls.error) {
            setError(resutls.error.message);
            console.log(__error("ERROR "), resutls)
            return;
        }

        setData(resutls);

    }

    useEffect(() => {
        getSystemUpdates()
    }, [client])

    return (<Loader loading={busy}>
        {error && <Alert message={error} type='error' showIcon />}
        {notes && <Alert message={notes} type='info' showIcon />}

        <DataRow
            data={(data && data?.find(o => o.type == 'update-order-accounts')) || { title: "Update Order Accounts", date: defaultDateTimeFormat }}
            onClick={() => startSystemUpdates('update-order-accounts')}
        />

    </Loader>)

}

export default withApollo(SystemUpdates);