import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Row, Col, message, Modal, Alert } from 'antd';
import { Drawer, Heading, Button, Icon, UserSelection, BasketSelection, DevBlock, IconButton, Avatar } from 'Common/components'
import { Select, FormField, FormComponent, FormFieldGroup, composeValidators, rules } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'


export const Status_Ready_To_Dispatch = ({ onCancel, onSubmit, order }) => {
    const on_submit = async (values) => {
        var error = false;

        const input = {
            _id: order._id,
            status: "ready-to-dispatch",
            dispatch_baskets: values.dispatch_baskets.map(o => (Number(o))),
        }

        if (error) {
            alert(error);
            return false;
        }

        return onSubmit(input);
    }


    if (!order?.pickup_by?._id) return <Alert message="Order has not been picked yet!" type="error" showIcon />
    if (!order?.till_by?._id) return <Alert message="Order has not been cleared from till yet!" type="error" showIcon />
    // if (!order?.pickup_data?.length<1) return <Alert message="Order have no pickup data!" type="error" showIcon />
    // if (!order?.picker_baskets?.length<1) return <Alert message="No pickup baskets mentioned!" type="error" showIcon />
    // if (!order?.dispatch_baskets?.length<1) return <Alert message="No dispatch baskets mentioned!" type="error" showIcon />

    let initial_values = {
        ...order,
        status: "ready-to-dispatch",
        dispatch_baskets: order?.dispatch_baskets.map(o=>(o._id)),
        // pickup_data: order.items,
        // picker_id: order?.pickup_by?._id,
        // picker_baskets: order?.picker_baskets?.map(o=>(o._id))
    }

    return (<>
        <Alert 
            message={<>Are you sure to change order status to <b>Ready To Dispatch</b>?</>} 
            description={<>
                <p>Following actions will be performed</p>
                <ol>
                    <li>Remove any Driver data</li>
                    <li>Remove any Delivery data</li>
                    <li>Update order status</li>
                </ol>
            </>} 
            type="warning" showIcon />

        <FormComponent onSubmit={on_submit} id='OrderStatusUpdateForm' fields={initial_values} debug={true}
            mutators={{
                ...arrayMutators,
                addItems: (newValueArray, state, tools) => {

                    let _id = newValueArray[0];
                    let pickup_data = state.formState.values.pickup_data.slice();
                    let thisField = pickup_data.find(o => o._id == _id); // pickedQty
                    let pickedQty = (thisField.pickedQty || 0) + 1;
                    if (pickedQty > thisField.qty || pickedQty < 0) return;

                    pickup_data = pickup_data.map(o => o._id == _id ? { ...o, pickedQty } : o)

                    tools.changeValue(state, 'pickup_data', () => pickup_data)
                },
                removeItems: (newValueArray, state, tools) => {
                    let _id = newValueArray[0];
                    let pickup_data = state.formState.values.pickup_data.slice();
                    let thisField = pickup_data.find(o => o._id == _id); // pickedQty
                    let pickedQty = (thisField.pickedQty || 0) - 1;
                    if (pickedQty > thisField.qty || pickedQty < 0) return;

                    pickup_data = pickup_data.map(o => o._id == _id ? { ...o, pickedQty } : o)

                    tools.changeValue(state, 'pickup_data', () => pickup_data)
                },
            }}

            form_render={formProps => {
                const { values, submitting, form, valid, dirty, errors } = formProps;

                return (<>
                    {/* <div>
                        <UserSelection style={{ maxWidth: "400px" }}
                            name="till_by._id" preload={true}
                            filter={{ type: 'picker' }}
                            label="Select the picker" placeholder="Search picker..."
                            validate={composeValidators(rules.required)}
                        />
                    </div> */}

                    <div>
                        <BasketSelection style={{ maxWidth: "400px" }}
                            mode="multiple" name="dispatch_baskets"
                            preload={{ category: 'dispatch', $or: [{ order_id: null }, { order_id: Number(order._id) } ]  }}
                            filter={{ category: 'dispatch', order_id: null }}
                            label="Select dispatch baskets" placeholder="Search baskets..." validate={composeValidators(rules.required)} />
                    </div>


                    <div style={{ height: '30px' }} />
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white-outline" disabled={submitting}>Cancel</Button></Col>
                        <Col><Button loading={submitting} htmlType="submit">Update Order Status</Button></Col>
                        {/* <Col><Button loading={submitting} disabled={!valid || !dirty} htmlType="submit">Update Order Status</Button></Col> */}
                    </Row>

                </>)
            }}
        />

    </>)
}
Status_Ready_To_Dispatch.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
