import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Drawer, Button, Heading, Icon, Loader, Table, DevBlock } from 'Common/components'
import { message, Row, Col, Alert } from 'antd';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { orderStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import RenderInvoice from './RenderInvoice'
import RenderHistory from './RenderHistory'
import CustomerInformation from './CustomerInformation'
import ActivityInfo from './ActivityInfo'
import { utcToDate } from 'Common/scripts/Functions'
import StatusUpdater from './StatusUpdater'
import { connect } from 'react-redux'
import ServicesDetails from './ServicesDetails';
import { checkRights } from 'Common/scripts/Security';
import moment from 'moment';

const RECORD = loader('src/graphqls/orders/order.graphql');
const CANCEL_ORDER = loader('src/graphqls/orders/cancel.graphql');
const UPDATE_ORDER = loader('src/graphqls/orders/update_order.graphql');


const _OrderDetails = ({ order, loadingStatus, onZoneMatch, statusUpdator, previewOnly, user, rights }) => {
    if (!user) return <Alert message={"User not found"} type="error" showIcon={true} />
    if (order.error) return <Alert message={order.error.message} type="error" showIcon={true} />
    if (!order || !order._id) return <Alert message={'Invalid order'} type="error" showIcon={true} />

    let time_range_utc = null;
    if (order) {
        time_range_utc = JSON.parse(order.delivery_slot.time_range_utc);
        time_range_utc = time_range_utc.map(itm => moment(itm, "DD-MM-YYYYTHH:mm"));
    }

    return (<>
        <Row>
            <Col flex="auto">
                <RenderInvoice order={order} statusUpdator={statusUpdator} previewOnly={previewOnly} />
                <RenderHistory order={order} previewOnly={previewOnly} />
            </Col>

            <Col flex="30%">
                <div className="grid-block">
                    {(!previewOnly && checkRights(rights, "orders-update-status", false)) &&  <StatusUpdater order={{ ...order }} />}
                    {(previewOnly || !checkRights(rights, "orders-update-status", false)) && <div style={{ fontSize: "24px" }}>{orderStatus().find(o => o._id == order.status)?.title}</div>}
                </div>

                <ActivityInfo order={order} />
                <CustomerInformation order={order} previewOnly={previewOnly} onZoneMatch={onZoneMatch} />

                <div className="grid-block">
                    <Heading>Delivery Schedule</Heading>
                    <p style={{ padding: "0 10px", marginTop: "-10px", fontWeight: "bold" }} align="center">
                        {utcToDate(order.delivery_slot.date).format("ddd, MMM Do YYYY")}
                        <br />{time_range_utc[0].format("hh:mm A")} to {time_range_utc[1].format("hh:mm A")}
                        {/* {convertTo12Hrs(order.delivery_slot.start_time)} to {convertTo12Hrs(order.delivery_slot.end_time)} */}
                        {/* On {order.delivery_slot.day} between {order.delivery_slot.start_time} ~ {order.delivery_slot.end_time} */}
                    </p>
                    {(order.note && order?.note?.length > 1) &&
                        <Alert message="Client Notes" description={order.note} type="info" showIcon />
                    }
                </div>

                {user.type == "super-admin" && <ServicesDetails services={order.invoice.service_charges} />}

            </Col>
        </Row>

        {/* {user.type == "super-admin" && <div>
            <h1>Service Charges info</h1>
            <DevBlock obj={order.invoice.service_charges} />
        </div>} */}

    </>)
}
export const OrderDetails = connect(({ grocer_storeadmin: { user, rights, store } }) => {
    return { user, store, rights };
})(_OrderDetails);



class OrderDetailsWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            order: false,
            loadingStatus: false,
            loadingEditNode: props.loadingEditNode,
            zone: null,
            showStatusPop: false
        }
        // this.onUpdateStatus = this.onUpdateStatus.bind(this);
        this.onStatusConfirmationClose = this.onStatusConfirmationClose.bind(this);
    }

    static getDerivedStateFromProps(props, state) { 
        if (props.loadingEditNode !== state.loadingEditNode) {
            return {
                ...state,
                loadingEditNode: props.loadingEditNode,
            };
        }
        return { ...state};
    }

    onStatusConfirmationClose = () => {
        console.log("onStatusConfirmationClose()")
        this.setState({ showStatusPop: !this.state.showStatusPop })
    }
    
    render() {
        const { onClose, showform, order } = this.props;
        const { loading, loadingEditNode, loadingStatus, showStatusPop } = this.state;

        return (<>
            <Drawer height={"100%"} destroyOnClose maskClosable={false} placement="top"
                loading={loadingEditNode}
                onClose={onClose}
                visible={showform}
                bodyStyle={{ backgroundColor: "#f0f2f5", padding: "5px" }}
                title={`Order Details : ${order && order.serial}`}
            >
                {this.props.showform && <>
                    {(!order || !order._id) && <Loader loading={true} center />}

                    {order && <OrderDetails {...this.props} 
                        // onUpdateStatus={this.onUpdateStatus} 
                        loadingStatus={loadingStatus} onZoneMatch={(zone) => this.setState({ zone })} 
                    />}
                </>}

                {/* {(this.props.showform && (!order || !order._id)) && <Loader loading={true} />} */}

                {/* {(this.props.showform && order) && <>
                    <OrderDetails {...this.props} 
                    // onUpdateStatus={this.onUpdateStatus} 
                    loadingStatus={loadingStatus} onZoneMatch={(zone) => this.setState({ zone })} />
                </>} */}

            </Drawer>

        </>)

    }

}
OrderDetailsWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    previewOnly: PropTypes.bool,
    order: PropTypes.object,
}

const WithApollo = compose(
    graphql(CANCEL_ORDER, {
        props: ({ mutate }) => ({
            cancelOrder: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    graphql(UPDATE_ORDER, {
        props: ({ mutate }) => ({
            updateOrder: (args) => mutate({
                variables: { input: args }
            }),
        })
    }),

)(OrderDetailsWrapper);


const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ order, match }) => {
            return { variables: { id: order._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, order, error } = data;
            
            if (error) console.log(__error("error"), error);
            return { loadingEditNode:loading, order, queryErrors: error, }
        },
    }),
)(WithApollo);


const Wrapper = props => (props.order && props.order._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />

const mapStateToProps = ({ grocer_storeadmin: { user, store } }) => {
    return { user, store };
}
export default connect(mapStateToProps)(Wrapper);

// export default Wrapper;
