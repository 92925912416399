import React, { Component } from 'react'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';

import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { Popconfirm, Alert, message, Checkbox, Select, Option } from 'antd';
import { Icon, Button, IconButton, Table, DevBlock, PageHeader } from 'Common/components';
import { UserRightsByCat } from 'configs';

const LIST_ROLES = loader('src/graphqls/user_role/userRoles.graphql');
const USER_ROLE = loader('src/graphqls/user_role/userRole.graphql');
const EDIT_USER_ROLE = loader('src/graphqls/user_role/edit.graphql');

const columns = [
    { title: 'Role Category', dataIndex: 'title' },
];


export class RolesManagementList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstRun: 1,
            loading: true,
            selected_account_type: null, selected_rights: [],
            UserList: null,
        };
        this.mounted = false;
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.getUserRole = this.getUserRole.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // console.log("----------------------------------------------------------componentWillReceiveProps()", nextProps);
        const { loading, userRoles } = nextProps;
        if (!loading && userRoles && this.state.firstRun === 1) {
            this.setState({
                loading: false,
                firstRun: 0,
                UserList: userRoles,
                selected_account_type: userRoles[0]._id,
                selected_rights: userRoles[0].rights ? userRoles[0].rights.split(',') : []
            })
        }
    }

    expandedRowRender = (row, index, indent, expanded) => {
        if (!expanded) return null;
        const { selected_rights } = this.state;

        // let _rules = UserRightsByCat.find(el => el.type==row.acc_type);
        let _rules = UserRightsByCat[index].rules;
        let _id = UserRightsByCat[index]._id;

        const inner_columns = [
            {
                title: '',
                render: (txt, rec, i) => <Checkbox checked={selected_rights.indexOf(rec.key) > -1} onChange={this.onCheckboxChange.bind(this, rec)} />, width: '30px'
                // render: (txt, rec, i) => <Checkbox onChange={this.onCheckboxChange.bind(this, rec)} />, width:'30px'
            },
            { title: "Title", dataIndex: "title", dataIndex: 'title' },
        ];

        return <Table size="small" showHeader={false}
            columns={inner_columns}
            dataSource={_rules}
            pagination={false}
            rowKey={ (data) => _id++ }
        />;
    };

    onCheckboxChange = (rec, e) => {
        let isChecked = e.target.checked;
        let selected_rights = this.state.selected_rights;
        let arrindex = selected_rights.indexOf(rec.key);
        if (isChecked) selected_rights.push(rec.key)
        if (!isChecked) selected_rights.splice(arrindex, 1);
        this.setState({ selected_rights: selected_rights })
    }

    onSaveClicked() {
        if (!this.state.selected_account_type || this.state.selected_account_type == null) return;
        this.setState({ loading: true });

        this.props.editUserRole({
            _id: this.state.selected_account_type,
            rights: this.state.selected_rights.join(),
            // rights:JSON.stringify(this.state.selected_rights||[]),
        }).then(r => {
            message.success("Updated")
            this.setState({ loading: false });
        })
    }

    getUserRole(selected_account_type) {
        this.setState({ loading: true, selected_rights: [], selected_account_type: selected_account_type });

        console.log(`getUserRole(selected_account_type)`);
        console.log(this.props);

        let error = false;
        
        this.props.client.query({ query: USER_ROLE, variables: { _id: selected_account_type } }).then(e => {
            if (e.error) {
                console.log("ERROR: ", e);    error = e.error.message;
            }
            if (e.errors) {
                console.log("ERROR: ", e);    error = e.errors[0].message;
            }
            
            if (error) {
                message.error(__error("ERROR "), error);
                this.setState({ loading: false });
                return;
            }

            // this.setState({ loading:false });
            let rights = e.data.userRole.rights ? e.data.userRole.rights.split(',') : [];
            this.setState({ loading: false, selected_rights: rights });
            
        }).catch(err => {
            console.log(__error("API Call ERROR: USER_ROLE : "), err);
            this.setState({ loading: false })
        })

    }

    renderHeader() {
        const { selected_account_type, UserList } = this.state;
        if (!UserList) return null;

        return (
            <Select onChange={this.getUserRole.bind(this)} value={selected_account_type} style={{ width: '200px' }}>
                {UserList.map((item, i) => {
                    return <Select.Option key={i} value={item._id}>{item.title}</Select.Option>
                })}
            </Select>
        )
    }


    render() {
        const { userRoles, queryErrors } = this.props;
        const { loading, error } = this.state;
        // if (loading) return <Loader loading={loading} />
        
        return (<>
            <PageHeader title="Roles Management" />
            { error && <Alert message="Error" type="error" description={error} onClose={() => this.setState({ error: false })} showIcon closable /> }

            <Table
                title={() => this.renderHeader()}
                footer={() => <Button onClick={this.onSaveClicked}>Save</Button>}
                loading={loading}
                columns={columns}
                dataSource={UserRightsByCat || null}
                pagination={false}
                expandedRowRender={this.expandedRowRender}
            />

            <DevBlock obj={{ account:this.state.selected_account_type, rights: this.state.selected_rights}} />

        </>)
    }
}

RolesManagementList.propTypes = {
    // prop: PropTypes.type.isRequired
}

const WithApollo = compose(
    graphql(LIST_ROLES, {
        options: props => {
            return { variables: {} };
        },
        props: ({ ownProps, data }) => {
            const { loading, userRoles, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, userRoles, queryErrors: error }
        }
    }),

    graphql(EDIT_USER_ROLE, {
        props: ({ mutate }) => ({
            editUserRole: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(RolesManagementList);

export default withApollo(WithApollo);