import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import _ from 'lodash'

const RECORDS = loader('src/graphqls/stores/stores.graphql');

const SelectStoreComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});
    // const [showList, setShowList] = React.useState(false);
    // const toggleDrawer = args => setShowList(!showList);
    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        return () => console.log;
    }, [props.preload])


    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
    
        var filter;
        if (_.isString(value)) {
            setState({ kw: value, loading: true })
            filter = JSON.stringify({ ...props.filter, search: { keywords: value } });
        }
        else if (value) {
            setState({ loading: true })
            filter = JSON.stringify({ ...props.preload });
        }

        // console.log("filter: ", filter)

        props.client.query({ query: RECORDS, variables: { filter } }).then(e => {
            const results = e.data.stores;

            if (results.error) {
                console.log(__error("ERROR "), e);
                message.error(results.error.message);
                setState({ kw: value, loading: false })
                return;
            }

            if (results) setList(results.map(o => ({ _id: o._id, title: o.title, raw: o })) )
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: stores : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField validate={props.validate}
            type={props.mode || 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder={props.placeholder || "Search stores"}
            addNull={false}
            compact={props.compact}
            size={props.size}
            style={{ width: props.width || "100%", ...props.style }}
            allowClear={props.allowClear}
            // keyMap="value=>text"
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
SelectStoreComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    filter: PropTypes.object,
}

export const SelectStore = withApollo(SelectStoreComp)
export default SelectStore;
