import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Popconfirm, Modal } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, Heading, Icon, DevBlock, Avatar, Loader } from 'Common/components'
import { string_to_slug } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup, UploadField } from 'Common/components/Form'
import { generalStatus, discountTypes, publishStatus, discountRequirements } from 'configs/constants';

const RECORD = loader('src/graphqls/brand/brand.graphql');
const RECORD_EDIT = loader('src/graphqls/brand/editBrand.graphql');
const RECORD_ADD = loader('src/graphqls/brand/addBrand.graphql');
const RECORD_DELETE = loader('src/graphqls/brand/deleteBrand.graphql');
const REMOVE_IMG = loader('src/graphqls/brand/deleteBrandImg.graphql');



const BrandForm = props => {
    const [busy, setBusy] = React.useState(false);
    const { loading, brand } = props;

    let _fields = { status:"published", ...brand};
    if (_fields._id) {
        _fields.activation_date = _fields.activation_date ? moment(_fields.activation_date) : _fields.activation_date;
        _fields.deactivation_date = _fields.deactivation_date ? moment(_fields.deactivation_date) : _fields.deactivation_date;
    }

    const [fields, setFields] = React.useState(_fields);

    React.useEffect(() => {
        setFields({ ...fields, ...brand})
    }, [brand])

    const updateFields = (__fileds) => {
        // _fields.activation_date = _fields.activation_date ? moment(_fields.activation_date) : _fields.activation_date;
        // _fields.deactivation_date = _fields.deactivation_date ? moment(_fields.deactivation_date) : _fields.deactivation_date;
        setFields({
            ...__fileds,
            activation_date: __fileds.activation_date ? moment(__fileds.activation_date) : __fileds.activation_date,
            deactivation_date: __fileds.deactivation_date ? moment(__fileds.deactivation_date) : __fileds.deactivation_date
        })
        document.getElementById('BrandForm').dispatchEvent(new Event('reset', { cancelable: true }))
    } 

    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));


    const onSubmit = values => {
        const { onClose, editBrand, addBrand } = props;
        const _id = fields ? fields._id : false;
        const _setFields = setFields;

        setBusy(true);

        let filteredValues = {
            status: values.status,

            title: values.title,
            description: values.description,

            slug: values.slug,
            seo_title: values.seo_title,
            seo_desc: values.seo_desc,
            // img: values.img,
            // img_thumb: values.img_thumb
        };

        // if (_id && values.items) {
        //     filteredValues = Object.assign(filteredValues, {
        //         items: values.items ? values.items.map(row => ({ id: row.id, img: row.img, thumb: row.thumb, url: row.url })) : null,
        //     })
        // }

       
        if (_id) {
            editBrand({ ...filteredValues, _id }).then((e) => {
                setBusy(false)
                if (e.data.editBrand.error) {
                    let err = e.data.editBrand.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                onClose(e.data.editBrand);
            }).catch(error => {
                setBusy(false)
                console.log(error);
                message.error("Query Error");
            });
        } else {
            addBrand(filteredValues).then((e) => {
                setBusy(false);
                if (e.data.addBrand.error) {
                    let err = e.data.addBrand.error;
                    message.error(err.message);
                    return false;
                }
                message.success("Success");
                updateFields({ ...e.data.addBrand });
            }).catch(error => {
                setBusy(false);
                console.log(error);
                message.error("Query Error");
            });
        }

    }

    const onFileDeleteClicked = async (fileInfo) => {
        let id = fields._id;

        setBusy(true);

        return props.deleteBrandImg(id).then((e) => {
            setBusy(false)
            if (e.data.deleteBrandImg.error) {
                message.error(e.data.deleteBrandImg.error.message);
                return false;
            }

            if (!e.data.deleteBrandImg || e.data.deleteBrandImg.error) {
                if (!e.data.deleteBrandImg) { message.error("No response"); }
                if (!e.data.deleteBrandImg.error) { message.error(e.data.deleteBrandImg.error.message); }
                return false;
            }

            // if (callback) callback(e.data.deleteBrandImg.items);

            return e.data.deleteBrandImg;
        }).catch(error => {
            setBusy(false)
            console.log(error);
            return { error: { message: "Query Error" } };
        });

    }


    return (
        <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
            bodyStyle={{ padding: "10px" }}
            onClose={props.onClose}
            visible={props.showform}
            footer={<>
                <span></span>
                <Button loading={busy} type="primary" onClick={() => {
                    document.getElementById('BrandForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button>
            </>}
            title={`${fields && fields._id ? 'Edit' : 'Add'} Brand`}
        ><>

            {loading && <><Loader loading={true} /></>}

            {!loading && 
                    <FormComponent onSubmit={onSubmit} id='BrandForm' loading={busy} fields={fields} enterSubmit={true} debug={true}
                    mutators={{
                        addItems: (newValueArray, state, tools) => {
                            tools.changeValue(state, 'items', () => newValueArray[0])
                        },
                        removeItems: (newValueArray, state, tools) => {
                            tools.changeValue(state, 'items', () => newValueArray[0])
                        },
                    }}
                    form_render={formProps => {
                        const { values } = formProps;

                        return (<>
                            <FormField type="select" name="status" label="Status" className={values.status == 'published' ? "active" : "inactive"} data={publishStatus} validate={rules.required} />

                            <FormField type="text" name="title" label="Brand Name" placeholder="Brand title" validate={composeValidators(rules.required, rules.minChar(2))} />
                            <FormField type="textarea" name="description" label="Description" placeholder="soemthing about the brand" />

                            {values && values._id && <div className="grid-block">
                                <Heading>Brand Logo</Heading>
                                <UploadField type="picture" listType="picture-card" limit={1}
                                    onDeleteClicked={onFileDeleteClicked}
                                    // label="Product default picture"
                                    buttonLabel="Add Image"
                                    name="img"
                                    fileList={values.img ? [{ srcUrl: `${process.env.REACT_APP_DATA_URL}/${values.img}`, thumbUrl: `${process.env.REACT_APP_DATA_URL}/${values.img_thumb}` }] : null}
                                    data={{ fieldsJson: JSON.stringify({ _id: values._id }), action: "brand.img" }}
                                />
                            </div>}

                            <div className="grid-block">
                                <Heading>SEO Data</Heading>
                                <FormField onChange={filterSlug} type="text" name="slug" _placeholder="Brand slug" label="slug (no space)" />
                                <FormField type="text" name="seo_title" label="SEO Title" _placeholder="SEO title" />
                                <FormField type="text" name="seo_desc" label="SEO Description" _placeholder="description..." />
                            </div>

                        </>)
                    }}
                />
            }

        </></Drawer>
    )
}

BrandForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editBrand: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addBrand: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteBrand: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(REMOVE_IMG, {
        props: ({ mutate }) => ({
            deleteBrandImg: (id) => mutate({
                variables: { id }
            }),
        })
    }),
)(BrandForm);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, brand, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, brand, queryErrors: error, }
        },
    }),
)(WithApollo);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;

