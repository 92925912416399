export const REQUEST_BRANCH = 'REQUEST_BRANCH';
export const RECEIVE_BRANCH = 'RECEIVE_BRANCH';
// export const SET_BRANCH = 'SET_BRANCH';

// export const ADD_PRODUCT = 'ADD_PRODUCT';

export const requestBranch = () => ({ type: REQUEST_BRANCH });
export const receiveBranch = payload => ({ type: RECEIVE_BRANCH, payload });
// export const setBranch = payload => ({ type: SET_BRANCH, payload });

// export const addProduct = prod => ({ type: ADD_PRODUCT, payload:prod });


// const addProduct = (state, payload) => {
//     return { ...state, ...payload }
// }
