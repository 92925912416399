import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col } from 'antd';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { Drawer, Button, SelectStore, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { rules, composeValidators, FormField, FormComponent, UploadField, SubmitButton } from 'Common/components/Form'
import { generalStatus, genders } from 'configs/constants';
import { generateApiKey } from 'generate-api-key';


const RECORD = loader('src/graphqls/user/user.graphql');
const RECORD_EDIT = loader('src/graphqls/user/edit.graphql');
const RECORD_ADD = loader('src/graphqls/user/add.graphql');
const RECORD_DELETE = loader('src/graphqls/user/delete.graphql');
const DELETE_USER_AVATAR = loader('src/graphqls/user/deleteUserAvatar.graphql');

const userTypes = [
    { _id: "system-admin", title: "System Admin" },
    { _id: "restfull-api-user", title: "Restfull API User" },
]

const defaultValues = {
    status: "enabled",
    gender: "male"
}

function generateUUID() {
    var d = new Date().getTime();

    let _key = generateApiKey({
        method: 'string',
        min: 32, max: 128,
        prefix: `GROCER-API`,
        // pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    });

    return `${_key}.${d}`
}



class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            busy: false, 
            error: null, 
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async values => {
        const { onClose, editUser, addUser, user } = this.props;

        let filteredValues = {
            type: values.type,
            fname: String(values.fname).trim(),
            lname: String(values.lname).trim(),
            email: String(values.email).trim(),
            status: values.status,
            gender: values.gender,
            note: values.note,
            phone: String(values.phone).trim(),
        };

        if (values.password && values.type != "restfull-api-user" && (values.password != values.confirm_pwd)){
            message.error("Password missmatch");
            return false;
        }
        if (values.password) Object.assign(filteredValues, { password: values.password });

        if (values.type == "restfull-api-user" && values.store_id){
            Object.assign(filteredValues, { store_id: values.store_id })
        }


        // this.setState({ busy: true });

        var results;
        if (user && user._id) {
            results = await editUser({ ...filteredValues, _id: user._id }).catch(error => {
                console.log(__error("Query error: "), error);
                return { error: { message:"Query Error" }}
            });
        } else {
            results = await addUser(filteredValues).catch(error => {
                console.log(__error("Query error: "), error);
                return { error: { message: "Query Error" } }
            });
        }

        
        if (results.error){
            // this.setState({ busy: false })
            message.error("Query Error");
            return false;
        }

        onClose(results);       
        return true;

    }

    render() {
        const { onClose, showform, user, loading } = this.props;
        const { busy } = this.state;

        return (
            <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
                bodyStyle={{ padding: "0px" }}
                onClose={onClose}
                visible={showform}
                // footer={<>
                //     <span></span>
                //     <SubmitButton loading={busy || loading} formid="UserForm">Save</SubmitButton>
                // </>}
                title={`${user && user._id ? 'Edit' : 'Add'} Staff`}
            ><>
                {loading && <Loader loading={true} center />}

                {(showform && !loading) && 
                    <FormComponent onSubmit={this.onSubmit} id='UserForm' fields={{ ...defaultValues, ...user }} enterSubmit debug={true}
                        mutators={{
                            generateKey: (newValueArray, state, tools) => {
                                tools.changeValue(state, 'password', () => generateUUID())
                            },
                        }}
                        form_render={formProps => {
                            const { values, form, submitting } = formProps;

                            return (<>
                                <FormField type="select" name="status" label="Status" className={values.status == 'enabled' ? "active" : "inactive"} data={generalStatus} validate={rules.required} />

                                <Row>
                                    <Col flex="50%"><FormField type="select" name="type" label="Type" 
                                        data={userTypes} 
                                        validate={rules.required} /></Col>
                                    <Col flex="auto"><FormField type="select" name="gender" label="Gender" data={genders} validate={rules.required} /></Col>
                                </Row>

                                {values.type == "restfull-api-user" && <>
                                    <SelectStore name="store_id" validate={rules.required} preload={values.store_id ? { _id: Number(values.store_id) } : false} />
                                </>}

                                <Row>
                                    <Col flex="50%"><FormField type="text" name="fname" label="First Name" validate={rules.required} /></Col>
                                    <Col flex="auto"><FormField type="text" name="lname" label="Last Name" /></Col>
                                </Row>

                                <FormField type="text" name="phone" label="Phone" placeholder="Mobile number" validate={composeValidators(rules.required, rules.minChar(4))} />

                                {values.type == "restfull-api-user" && <>
                                    <FormField type="text" name="email" label="App ID" validate={composeValidators(rules.required)} />
                                    <FormField type="text" name="password" label="Api Key" validate={rules.required} />
                                    <Col align="right"><Button onClick={() => form.mutators.generateKey()}>Generate</Button></Col>
                                </>}
                                
                                {values.type != "restfull-api-user" && <>

                                    <FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} />
                                    <Row>
                                        <Col flex="auto"><FormField type={"password"} name="password" label={"Password"} /></Col>
                                        <Col flex="49%"><FormField type={"password"} name="confirm_pwd" label={"Confirm Password"} /></Col>
                                    </Row>
                                    
                                </>}

                                <FormField type="textarea" name="note" label="Note" placeholder="Notes" />

                                <Col align="right"><SubmitButton loading={submitting} formid="UserForm">Save</SubmitButton></Col>
                            </>)
                        }}
                    />
                }
            </></Drawer>
        )
    }
}
UserForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),
    graphql(DELETE_USER_AVATAR, {
        props: ({ mutate }) => ({
            deleteUserAvatar: (id, fields) => mutate({
                variables: { id, fields }
            }),
        })
    }),

)(UserForm);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return { variables: { id: fields._id } };
        },
        props: ({ ownProps, data }) => {
            const { loading, user, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, user, queryErrors: error, }
        },
    }),
)(WithApollo);


export const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;
// export default WithApollo;