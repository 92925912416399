import React from 'react'
import PropTypes from 'prop-types';
import { Heading, Icon, IconButton, GMap, DevBlock } from 'Common/components'
import { Alert } from 'antd';


const ServicesDetails = ({ services }) => {
    if (!services || !services[0]) return <Alert message="No services found!" showIcon type='error' />;

    const delivery_service = services.find(o => o.type =="delivery")
    if (!delivery_service) return <Alert message="No delivery service found!" showIcon type='error' />;

    var markers = []
    if (delivery_service?.grocerLocation?.geo_point?.coordinates) markers.push({
            title: "Grocer Start Point",
            position: { lat: delivery_service.grocerLocation.geo_point.coordinates[0], lng: delivery_service.grocerLocation.geo_point.coordinates[1] }
        })
    
    if (delivery_service?.storeLocation?.geo_point?.coordinates) markers.push({
            title: "Store Pickup",
            position: { lat: delivery_service.storeLocation.geo_point.coordinates[0], lng: delivery_service.storeLocation.geo_point.coordinates[1] }
        })

    if (delivery_service?.delivery_coordinates?.geo_point?.coordinates) markers.push({
            title: "Delivery Point",
            position: { lat: delivery_service.delivery_coordinates.geo_point.coordinates[0], lng: delivery_service.delivery_coordinates.geo_point.coordinates[1] }
        })

    if (!markers || markers.length < 1) return <Alert message="No markers found!" showIcon type='error' />;

    return (<>
        <div className="grid-block">
            <Heading>Service Charges Details</Heading>
            {services.map((service, i) => {
                return <div key={i} style={{ marginBottom:"5px", marginBottom:"1px solid #EEE"}}>
                    <div style={{ fontSize:"18px", fontWeight:"bold" }}>{service.type}</div>
                    <div>Distance: {service.distance} km</div>
                    <div>Charge/km: {service.charge_per_km}</div>
                    <div>Amount: {service.amount}</div>
                    <div>Expected duration: {service.duration.min < 60 ? <span>{service.duration.min} minutes</span> : <span>{service.duration.hrs} hours</span>}</div>

                    <div>Grocer Start Point: {service.grocerLocation.title}</div>
                    <div>Store Pickup: {service.storeLocation.title}</div>
                    <div>Delivery Point: {service.delivery_coordinates.title}</div>
                </div>
            })}

            <div style={{ width: '100%', height: '400px', border:"0px solid black" }}>
                <GMap
                    skipInfo
                    encodedPolyline={delivery_service.polyline.encodedPolyline}
                    markers={markers}
                    zoom={11}
                    center={{ lat: delivery_service.delivery_coordinates.geo_point.coordinates[0], lng: delivery_service.delivery_coordinates.geo_point.coordinates[1] }}
                />
            </div>

        </div>
    </>)
}
ServicesDetails.propTypes = {
    services: PropTypes.array.isRequired,
}

export default ServicesDetails
