import { __error } from 'Common/scripts/consoleHelper';
import { UPDATE_SETTINGS, RESET_SETTINGS, LOGIN_USER, LOGOUT_USER, SET_LOADING, UPDATE_STORE, UPDATE_USER } from '../grocer_storeadmin.actions.js';

const INITIAL_STATE = {
  settings:{
    deliveryCharges: 0,
    currency: '',
    min_order_amount: 0,
    max_order_amount: 0,
    navCollapsed: false
  },
  user: null,
  rights: null,
  store: null,
  loading_settings: false,
}


const updateSettings = (state, payload) => {
  // console.log("Redux > updateSettings()", payload)
  return { ...state, settings: { ...state.settings, ...payload } }
}
const resetSettings = (state, payload) => {
  // console.log("Redux > resetSettings()", payload)
  return { ...state, settings: { ...INITIAL_STATE.settings, ...payload } }
}

const setLoading = (state, payload) => {
  // console.log("Redux > setLoading()", payload)
  return { ...state, loading_settings:payload }
}

const loginUser = (state, payload) => {
  if (!payload.token){
    alert("User token not found!");
    return INITIAL_STATE;
  }
  // return { ...state, user: payload.user, rights: payload.rights, token: payload.token }
  return { ...INITIAL_STATE, 
    user: payload.user, 
    rights: payload.rights, 
    store: payload.store, 
    token: payload.token 
  }
}

const handel_storeChange = (state, payload) => {
  // console.log("handel_storeChange > state.store : ", state.store)
  // console.log("handel_storeChange > payload : ", payload)
  return { ...state, store: { ...state.store, ...payload } };
}

const handel_userChange = (state, payload) => {
  let updates = { ...state, user: { ...state.user } }
  if (payload.user) updates.user = { ...updates.user, ...payload.user };
  if (payload.rights) updates.rights = payload.rights;

  return updates;

  // return { ...state, user: { ...state.user, ...payload } };
}


export const grocer_storeadmin_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case SET_LOADING:
      return setLoading(state, payload);
      break;
    case UPDATE_SETTINGS:
      return updateSettings(state, payload);
      break;
    case RESET_SETTINGS:
      return resetSettings(state, payload);
      break;
    case LOGIN_USER:
      return loginUser(state, payload);
      break;
    case LOGOUT_USER:
      return { ...INITIAL_STATE }; // logoutUser(state, payload);
      break;
      case UPDATE_STORE:
        return handel_storeChange(state, payload)
        break;
        case UPDATE_USER:
      return handel_userChange(state, payload)
      break;
    default:
      return state;
  }
}

export default { grocer_storeadmin:grocer_storeadmin_reducer };
