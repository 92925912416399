import React from 'react'
import { Divider } from 'antd'

export const Heading = props => {
    let style = {
        ...props.style,
    }
    if (props.compact) Object.assign(style, { margin:0 })
    if (props.color) Object.assign(style, { color: props.color })

    return (
        <Divider className={`heading ${props.type}`} orientation={props.orientation || "left"} style={style}><span style={{ color:props.color }}>{props.children}</span></Divider>
    )
}
